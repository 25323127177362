import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import {
  Button,
  Form,
  Toast,
  Row,
  Col,
  FormControl,
  Tab,
  Tabs,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../tables/table";
import ReactPaginate from "react-paginate";

import {
  getMethod,
  postMethod,
  getTableData,
  tableDefault,
  AccessDenied,
  isNotLoading,
} from "../../Library";

import { ModalPopMd } from "../modals";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEye,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../Dropdown";

export default function ChallengeTable(props) {
  const program_key = "program";
  const quick_key = "quick";
  const challenge_key = "challenge";

  const [key, setKey] = useState(program_key);
  const [tableList, setTableList] = useState([]);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [fun, setFun] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [filterList, setFilterList] = useState();
  const [filterArr, setFilterArr] = useState({
    program_name: "",
    program_category: "",
    goal: "",
    activity_level: "",
  });
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedWeeks, setSelectedWeeks] = useState();
  const [selectedID, setSelectedID] = useState();
  const [duplicateID, setDuplicateID] = useState();
  const [weekOptions, setWeekOptions] = useState();
  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [search, setSearch] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };
  const closeModal = () => {
    setModalShow(false);
    setSelectedWeeks();
    setSelectedOptions();
    setDuplicateID();
    setSelectedID();
  };

  const openModal = (e) => {
    const id = e.target.getAttribute("dataid");
    setDuplicateID(id);
    setFun(!fun);
  };
  useEffect(() => {
    ProgramWeeks();
  }, [selectedID]);

  useEffect(() => {
    console.log(selectedWeeks, "weeels");
  }, [selectedWeeks]);

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        arr.push(item.value);
      });
    return arr;
  }
  
  const getSelections = (data) => {
    setSelectedOptions(data);
    setSelectedWeeks(convert(data));
  };
  

  const ProgramWeeks = async () => {
    const data = {
      url: "program_weeks",
      body: {
        program_id: selectedID,
      },
    };
    const newData = await postMethod(data);
    
    if (newData.status == 1) {
      isNotLoading();
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setWeekOptions(arr);
    } else {
      isNotLoading();

      setWeekOptions([]);
    }
  };

  const markPublished = (e) => {
    let id = e.target.getAttribute("dataid");
let is_active =e.target.getAttribute("dataStatus");
    const data = {
      url: `change_publish_unpublish_workout`,
      body: {
        workout_master_id: parseInt(id),

      },
    };
    confirmAlert({
      title: `${is_active == "published" ? "Mark as unpublished" : "  Mark as Published"} `,
      message: `${is_active == "published" ? "Are you sure you want to mark this workout as unpublished" : "  Are you sure you want unmark this workout as published"} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            showToastFn(newData.message)
            if (newData.status == 1) {
                            // getTableListCategory();
                            isNotLoading();
                            getTableFilterList()

            }else{
              isNotLoading();
              getTableFilterList()
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };


  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const modalBody = () => {
    return (
      <>
        <div>
          <Form.Group>
            <Form.Label className="required">Choose Week(s)</Form.Label>
            <Dropdown
              getSelections={getSelections}
              name="week-list"
              options={weekOptions}
              isDisabled={false}
              value={selectedOptions}
            />

            {/* <Form.Control
              as="select"
              name="week"
              // value={filterArr.goal}
              onChange={getSelections}
            >
              <option value="">Select Week</option>
              {weekOptions &&
                weekOptions.length > 0 &&
                weekOptions.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
            </Form.Control> */}
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">Choose Program</Form.Label>
            <Form.Control
              as="select"
              name="program_id"
              value={selectedID}
              onChange={(e) => {
                setSelectedID(e.target.value);
              }}
            >
              <option value="" disabled>
                Select a Program
              </option>
              {filterList &&
                filterList?.all_program_name?.length > 0 &&
                filterList.all_program_name.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.program_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <small className="mt-3">
            Note: This will create a duplicate workout based on the above
            selected week(s) and program.
          </small>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={dupWorkout}
            disabled={
              !selectedOptions ||
              selectedOptions?.length == 0 ||
              !selectedID ||
              selectedID?.length == 0
            }
          >
            Submit
          </Button>
        </div>
      </>
    );
  };

  function btnFormatter(cell, row, rowIndex) {
    
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-workout/add-workout/`,
            state: {
              workout_master_id: row.id,
              workout_category_name: row.workout_category_name,
              editAccess: false,
              eye:true,
            },
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
        |
        <Link
          variant="link"
          to={{
            pathname: `/manage-workout/add-workout/`,
            state: {
              workout_master_id: row.id,
              workout_category_name: row.workout_category_name,
              edit: false,
              duplicate: false,
            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        |
        {/* {row.workout_category_name == "Program Based Workout" && ( */}
          <>
            <Button
              variant="link"
              dataid={row.id}
              onClick={(e) => {
                openModal(e);
                setSelectedID(row.program_ids[0]);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
            |
          </>
        {/* )} */}
        <Button variant="link" dataid={row.id} onClick={trashWorkout}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        |
        <Form.Check
          className="d-inline-block ml-3"
          type="switch"
          id={`toggle-switchs-${rowIndex}`}
          dataid={row.id}
          dataStatus={row.workout_master_entry_status}
          checked={row.workout_master_entry_status == "unpublished" ? false : true}
          onChange={ markPublished}
        />
      </>
    );
  }
useEffect(() => {
  dupWorkout()
}, [fun])

  const dupWorkout = async (e,row) => {
   console.log("hello",'row')
   if(fun){
    const data = {
      url: "duplicate_quick_challenge_workouts",
      body: {
        workout_master_id: parseInt(duplicateID),

      },
    };
    // console.log("dpdata ", data);
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      resetFilters();
    }
    else{
      isNotLoading();

    }
    setFun(false)
  }
  };

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "workout_delete",
      body: {
        id: id,
      },
    };
    // console.log("delete ", id);

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if (newData.status == 1){
            isNotLoading();
            showToastFn(newData.message);
            getTableList();
            }
            else{
              isNotLoading();
              showToastFn(newData.message);
 
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "workout_category_name", text: "Workout Category" },
    { dataField: "challenge_name", text: "Challenge Name" },
    { dataField: "challenge_start_date", text: "Start Date" },
    { dataField: "challenge_end_date", text: "End Date" },

    { dataField: "workout_master_entry_status", text: "Status" },
    {
      dataField: "id",
      text: "Action",
      formatter: btnFormatter,
      classes: "colNoWrap text-right",
    },
  ];

  const getTableList = async () => {
    let tableList = await getTableFilterList();
    if (tableList && tableList.workouts) {
      setTableList(tableList.workouts);
    }
    // console.log("loop running 1");
  };
  // const getTableDataList = async (url) => {
  //   isLoading();
  //   const data = {
  //     url: url,
  //     body: {
  //       length: 10,
  //       start: 0,
  //       draw: 10,
  //       // filter: filter,

  //     },
  //   };
  //   try {
  //     const newData = await postMethod(data);
  //     isNotLoading();
  //     if (newData.status == 1 && newData.data) {
  //       return newData.data;
  //     } else if (newData.status === false) {
  //       handleFalseStatus(newData);
  //     }
  //   } catch (err) {
  //     isNotLoading();
  //     handleCatchErr(err);
  //   }
  // };
  const getFilterList = async (filter) => {
    const data = {
      url: "workout_filter_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1 && newData.data) {
      isNotLoading();
      setFilterList(newData.data);
    }
    else{
      isNotLoading();

    }
  };

  const getTableFilterList = async () => {
    const filters = {
      program_name: filterArr.program_name ? filterArr.program_name : "",
      program_category: filterArr.program_category
        ? filterArr.program_category
        : "",
      goal: filterArr.goal ? filterArr.goal : "",
      activity_level: filterArr.activity_level ? filterArr.activity_level : "",
    };
    const data = {
      url: "workout_list",
      body: {
        workout_category_name: "Challenge Workout",
        search: { value: search },
        length: 10,
        start: offset == 0 ? 0 : (offset - 1) * 10,
        draw: 10,
        filter: filters,
      },
    };
    const newData = await postMethod(data);
    // showToastFn(newData.message);
    if (newData.status == 1) {
      if (newData.data.workouts) {
        isNotLoading();
        setTableList(newData.data.workouts);
        setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      } else {
        setTableList([]);
        isNotLoading();
      }
    }
    // console.log("loop running 2");
  };

  const handleFilters = async (e) => {
    const val = e.target.value;
    const name = e.target.name;
    setFilterArr((prev) => ({ ...prev, [name]: val }));
    setOffset(1);
    // setFilterArrUpdated(true);
  };

  const resetFilters = async (e) => {
    setFilterArr({
      program_name: "",
      program_category: "",
      goal: "",
      activity_level: "",
    });
    getTableFilterList();
    setOffset(1);
  };

  // function updateTableList() {
  //   if (filterArr) {
  //     // workout_category: filterArr.category ? [filterArr.category] : null,
  //     const filters = {
  //       program_name: filterArr.program_name ? filterArr.program_name : null,
  //       program_category: filterArr.program_category
  //         ? filterArr.program_category
  //         : null,
  //       goal: filterArr.goal ? filterArr.goal : null,
  //       activity_level: filterArr.activity_level
  //         ? filterArr.activity_level
  //         : null,
  //     };
  //     // let result = _.pickBy(
  //     //   filters,
  //     //   (v) => !_.isUndefined(v) && !_.isNull(v)
  //     // );
  //     getTableFilterList(filters);
  //   } else {
  //     // console.log("loop running 3");
  //     getTableFilterList();

  //   }
  // }

  // useEffect(() => {
  //   if (filterArrUpdated) {
  //     updateTableList();
  //   }
  // }, [setFilterArr, filterArr]);

  useEffect(() => {
    // props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // getTableList();
      getFilterList();
      // getWeekList();
      getTableFilterList();
    }
  }, [pageLoaded, setPageLoaded, filterArr, offset, search]);

  const tableFilters = () => {
    return <></>;
  };

  return (
 <div className="mt-3">
        <>
          {/* filter */}
          <div className="d-flex align-items-center flex-wrap">
            {/* <Form.Group controlId="program_name" className="col-3">
              <Form.Control
                as="select"
                name="program_name"
                value={filterArr.program_name}
                onChange={handleFilters}
              >
                <option value="">Filter By program name</option>
                {filterList &&
                  filterList?.program_name?.length > 0 &&
                  filterList.program_name.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group> */}

            {/* <Form.Group controlId="program_category" className="col-3">
              <Form.Control
                as="select"
                value={filterArr.program_category}
                name="program_category"
                onChange={handleFilters}
              >
                <option value="">Filter By program category</option>
                {filterList &&
                  filterList?.category_name?.length > 0 &&
                  filterList.category_name.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="goal" className="col-3">
              <Form.Control
                as="select"
                name="goal"
                value={filterArr.goal}
                onChange={handleFilters}
              >
                <option value="">Filter By Goal</option>
                {filterList &&
                  filterList?.goal?.length > 0 &&
                  filterList.goal.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="activity_level" className="col-3">
              <Form.Control
                as="select"
                name="activity_level"
                value={filterArr.activity_level}
                onChange={handleFilters}
              >
                <option value="">Filter By Activity Level</option>
                {filterList &&
                  filterList?.activity_level?.length > 0 &&
                  filterList.activity_level.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group> */}

            {/* <Row className="col-12 align-items-center justify-content-center mt-4">
              <Col xs="auto">
                <Button
                  type="reset"
                  variant="secondary"
                  className="mb-2 mr-3"
                  onClick={resetFilters}
                  disabled={
                    filterArr.activity_level != "" ||
                    filterArr.goal != "" ||
                    filterArr.program_category != "" ||
                    filterArr.program_name != ""
                      ? false
                      : true
                  }
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  className="mb-2"
                  onClick={getTableFilterList}
                  disabled={
                    filterArr.activity_level != "" ||
                    filterArr.goal != "" ||
                    filterArr.program_category != "" ||
                    filterArr.program_name != ""
                      ? false
                      : true
                  }
                >
                  Apply Filter
                </Button>
              </Col>
            </Row> */}
          </div>
          <div className="d-flex row ">
            <div className="col-6 mb-3">
              <InputGroup className="search-register-user mr-5">
                <FormControl
                  placeholder="Search"
                  aria-label="search"
                  aria-describedby="basic-addon1"
                  className="search-regUser-input search-border"
                  onChange={handleSearchInput}
                />
              </InputGroup>
            </div>
          </div>
          <Table
            data={tableList}
            columns={columns}
            filters={tableFilters}
            offset={offset}
            searchBar="false"
          />
          <div className="d-flex float-right justify-content-end">
            {/* {filter?null: */}
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              // onPageActive={onActivePage}
              className="pagination active"
              activeClassName={"active"}
            />

            {/* } */}
          </div>

          <ModalPopMd
            show={modalShow}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead="Duplicate Workout"
          />
        </>
     

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
      </div>
  );
}
