import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Toast,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import TableSelect from "../components/tables/tableselect";
import ModalPop from "../components/modals";
import TableLong from "../components/tables/tablelong";
import Table from "../components/tables/table";

import {
  postMethod,
  getTableDatawithFilter,
  AccessDenied,
  findErrors,
  isNotLoading,
  getMethod,
} from "../Library";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { getRoles } from "@testing-library/react";

export const UserBody = (props) => {
  const [validated, setValidated] = useState(false);

  const [emailOld, setEmailOld] = useState();
  const [emailEdited, setEmailEdited] = useState(false);
  const [roleList, setRoleList] = useState();

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    role_id: null,
  });

  function generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      editupdateUser();
    }
  };

  useEffect(() => {
    getRoleList()
  }, [])

  const getRoleList = async () => {
    const data = {
      url: `role_drop_down_list`,
      // body: {
      //   id: id,
      // },
    };

    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setRoleList(newData.data)
    }
    else {
      isNotLoading();
    }
  }

  const editupdateUser = async () => {
    const data = {
      url: props.editId ? "update_user" : "add_admin_user",
      body: formInputs,
    };

    let errors = true;

    if (props.editId) {
      let reqFields = _.pick(
        formInputs,
        "first_name",
        "mobile",
        "email",
        "role_id"
      );
      errors = findErrors(reqFields);
    } else {
      let reqFields = _.pick(
        formInputs,
        "first_name",
        "mobile",
        "email",
        "password",
        "role_id"
      );
      errors = findErrors(reqFields);
    }

    if (
      errors == false &&
      JSON.stringify(formInputs.mobile).replace(/"/g, "").length == 10
    ) {
      const newData = await postMethod(data);
      props.showToastFn(newData.message);

      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getLists();
        if (emailEdited) {
          localStorage.clear();
          window.location.href = "/";
        }
      } else {
        isNotLoading();
      }
    }
  };

  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let currEmail = JSON.parse(localStorage.getItem("user"));
    if (formInputs && formInputs.email && currEmail) {
      if (formInputs.email != emailOld && currEmail.email == emailOld) {
        setEmailEdited(true);
      }
    }
  }, [setFormInputs, formInputs]);

  const getEditData = async () => {
    if (props.editId) {
      const data = {
        url: "edit_user",
        body: {
          user_id: props.editId,
        },
      };
      const newData = await postMethod(data);

      if (newData.data && newData.data.user) {
        isNotLoading();
        setFormInputs(() => ({
          ...newData.data.user,
          user_id: props.editId,
          // password: generatePassword(),
        }));
        setEmailOld(newData.data.user.email);
      } else {
        isNotLoading();
      }
    }
  };
  const handlerRoleFilter = () => {

  }
  useEffect(() => {
    if (!props.editId) {
      setFormInputs((prev) => ({
        ...prev,
        password: generatePassword(),
      }));
    }
    getEditData();
  }, []);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group as={Row} controlId="first_name">
        <Form.Label column sm="3" className="required">
          Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="first_name"
            type="text"
            required
            placeholder="User Name"
            value={formInputs.first_name ? formInputs.first_name : ""}
            onChange={handleInput}
          />
        </Col>
      </Form.Group>

      {/* <Form.Group as={Row} controlId="last_name">
        <Form.Label column sm="3" className="required">
          Last Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="last_name"
            type="text"
            // value={values.assign_user}
            // onChange={handleInput}
          />
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} controlId="email">
        <Form.Label column sm="3" className="required">
          Email ID
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="email"
            type="email"
            placeholder="example@somemail.com"
            pattern="[a-zA-Z0-9!#$%^&*_+-.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            required
            value={formInputs.email ? formInputs.email : ""}
            onChange={handleInput}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="phone_no">
        <Form.Label column sm="3" className="required">
          Phone Number
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="mobile"
            type="tel"
            pattern="[0-9]{10}"
            placeholder="9876543210"
            required
            value={formInputs.mobile ? formInputs.mobile : ""}
            onChange={handleInput}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId="role_id">
        <Form.Label column sm="3" className="required">
          Role
        </Form.Label>

        <Col sm="9">
          <Form.Control
            as="select"
            name="role_id"
            value={formInputs.role_id ? formInputs.role_id : ''}
            onChange={handleInput}
            className
            required
          >
            <option value="">Select Role</option>
            {roleList &&
              roleList.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Form.Group>
      {!props.editId && (
        <Form.Group as={Row} controlId="password">
          <Form.Label column sm="3" className="required">
            Password
          </Form.Label>
          <Col sm="9">
            <Form.Control
              name="password"
              autoComplete="off"
              placeholder="Password"
              type="text"
              required
              value={formInputs.password ? formInputs.password : ""}
              onChange={handleInput}
            />
          </Col>
        </Form.Group>
      )}

      <div className="d-flex justify-content-end mt-5">
        <Button variant="secondary" onClick={props.onHide} className="mr-3">
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          {props.editId ? "Update" : "Save"} User
        </Button>
      </div>
    </Form>
  );
};

export default function NutritionistPage(props) {
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [userData, setUserData] = useState([]);
  // const [statusShow, statusShowFun] = useState(false);
  // const [getDetail, getDetailFun] = useState({});
  const [tableList, setTableList] = useState([]);
  const [filternameList, filternameListFun] = useState([]);
  const [assignuserList, assignuserListFun] = useState([]);
  const [assignuserListCpy, setAssignuserListCpy] = useState([]);
  const [assignuserFilters, setAssignuserFilters] = useState({
    is_active: "",
    program_name: "",
  });
  const [programList, setProgramList] = useState([]);
  const [nutId, setNutId] = useState("");
  const [nutri_name, filterNmae] = useState("");
  const [nutri_status, filterStatus] = useState("");
  const [selectedRow, setSelectedRow] = useState();
  const [editId, setEditId] = useState();
  const [key, setKey] = useState();
  const [modalHead, setModalHead] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [pageCount1, setPageCount1] = useState(0);
  const [offset1, setOffset1] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [perPage] = useState(10);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  function activeFormatter(cell, row) {
    return <span>{cell == "1" ? "Active" : "Inactive"}</span>;
  }

  const userColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "first_name", text: "Username" },
    { dataField: "email", text: "Email ID" },
    { dataField: "onboarded_date", text: "Onboarded Date" },
    { dataField: "program_name", text: "Program Name" },
    { dataField: "is_active", text: "Status", formatter: activeFormatter },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      userListFun("nofilter");
    }
  }, [
    pageLoaded,
    setPageLoaded,
    offset,
    searchValue,
    nutri_status,
    nutri_name,
  ]);

  const clearFilters = () => {
    setAssignuserFilters({
      is_active: "",
      program_name: "",
    });
  };

  const userListFun = async (data, name, status) => {
    let tableListData;
    if (name) {
      tableListData = await getTableDatawithFilters("nutritionist_list", name);
    } else if (status) {
      tableListData = await getTableDatawithFilters(
        "nutritionist_list",
        null,
        status
      );
    } else {
      tableListData = await getTableDatawithFilters("nutritionist_list");
    }
    // console.log(tableList);
    if (tableListData && tableListData?.user_list?.length > 0) {
      setTableList(tableListData.user_list);
      if (data == "nofilter") {
        filternameListFun(tableListData.nutritionist_name_list);
      }
    } else {
      setTableList([]);
    }
  };

  const getTableDatawithFilters = async (url, name, status) => {
    // isLoading();
    let obj = {
      search: { value: searchValue },
      length: 10,
      start: offset == 1 ? 0 : (offset - 1) * 10,
      draw: 10,
      nutritionist_status: nutri_status ? nutri_status : "",
      nutritionist_first_name: nutri_name ? nutri_name : "",
    };
    // if (name) {
    //   obj.nutritionist_first_name = name;
    // }
    // if (status) {
    //   obj.nutritionist_status = status;
    // }
    const data = {
      url: url,
      body: obj,
    };
    try {
      const newData = await postMethod(data);
      // isNotLoading();
      if (newData.status == 1 && newData.data) {
        isNotLoading();
        setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
        // setTableList(newData.data)
        return newData.data;
      } else if (newData.status === false) {
        isNotLoading();
        // handleFalseStatus(newData);

        return null;
      } else {
        isNotLoading();
      }
    } catch (err) {
      // isNotLoading();
      // handleCatchErr(err);
    }
  };
  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };
  const handleSearchInput = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
      setOffset(1);
    }, 1000);

    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };
  function assignedUsers(cell, row) {
    return (
      <>
        <Button
          variant="link"
          className="btn btn-link link-btn"
          disabled={cell == "0" ? true : false}
          onClick={() => assignUserShowFun(row)}
        >
          {cell == "0" ? cell : <u>{cell}</u>}
        </Button>
      </>
    );
  }

  useEffect(() => {
    let selUser = [...assignuserListCpy];
    // console.log(assignuserFilters);
    // let filterArr = _.pickBy(
    //   assignuserFilters,
    //   (v) => !_.isUndefined(v) && !_.isNull(v)
    // );
    // if (Object.keys(filterArr).length > 0) {
    // let x = [];
    if (assignuserFilters.is_active && assignuserFilters.program_name == "") {
      let x = _.filter(
        selUser,
        (item) => item.is_active == parseInt(assignuserFilters.is_active)
      );
      assignuserListFun(x);
    }

    if (assignuserFilters.program_name && assignuserFilters.is_active == "") {
      let x = [];
      // console.log(selUser);
      if (assignuserFilters.program_name == "Unassigned") {
        x = _.filter(selUser, (item) => item.master_program_id == null);
      } else {
        x = _.filter(
          selUser,
          (item) => item.program_name == assignuserFilters.program_name
        );
      }
      assignuserListFun(x);
    }

    if (assignuserFilters.program_name && assignuserFilters.is_active) {
      // console.log(3);
      let x = _.filter(
        selUser,
        (item) =>
          item.program_name == assignuserFilters.program_name &&
          item.is_active == assignuserFilters.is_active
      );
      assignuserListFun(x);
    }
    if (
      assignuserFilters.program_name == "" &&
      assignuserFilters.is_active == ""
    ) {
      // console.log(4);
      assignuserListFun(assignuserListCpy);
    }

    // selUser.forEach((item) => {
    //   for (let key in filterArr) {
    //     if (item[key] == filterArr[key]) {
    //       x.push(item);
    //     }
    //   }
    // });
    // console.log(x);
    // assignuserListFun(rmvDuplicate(x));
    // assignuserListFun([]);

    // }
  }, [setAssignuserFilters, assignuserFilters]);

  const handleSelUserFilter = (e) => {
    let val = e.target.value;
    let name = e.target.name;

    setOffset1(1);

    if (val != "") {
      setAssignuserFilters((prev) => ({
        ...prev,
        [name]: val,
      }));
    } else {
      setAssignuserFilters((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const rmvDuplicate = (arr) => {
    let uniqueArr = arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
    return uniqueArr;
  };

  const assignProgramFilters = (data) => {
    let arr = [];
    data.forEach((item) => {
      if (item.program_name) {
        arr.push(item.program_name);
      } else {
        arr.push("Unassigned");
      }
    });
    setProgramList(rmvDuplicate(arr));
  };

  function assignUserShowFun(row) {
    // console.log("selected user", row.nutritionist_user);
    // assignProgramFilters(row.nutritionist_user);
    // assignuserListFun(row.nutritionist_user);
    // setAssignuserListCpy(row.nutritionist_user);
    getNutritionistUser(row.id);
    setNutId(row.id);
    setModalHead("View Assigned Users");
    setKey("nutritionist");
    setModalShow(true);
  }

  function actionFormatter(cell, row, rowIndex) {
    return (
      <>
        <Button
          variant="link"
          className="d-inline-block mr-2"
          dataid={row.id}
          onClick={() => {
            setEditId(row.id);
            setKey("nutuser");
            setModalHead("Edit Nutritionist");
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Form.Check
          type="switch"
          className="d-inline-block ml-2"
          id={`action_check_${rowIndex}`}
          checked={cell == 0 ? false : true}
          onChange={(e) => {
            actionChange(row.id, row.is_active);
          }}
        />
      </>
    );
  }

  function statusFormatter(cell, row, rowIndex) {
    return row.is_active && row.is_active == 1 ? "Active" : "Not Active";
  }

  function actionChange(id, status) {
    confirmAlert({
      title: `Confirm ${status == 0 ? "Activate" : "De-Activate"
        } Nutritionist?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            let obj = {
              user_id: id,
              is_active: status == 0 ? 1 : 0,
            };
            const data = {
              url: "user_active",
              body: obj,
            };
            const newData = await postMethod(data);
            // console.log(newData);
            showToastFn(newData.message);
            isNotLoading();
            if (newData && newData.status == 1) {
              userListFun("nofilter");
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }

  const modalBody = () => {
    switch (key) {
      case "nutritionist":
        return <ReassNutritionist />;
        break;
      case "nutuser":
        return (
          <UserBody
            getLists={() => userListFun("nofilter")}
            showToastFn={showToastFn}
            editId={editId}
            onHide={() => {
              setModalShow(false);
              setEditId();
            }}
          />
        );
        break;
      default:
        return null;
    }
  };

  const ReassNutritionist = () => {
    return (
      <>
        <div className="d-flex mt-5">
          <h3>Assigned Users</h3>
          <div className="ml-auto d-flex">
            <Form.Group controlId="assUsers" className="mb-0 mr-3 mw-200">
              <Form.Control
                as="select"
                name="program_name"
                value={assignuserFilters?.program_name}
                onChange={handleSelUserFilter}
              >
                <option value="">Filter By Program Name</option>
                {programList &&
                  programList.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.program_name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="assProgram" className="mb-0 mr-3 mw-200">
              <Form.Control
                as="select"
                name="is_active"
                value={assignuserFilters?.is_active}
                onChange={handleSelUserFilter}
              >
                <option value="">Filter By Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        {/* <TableLong data={userData} columns={userColumns} /> */}
        <Table
          data={userData}
          columns={userColumns}
          offset={offset1}
          searchBar="false"
        />
        <br />
        <div className="d-flex float-right justify-content-end">
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={(pageNumber) => setOffset1(pageNumber.selected + 1)}
            forcePage={offset1 - 1}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            // onPageActive={onActivePage}
            className="pagination active"
            activeClassName={"active"}
          />
        </div>
      </>
    );
  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "first_name", text: "Name" },
    { dataField: "email", text: "Email ID" },
    { dataField: "mobile", text: "Mobile Number" },
    {
      dataField: "nutritionist_user_count",
      text: "Assigned Users",
      formatter: assignedUsers,
    },
    {
      dataField: "role_name",
      text: "Role Name",
      formatter: roleFormatter,
      filterValue: (cell, row) => formatterRole(cell, row),
    },
    { dataField: "status", text: "Status", formatter: statusFormatter },
    {
      dataField: "is_active",
      text: "Action",
      classes: "no-wrap",
      formatter: actionFormatter,
    },
  ];
  function roleFormatter(cell, row) {
    return <span>{cell && cell.name ? cell.name : "-"}</span>;
  }
  function formatterRole(cell, row) {
    return cell?.name ? cell.name : "-";
  }
  useEffect(() => {
    getProgramList();
  }, []);

  const getProgramList = async () => {
    const data = {
      url: "program_name_filter_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      // let arr = newData.data.program_weeks.map((item) => ({
      //   name: item,
      // }));
      // setProgramList(arr);
      setProgramList(newData.data);
      // console.log(newData,'program')
    }
  };

  const nutriNameChange = (e) => {
    filterNmae(e.target.value);
    // userListFun("filter", e.target.value);
  };

  useEffect(() => {
    if (modalShow) {
      getNutritionistUser();
    }
  }, [offset1, assignuserFilters.is_active, assignuserFilters.program_name]);

  const nutriStatusChange = (e) => {
    filterStatus(e.target.value);
    // userListFun("filter", null, e.target.value);
  };

  const getNutritionistUser = async (id) => {
    const data = {
      url: "nutritionist_user_list",
      body: {
        length: 10,
        start: offset1 == 1 ? 0 : (offset1 - 1) * 10,
        draw: 10,
        nutritionist_id: id ? id : nutId,
        program_id: assignuserFilters.program_name,
        is_active: assignuserFilters.is_active,
      },
    };
    const newData = await postMethod(data);

    if (newData.data && newData.data.user_list) {
      isNotLoading();
      setUserData(newData.data.user_list);
      setPageCount1(Math.ceil(newData.data.recordsTotal / perPage));
    } else {
      isNotLoading();
    }
  };

  const tableFilters = () => {
    return (
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex justify-content-start">
          <InputGroup className="search-register-users">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleSearchInput}
            />
          </InputGroup>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <Form.Group controlId="nutName" className="mb-0 mr-3 mw-200">
            <Form.Control
              as="select"
              name="nut_name"
              value={nutri_name}
              onChange={nutriNameChange}
            >
              <option value="">Filter By Name</option>
              {filternameList.map((item, index) => (
                <option value={item.first_name} key={index}>
                  {item.first_name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="status" className="mb-0 mr-3 mw-200">
            <Form.Control
              as="select"
              name="status"
              value={nutri_status}
              onChange={nutriStatusChange}
            >
              <option value="">Filter By Status</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </Form.Control>
          </Form.Group>

          <Button
            variant="primary"
            disabled={selectedRow && selectedRow.length > 0 ? false : true}
            onClick={deletedFun}
          >
            Delete
          </Button>
        </div>
      </div>
    );
  };

  const getSelectedRow = (data) => {
    // console.log(data);
    setSelectedRow(data);
  };

  const deletedFun = async () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "multi_user_delete",
              body: {
                user_id: selectedRow,
              },
            };
            const newData = await postMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              setSelectedRow();
              userListFun("nofilter");
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(4) ? (
        <>
          <div className="d-flex align-items-center mb-5">
            <h3 className="text-capitalize">Manage Nutritionist</h3>
            <Button
              className="h-100 ml-auto text-capitalize"
              onClick={() => {
                setEditId();
                setModalShow(true);
                setModalHead("Add Nutritionist");
                setKey("nutuser");
              }}
            >
              Add Nutritionist
            </Button>
          </div>

          <TableSelect
            clickSelect="true"
            data={tableList}
            columns={columns}
            filters={tableFilters}
            getSelectedRow={getSelectedRow}
            offset={offset}
            searchBar="false"
          />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              // onPageActive={onActivePage}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
          <ModalPop
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              clearFilters();
            }}
            modalcontent={modalBody()}
            modalhead={modalHead}
            modalSize={key == "nutuser" ? "small" : "big"}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
