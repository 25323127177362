import React from "react";
// import React, { useState } from "react";

import { debounce } from "lodash";

// import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export default function Index(props) {
  // const [state, setstate] = useState();
  const { getSearchVal } = props;

  const handleInput = (e) => {
    // setstate(e.target.value);
    getSearchVal(e.target.value);
  };
  return (
    <>
      <label
        htmlFor="search-bar-0"
        className={
          props.margin
            ? `searchIcon search-label position-relative mb-${props.margin}`
            : "searchIcon position-relative  mb-4"
        }
      >
        <span id="search-bar-0-label" className="sr-only">
          Search this table
        </span>
        <FontAwesomeIcon icon={faSearch} className="searchIcon" />
        <input
          id="search-bar-0"
          type="search"
          aria-labelledby="search-bar-0-label"
          className="form-control "
          placeholder="Search"
          // value={state}
          onChange={debounce(handleInput, 300)}
        />
      </label>
    </>
  );
}
