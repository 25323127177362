import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import { Button, Form, Toast } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Table from "../components/tables/table";
import { Formik } from "formik";
import * as yup from "yup";

import { postMethod, postMethodFormData, AccessDenied, handleFalseStatus, isNotLoading } from "../Library";

import ModalPop from "../components/modals";
// import ImageBoxUpload from "../components/ImageBox/imageboxupload";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function CategoryPage(props) {
  const [tableList, setTableList] = useState([]);
  const [blogTable, setBlogTable] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [editId, setEditId] = useState("");
  const [catType, setType] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };
  let formDataBody = new FormData();

  function btnFormatter(cell, row) { 
    return (
      <>
        <Button
          variant="link"
          dataid={row.id}
          disabled={row.name==="7 day logic"}
          onClick={() => {
            editEquipment(row);
            setModalShow(true);
            setModalHead("Edit");
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button variant="link" dataid={row.id}  onClick={trashWorkout}
          disabled={row.name==="7 day logic"}
          >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "category_delete",
      body: {
        id: id,
      },
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
           
            showToastFn(newData.message);
            if (newData.status === 1) {
              isNotLoading();
              getFaqList();
              getBlogList();
            }
            else{
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Category Name" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getFaqList();
      getBlogList();
      if (editId) {
        // console.log("EDITiD", editId);
        // getEditData(props.editId);
      } else {
        setFormInputs({
          name: "",
        });
      }
    }
  }, [pageLoaded, setPageLoaded]);

  // getFaqTable data
  const getFaqList = async () => {
    const data = {
      url: "category_list",
      body: {
        type: "faq",
      },
    };
    let tableList = await postMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setTableList(tableList.data.categories);
    } else if (tableList.status === false) {
      handleFalseStatus(tableList);
      isNotLoading();
    } else if (tableList.status === 0) {
      setTableList(tableList.data);
      isNotLoading();
    }
    else{
      isNotLoading();
    }
  };

  // editEquipment
  const editEquipment = (data) => {
    // console.log("dataa", data);
    let form = { ...formInputs };
    form["name"] = data.name;
    form["id"] = data.id;
    setType(data.type);
    setFormInputs(form);
  };

  // blogTableData
  const getBlogList = async () => {
    const data = {
      url: "category_list",
      body: {
        type: "blog",
      },
    };
    let tableList = await postMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setBlogTable(tableList.data.categories);
    }
    else{
      isNotLoading();
    }
    if (tableList.status === 0) {
      isNotLoading();
      setBlogTable(tableList.data);
    }
    else{
      isNotLoading();
    }
  };
  // forms functions

  const formSchema = yup.object({
    name: yup.string().required("This field is required"),
  });

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const handleSubmit = () => {
    for (const [key, value] of Object.entries(formInputs)) {
      formDataBody.append(key, value);
    }
    formDataBody.append("type", catType);
    addNewCategory();
  };

  // add new categories
  const addNewCategory = async (type) => {
    const data = {
      url: "category_create_or_update",
      body: formDataBody,
    };
    const newData = await postMethodFormData(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      if (catType === "faq") {
        getFaqList();
      }
      if (catType === "blog") {
        getBlogList();
      }
    }
    else{
      isNotLoading();
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs();
    setType("");
  };

  const modalBody = () => {
    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          // console.log(values);
          if (values) {
            handleSubmit();
          }
        }}
        initialValues={{
          name: formInputs && formInputs.name ? formInputs.name : "",
        }}
      >
        {({
          validateForm,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          dirty,
          isValid,
          isInvalid,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
              setFormDirty(true);
            }}
          >
            <div className="column">
              <div className="col-md-8">
                <Form.Group controlId="name">
                  <Form.Label className="required">Name</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    required
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.name && touched.name ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Button
                variant="secondary"
                onClick={() => {
                  closeModal();
                  // setModalHead("Add");
                }}
                className="mr-3"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {modalHead == "Add" ? "Add" : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.4) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">FAQ category</h3>
            <Button
              className="h-100"
              onClick={() => {
                setType("faq");
                setModalShow(true);
                setModalHead("Add");
              }}
            >
              Add new
            </Button>
          </div>
          <Table data={tableList} columns={columns} filters={tableFilters} />
          <div className="d-flex align-items-center mt-5 justify-content-between">
            <h3 className="text-capitalize">BLOG category</h3>
            <Button
              className="h-100"
              onClick={() => {
                setType("blog");
                setModalShow(true);
                setModalHead("Add");
              }}
            >
              Add new
            </Button>
          </div>
          <Table data={blogTable} columns={columns} filters={tableFilters} />
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
              //   setEditFaqBlogData();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} category`}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
