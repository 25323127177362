import React, { useState, useEffect } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import Editor from "../../../components/editor";
// import ImageBoxUpload from "../../ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../../ImageBox/imageboxuploadcrop";

import { Formik } from "formik";
import * as yup from "yup";

import { getMethod, isNotLoading, postMethod } from "../../../Library";
import TextEditorImage from "../../editor/withimage";

import Table from "../../tables/table";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export function FAQBlogBody(props) {
  const [formDirty, setFormDirty] = useState(false);

  const [formInputs, setFormInputs] = useState();

  const gettypedData = (data) => {
    let form = { ...formInputs };
    form.description = data;
    setFormInputs(form);
  };

  const chooseImageFn = (url) => {
    let form = { ...formInputs };
    form.image_url = url;
    setFormInputs(form);
  };

  const addFaqBlog = async (id) => {
    let err = true;
    if (props.type == "Blogs") {
      if (!formInputs.image_url) {
        err = true;
      } else {
        err = false;
      }
    } else {
      err = false;
    }
    // if (formInputs?.description && formInputs?.image_url) {

    if (!err && formInputs.description) {
      const data = {
        url:
          props.type == "FAQ's"
            ? "faq_create_or_update"
            : "blog_create_or_update",
        body: {
          ...formInputs,
          id: props.editId ? props.editId : null,
        },
      };
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getData(props.type);
      }
      else {
        isNotLoading();

      }
    }
  };

  const getEditData = async (id) => {
    const data = {
      url: props.type == "FAQ's" ? `faq_list?id=${id}` : `blog_list?id=${id}`,
      // body: {
      //   id: id,
      // },
    };

    let fetchPath = props.type == "FAQ's" ? "faqs" : "blogs";
    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFormInputs({
        category_id: newData.data[fetchPath][0].category_id,
        title: newData.data[fetchPath][0].title,
        description: newData.data[fetchPath][0].description,
        video_url: newData.data[fetchPath][0].video_url
          ? newData.data[fetchPath][0].video_url.iso_video_url
          : null,
        image_url: newData.data[fetchPath][0].image_url,
      });
    }
    else {
      isNotLoading();

    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const formSchema = yup.object({
    category_id: yup.string().required("This field is required"),
    title: yup.string().required("This field is required"),
  });

  useEffect(() => {
    if (props.editId) {
      getEditData(props.editId);
    } else {
      setFormInputs({
        category_id: "",
        title: "",
        description: "",
        video_url: "",
        image_url: "",
      });

    }
  }, []);

  return formInputs ? (
    <Formik
      validationSchema={formSchema}
      onSubmit={(values, actions) => {
        // console.log(values);
        if (values) {
          addFaqBlog();
        }
      }}
      initialValues={{
        category_id:
          formInputs && formInputs.category_id ? formInputs.category_id : "",
        title: formInputs && formInputs.title ? formInputs.title : "",
        video_url:
          formInputs && formInputs.video_url ? formInputs.video_url : "",
      }}
    >
      {({
        validateForm,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        dirty,
        isValid,
        isInvalid,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            setFormDirty(true);
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="category">
                <Form.Label className="required">Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category_id"
                  required
                  value={values.category_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={
                    errors.category_id && touched.category_id ? true : false
                  }
                >
                  <option value="">Select a Category</option>
                  {props.categoryList &&
                    props.categoryList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.category_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="title">
                <Form.Label className="required">Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  required
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={errors.title && touched.title ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {props.type == "Blogs" && (
              <div className="col-md-4 d-flex align-items-center">
                <div className="position-relative w-100">
                  <Form.Label className="required">Image</Form.Label>
                  {/* <ImageBoxUpload
                      data={chooseImageFn}
                      image_url={formInputs ? formInputs.image_url : ""}
                    /> */}
                  <ImageBoxUploadCrop
                    data={chooseImageFn}
                    folder="blog"
                    as_w="16"
                    as_h="9"
                    dataid=""
                    image_url={
                      formInputs?.image_url ? formInputs.image_url : ""
                    }
                  />
                  {formDirty && !formInputs?.image_url && (
                    <div className="err-feedback text-center">
                      This field is required
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <Form.Group controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <TextEditorImage
              text={
                formInputs && formInputs.description
                  ? formInputs.description
                  : ""
              }
              data={gettypedData}
            />
            {/* <Editor
              text={
                formInputs && formInputs.description
                  ? formInputs.description
                  : ""
              }
              data={gettypedData}
            /> */}
            {formDirty && formInputs.description == "" && (
              <div className="err-feedback">This field is required</div>
            )}
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>Video URL (MP4)</Form.Label>
            <Form.Control
              name="video_url"
              type="text"
              value={values.video_url}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                handleInput(e);
              }}
              isInvalid={errors.video_url && touched.video_url ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {errors.video_url}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-end mt-5">
            <Button variant="secondary" onClick={props.onHide} className="mr-3">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {props.modalHead == "Add" ? "Add" : "Update"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <h3 className="mt-5 mb-5 text-center">Loading...</h3>
  );
}

export function QuotesBody(props) {
  const [formDirty, setFormDirty] = useState(false);
  const [formInputs, setFormInputs] = useState();

  const [validated, setValidated] = useState(false);

  const getEditData = async (id) => {
    const data = {
      url: `quote_tip_list?id=${id}`,
      // body: {
      //   id: id,
      // },
    };

    let fetchPath = "quote_tips";
    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFormInputs({
        quote_description: newData.data[fetchPath][0].quote_description,
        tip_description: newData.data[fetchPath][0].tip_description,
      });
    }
    else {
      isNotLoading();
    }
  };

  useEffect(() => {
    if (props.editId) {
      getEditData(props.editId);
    } else {
      setFormInputs({
        quote_description: "",
        tip_description: "",
      });
    }
  }, []);

  // const gettypedData = (data, index) => {
  //   setFormInputs((prev) => ({
  //     ...prev,
  //     [index]: data,
  //   }));
  // };

  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (formInputs.quote_description && formInputs.tip_description) {
      const data = {
        url: "quote_tip_create_or_update",
        body: {
          ...formInputs,
          id: props.editId ? props.editId : null,
        },
      };
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();

        props.onHide();
        props.getData(props.type);
      }
      else {
        isNotLoading();

      }
    }
  };

  return formInputs ? (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Group controlId="description" className="position-relative">
        <Form.Label className="required">Add Quotes</Form.Label>
        {/* <Editor
          data={gettypedData}
          text={
            formInputs && formInputs.quote_description
              ? formInputs.quote_description
              : ""
          }
          index="quote_description"
        />
        {formDirty && formInputs.quote_description == "" && (
          <div className="err-feedback">This field is required</div>
        )} */}
        <Form.Control
          as="textarea"
          name="quote_description"
          rows={3}
          maxLength="60"
          required
          value={
            formInputs && formInputs.quote_description
              ? formInputs.quote_description
              : ""
          }
          onChange={handleInput}
        />
        <small className="char-len">
          {formInputs && 60 - formInputs.quote_description.length} characters
          left
        </small>
        <Form.Control.Feedback type="invalid">
          This field is required
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="description" className="position-relative">
        <Form.Label className="required">Add Tips</Form.Label>
        {/* <Editor
          data={gettypedData}
          text={
            formInputs && formInputs.tip_description
              ? formInputs.tip_description
              : ""
          }
          index="tip_description"
        />
        {formDirty && formInputs.tip_description == "" && (
          <div className="err-feedback">This field is required</div>
        )} */}
        <Form.Control
          as="textarea"
          name="tip_description"
          rows={3}
          maxLength="60"
          required
          value={
            formInputs && formInputs.tip_description
              ? formInputs.tip_description
              : ""
          }
          onChange={handleInput}
        />
        <small className="char-len">
          {formInputs && 60 - formInputs.tip_description.length} characters left
        </small>
        <Form.Control.Feedback type="invalid">
          This field is required
        </Form.Control.Feedback>
      </Form.Group>

      <div className="d-flex justify-content-end mt-5">
        <Button variant="secondary" onClick={props.onHide} className="mr-3">
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          {props.modalHead == "Add" ? "Add" : "Update"}
        </Button>
      </div>
    </Form>
  ) : (
    <h3 className="mt-5 mb-5 text-center">Loading...</h3>
  );
}

// new Faq body design
export function FAQGeneralBody(props) {
  const [formDirty, setFormDirty] = useState(false);
  const [formInputs, setFormInputs] = useState();

  const gettypedData = (data) => {
    let form = { ...formInputs };
    form.description = data;
    setFormInputs(form);
  };

  const addFaqBlog = async (id) => {
    let err = false;
    if (!err && formInputs.description) {
      const data = {
        url: 'faq_create_or_update',
        body: {
          ...formInputs,
          id: props.editId ? props.editId : null,
        },
      };
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getData("FAQ's");
      }
      else {
        isNotLoading();

      }
    }
  };

  const getEditData = async (id) => {
    const data = {
      url: `faq_list?id=${id}`
    }

    let fetchPath = 'faqs'
    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFormInputs({
        category_id: newData.data[fetchPath][0].category_id,
        title: newData.data[fetchPath][0].title,
        description: newData.data[fetchPath][0].description,
        video_url: newData.data[fetchPath][0].video_url
          ? newData.data[fetchPath][0].video_url.iso_video_url
          : null,
      });
    }
    else {
      isNotLoading();

    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const formSchema = yup.object({
    category_id: yup.string().required("This field is required"),
    title: yup.string().required("This field is required"),
  });

  useEffect(() => {
    console.log("props.editId == undefined &&", props.editId, props.activeFaqTab)
    setTimeout(() => {
      if (props.editId === undefined) {
        console.log("ged treue")
        values: { }
        setFormInputs({
          category_id: "",
          title: "",
          description: "",
          video_url: "",
        });
      }
    }, 5);



  }, [props.activeFaqTab]);



  useEffect(() => {
    if (props.editId) {
      getEditData(props.editId);
    } else {
      setFormInputs({
        category_id: "",
        title: "",
        description: "",
        video_url: "",
      });
    }
  }, []);

  return formInputs ? (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={(values, actions) => {
        console.log("values", values)
        if (values) {
          addFaqBlog();
        }
      }}
      initialValues={{
        category_id:
          formInputs && formInputs.category_id ? formInputs.category_id : "",
        title: formInputs && formInputs.title ? formInputs.title : "",
        video_url:
          formInputs && formInputs.video_url ? formInputs.video_url : "",
      }}
    >
      {({
        validateForm,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        dirty,
        isValid,
        isInvalid,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            setFormDirty(true);
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="category">
                <Form.Label className="required">Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category_id"
                  required
                  value={values.category_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={
                    errors.category_id && touched.category_id ? true : false
                  }
                >
                  <option value="">Select a Category</option>
                  {props.categoryList &&
                    props.categoryList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.category_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="title">
                <Form.Label className="required">Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  required
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={errors.title && touched.title ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <Form.Group controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <TextEditorImage
              text={
                formInputs && formInputs.description
                  ? formInputs.description
                  : ""
              }
              data={gettypedData}
            />
            {formDirty && formInputs.description == "" && (
              <div className="err-feedback">This field is required</div>
            )}
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>Video URL (MP4)</Form.Label>
            <Form.Control
              name="video_url"
              type="text"
              value={values.video_url}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                handleInput(e);
              }}
              isInvalid={errors.video_url && touched.video_url ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {errors.video_url}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-end mt-5">
            <Button variant="secondary" onClick={props.onHide} className="mr-3">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {props.modalHead == "Add" ? "Add" : "Update"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <h3 className="mt-5 mb-5 text-center">Loading...</h3>
  );
}

export function FAQProgramBody(props) {
  const [programFormDirty, setprogramFormDirty] = useState(false);
  const [programFormInputs, setprogramFormInputs] = useState();
  const [programList, setProgramList] = useState([])

  const gettypedData = (data) => {
    let form = { ...programFormInputs };
    form.description = data;
    setprogramFormInputs(form);
  };

  const addFaqBlog = async (id) => {
    let err = false;
    if (!err && programFormInputs.description) {
      const data = {
        url: 'faq_create_or_update',
        body: {
          ...programFormInputs,
          id: props.editId ? props.editId : null,
        },
      };
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getData("FAQ's");
      }
      else {
        isNotLoading();

      }
    }
  };

  const getEditData = async (id) => {
    const data = {
      url: `faq_list?id=${id}`
    }

    let fetchPath = 'faqs'
    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();

      console.log("edit", newData.data)
      setprogramFormInputs({
        program_id: newData.data[fetchPath][0].program_id,
        title: newData.data[fetchPath][0].title,
        description: newData.data[fetchPath][0].description,
        video_url: newData.data[fetchPath][0].video_url
          ? newData.data[fetchPath][0].video_url.iso_video_url
          : null,
      });
    }
    else {
      isNotLoading();

    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...programFormInputs };
    form[name] = value;
    setprogramFormInputs(form);
  };

  const formSchema = yup.object({
    program_id: yup.string().required("This field is required"),
    title: yup.string().required("This field is required"),
  });

  useEffect(() => {
    console.log("activeFaqTab={faqKey}", props.activeFaqTab)

    setTimeout(() => {
      if (props.editId == undefined) {
        values: { }
        setprogramFormInputs({
          program_id: "",
          title: "",
          description: "",
          video_url: "",
        });
      }
    }, 5);
  }, [props.activeFaqTab]);



  useEffect(() => {
    getProgramList();
    if (props.editId) {
      getEditData(props.editId);
    } else {
      setprogramFormInputs({
        program_id: "",
        title: "",
        description: "",
        video_url: "",
      });
    }
  }, []);

  const getProgramList = async () => {
    const data = {
      url: "program_name_filter_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setProgramList(newData.data);
    }
  };

  return programFormInputs ? (
    <Formik
      enableReinitialize
      validationSchema={formSchema}
      onSubmit={(values, actions) => {
        console.log("values", values)
        if (values) {
          addFaqBlog();
        }
      }}
      initialValues={{
        program_id:
          programFormInputs && programFormInputs.program_id ? programFormInputs.program_id : "",
        title: programFormInputs && programFormInputs.title ? programFormInputs.title : "",
        video_url:
          programFormInputs && programFormInputs.video_url ? programFormInputs.video_url : "",
      }}
    >
      {({
        validateForm,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        dirty,
        isValid,
        isInvalid,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
            setprogramFormDirty(true);
          }}
        >
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="program">
                <Form.Label className="required">Select Program</Form.Label>
                <Form.Control
                  as="select"
                  name="program_id"
                  required
                  value={values.program_id}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={
                    errors.program_id && touched.program_id ? true : false
                  }
                >
                  <option value="">Select a program</option>
                  {programList &&
                    programList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.program_name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.program_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="title">
                <Form.Label className="required">Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  required
                  value={values.title}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={errors.title && touched.title ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>

          <Form.Group controlId="description">
            <Form.Label className="required">Description</Form.Label>
            <TextEditorImage
              text={
                programFormInputs && programFormInputs.description
                  ? programFormInputs.description
                  : ""
              }
              data={gettypedData}
            />
            {programFormDirty && programFormInputs.description == "" && (
              <div className="err-feedback">This field is required</div>
            )}
          </Form.Group>
          <Form.Group controlId="url">
            <Form.Label>Video URL (MP4)</Form.Label>
            <Form.Control
              name="video_url"
              type="text"
              value={values.video_url}
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                handleInput(e);
              }}
              isInvalid={errors.video_url && touched.video_url ? true : false}
            />
            <Form.Control.Feedback type="invalid">
              {errors.video_url}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-end mt-5">
            <Button variant="secondary" onClick={props.onHide} className="mr-3">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {props.modalHead == "Add" ? "Add" : "Update"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  ) : (
    <h3 className="mt-5 mb-5 text-center">Loading...</h3>
  );
}

