import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import { Button, Form, Toast } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Table from "../components/tables/table";
import { Formik } from "formik";
import * as yup from "yup";
import Editor from "../components/editor";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { getMethod, postMethod, getTableData, AccessDenied, isNotLoading } from "../Library";

import ModalPop from "../components/modals";
// import ImageBoxUpload from "../components/ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../components/ImageBox/imageboxuploadcrop";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function DietConditions(props) {
  const [tableList, setTableList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [conditionOptions, setConditionOptions] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [validated, setValidated] = useState(false);
  const [dietOptions, setDietOptions] = useState([]);
  const [formInputs, setFormInputs] = useState();
  const [err, setErr] = useState();

  const defaultFormData = {
    medical_condition: "",
    guideline_items: [
      {
        title: "",
        image_url: "",
        description: "",
      },
    ],
  };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={row.medical_condition}
          dietType={row.diet_type}
          onClick={() => {
            getConditionID(row);
            setModalShow(true);
            setModalHead("Edit");
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button
          variant="link"
          dataid={row.medical_condition}
          // dietType={row.diet_type}
          onClick={trashCondition}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const trashCondition = (e) => {
    let medical_condition = e.target.getAttribute("dataid");
    // let diet_type = e.target.getAttribute("dietType");
    const data = {
      url: "admin_rule_of_thumb_delete",
      body: {
        medical_condition: medical_condition,
        // diet_type: diet_type
      },
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);

            showToastFn(newData.message);
            if (newData.status === 1) {
              isNotLoading();
              getTableList();
            }
            else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };
  const columns = [
    { dataField: "sno", text: "S.No", searchable: false },
    { dataField: "medical_condition", text: "Condition Name / Diet type" },
    // { dataField: "diet_type", text: "Diet Type" },
    {
      dataField: "id",
      text: "Action",
      searchable: false,
      formatter: btnFormatter,
    },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getTableList();
      getConditionList();
      getDiet()
    }
  }, [pageLoaded, setPageLoaded]);

  const addGuidelines = () => {
    setValidated(false);
    setErr(false);
    setFormInputs((prev) => ({
      ...prev,
      guideline_items: [
        ...prev.guideline_items,
        {
          title: "",
          image_url: "",
          description: "",
        },
      ],
    }));
  };

  const deleteGuidelines = (e) => {
    let idx = e.target.getAttribute("dataid");
    // console.log(idx);
    let cpy = Object.assign({}, formInputs);
    cpy.guideline_items.splice(idx, 1);
    // console.log(cpy);
    setFormInputs(cpy);
  };

  const getConditionList = async () => {
    const data = {
      // url: "static_content_list",
      url: 'admin_rule_of_thumb_condition_list'
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      // setConditionOptions(newData.data.conditions);
      setConditionOptions(newData.data.condition);
    }
  };

  const getDiet = async () => {
    const data = {
      url: "list_recipe_diet_type",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      console.log(newData)
      setDietOptions(newData.data.recipe_diet_type_list);
    }
  };

  // editCondition
  const getConditionID = async (row) => {
    const data = {
      url: "admin_rule_of_thumb_by_name",
      body: {
        medical_condition: row.medical_condition,
        // diet_type: row.diet_type

      },
    };
    // console.log("data", data);
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFormInputs(newData.data);
      setConditionOptions(newData.data.condition_list);
    }
    else {
      isNotLoading();
    }
  };

  // getFaqTable data
  const getTableList = async () => {
    let tableList = await getTableData("admin_rule_of_thumb_list");
    if (tableList?.rule_of_thumb) {
      setTableList(tableList.rule_of_thumb);
    } else {
      setTableList([]);
    }
  };

  const handleInput = (e, index) => {
    if (index >= 0) {
      let cpy = Object.assign({}, formInputs);
      cpy.guideline_items[index][e.target.name] = e.target.value;
      setFormInputs(cpy);
    } else {
      setFormInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };
  const handleGuideline = (data, index) => {
    if (index >= 0) {
      let cpy = Object.assign({}, formInputs);
      cpy.guideline_items[index]["description"] = data;
      setFormInputs(cpy);
      console.log(cpy, "cpy")
    } else {
      setFormInputs((prev) => ({ ...prev, ["description"]: data }));
      console.log(formInputs, "cpy")

    }
  };

  const chooseImageFn = (url, ps) => {
    let index = ps.dataid;
    let cpy = Object.assign({}, formInputs);
    cpy.guideline_items[index].image_url = url;
    setFormInputs(cpy);
  };

  const checkImgValidation = (formData) => {
    let err = false;
    formData.guideline_items.forEach((item) => {
      if (
        !item?.image_url ||
        item?.image_url == "" ||
        item?.image_url?.length == 0
      ) {
        err = true;
      }
    });
    return err;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = true;
    formInputs.guideline_items.forEach((item) => {
      console.log("sd",item.description)
      if (
        !item?.description ||
        item?.description === "" ||
        item?.description?.length == 0 ||
        item?.description === null
      ) {
        console.log("11")
        setErr(true);
        errors = true;
      } else {
        console.log("22")
        setErr(false);
        errors = false;
      }
    });
    const isNameValid = formInputs.guideline_items.every(
      (x, i) =>
        x.description
      
    );
    console.log(isNameValid, "errortext");
    if(!isNameValid){
      setErr(true);
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false && errors) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    // let imgErr = checkImgValidation(formInputs);
    // console.log(imgErr);

    // if (form.checkValidity() === true && !imgErr) {
    if (form.checkValidity() === true && errors === false && isNameValid) {
      saveRuleofThumb();
    } else {
      showToastFn("Please Check All Required Fields");
    }
  };

  async function saveRuleofThumb() {
    console.log(formInputs, "aaaaa");
    const data = {
      url: "admin_create_or_update_rule_of_thumb",
      body: formInputs,
    };
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      getTableList();
    }
    else {
      isNotLoading();
    }
  }

  const closeModal = () => {
    setValidated(false);
    setModalShow(false);
    setFormInputs();
    setErr(false);
  };


  const modalBody = () => {
    console.log(formInputs, 'test')
    return (
      <>
        <Form
          noValidate
          validated={validated}
          className={
            formInputs?.guideline_items?.length == 1 ? "single" : "multi"
          }
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-12">
              <Form.Group controlId="medical_condition">
                <Form.Label className="required">Choose a condition/diet type</Form.Label>
                <Form.Control
                  as="select"
                  name="medical_condition"
                  required
                  value={formInputs?.medical_condition}
                  onChange={handleInput}
                >
                  <option value="">Select a condition/diet type</option>
                  {conditionOptions &&
                    conditionOptions.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            {/* <div className="col-6">
              <Form.Group controlId="diet_type">
                <Form.Label className="required">Choose a diet type</Form.Label>
                <Form.Control
                  as="select"
                  name="diet_type"
                  required
                  value={formInputs?.diet_type}
                  onChange={handleInput}
                >
                  <option value="">Select a diet type</option>
                  {dietOptions &&
                    dietOptions.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div> */}

          </div>
          <div className="mul-box-ct">
            {formInputs?.guideline_items &&
              formInputs?.guideline_items?.map((item, index, arr) => (
                <div className="row mul-box" key={index}>
                  <div className="col-6">
                    <Form.Group controlId={`title-${index}`}>
                      <Form.Label className="required">Title</Form.Label>
                      <Form.Control
                        name="title"
                        type="text"
                        required
                        dataid={index}
                        value={item?.title}
                        onChange={(e) => handleInput(e, index)}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {/* <div className="col-4 position-relative">
                    <ImageBoxUploadCrop
                      folder="dietcondition"
                      as_w="1"
                      as_h="1"
                      data={chooseImageFn}
                      dataid={index}
                      image_url={
                        item?.image_url?.length > 0 ? item?.image_url : ""
                      }
                    />
                    {validated && !item?.image_url ? (
                      <div className="err-feedback text-center">
                        This field is required
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  <div className="col-6 d-flex align-items-center justify-content-end">
                    {formInputs && (
                      <>
                        {arr.length > 1 && (
                          <Button
                            variant="link"
                            dataid={index}
                            onClick={deleteGuidelines}
                            className="p-0 mt-3 mx-2 del"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        )}

                        {/* {arr.length > 1 && (
                        )} */}
                        <Button
                          variant="link"
                          onClick={addGuidelines}
                          // item.image_url?.length == 0 ||
                          disabled={
                            item.description?.length == 0 ||
                            item?.title?.length == 0
                          }
                          className="p-0 mt-3 mx-2 add"
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="col-12">
                    <Form.Group controlId={`description-${index}`}>
                      <Form.Label className="required">Guidelines</Form.Label>
                      {/* <Form.Control
                        name="description"
                        as="textarea"
                        rows={3}
                        required
                        dataid={index}
                        value={item?.description}
                        onChange={(e) => handleInput(e, index)}
                      /> */}
                      <Editor
                        text={item?.description}
                        data={(e) => handleGuideline(e, index)}
                      />
                      {item?.description == "" && err ? (
                        <p className="text-danger error-msg">Please Fill This Field</p>
                      ) : null}
                    </Form.Group>
                  </div>
                  {arr.length > 1 && (
                    <div className="col-12">
                      <hr />
                    </div>
                  )}
                </div>
              ))}
          </div>

          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {modalHead == "Add" ? "Add" : "Update"}
            </Button>
          </div>
        </Form>
      </>
    );
  };

  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(8.5) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">Manage Guidelines</h3>
            <Button
              className="h-100"
              onClick={() => {
                setFormInputs(defaultFormData);
                setModalHead("Add");
                setModalShow(true);
                getConditionList();
              }}
            >
              Add new
            </Button>
          </div>
          <Table data={tableList} columns={columns} filters={tableFilters} />
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} Guidelines`}
            disableEnforceFocus={true}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
