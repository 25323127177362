import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import { Button, Form, Toast, InputGroup, FormControl } from "react-bootstrap";
// import { Link } from "react-router-dom";
import Table from "../components/tables/table";
import ModalPop from "../components/modals";
import { ExerciseBody } from "../components/Tabs/contents/manageexercise";
import ReactPaginate from "react-paginate";

import {
  postMethod,
  tableDefault,
  AccessDenied,
  handleFalseStatus,
  isNotLoading,
  getMethod,
} from "../Library";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function ExercisePage(props) {
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [key, setKey] = useState();
  const [tableList, setTableList] = useState([]);
  const [editId, setEditId] = useState();
  const [filternameList, filternameListFun] = useState([]);
  const [equi_name, filterNmae] = useState("");
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setKey("Edit");
            setEditId(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button variant="link" dataid={cell} onClick={trashExercise}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const trashExercise = async (e) => {
    let id = e.target.getAttribute("dataid");
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "delete_exercise",
              body: {
                id: id,
              },
            };
            const newData = await postMethod(data);
            if (newData.status == 1){
            isNotLoading();
            showToastFn(newData.message);
            userListFun();
            }
            else{
              isNotLoading();

            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  function eqpFormatter(cell, row) {
    let arr = [];
    if (cell && cell.length > 0) {
      cell.forEach((element) => {
        arr.push(element.name);
      });
    }
    return <p>{arr && arr.length > 0 ? arr.join(", ") : "NA"}</p>;
  }

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Title" },
    {
      dataField: "equipments",
      text: "Equipment Name",
      formatter: eqpFormatter,
    },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      userListFun();
      filterList();
    }
  }, [pageLoaded, searchValue, setPageLoaded, equi_name, offset]);

  const userListFun = async (id) => {
    // console.log(equi_name)
    let obj = {
      search: { value: searchValue },
      equipment_id: parseInt(equi_name)== null ? '':parseInt(equi_name),
      length: 10,
      start:  offset == 1 ? 0 : (offset - 1) * 10,
      draw: 10,
    };
    if (id) {
      obj.equipment_id = id;
    }
    const data = {
      url: "list_exercise",
      body: obj,
    };
    const newData = await postMethod(data);
    // console.log(newData);
    if (newData.status == 1 && newData?.data) {
      isNotLoading();
      setTableList(newData.data.exercises);
      setTotal(newData.data.recordsTotal)
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));

    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
    else{
      isNotLoading();
    }
  };

  const filterList = async () => {
    let data = {
      url: "exercise_equipment_list",
    };
    const list = await getMethod(data);
    // console.log(list);
    if (list && list.status == 1) {
      isNotLoading();
      filternameListFun(list.data);
    }
    else{
      isNotLoading();
    }
  };
  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
    setOffset(1);
    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };


  useEffect(() => {
    if (modalShow == false) {
      setEditId();
    }
  }, [modalShow, setModalShow]);

  const nutriNameChange = (e) => {
    filterNmae(e.target.value);
    userListFun(e.target.value);
    setOffset(1);
    
  };

  const tableFilters = () => {
    return (
      <div className="d-flex row justify-content-between w-100 pl-3  ">
        
         <InputGroup className="search-register-user mr-5">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleSearchInput}
            />
          </InputGroup>
         

   
        <Form.Group controlId="program" className="mb-0 mr-3 mw-200">
          <Form.Control
            as="select"
            name="program"
            value={equi_name}
            onChange={nutriNameChange}
          >
            <option value="">Filter By Equipments</option>
            {filternameList.map((item, index) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
       
      </div>
    );
  };

  const modalBody = () => {
    return (
      <ExerciseBody
        showToastFn={showToastFn}
        getLists={userListFun}
        modalType={key}
        editId={editId}
        onHide={() => setModalShow(false)}
      />
    );
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(6) ? (
        <>
          <div className="d-flex align-items-center mb-3  ">
            <h3 className="text-capitalize">Manage Exercises</h3>
            <Button
              className="h-100 ml-auto text-capitalize"
              onClick={() => {
                setModalShow(true);
                setKey("Add");
              }}
            >
              Add Exercise
            </Button>
          </div>

          <Table data={tableList} columns={columns} filters={tableFilters} searchBar="false" offset={offset} />
          <div className="d-flex float-right justify-content-end">
            {pageCount>1?
          <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setOffset(pageNumber.selected + 1)
                }
                forcePage={offset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              />
              : null}
              </div>
          <ModalPop
            show={modalShow}
            onHide={() => setModalShow(false)}
            modalcontent={modalBody()}
            modalhead={`${key} Exercise`}
          />
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
