import React from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Button } from "react-bootstrap";

export default function ToolTipBox(props) {
  // console.log("tooltip ", props);
  return (
    <>
      <OverlayTrigger
        placement={props?.placement ? props?.placement : "left"}
        overlay={
          <Tooltip className="tooltip">
            {props?.text ? props?.text : "No tool tip text"}
          </Tooltip>
        }
      >
        {props?.children ? props.children : "No Button"}
      </OverlayTrigger>
    </>
  );
}
