import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Toast,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import ModalPop from "../components/modals";
import Select from "react-select";
// import SideBar from "../components/sidebar";
import TableSelect from "../components/tables/tableselectDraw";
import ReactPaginate from "react-paginate";
import {
  getMethod,
  postMethod,
  getTableData,
  handleFalseStatus,
  getTableDataDraw,
  tableDefault,
  AccessDenied,
  isLoading,
  isNotLoading,
  findErrors
} from "../Library";
import _ from "lodash";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faFillDrip,
  faShoePrints,
  faGlassWhiskey,
} from "@fortawesome/free-solid-svg-icons";
import TableDefaultSelect from "../components/tables/tableDefaultSelect";
import SwitchIcon from "../assets/images/switch.png";
import ResetIcon from "../assets/images/reset.png";
import Dropdown from "../components/Dropdown";

export default function RegisteredUsersPage(props) {
  const [modalShow, setModalShow] = useState(false);
  const [modalCountShow, setModalCountShow] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [modalUserShow, setModalUserShow] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [nutritionistList, setNutritionistList] = useState([]);
  const [filterNutritionistList, setFilterNutritionistList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [userDetails, setUserDetails] = useState();
  const [searchValue, setSearchValue] = useState("");

  const [tableCount, setTableCount] = useState(0);
  const [tableDraw, setTableDraw] = useState(0);
  const [showButton, setShowButton] = useState(true);
  const [filter, setFilter] = useState(false);

  const [reassignId, setReassignId] = useState();
  const [validated, setValidated] = useState(false);

  const [performanceDisabled, setPerformanceDisabled] = useState(false);

  const tableRef = useRef(null);
  const [selectAll, setSelectAll] = useState(false);


  const [dateInputs, setDateInputs] = useState({
    select_to_date: null,
    select_from_date: null,
    select_program_start_date: null,
  });

  const [filterInputs, setFilterInputs] = useState({
    nutritionist_first_name: "",
    nutritionist_last_name: "",
    program_id: "",
    program_start_date: "",
    from_date: "",
    to_date: "",
    status: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [modalResetShow, setModalResetShow] = useState(false);
  const [resetInputs, setResetInputs] = useState();
  const [modalSwitchShow, setModalSwitchShow] = useState(false);
  const [switchInputs, setSwitchInputs] = useState();
  const [switchProgram, setSwitchProgram] = useState({
    switch_select_program: ''
  });
  const [switchProgramInputs, setSwitchProgramInputs] = useState({
    program_id: '',
    week: '',
  });
  const [switchValidated, setSwitchValidated] = useState(false);
  const [switchProgramList, setSwitchProgramList] = useState();
  const [weekList, setWeekList] = useState()
  const [switchUserDetailsList, setSwitchUserDetailsList] = useState();
  const [switchEditClick, setSwitchEditClick] = useState(false);
  const [goalOptions, setGoalOptions] = useState()
  const [levelOptions, setLevelOptions] = useState()
  const [dietTypeList, setDietTypeList] = useState()
  const [healthConditionOptions, setHealthConditionOptions] = useState()
  const [conditionValue, setConditionsValue] = useState([]);
  const [statiConditions, setStatiConditions] = useState([]);


  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
    setOffset(1);
    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item.id,
          label: item.first_name,
          name: item.first_name,
        });
      });
    }
    return finalArr;
  };

  const getNutritionList = async () => {
    const data = {
      url: "nutritionist_name_list",
      body: {
        user_ids: []
      },
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setNutritionistList(newData.data.nutritionist_name_list);
    }
  };

  const getUserDetails = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      url: "user_insight",
      body: {
        user_id: e.target.getAttribute("dataid"),
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setUserDetails({
        ...newData.data.insights,
        ...newData.data.program,
        ...newData.data.user,
        week: newData.data.userCurrentWeek,
      });

      setModalUserShow(true);
      // setUserDetails(newData);
    }
    else {
      isNotLoading();

    }
  };

  const getProgramList = async () => {
    const data = {
      url: "master_program_name_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setProgramList(newData.data.master_program_name_list);
    }
  };

  function openCountModal(e) {
    let user_id = e.target.getAttribute("user_id");
    let user_name = e.target.getAttribute("user_name");
    let out_of_step = e.target.getAttribute("out_of_step");
    let out_of_water = e.target.getAttribute("out_of_water");
    setFormInputs({
      user_id: user_id,
      user_name: user_name,
      out_of_step: out_of_step,
      out_of_water: out_of_water,
    });
    setModalCountShow(true);
  }

  const modalUserBody = () => {
    return (
      <>
        <Row className="p-3">
          <Col md="5">
            <strong>Gender</strong>
          </Col>
          <Col md="7">{userDetails?.gender ? userDetails?.gender : "N/A"}</Col>

          <Col md="5">
            <strong>Age</strong>
          </Col>
          <Col md="7">{userDetails?.age ? userDetails?.age : "N/A"}</Col>
          <Col md="5">
            <strong>Height</strong>
          </Col>
          <Col md="7">
            {userDetails?.height
              ? `${userDetails?.height} ${userDetails?.height_sign}`
              : "N/A"}
          </Col>
          <Col md="5">
            <strong>Weight</strong>
          </Col>
          <Col md="7">
            {userDetails?.weight
              ? `${userDetails?.weight} ${userDetails?.weight_sign}`
              : "N/A"}
          </Col>
          <Col md="5">
            <strong>Diet Type</strong>
          </Col>
          <Col md="7">
            {userDetails?.diet_type ? userDetails?.diet_type : "N/A"}
          </Col>
          <Col md="5">
            <strong>Goal</strong>
          </Col>
          <Col md="7">{userDetails?.goal ? userDetails?.goal : "N/A"}</Col>
          <Col md="5">
            <strong>Level</strong>
          </Col>
          <Col md="7">
            {userDetails?.activity_level ? userDetails?.activity_level : "N/A"}
          </Col>
          <Col md="5">
            <strong>Program</strong>
          </Col>
          <Col md="7">
            {userDetails?.program_name ? userDetails?.program_name : "N/A"}
          </Col>
          <Col md="5">
            <strong>Week</strong>
          </Col>
          <Col md="7">{userDetails?.gender ? userDetails?.week : "N/A"}</Col>
          <Col md="5">
            <strong>Health Conditions</strong>
          </Col>
          <Col md="7">
            {userDetails?.conditions ? userDetails?.conditions : "N/A"}
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    isLoading()
  }, [offset])
  const modalCountBody = () => {
    const handleCountInput = (e) => {
      let val =
        e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
      setFormInputs((prev) => ({
        ...prev,
        [e.target.name]: val,
      }));
    };
    const handleCountSubmit = async (e) => {
      e.preventDefault();
      const form = e.currentTarget;

      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);

      if (form.checkValidity() === true) {
        const data = {
          url: "update_user_water_and_step",
          body: {
            ...formInputs,
          },
        };
        const newData = await postMethod(data);
        showToastFn(newData.message);


        if (newData.status == 1) {
          isNotLoading();
          setModalCountShow(false);
          setValidated(false);
          if (performanceDisabled) {
            getTableList("registered_user_list", true);
          } else {
            getTableList("registered_user_list");
          }
        }
        else {
          isNotLoading();

        }
      }
    };

    return (
      <>
        <h5 className="text-left mt-0 mb-3">
          User: <u>{formInputs?.user_name}</u>
        </h5>
        <Form
          noValidate
          validated={validated}
          className="nne"
          onSubmit={handleCountSubmit}
        >
          <Row>
            <Col>
              <Form.Group controlId="waterCt">
                <Form.Label className="required">Water Count</Form.Label>
                <Form.Control
                  name="out_of_water"
                  type="number"
                  min="1"
                  value={formInputs?.out_of_water}
                  required
                  onChange={handleCountInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="stepCt">
                <Form.Label className="required">Step Count</Form.Label>
                <Form.Control
                  name="out_of_step"
                  type="number"
                  min="1"
                  value={formInputs?.out_of_step}
                  required
                  onChange={handleCountInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-center my-3">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </>
    );
  };
  useEffect(() => {
    if (modalShow) {
      getNutritionListByIds()
    }
  }, [modalShow])

  function removeDuplicates(arr) {
    return [...new Set(arr)];
  }

  const getNutritionListByIds = async () => {
    // let filteredArray =tableList.filter(val=>selectedRow.includes(val.id))
    // let nutId = filteredArray.map((item)=>item.nutritionist_name.id)
    // let finalNutId = removeDuplicates(nutId)
    const data = {
      url: "nutritionist_name_list",
      body: {
        user_ids: selectAll ? [] : selectedRow
      },
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setFilterNutritionistList(newData.data.nutritionist_name_list);
    }
  };

  const ModalBody = () => {

    const handleReassignInput = (selectedOptions) => {
      setErrMsg('')
      if (selectedOptions && selectedOptions.length > 0) {
        setReassignId(selectedOptions[selectedOptions.length - 1]);
      } else {
        setReassignId();
      }
    };

    const handleReassignSubmit = async (e) => {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!reassignId) {
        setErrMsg('Please select a nutritionist')
      }
      setValidated(true);


      if (reassignId) {
        const data = {
          url: "reassign_nutrition",
          body: {
            search: { value: searchValue },
            is_select_all: selectAll,
            user_ids: selectAll ? [] : selectedRow,
            nutrition_id: reassignId.value,
            ...filterInputs,
          },
        };
        const newData = await postMethod(data);
        showToastFn(newData.message);
        if (newData.status == 1) {
          isNotLoading();
          getTableList("registered_user_list");
          setModalShow(false);
          setSelectedRow([]);
          tableRef.current.resetSelectRow();
          setSelectAll(false)


        }
        else {
          isNotLoading();

        }
      }
    };

    return (
      <>
        <h3 className="text-center mt-3 mb-5">Re-assign Nutritionist</h3>
        <Form
          noValidate
          validated={validated}
          className="mwx-500 ml-auto mr-auto"
          onSubmit={handleReassignSubmit}
        >
          <Form.Group controlId="reassign" className="mw-200">
            <Form.Label className="required">Select a Nutritionist</Form.Label>
            {/* <Form.Control
              as="select"
              name="reassign_user"
              required
              value={reassignId ? reassignId : ""}
              onChange={handleReassignInput}
            >
              <option value="">Filter By</option>
              {nutritionistList &&
                nutritionistList.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.first_name}`gmjo;.]-
                  </option>
                ))}
            </Form.Control> */}
            <Select
              value={reassignId ? reassignId : ""}
              isMulti
              name="nut-dropdown"
              options={formatSelectOptions(filterNutritionistList)}
              className="basic-multi-select"
              classNamePrefix="select"
              required
              onChange={(selectedOption) => handleReassignInput(selectedOption)}
            />
            <p className="text-danger m-1">{errMsg && errMsg}</p>
            <Form.Control.Feedback type="invalid">
              {errMsg}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-center mt-5 mb-3">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </>
    );
  };
  const handleCloseAssign = () => {
    setModalShow(false)
    setErrMsg('')
  }
  const userStatusChange = (id, is_active) => {
    const data = {
      url: "user_active",
      body: {
        user_id: id,
        is_active: is_active == 0 ? 1 : 0,
      },
    };

    confirmAlert({
      title: `Confirm ${is_active == 0 ? "Activate" : "De-Activate"} user?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              isNotLoading();
              getTableList("registered_user_list");
            }
            else {
              isNotLoading();

            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const prgStatusChange = (row) => {
    const data = {
      url: "submit_program_switch_off",
      body: {
        user_id: row.id,
        program_switch_off: row.program_switch_off == 0 ? 1 : 0,
      },
    };

    confirmAlert({
      title: `Confirm ${row.program_switch_off == 0 ? "Activate" : "De-Activate"
        } program for this user`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              isNotLoading();
              if (performanceDisabled) {
                getTableList("registered_user_list", true);
              } else {
                getTableList("registered_user_list");
              }
            }
            else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  function btnFormatter(cell, row) {
    // const renderTooltip = (props) => (
    //   <Tooltip id="button-tooltip" {...props}>
    //     Simple tooltip
    //   </Tooltip>
    // );
    return (
      <>
        <Form.Check
          type="switch"
          id={`toggle-switch-${cell}`}
          checked={row.is_active == 0 ? false : true}
          onChange={() => userStatusChange(cell, row.is_active)}
        />
      </>
    );
  }

  function btnFormatterPrg(cell, row, rowIndex) {
    return (
      <>
        <Form.Check
          type="switch"
          id={`toggle-switch-prg-${rowIndex}`}
          checked={row.program_switch_off == 0 ? false : true}
          onChange={() => prgStatusChange(row)}
        />
      </>
    );
  }

  function btnFormatterPerf(cell, row, rowIndex) {
    return (
      <>
        <Form.Check
          type="checkbox"
          id={`toggle-switch-prf-${rowIndex}`}
          checked={row.is_performer_of_the_week == 0 ? false : true}
          disabled={row.is_active == 0 || row.program_switch_off == 0}
          onChange={() => prfStatusChange(row)}
        />
      </>
    );
  }

  function btnFormatterCount(cell, row, rowIndex) {
    return (
      <>
        <Button
          user_id={row.id}
          user_name={row.first_name}
          out_of_step={row.out_of_step}
          out_of_water={row.out_of_water}
          onClick={openCountModal}
          variant="link"
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        {parseInt(cell) > 0 && (
          <div className="ct-icons">
            <FontAwesomeIcon icon={faGlassWhiskey} />
            <FontAwesomeIcon icon={faShoePrints} />
          </div>
        )}
      </>
    );
  }

  const handleSelect = (e) => {
    setSelectAll(!selectAll)
  }
  function prfStatusChange(row) {
    const data = {
      url: `add_performer_of_the_week/${row.id}`,
    };
    confirmAlert({
      title: `Confirm the action`,
      message: `Are you sure to ${row.is_performer_of_the_week == 0 ? "add" : "remove"
        } ${row.first_name} as the performer of the week ?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await getMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              if (performanceDisabled) {
                getTableList("registered_user_list", true);
              } else {
                getTableList("registered_user_list");
              }
              // setPerformanceDisabled(false);
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }

  function nutritionFormatter(cell, row) {
    return <span>{cell && cell.first_name ? cell.first_name : "-"}</span>;
  }
  function formatterNutFilter(cell, row) {
    return cell?.first_name ? cell.first_name : "-";
  }

  function startdtFormatter(cell, row) {
    return <span>{cell && cell.start_date ? cell.start_date : "-"}</span>;
  }

  function progNameFormatter(cell, row) {
    return <span>{cell && cell.program_name ? cell.program_name : "-"}</span>;
  }

  function nameFormatter(cell, row) {
    return (
      <Button variant="link btnLinkUs" dataid={row.id} onClick={getUserDetails}>
        {cell}
        {row.is_suggest == 1 && <span className="pertext">Performer</span>}
      </Button>
    );
  }

  function formatterFilter(cell, row) {
    return cell;
  }
  function formatterPrgFilter(cell, row) {
    return cell && cell.program_name ? cell.program_name : null;
  }

  function formatterNameFilter(cell, row) {
    return cell;
  }


  const switchProgramFormatter = (cell, row, id) => {
    return (
      <>
        <Button
          switch_details={row}
          onClick={() => { openSwitchModal(row) }}
          variant="link"
          className="switch-btn"
        >
          <img src={SwitchIcon} alt="icon" />
        </Button>
      </>
    );
  };

  function openSwitchModal(row) {
    setSwitchInputs(row);
    setSwitchProgramInputs(row)
    setModalSwitchShow(true);
    setSwitchValidated(false)
  }
  const resetPasswordFormatter = (cell, row, id) => {
    return (
      <>
        <Button
          reset_details={row}
          onClick={() => { openResetModal(row) }}
          variant="link"
        >
          <img src={ResetIcon} alt="icon" />
        </Button>
      </>
    );
  };
  function openResetModal(row) {
    setResetInputs(row);
    setModalResetShow(true);
  }

  const getSelections = (data) => {
    setConditionsValue(data);
    setSwitchProgramInputs((prev) => ({
      ...prev,
      conditions: data?.length > 0
        ? data?.map((item) => {
          return item.value;
        })
        : "",
    }));
  };

  const handleResetPassword = async (email) => {
    const data = {
      url: "user/admin_user_reset_password",
      body: {
        email: email
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      showToastFn(newData.message);
      setModalResetShow(false);
      getTableList("registered_user_list");
    } else {
      showToastFn(newData.message);
      setModalResetShow(false);
    }
  }

  const handleSwitchChange = (e) => {
    setSwitchValidated(false)
    setSwitchProgram((prev) => ({
      ...prev,
      switch_select_program: e.target.value,
    }));
    if (e.target.value == "1") {
      getTableList("registered_user_list");
    }
  };

  const modalResetBody = () => {
    return (
      <>
        <div className="text-left mt-0 mb-3">
          User Name : {resetInputs?.first_name}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div>Current Program : {resetInputs?.program?.program_name ? resetInputs?.program?.program_name : "N/A"}</div>
          <div>Current Week :  {resetInputs?.userCurrentWeek ? resetInputs?.userCurrentWeek : "N/A"}</div>
        </div>
        <div className="my-4">
          Are you sure do you want to reset the password for this user?
        </div>
        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              setModalResetShow(false);
            }}
            className="mr-3"
            type="button"
          >
            Cancel
          </Button>
          <Button variant="primary"
            type="button"
            onClick={() => {
              handleResetPassword(resetInputs?.email);
            }}>Yes
          </Button>
        </div>
      </>
    );
  };

  const handleInput = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    setSwitchProgramInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === 'program_id') {
      setSwitchProgramInputs((prev) => ({
        ...prev,
        weekKey: '0'
      }));
    }
    if (name === 'week') {
      var SwitchWeekId = document.querySelector("#SwitchWeek");
      var SwWeekName = SwitchWeekId.options[SwitchWeekId.selectedIndex].getAttribute('SwitchWeekName')
      var SwWeekkey = SwitchWeekId.options[SwitchWeekId.selectedIndex].getAttribute('weekKey')
      setSwitchProgramInputs((prev) => ({
        ...prev,
        switchWeekName: SwWeekName,
        weekKey: SwWeekkey
      }));
    }
  };
  const handleEditClick = () => {
    setSwitchEditClick(true);
    setSwitchValidated(false)
  };

  const modalSwitchBody = () => {
    return (
      <>
        <div className="text-left mt-0 mb-3">
          User Name : {switchInputs?.first_name}
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div>Current Program : {switchInputs?.program?.program_name ? switchInputs?.program?.program_name : 'N/A'}</div>
          <div>Current Week :  {switchInputs?.userCurrentWeek ? switchInputs?.userCurrentWeek : 'N/A'}</div>
        </div>
        <div className="my-4">
          <Form.Group className="mb-3 switch-select-program" controlId="switch_select_program">
            <div>
              <Form.Check
                inline
                label="Switch Program"
                name="switch_select_program"
                type="radio"
                checked={switchProgram.switch_select_program == "1" ? true : false}
                value="1"
                onChange={(e) => handleSwitchChange(e)}
                id="switch"
              />
              <Form.Check
                inline
                label="Reset Program"
                name="switch_select_program"
                type="radio"
                checked={switchProgram.switch_select_program == "0" ? true : false}
                value="0"
                onChange={(e) => handleSwitchChange(e)}
                id="reset"
              />
            </div>
            {
              switchValidated && (switchProgram.switch_select_program === '' || switchProgram.switch_select_program === null || switchProgram.switch_select_program === undefined) && (
                <div className="err-msg">
                  This field is required
                </div>
              )
            }
          </Form.Group>
        </div>
        {
          switchProgram.switch_select_program == "0" &&
          <div className="my-4">
            The program will be reset and starts from the onboarding
          </div>
        }
        {
          switchProgram.switch_select_program == "1" &&
          <div className="my-4">
            <Form noValidate validated={switchValidated}>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="program_id">
                    <Form.Label className="required">Program Name</Form.Label>
                    <Form.Control
                      as="select"
                      name="program_id"
                      value={switchProgramInputs.program_id}
                      required
                      disabled={switchProgramInputs?.is_free_trail_user === 1 || switchProgramInputs?.program_id === null}
                      onChange={handleInput}
                    >
                      <option value="">Select a program</option>
                      {switchProgramList?.map((item, index) => (
                        <option key={index} value={item?.id}>
                          {item?.program_name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="week">
                    <Form.Label className="required">Select a Start Week </Form.Label>
                    <Form.Control
                      as="select"
                      name="week"
                      value={switchProgramInputs?.week}
                      required
                      disabled={switchProgramInputs?.is_free_trail_user === 1 || switchProgramInputs?.program_id === null}
                      onChange={handleInput}
                      id="SwitchWeek"
                    >
                      <option value="">Select a week</option>
                      {weekList?.map((item, index) => (
                        <option key={index} value={item} SwitchWeekName={item} weekKey={index}>
                          {item}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <div className="switch-title">User details</div>
                </Col>
                <Col md={4} >
                  <div className="text-right">
                    <Button variant="primary"
                      type="button"
                      className="switch-edit"
                      disabled={switchEditClick}
                      onClick={() => {
                        handleEditClick();
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Col>
              </Row>
              <div className="mt-3 mt-4">
                <Row className="mb-2">
                  <Col md="5">
                    <b>Email</b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="email">
                          <Form.Control
                            name="email"
                            type="email"
                            pattern="[a-zA-Z0-9!#$%^&*_+-.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                            value={switchProgramInputs.email}
                            required
                            onChange={handleInput}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter a valid Email ID
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.email
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Gender</b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="gender">
                          <Form.Control
                            as="select"
                            name="gender"
                            value={switchProgramInputs.gender}
                            required
                            onChange={handleInput}
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.gender
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Age</b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="age">
                          <Form.Control
                            type="number"
                            min="18"
                            max="78"
                            name="age"
                            placeholder="Minimum age 18"
                            value={switchProgramInputs.age}
                            required
                            onChange={handleInput}
                            isInvalid={
                              switchProgramInputs?.program_id !== null &&
                              (!switchProgramInputs.age && switchProgramInputs.age !== 0) ||
                              (switchProgramInputs.age < 18 || switchProgramInputs.age > 78)
                            }
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {(!switchProgramInputs.age && switchProgramInputs.age !== 0) ? "This field is required" : "Please enter an age between 18 and 78."}
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.age
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Height</b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="height">
                          <Form.Control
                            type="number"
                            min="122"
                            max="198"
                            placeholder="Minimum height 122cm"
                            name="height"
                            value={switchProgramInputs.height}
                            required
                            onChange={handleInput}
                            isInvalid={
                              switchProgramInputs?.program_id !== null &&
                              (!switchProgramInputs.height && switchProgramInputs.height !== 0) ||
                              (switchProgramInputs.height < 122 || switchProgramInputs.height > 198)
                            }
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {(!switchProgramInputs.height && switchProgramInputs.height !== 0) ? "This field is required" : "Please enter an height between 122cm and 198cm."}
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.height
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Weight</b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="weight">
                          <Form.Control
                            type="number"
                            min="20"
                            max="200"
                            name="weight"
                            placeholder="Minimum weight 20kg"
                            value={switchProgramInputs.weight}
                            required
                            onChange={handleInput}
                            isInvalid={
                              switchProgramInputs?.program_id !== null &&
                              (!switchProgramInputs.weight && switchProgramInputs.weight !== 0) ||
                              (switchProgramInputs.weight < 20 || switchProgramInputs.weight > 200)
                            }
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {(!switchProgramInputs.weight && switchProgramInputs.weight !== 0) ? "This field is required" : "Please enter an weight between 20kg and 200kg."}
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.weight
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Diet type </b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="diet_type">
                          <Form.Control
                            as="select"
                            name="diet_type"
                            value={switchProgramInputs.diet_type}
                            required
                            onChange={handleInput}
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          >
                            <option value="">Choose Diet Type</option>
                            {dietTypeList &&
                              dietTypeList?.map((item, index) => (
                                <option key={index} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.diet_type
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Goal  </b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="goal">
                          <Form.Control
                            as="select"
                            name="goal"
                            value={switchProgramInputs.goal}
                            required
                            onChange={handleInput}
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          >
                            <option value="">Select goal</option>
                            {goalOptions &&
                              goalOptions?.map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.goal
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Level   </b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="activity_level">
                          <Form.Control
                            as="select"
                            name="activity_level"
                            value={switchProgramInputs.activity_level}
                            required
                            onChange={handleInput}
                            disabled={switchProgramInputs?.program_id === null ? true : false}
                          >
                            <option value="">Select level</option>
                            {levelOptions &&
                              levelOptions?.map((item, index) => (
                                <option key={index} value={item}>
                                  {item}
                                </option>
                              ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                        :
                        switchUserDetailsList?.user_combination?.activity_level
                    }
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md="5">
                    <b>Health Conditions  </b>
                  </Col>
                  <Col>
                    {
                      switchEditClick ?
                        <Form.Group controlId="conditions">
                          <Dropdown
                            options={
                              switchProgramInputs?.gender
                                ? switchProgramInputs?.gender == "Male"
                                  ? statiConditions.male.answer
                                  : statiConditions.female.answer
                                : null
                            }
                            value={conditionValue}
                            getSelections={getSelections}
                            name="conditions"
                            placeholder="Select conditions"
                            isDisabled={switchProgramInputs?.program_id === null ? true : false}
                          />
                          {
                            switchValidated && switchProgramInputs?.conditions?.length === 0 &&
                            <div className="err-msg">
                              This field is required
                            </div>
                          }
                        </Form.Group>
                        :
                        <div className="d-flex flex-row">
                          {switchUserDetailsList?.user_combination?.conditions?.map((item, index) => (
                            <React.Fragment key={item}>
                              <div>{item}</div>
                              {index !== switchUserDetailsList.user_combination.conditions.length - 1 && <div className="mx-2">,</div>}
                            </React.Fragment>
                          ))}
                        </div>

                    }
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        }

        <div className="d-flex justify-content-end mt-3">
          <Button
            variant="secondary"
            onClick={() => {
              closeSwitchModal()
            }}
            className="mr-3"
            type="button"
          >
            Cancel
          </Button>
          <Button variant="primary"
            type="submit"
            onClick={(e) => handleSwitchSubmit(e)}
          >
            {switchProgram.switch_select_program == "0" ? 'Reset' : switchProgram.switch_select_program == "1" ? 'Update' : 'Submit'}
          </Button>
        </div >
      </>
    );
  };


  const handleSwitchSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      setSwitchValidated(true);

      if (form.checkValidity() === true) {
        handleSwitchProgram();
      }
    }


  }

  const handleSwitchProgram = async () => {
    if (switchProgram.switch_select_program == "0") {
      const data = {
        url: "user/reset_user",
        body: {
          email: switchInputs?.email
        },
      };
      const newData = await postMethod(data);
      if (newData.status == 1) {
        isNotLoading();
        showToastFn(newData.message);
        setModalSwitchShow(false);
        getTableList("registered_user_list");
      } else {
        showToastFn(newData.message);
        setModalSwitchShow(false);
      }
    } else if (switchProgram.switch_select_program == "1") {
      const data = {
        url: "user/admin_switch_user ",
        body: {
          id: switchInputs?.id,
          ...switchProgramInputs
        },
      };

      let reqFields;
      if (switchEditClick) {
        if (switchProgramInputs?.program_id !== null) {
          reqFields = _.pick(
            switchProgramInputs,
            "program_id",
            "week",
            "conditions",
            "activity_level",
            "goal",
            "diet_type",
            "weight",
            "height",
            "age",
            "gender",
            "email"
          );
          if (!switchProgramInputs?.age || switchProgramInputs?.age < 18 || switchProgramInputs?.age > 78) {
            return;
          } else if (!switchProgramInputs?.weight || switchProgramInputs?.weight < 20 || switchProgramInputs?.weight > 200) {
            return;
          } else if (!switchProgramInputs?.height || switchProgramInputs?.height < 122 || switchProgramInputs?.height > 198) {
            return;
          }
        } else {
          console.log('here')
          reqFields = _.pick(
            switchProgramInputs,
            "email"
          );
        }

        // Validate the form inputs

      } else {
        if (switchProgramInputs?.is_free_trail_user === 0) {
          reqFields = _.pick(
            switchProgramInputs,
            "program_id",
            "week"
          );
        } else {
          reqFields = _.pick(
            switchProgramInputs
          );
        }
      }

      const errors = findErrors(reqFields);
      console.log("errors", errors)
      if (!errors) {
        const newData = await postMethod(data);
        if (newData.status == 1) {
          isNotLoading();
          showToastFn(newData.message);
          getTableList("registered_user_list");
          setModalSwitchShow(false);
          setSwitchEditClick(false);
          setSwitchProgram({
            switch_select_program: ''
          });
        } else {
          showToastFn(newData.message);
          setModalSwitchShow(false);
          setSwitchEditClick(false);
          setSwitchProgram({
            switch_select_program: ''
          });
          isNotLoading();
        }
      }
    }
  }

  const closeSwitchModal = () => {
    setSwitchValidated(false)
    setModalSwitchShow(false);
    setSwitchProgram({
      switch_select_program: ''
    });
    setSwitchEditClick(false);

  }

  const columns = [
    { dataField: "sno", text: "S.No" },
    {
      dataField: "first_name",
      text: "Username",
      classes: "prf-col position-relative",
      formatter: nameFormatter,
      filterValue: (cell, row) => formatterNameFilter(cell, row),
    },
    { dataField: "email", text: "Email ID" },
    { dataField: "mobile", text: "Mobile Number" },
    { dataField: "onboarded_date", text: "Onboarded Date" },
    { dataField: "buffer_start", text: "Buffer Start Date" }, { dataField: "buffer_end", text: "Buffer End Date" },
    {
      dataField: "program",
      text: "Program Name",
      formatter: progNameFormatter,
      filterValue: (cell, row) => formatterPrgFilter(cell, row),
    },
    {
      dataField: "switch",
      text: "Switch Program",
      formatter: switchProgramFormatter,
    },
    {
      dataField: "reset",
      text: "Reset Password",
      formatter: resetPasswordFormatter,
    },
    {
      dataField: "user_program",
      text: "Program Start Date",
      formatter: startdtFormatter,
    },
    {
      dataField: "nutritionist_name",
      text: "Nutritionist Name",
      formatter: nutritionFormatter,
      filterValue: (cell, row) => formatterNutFilter(cell, row),
    },
    // { dataField: "status", text: "Status" },
    {
      dataField: "out_of_step",
      text: "Water & Step Count",
      classes: "count-col position-relative",
      formatter: btnFormatterCount,
    },
    {
      dataField: "is_performer_of_the_week",
      text: "Performer of the week",
      formatter: btnFormatterPerf,
    },
    {
      dataField: "program_switch_off",
      text: "Program Status",
      formatter: btnFormatterPrg,
    },
    { dataField: "id", text: "User Status", formatter: btnFormatter },
  ];

  const getSelectedRow = (data) => {
    setSelectedRow(data);
  };

  const assignNutrition = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setModalShow(true);
    setReassignId();
    setValidated(false);
  };

  const tableFilters = () => {
    return (
      <Row>
        <Col md="12" className="d-flex">
          <InputGroup className="search-register-user mr-5">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleSearchInput}
            />
          </InputGroup>
          <Button
            className="mr-3"
            variant="primary"
            disabled={performanceDisabled}
            onClick={(e) => {
              getTableList("registered_user_list", true);
              setPerformanceDisabled(true);
            }}
          >
            Recommended Performer of the week
          </Button>
          <Button
            variant="primary"
            disabled={selectedRow && selectedRow.length != 0 ? false : selectAll ? false : true}
            onClick={(e) => assignNutrition(e)}
          >
            Re-Assign Nutrition
          </Button>
        </Col>
        <Col md="2" className="d-flex align-items-center defaultSelectBox mt-1 ml-3 ">
          <Form.Group
            className="mb-3 d-flex"
            controlId="formBasicCheckbox"
          >
            <Form.Check
              type={'checkbox'}
              id={`default-checkbox`}
              checked={selectAll}
              onChange={handleSelect}
            />
            <Form.Label className="mt-3 pl-2">
              Select All
            </Form.Label>
          </Form.Group>
        </Col>

      </Row>
    );
  };

  const getTableList = async (url, perf, flIps) => {
    const data = {
      url: url,
      body: {
        search: { value: searchValue },
        length: 10,
        start: offset == 1 ? 0 : (offset - 1) * 10,
        draw: 10,
        is_recommended: 0,
        ...filterInputs,
      },
    };
    if (perf) {
      data.body.is_recommended = 1;
    }
    if (flIps) {
      data.body = {
        ...data.body,
        ...flIps,
      };
    }
    const newData = await postMethod(data);
    setShowToast(true);
    if (newData.status == 1 && newData.data?.user_list?.length > 0) {
      isNotLoading();
      setToastMessage(newData.message);
      setTableCount(newData.data.recordsTotal);
      setTableList(newData.data.user_list);
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));

    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
      setToastMessage(newData.message);

    }
    else if (newData.status == 1 && newData.data?.user_list?.length == 0) {
      setToastMessage(newData.message);
      isNotLoading();

    } else {
      isNotLoading();
      setTableList([]);
      setPageCount(0);
      setToastMessage(newData.message);

    }
  };

  // useEffect(() => {
  //   getTableList("registered_user_list");
  // }, [offset]);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1);
  };

  const showAllRecords = () => {
    setTableDraw(tableCount);
  };

  const getTableListFull = async (url, draw) => {
    let tableList = await getTableData(url);
    if (tableList && tableList?.user_list?.length > 0) {
      setTableList(tableList.user_list);
      setTableCount(tableList.recordsTotal);
      setShowButton(false);
    }
  };

  // useEffect(() => {
  //   getTableList("registered_user_list");
  // }, [offset,])

  useEffect(() => {
    setTimeout(() => {
      getTableList("registered_user_list");
    }, 1000);
  }, [offset, searchValue]);



  useEffect(() => {
    if (tableDraw && tableDraw > 0) {
      getTableListFull("registered_user_list", tableDraw);
    }
  }, [setTableDraw, tableDraw, offset]);

  const handleFilterInput = (e) => {
    setFilterInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    const data = {
      url: "registered_user_list",
      body: {
        search: { value: searchValue },
        length: 10,
        start: offset == 0 ? 0 : (offset - 1) * 10,
        draw: 10,
        ...filterInputs,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data?.user_list?.length > 0) {
      // alert('if')
      isNotLoading();
      setTableCount(newData.data.recordsTotal);
      setTableList(newData.data.user_list);
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      setPerformanceDisabled(false);
    } else {
      isNotLoading();
      setPageCount(0);
      setTableList([]);
      setTableCount(0);
      setTableList([]);
      showToastFn(newData.message);
    }
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name, dataid) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();
    if (chkDt > 0) {
      let val = formatDates(date);
      setFilterInputs((prev) => ({
        ...prev,
        [name]: val,
      }));

      setDateInputs((prev) => ({
        ...prev,
        [dataid]: dateSel,
      }));
    }
  };

  const clearDate = () => {
    setDateInputs({
      select_to_date: null,
      select_from_date: null,
      select_program_start_date: null,
    });

    setFilter(false);
    setOffset(1);
    const flIps = {
      nutritionist_first_name: "",
      nutritionist_last_name: "",
      program_id: "",
      program_start_date: "",
      from_date: "",
      to_date: "",
      status: "",
    };
    setFilterInputs(flIps);
    setPerformanceDisabled(false);
    getTableList("registered_user_list", false, flIps);
  };

  useEffect(() => {
    getSwitchProgramList();
    getStaticList();
    getUserDetailsById();
    getDietType()
    getStaticCondtionsList();
  }, [switchProgram.switch_select_program == "1"])

  useEffect(() => {
    ProgramWeeks();
  }, [switchProgram.switch_select_program == "1" && switchProgramInputs.program_id]);

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
    return () => {
      setPageLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // getTableList("registered_user_list");
      getNutritionList();
      getProgramList();
    }
  }, [pageLoaded, setPageLoaded]);

  const getSwitchProgramList = async () => {
    const data = {
      url: "user/active_program_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setSwitchProgramList(newData.data);
      isNotLoading();
    }
  };

  const getStaticList = async () => {
    const data = {
      url: "static_content_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setGoalOptions(newData?.data?.goal);
      setLevelOptions(newData?.data?.activity_level);
      setHealthConditionOptions(newData?.data?.conditions)
      isNotLoading();
    }
  };

  const getUserDetailsById = async () => {
    const data = {
      url: "user/view_user_by_id",
      body: {
        id: switchInputs?.id
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      setSwitchUserDetailsList(newData.data);
      setSwitchProgramInputs((prev) => ({
        ...prev,
        gender: newData?.data?.user_combination?.gender,
        age: newData?.data?.user_combination?.age,
        height: newData?.data?.user_combination?.height,
        weight: newData?.data?.user_combination?.weight,
        diet_type: newData?.data?.user_combination?.diet_type,
        goal: newData?.data?.user_combination?.goal,
        activity_level: newData?.data?.user_combination?.activity_level,
        conditions: newData?.data?.user_combination?.conditions
      }));
      setConditionsValue(newData?.data?.user_combination?.conditions?.map((item, index) => {
        return { key: index, title: item, label: item, value: item };
      })
      );
      isNotLoading();
    }
  };

  const ProgramWeeks = async () => {
    const data = {
      url: "program_weeks",
      body: {
        program_id: switchProgramInputs.program_id,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setWeekList(newData.data.program_weeks);
    } else {
      isNotLoading();
      setWeekList([]);
    }
  };

  const getDietType = async () => {
    const data = {
      url: "list_recipe_diet_type",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setDietTypeList(newData?.data?.recipe_diet_type_list);
      isNotLoading();
    }
  };

  const getStaticCondtionsList = async () => {
    const data = {
      url: "static_qus_ans",
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setStatiConditions(newData.data.satic_qus_ans[3]);
    }
    else {
      isNotLoading();
    }
  };
  return (
    <section className="rightContent">
      {props.menuList.includes(3) ? (
        <>
          <h3>Manage Registered Users</h3>
          <Form className="inline-form mt-3" onSubmit={handleFilterSubmit}>
            <Form.Row className="align-items-center ">
              <Col xs="auto" className="d-flex flex-column my-2">
                <Form.Label>Program Name</Form.Label>
                <Form.Control
                  as="select"
                  name="program_id"
                  value={filterInputs.program_id}
                  onChange={handleFilterInput}
                >
                  <option value="">Select a Program</option>
                  {programList &&
                    programList.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.program_name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
              <Col xs="auto" className="d-flex flex-column my-2">
                <Form.Label>Nutritionist Name</Form.Label>
                <Form.Control
                  as="select"
                  name="nutritionist_first_name"
                  value={filterInputs.nutritionist_first_name}
                  onChange={handleFilterInput}
                >
                  <option value="">Select a Nutritionist</option>
                  {nutritionistList &&
                    nutritionistList.map((item, index) => (
                      <option value={item.first_name} key={index}>
                        {item.first_name}
                      </option>
                    ))}
                </Form.Control>
              </Col>
              <Col xs="auto" className="d-flex flex-column my-2">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  name="status"
                  onChange={handleFilterInput}
                  value={filterInputs.status}
                >
                  <option value="">Select a Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Control>
              </Col>
              {/* </Form.Row>
              <Form.Row className="align-items-center "> */}
              <Col xs="auto" className="d-flex flex-column my-2 ">
                <Form.Label>Onboarded Date</Form.Label>
                <Row className="datePickerBox">
                  <Col className="pr-0">
                    <DatePicker
                      onChange={(date) =>
                        selectUploadDt(date, "from_date", "select_from_date")
                      }
                      selected={
                        dateInputs.select_from_date
                          ? dateInputs.select_from_date
                          : null
                      }
                      maxDate={new Date()}
                      name="from_date"
                      dataid="select_from_date"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="yyyy/MM/dd"
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="pr-0">
                    <DatePicker
                      onChange={(date) =>
                        selectUploadDt(date, "to_date", "select_to_date")
                      }
                      selected={
                        dateInputs.select_to_date
                          ? dateInputs.select_to_date
                          : null
                      }
                      name="to_date"
                      dataid="select_to_date"
                      maxDate={new Date()}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="yyyy/MM/dd"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs="auto" className="d-flex flex-column my-2 datePickerBox">
                <Form.Label>Program Start Date</Form.Label>
                <DatePicker
                  onChange={(date) =>
                    selectUploadDt(
                      date,
                      "program_start_date",
                      "select_program_start_date"
                    )
                  }
                  selected={
                    dateInputs.select_program_start_date
                      ? dateInputs.select_program_start_date
                      : null
                  }
                  name="program_start_date"
                  dataid="select_program_start_date"
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/MM/dd"
                  autoComplete="off"
                />
              </Col>
              <Col xs="auto" className="pt-4">
                <Button
                  type="reset"
                  variant="secondary"
                  className="mb-2 mr-3"
                  disabled={
                    filterInputs.nutritionist_first_name != "" ||
                      filterInputs.nutritionist_last_name != "" ||
                      filterInputs.program_id != "" ||
                      filterInputs.from_date != "" ||
                      filterInputs.to_date != "" ||
                      filterInputs.program_start_date != "" ||
                      filterInputs.status != ""
                      ? false
                      : true
                  }
                  onClick={clearDate}
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  className="mb-2"
                  disabled={
                    filterInputs.nutritionist_first_name != "" ||
                      filterInputs.nutritionist_last_name != "" ||
                      filterInputs.program_id != "" ||
                      filterInputs.from_date != "" ||
                      filterInputs.to_date != "" ||
                      filterInputs.program_start_date != "" ||
                      filterInputs.status != ""
                      ? false
                      : true
                  }
                >
                  Apply Filter
                </Button>
              </Col>
            </Form.Row>
            {/* <Form.Row className="align-items-center justify-content-center mt-4">
              <Col xs="auto">
                <Button
                  type="reset"
                  variant="secondary"
                  className="mb-2 mr-3"
                  disabled={
                    filterInputs.nutritionist_first_name != "" ||
                    filterInputs.nutritionist_last_name != "" ||
                    filterInputs.program_id != "" ||
                    filterInputs.from_date != "" ||
                    filterInputs.to_date != "" ||
                    filterInputs.program_start_date != "" ||
                    filterInputs.status != ""
                      ? false
                      : true
                  }
                  onClick={clearDate}
                >
                  Clear
                </Button>
                <Button
                  type="submit"
                  className="mb-2"
                  disabled={
                    filterInputs.nutritionist_first_name != "" ||
                    filterInputs.nutritionist_last_name != "" ||
                    filterInputs.program_id != "" ||
                    filterInputs.from_date != "" ||
                    filterInputs.to_date != "" ||
                    filterInputs.program_start_date != "" ||
                    filterInputs.status != ""
                      ? false
                      : true
                  }
                >
                  Apply Filter
                </Button>
              </Col>
            </Form.Row> */}
          </Form>
          <div className="fixedHeightTable mt-5">
            {selectAll ?
              <TableDefaultSelect
                clickSelect="true"
                data={tableList}
                offset={filter ? offset : offset}
                // resetSelectRow={resetSelectRow}
                ref={tableRef}
                defaultSelect={selectAll}
                tableCount={tableCount}
                showAllRecords={showAllRecords}
                showButton={showButton}
                columns={columns}
                filters={tableFilters}
                getSelectedRow={getSelectedRow}
                searchBar="false"
                total={tableCount}
              /> :
              <TableSelect
                clickSelect="true"
                data={tableList}
                offset={filter ? offset : offset}
                // resetSelectRow={resetSelectRow}
                ref={tableRef}
                defaultSelect={selectAll}
                tableCount={tableCount}
                showAllRecords={showAllRecords}
                showButton={showButton}
                columns={columns}
                filters={tableFilters}
                getSelectedRow={getSelectedRow}
                searchBar="false"
                total={tableCount}
              />}

            <div className="d-flex float-right justify-content-end">
              {/* {filter?null: */}
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setOffset(pageNumber.selected + 1)
                }
                forcePage={offset - 1}
                containerClassName="pagination"
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              />

              {/* } */}
            </div>
          </div>
          <ModalPop
            show={modalShow}
            onHide={handleCloseAssign}
            modalcontent={ModalBody()}
            modalhead={`Re-Assign User`}
          />
          <ModalPop
            show={modalCountShow}
            onHide={() => setModalCountShow(false)}
            modalcontent={modalCountBody()}
            modalhead={`Water & Step Count`}
          />
          <ModalPop
            show={modalUserShow}
            onHide={() => {
              setModalUserShow(false);
              setUserDetails();
            }}
            modalcontent={modalUserBody()}
            modalhead="User Details"
          />
          <ModalPop
            show={modalResetShow}
            onHide={() => setModalResetShow(false)}
            modalcontent={modalResetBody()}
            modalhead={`Reset password`}
          />
          <ModalPop
            show={modalSwitchShow}
            onHide={() => closeSwitchModal()}
            modalcontent={modalSwitchBody()}
            modalhead={`Switch Program`}
          />
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}


