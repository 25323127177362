import React, { useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { isNotLoading, postMethod, validEmailRegex } from "../Library";

export default function ResetpswdPage() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailAdd, setEmailAdd] = useState();
  const [loginStatus, setLoginStatus] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loginError, setLoginError] = useState();
  const [emailError, setEmailError] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    let email = e.target.email.value.toLowerCase().trim();

    if (email.length == 0) {
      setEmailError("Please enter a Email ID");
      setLoginError();
      setLoginStatus(false);
    } else {
      let x = validEmailRegex.test(email);
      if (x) {
        const objdata = {
          email_id: email,
          user_type: "admin",
        };
        forgotPass(objdata);
        setEmailAdd(email);
        setLoginError();
        setLoginStatus(true);
      } else {
        setEmailError("Please enter a valid Email ID");
      }
    }
  };

  const forgotPass = async (objdata) => {
    const data = {
      url: "forgot_password",
      body: objdata,
    };
    const newData = await postMethod(data);
    // console.log(newData);
    // setValidated(false);
    setEmailError();
    if (newData.status === 1) {
      // console.log(newData);
      isNotLoading();
      setShowSuccess(true);
    } else {
      setLoginStatus(false);
      isNotLoading();
      setLoginError(newData);
    }
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      <div className="centerForm">
        <Form
          noValidate
          validated={validated}
          className="d-flex align-items-center flex-column resetpassBox"
          onSubmit={handleSubmit}
        >
          <h3 className="text-center mb-5">Reset Password</h3>
          {showSuccess ? (
            <>
              <p className="text-center pl-5 pr-5">
                A reset link has been sent to your registered email id{" "}
                {emailAdd}
              </p>
              <Link variant="primary" to="/" className="btn btn-primary">
                Ok
              </Link>
            </>
          ) : (
            <>
              <p className="text-center mb-3">
                Enter your user account's verified email address and we will
                send you a password reset link.
              </p>
              <Form.Group className="w-100 mb-0" controlId="email">
                <Form.Control
                  type="email"
                  name="email"
                  required
                  placeholder="Enter your email address"
                />
              </Form.Group>
              {validated && emailError && !loginError && !loginStatus && (
                <p className="text-center mt-2 mb-0 text-capitalize err-feedback">
                  {emailError}
                </p>
              )}
              {loginError && (
                <p className="text-center mt-2 mb-0 text-capitalize err-feedback">
                  {loginError.message}
                </p>
              )}
              {loginStatus && (
                <small className="text-center mt-2 mb-0 text-capitalize">
                  Verifying Email...
                </small>
              )}
              <Button variant="primary" type="submit" className="mb-3 mt-4">
                Send password reset email
              </Button>
            </>
          )}
        </Form>
      </div>
    </>
  );
}
