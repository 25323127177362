import React, { useEffect } from "react";
// import SideBar from "../components/sidebar";
// import Table from "../components/tables/table";

export default function ContentsPage(props) {
  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "batch_name", text: "Username" },
    { dataField: "start_date", text: "Email ID" },
    { dataField: "end_date", text: "Onboarded Date" },
    { dataField: "user_count", text: "Programe Name" },
    { dataField: "nutritionist_name", text: "Batch" },
    { dataField: "status", text: "Status" },
    { dataField: "action", text: "Action" },
  ];

  const list = [
    {
      batch_name: "xxxx",
      start_date: "1/1/2020",
      end_date: "1/5/2020",
      user_count: 20,
      nutritionist_name: "John",
      status: 1,
    },
    {
      batch_name: "xxxx",
      start_date: "1/4/2020",
      end_date: "1/9/2020",
      user_count: 10,
      nutritionist_name: "Mary",
      status: 1,
    },
  ];

  useEffect(() => {
    props.getMenuList();
  }, []);

  return (
    <section className="rightContent">
      <h3>Manage Content</h3>
      {/* <Table data={list} columns={columns} /> */}
    </section>
  );
}
