import React, { useState, useEffect } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function TableSelect(props) {
  const { SearchBar } = Search;

  const [selectRowId, setSelectRowId] = useState([]);

  const columns = props.columns;
  const filters = props.filters ? props.filters() : null;
  const searchBar = props.searchBar ? props.searchBar : null;
  const clickSelect = props.clickSelect ? false : true;

  const rmvDuplicate = (arr) => {
    let uniqueArr = arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
    return uniqueArr;
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: clickSelect,
    style: { background: "#eee" },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectRowId((prev) => [...prev, row.id]);
      } else {
        var index = selectRowId.indexOf(row.id);
        if (index !== -1) {
          const dup = [...selectRowId];
          dup.splice(index, 1);
          setSelectRowId([...dup]);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      console.log(isSelect,'select')
      if (isSelect) {
        rows.forEach((item) => {
          setSelectRowId((prev) => [...prev, item.id]);
        });
      } else {
        setSelectRowId([]);
      }
    },
  };

  const resetSelectRow = () => {
    setSelectRowId([]);
  };

  useEffect(() => {
    if (selectRowId && selectRowId.length > 0) {
      props.getSelectedRow(rmvDuplicate(selectRowId));
    } else {
      props.getSelectedRow([]);
    }
  }, [selectRowId, setSelectRowId]);

  useEffect(() => {
    setSelectRowId([]);
  }, []);

  const pagintnoptions = {
    // pageStartIndex: 0,
    sizePerPage: 15,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };
 
  const formatDatas = (initArr) => {

    let sn = 0;
    let filteredarray = [];
    // console.log("initArr ", initArr);
    initArr.forEach((element) => {
      const offVal = ((props.offset-1)*10)+(sn+1);
      const offsetValue = (props.offset ? offVal : sn+1 )
      filteredarray.push({
        ...element,
        sno: offsetValue,
        // action: element.id,
      });
      sn++;
    });
    return filteredarray;
  };

  const data = formatDatas(props.data);

  return (
    <div className="customTable mt-5">
      <ToolkitProvider keyField="sno" data={data} columns={columns} search>
        {(props) => (
          <div>
            <div className="d-flex align-items-center justify-content-end mb-4">
              <div
                className={`w-100 d-flex align-items-center ${
                  filters ? "justify-content-between" : "justify-content-end"
                }`}
              >
                {searchBar && searchBar == "false" ? (
                  <></>
                ) : (
                  <div className="mr-3">
                    <SearchBar {...props.searchProps} />
                  </div>
                )}

                {filters ? filters : null}
              </div>
            </div>
            <BootstrapTable
              // noDataIndication="No Results Found"
              bordered={false}
              {...props.baseProps}
              selectRow={selectRow}
              bootstrap4={true}
              pagination={paginationFactory(pagintnoptions)}
              headerWrapperClasses="thead-dark"
              bodyClasses="tableBody"
              wrapperClasses="table-responsive"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
