import React, { useState, useEffect } from "react";

import { Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

import {
  postMethod,
  MenuRedirect,
  MenuRedirectFirst,
  isNotLoading,
} from "../Library";

// import Toasts from "../components/Toasts";

export default function Signin(props) {
  const history = useHistory();

  const [loginError, setLoginError] = useState();
  const [loginStatus, setLoginStatus] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    let email = e.target.email.value.toLowerCase();
    let password = e.target.password.value;
    if (email && password) {
      const objdata = {
        email: email,
        password: password,
      };
      loginUser(objdata);
    }
  };

  const loginUser = async (objdata) => {
    setLoginError();
    setLoginStatus(true);
    const data = {
      url: "login",
      body: objdata,
    };
    const newData = await postMethod(data);
    if (newData.status === 1) {
      isNotLoading();
      localStorage.setItem("YbpWWDicin_token", newData.data.token);
      localStorage.setItem("user", JSON.stringify(newData.data.user));
      localStorage.setItem(
        "ct8_kn1_Mn",
        JSON.stringify(newData.data.access_privilege.sort())
      );
      props.getMenuList();
      let firstMenu = JSON.parse(localStorage.getItem("ct8_kn1_Mn"))[0];
      let x = MenuRedirect(firstMenu);
      history.push(x);
    } else {
      isNotLoading();
      setLoginStatus(false);
      setLoginError(newData);
    }
  };

  // function fakeLogin() {
  //   localStorage.setItem("token", "1234567");
  //   history.push("/dashboard");
  // }

  useEffect(() => {
    if (localStorage.getItem("YbpWWDicin_token")) {
      let x = MenuRedirectFirst();
      history.push(x);
    } else {
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <div className="centerForm">
        <Form
          noValidate
          validated={validated}
          className="d-flex align-items-center flex-column"
          onSubmit={handleSubmit}
        >
          <h2 className="text-center mb-3">Nourish with SIM</h2>
          <h5 className="text-center mb-4">Admin Login</h5>
          <Form.Group className="w-100 mb-0" controlId="email">
            <Form.Control
              type="email"
              name="email"
              required
              // size="lg"
              className="mb-4"
              placeholder="Email Id"
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="w-100 mb-0" controlId="password">
            <Form.Control
              type="password"
              name="password"
              required
              placeholder="Password"
            />
            <Form.Control.Feedback
              type="invalid"
              className="position-relative top-auto"
            >
              This field is required
            </Form.Control.Feedback>
          </Form.Group>

          {loginError && (
            <small className="text-center mt-1 mb-2 text-capitalize err-feedback">
              {loginError.message}
            </small>
          )}
          {loginStatus && (
            <small className="text-center mt-1 mb-2 text-capitalize err-feedback font-black">
              Verifying credentials...
            </small>
          )}
          <p className="d-flex justify-content-end w-100 mt-2">
            <Link to="/forgot-password/" className="text-underline">
              <u>Forgot Password?</u>
            </Link>
          </p>

          <Button variant="primary" className="mt-3" type="submit">
            Login
          </Button>
        </Form>
      </div>
      {/* <Toasts show={showToast} msg="hello" /> */}
    </>
  );
}
