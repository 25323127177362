import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function AlertRedirect(title, message, history, redirectLink) {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Ok",
        onClick: () => {
          history.push(`/${redirectLink}`);
        },
      },
    ],
    closeOnEscape: false,
    closeOnClickOutside: false,
  });
}

export function AlertBox(title, message) {
  confirmAlert({
    title: title,
    message: message,
    buttons: [
      {
        label: "Ok",
        onClick: () => {
          console.log("ok");
        },
      },
    ],
    closeOnEscape: false,
    closeOnClickOutside: false,
  });
}
