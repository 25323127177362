import React, { useState, useEffect } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import ModalPop from "../modals";
import { RoleBody, UserBody } from "./contents/adminusertabbody";
import Table from "../tables/table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { postMethod, tableDefault, handleFalseStatus, isNotLoading } from "../../Library";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function AdminUsersTabs(props) {
  const role_key = "role";
  const user_key = "user";

  const [key, setKey] = useState(role_key);
  const [modalShow, setModalShow] = useState(false);
  const [adminUserList, setAdminUserList] = useState([]);
  const [adminRoleList, setAdminRoleList] = useState([]);
  const [reportingList, setReportingList] = useState([]);
  const [editId, setEditId] = useState();

  useEffect(() => {
    if (key == "role") {
      getRoleLists();
    }
    else if (key == 'user') {
      getUserLists();
    }
    getReportinTo();
  }, [key]);

  const getDataFn = async (url) => {
    const data = {
      url: url,
      body: {
        length: tableDefault,
        start: 0,
        draw: tableDefault,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data) {
      isNotLoading();

      return newData.data;
    } else {
      return null;
      isNotLoading();

    }
  };

  const getRoleLists = async () => {
    let x = await getDataFn("role_list");
    if (x && x?.role_list?.length > 0) {
      setAdminRoleList(x.role_list);
    }
  };

  // const getActiveRoleLists = async () => {
  //   let x = await getDataFn("admin_role_name_list");
  //   if (x) {
  //     setAdminActiveRoleList(x.role_list);
  //   }
  // };

  const getUserLists = async () => {
    let x = await getDataFn("admin_user_list");
    // console.log(x);
    if (x && x?.user_list?.length > 0) {
      setAdminUserList(x.user_list);
      console.log(x.user_list, 'list')
    }
  };

  const getReportinTo = async () => {
    const data = {
      url: "admin_reporting_to_list",
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data && newData.data.length > 0) {
      setReportingList(newData.data);
      isNotLoading();

    } else if (newData.status === false) {
      handleFalseStatus(newData);
      isNotLoading();

    }
  };

  function userButtonFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setEditId(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        {row.role_id !== 1 && (
          <>
            |
            <Button variant="link" dataid={cell} onClick={trashUser}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </>
        )}
      </>
    );
  }

  function roleButtonFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          disabled={row.id == 1}
          onClick={() => {
            setEditId(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Form.Check
          className="d-inline-block ml-2"
          type="switch"
          disabled={row.id == 1}
          id={`toggle-switch-${row.id}`}
          checked={row.status == "0" ? false : true}
          onChange={() => trashRole(row.id, row.status)}
        />
      </>
    );
  }

  function roleNameFormatter(cell, row) {
    // console.log(cell);
    return (
      <>
        <span>{cell.name}</span>
      </>
    );
  }

  const trashRole = async (id, status) => {
    confirmAlert({
      title: `Confirm ${status == 0 ? "Activate" : "De-Activate"} role?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "change_role_status",
              body: {
                role_id: id,
                status: status == "0" ? "1" : "0",
              },
            };
            const newData = await postMethod(data);

            if (newData.status == 1) {
              isNotLoading();

              props.showToastFn(newData.message);
              getRoleLists();
            }
            else {
              isNotLoading();

            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const trashUser = async (e) => {
    let id = e.target.getAttribute("dataid");
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "user_delete",
              body: {
                user_id: id,
              },
            };
            const newData = await postMethod(data);
            if (newData.status == 1) {
              isNotLoading();

              props.showToastFn(newData.message);
              getUserLists();
            }
            else {
              isNotLoading();
              props.showToastFn(newData.message);
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const adminUserColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "role_names", text: "Role Name" },
    { dataField: "first_name", text: "Name" },
    { dataField: "email", text: "Email" },
    { dataField: "mobile", text: "Phone No" },
    { dataField: "id", text: "Action", formatter: userButtonFormatter },
  ];

  const adminRoleColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Role Name" },
    { dataField: "reporting_to", text: "Reporting To" },
    { dataField: "id", text: "Action", formatter: roleButtonFormatter },
  ];

  function TabBody() {
    switch (key) {
      case role_key:
        return (
          <div className="tabBody offsetSearch mt-3">
            <Table data={adminRoleList} columns={adminRoleColumns} />
          </div>
        );
        break;
      case user_key:
        return (
          <div className="tabBody offsetSearch mt-3">
            <Table data={adminUserList} columns={adminUserColumns} />
          </div>
        );
        break;
      default:
        return null;
    }
  }

  const modalBody = () => {
    switch (key) {
      case role_key:
        return (
          <RoleBody
            getLists={getRoleLists}
            showToastFn={props.showToastFn}
            editId={editId}
            reportingList={reportingList}
            onHide={() => {
              setModalShow(false);
              setEditId();
            }}
          />
        );
        break;
      case user_key:
        return (
          <UserBody
            getLists={getUserLists}
            showToastFn={props.showToastFn}
            editId={editId}
            roleList={adminRoleList}
            onHide={() => {
              setModalShow(false);
              setEditId();
            }}
          />
        );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <h3 className="text-capitalize">Manage {key}s</h3>
        <Button
          className="h-100 ml-auto text-capitalize"
          onClick={() => setModalShow(true)}
        >
          Add New {key}
        </Button>
      </div>
      <Tabs
        id="TabContainer"
        transition={false}
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey={role_key} title="Manage Roles">
          <TabBody />
        </Tab>
        <Tab eventKey={user_key} title="Manage Admin Users">
          <TabBody />
        </Tab>
      </Tabs>
      <ModalPop
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setEditId();
        }}
        modalcontent={modalBody()}
        modalhead={`${editId ? "Edit" : "Add New"} ${key}`}
      />
    </>
  );
}
