import React from "react";
import Select from "react-select";

export default function DropdownSingle(props) {
  const handleChange = (data) => {
    let output = [];
    let conv = null;
    if (data && data?.length > 0) {
      output = data[data.length - 1];
      conv = [{ ...output }];
    }
    props.getSelections(conv, props.name);
    // console.log("selected", data);
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      //   console.log(data);
      data.forEach((item) => {
        finalArr.push({
          value: item?.name ? item.name : item?.title,
          label: item?.name ? item.name : item?.title,
          name: item?.name ? item.name : item?.title,
          id: item.id,
        });
      });
    }
    // console.log("format", finalArr);
    return finalArr;
  };

  return (
    <Select
      value={formatSelectOptions(props.value)}
      isMulti
      name={props.name ? props.name : "dropdown"}
      isDisabled={props?.isDisabled ? true : false}
      isClearable={true}
      options={props.options ? formatSelectOptions(props.options) : []}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={props?.placeholder ? props.placeholder : "Select..."}
      onChange={(selectedOption) => handleChange(selectedOption)}
    />
  );
}
