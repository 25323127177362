import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// custom build https://ckeditor.com/docs/ckeditor5/latest/api/alignment.html https://ckeditor.com/ckeditor-5/online-builder/
// import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";

// import Context from "@ckeditor/ckeditor5-core/src/context";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
// import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default function index(props) {
  const { text, data, index } = props;
  // console.log("editor props ", props);
  const custom_config = {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    link: {
      defaultProtocol: 'https://',
      addTargetToExternalLinks: true,
      decorator: [
        {
          mode: 'automatic',
          label: 'Open in a new window',
          callback: function (url) {
            return url.startswith('https://');
          },
          attributes: {
            rel: 'noopener noreferrer',
            target: '_blank'
          }
        }]
    }

  };

  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={text ? text : ""}
        // onReady={(editor) => {
        //   console.log("Editor is ready to use!", editor);
        // }}
        onChange={(event, editor) => {
          const typedData = editor.getData();
          // console.log({ typedData });
          data(typedData, index);
        }}
        disabled={props.readOnly ? true : false}
        config={custom_config}
      />

      {/* <CKEditorContext context={Context}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            plugins: [Paragraph, Bold, Italic, Essentials],
            toolbar: ["bold", "italic"],
          }}
          data="<p>Hello from the first editor working with the context!</p>"
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor1 is ready to use!", editor);
          }}
        />
      </CKEditorContext> */}
    </>
  );
}
