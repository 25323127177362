import React, { useState, useEffect } from "react";
import { Button, Form, Toast } from "react-bootstrap";
import Table from "../components/tables/table";
import { Formik } from "formik";
import * as yup from "yup";
import { postMethodFormData, AccessDenied, handleFalseStatus, isNotLoading, getMethod } from "../Library";
import ModalPop from "../components/modals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen} from "@fortawesome/free-solid-svg-icons";

export default function AppHistory(props) {
  const [validated, setValidated] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [formInputs, setFormInputs] = useState();
  const [editId, setEditId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  let formDataBody = new FormData();
  function linkFormatter(cell, row) {
    return (
      <>
      <a href={row.play_store_link} target="_blank" rel="noreferrer" className="text-primary">Click Here</a>
      </>
    );
  }
  function btnFormatter(cell, row) {
    return (
      <>
        {/* <Button variant="link" className="mt-1" dataid={row.id}
          onClick={() => {
            // viewPromotionalContent(row);
            setModalShow(true);
            setModalHead("View");
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
        | */}
        <Button
          variant="link"
          dataid={row.id}
          onClick={() => {
            editPromotionalContent(row);
            setModalShow(true);
            setModalHead("Edit");
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        {/* |
        <Button variant="link" dataid={row.id} onClick={trashPromotionalContent}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button> */}
      </>
    );
  }

//   const viewPromotionalContent = (data) => {
//     let id = data.id;
//     let form = { ...formInputs };
//     form["content_name"] = data.content_name;
//     form["id"] = data.id;
//     if (data.promotional_image_url) {
//       form["promotional_image_url"] = data.promotional_image_url;
//     }
//     setFormInputs(form);
//   }
  // const trashPromotionalContent = (e) => {
  //   let id = e.target.getAttribute("dataid");
  //   const data = {
  //     url: "delete_promotional_content",
  //     body: {
  //       id: id,
  //     },
  //   };
  //   confirmAlert({
  //     title: "Confirm to delete",
  //     message: "Are you sure to do this ?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: async () => {
  //           const newData = await postMethod(data);

  //           showToastFn(newData.message);
  //           if (newData.status === 1) {
  //             isNotLoading();
  //             getTableList();
  //           }
  //           else {
  //             isNotLoading();
  //           }
  //         },
  //       },
  //       {
  //         label: "No",
  //         // onClick: () => console.log("Clicked No"),
  //       },
  //     ],
  //   });
  // };

  const columns = [
    { dataField: "sno", text: "S.No", searchable: false },
    { dataField: "version", text: "Version Number" },
    { dataField: "Platform", text: "Platform"},
    { dataField: "update_type", 
    classes: "prf-col position-relative",
    text: "Update Type",
    formatter: (cell) => {
      return <>{cell==1?"Force Update":"Optional Update"}</>
    }, },
    { dataField: "title", text: "Title",
    classes: "prf-col position-relative",
   },
  //   { dataField: "description", text: "Description",
  //   classes: "prf-col position-relative",
  //  },
    { dataField: "play_store_link", 
    classes: "prf-col position-relative",
    text: "Store Link",formatter: linkFormatter, },
    { dataField: "created_at", text: "Created At" },
    { dataField: "updated_at", text: "Updated At" },
    {
      dataField: "id",
      text: "Action",
      searchable: false,
      formatter: btnFormatter,
    },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);
useEffect(() => {
console.log(formInputs,'inp')
}, [formInputs])


  useEffect(() => {
    if (pageLoaded) {
      getTableList();
      if (editId) {
      } else {
        setFormInputs({
          version: "",
          Platform: "",
          play_store_link:"",
          update_type:"",
          title:'',
          description:''
        });
      }
    }
  }, [pageLoaded, setPageLoaded]);

  // edit Promotional Content
  const editPromotionalContent = (data) => {
    let form = { ...data };
    ['created_at', 'updated_at', 'is_active', 'sno'].forEach(e => delete form[e]);

    form.update_type = data?.update_type==0?"0":data?.update_type==1?"1":null
    setFormInputs(form);
  };

  const getTableList = async () => {
    const data = {
      url: "app/get-app-version-list",

    };
    let tableList = await getMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setTableList(tableList.data);
    } else if (tableList.status === false) {
      isNotLoading();
      handleFalseStatus(tableList);
    } else if (tableList.status === 0) {
      isNotLoading();
      setTableList([]);
    }
  };

  // form functions
  const formSchema = yup.object({
    version: yup.string().required("This field is required"),
    Platform: yup.string().oneOf(["android", "ios"]).required("This field is required"),
    update_type: yup.string().oneOf([true,false,"0","1"]).required("This field is required"),
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
    play_store_link: yup.string().required("This field is required")
  });

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const handleSubmit = () => {
    for (const [key, value] of Object.entries(formInputs)) {
      formDataBody.append(key, value);
    }
    console.log('hitted')
    addAppUpdate(modalHead);
  };

  const addAppUpdate = async (type) => {
    const data = {
      url: type==="Edit"?"app/edit-app-version":"app/add-app-version",
      body: formDataBody,
    };
    const newData = await postMethodFormData(data);

    showToastFn(newData.message);
    if (newData.success == 1) {
      isNotLoading();
      closeModal();
      getTableList();
    }
    else {
      isNotLoading();
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs({});
  };

  const modalBody = () => {
    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          if (values) {
            handleSubmit();
          } 
        }}
        initialValues={{
          version: formInputs && formInputs.version ? formInputs.version : "",
          Platform: formInputs && formInputs.Platform ? formInputs.Platform : "",
          update_type: formInputs && formInputs.update_type ? formInputs.update_type : "",
          title: formInputs && formInputs.title ? formInputs.title : "",
          description: formInputs && formInputs.description ? formInputs.description : "",
          play_store_link: formInputs && formInputs.play_store_link ? formInputs.play_store_link : "",

        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setValidated(false)
            }}
          >
            <div className="column">
              <div className="col-md-8">
                <Form.Group controlId="version">
                  <Form.Label className="required">Version Name</Form.Label>
                  <Form.Control
                    name="version"
                    type="text"
                    required
                    value={values.version}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.version && touched.version ? true : false}
                    disabled={modalHead == "View" ? true : false}
                    className="promoContentName"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.version}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-8 position-relative">
              <Form.Group controlId="Platform">
                <Form.Label className="required">Choose a Platform</Form.Label>
                <Form.Control
                  required as="select"
                  name="Platform"
                  value={formInputs?.Platform}
                  onBlur={handleBlur}
                  isInvalid={errors.Platform && touched.Platform ? true : false}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                >
                  <option value="" >Select a Platform</option>
                  <option value="android">Android</option>
                  <option value="ios">IOS</option>
               
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.Platform}
                </Form.Control.Feedback>
              </Form.Group>
              </div>
              <div className="col-md-8 position-relative">
              <Form.Group controlId="update_type">
                <Form.Label className="required">Update Type</Form.Label>
                <Form.Control
                  as="select"
                  name="update_type"
                  required
                  value={formInputs?.update_type}
                  onBlur={handleBlur}
                  isInvalid={errors.update_type && touched.update_type ? true : false}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                >
                  <option value="">Select a Update Type</option>
                  <option value="1">Force Update</option>
                  <option value="0">Optional Update</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.update_type}
                </Form.Control.Feedback>
              </Form.Group>
              </div>
              <div className="col-md-8">
                <Form.Group controlId="title">
                  <Form.Label className="required">Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.title && touched.title ? true : false}
                    disabled={modalHead == "View" ? true : false}
                    className="promoContentName"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-8">
                <Form.Group controlId="description">
                  <Form.Label className="required">Description</Form.Label>
                  <Form.Control
                    name="description"
                    type="text"
                    required
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.description && touched.description ? true : false}
                    disabled={modalHead == "View" ? true : false}
                    className="promoContentName"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-8">
                <Form.Group controlId="play_store_link">
                  <Form.Label className="required">Link</Form.Label>
                  <Form.Control
                    name="play_store_link"
                    type="text"
                    required
                    value={values.play_store_link}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.play_store_link && touched.play_store_link ? true : false}
                    disabled={modalHead == "View" ? true : false}
                    className="promoContentName"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.play_store_link}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Button
                variant={modalHead == "View" ? "primary" : "secondary"}
                onClick={() => {
                  closeModal();
                }}
                className="mr-3"
              >
                {modalHead == "View" ? "Close" : "Cancel"}
              </Button>
              {
                modalHead != 'View' ?
                  <Button variant="primary" type="submit">
                    {modalHead == "Add" ? "Add" : "Update"}
                  </Button>
                  : ''
              }

            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.13) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">App Update History </h3>
            <Button
              className="h-100"
              onClick={() => {
                setModalShow(true);
                setModalHead("Add");
                setValidated(true)
              }}
            >
              Add new
            </Button>
          </div>
          <Table data={tableList} columns={columns} filters={tableFilters} />
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} App Update History`}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
