
import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab, Toast, Row, Col, Button } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import { postMethod, AccessDenied, handleFalseStatus, isNotLoading, getMethod } from "../Library";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { AddLibraryTabs } from "../components/Tabs/contents/addLibraryTabs";
import { set } from "lodash";

export default function DietLibraryAdd(props) {
  const [validated, setValidated] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [emptyArr, setEmptyArr] = useState();

  const [tabList, setTabList] = useState([]);
  const [tabListLoaded, setTabListLoaded] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [createLibrary, setCreateLibrary] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [finalTab, setFinalTab] = useState();

  const [dietPlanName, setDietPlanName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [planOverview, setPlanOverview] = useState("");
  const [dietPlanId, setDietPlanId] = useState();
  const [selectedTabs, setSelectedTabs] = useState();
  const [tab, setTab] = useState();
  const [tabInputs, setTabInputs] = useState([]);
  const [newArr, setNewArr] = useState();
  const [dietLibraryID, setDietLibraryID] = useState();
  const [disableOptions, setDisableOptions] = useState(true);
  const [render, setRender] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [editID, setEditID] = useState();
  const [key, setKey] = useState();
  const [masterHeadOther, setMasterHeadOther] = useState({
    id: null,
    meal_category_id: null,
  });
  const [masterHeadMain, setMasterHeadMain] = useState({
    diet_plan_name: null,
    meal_category_id: null,
    // display_name: null,
    // plan_overview: null,
  });

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const getTabs = async () => {
    const data = {
      url: "admin_user_diet_header_dropdown",
    };
    const newData = await getMethod(data);
    if (
      newData.status == 1 &&
      newData.data &&
      newData.data.diet_catergory_tabs
    ) {
      isNotLoading();
      setTabList(newData.data.diet_catergory_tabs);
    } else if (newData.status === false) {
      handleFalseStatus(newData);
      isNotLoading();
    } else {
      showToastFn(newData.message);
      isNotLoading();
    }
  };

  useEffect(() => {
    if (tabList && tabList.length > 0) setTabListLoaded(true);
  }, [tabList, setTabList, key, setKey]);

  useEffect(() => {
    if (finalTab && key && dietPlanName) {
      setMasterHeadMain({
        diet_plan_name: dietPlanName,
        // display_name: displayName,
        // plan_overview: planOverview,
        meal_category_id: parseInt(key),
      });
    }
  }, [
    dietPlanName,
    setDietPlanName,
    tabInputs,
    setTabInputs,
    tabList,
    setTabList,
    key,
    setKey,
    // displayName,
    // setDisplayName,
    // planOverview,
    // setPlanOverview
  ]);

  useEffect(() => {
    if (dietLibraryID) {
      setMasterHeadOther({
        id: dietLibraryID,
        meal_category_id: key,
      });
    }
  }, [key, setKey, dietLibraryID, setDietLibraryID]);

  useEffect(() => {
    if (dietLibraryID) {
      setDisableOptions(false);
    } else {
      setDisableOptions(true);
    }
  }, [setDietLibraryID, dietLibraryID]);

  useEffect(() => {
    let x = [
      {
        dish_name: "",
        dish_type: [],
        image_url: "",
        roo_and_notes: "",
        editor_status: false,
        description: "",
        randID: randIdGen(),
      },
    ];
    setEmptyArr(x);
    if (tabListLoaded) {
      checkIfEdit();
    }
  }, [setTabListLoaded, tabListLoaded]);

  const checkIfEdit = () => {
    if (location.state && location.state.id) {
      setEditID(location.state.id);
      setTab(location.state.category_ids);
      getData(location.state.category_ids);
      setTab(location.state.category_ids);
      setDietPlanName(location.state.diet_plan_name);
      // setDisplayName(location.state.display_name);
      // setPlanOverview(location.state.plan_overview)
      setCreateLibrary(true);
    } else {
      newLibraryAddInputs();
      setEditID();
      setCreateLibrary(false);
    }
  };

  const randIdGen = () => {
    return uuidv4();
  };

  const newLibraryAddInputs = () => {
    let arr = [];
    tabList.forEach(() => {
      arr.push([
        {
          dish_name: "",
          dish_type: [],
          image_url: "",
          roo_and_notes: "",
          description: "",
          randID: randIdGen(),
        },
      ]);
    });
    setTabInputs([...arr]);
  };


  const getEditInfo = async (id, a) => {
    setDietPlanId(id);
    const data = {
      url: "diet_library_item_by_id",
      body: {
        diet_library_id: id ? id : editID ? editID : dietPlanId,
        meal_category_id: a ? a : key,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setNewArr(newData.data.diet_items);
      setRender(!render);
    } else {
      isNotLoading();
    }

    setDisableOptions(false);
    updateLibraryId(id ? id : dietPlanId ? dietPlanId : editID);
  };


  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
    return () => {
      setPageLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (key != undefined)
      getEditInfo()
  }, [key]);

  useEffect(() => {
    if (pageLoaded) {
      getTabs();
    }
  }, [pageLoaded, setPageLoaded]);

  const updateLibraryId = (id) => {
    setDietLibraryID(id);
    setEditID(id);
  };

  const getSelections = (data, name) => {
    setSelectedTabs(data);
    setTab(convert(data));
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.id);
      });
    return arr;
  }

  const handleInputDiet = (e) => {
    setDietPlanName(e.target.value);
  };

  const handleInputDisplayName = (e) => {
    setDisplayName(e.target.value);
  }

  const handleInputPlanOverview = (e) => {
    setPlanOverview(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const dietValidation = () => {
    if (dietPlanName === "" ) {
      setValidated(true);
      return true;
    }
  }

  const scrollToTop = () => {
    let el = document.getElementById("dietPlanForm");
    let topPos = el.offsetTop;
    if (topPos > 100)
      window.scrollTo({
        top: topPos - 100,
        left: 0,
        behavior: "smooth",
      });
  };

  const getData = async (a) => {
    const data = {
      url: "selected_admin_user_diet_category_tabs",
      body: {
        category_ids: a ? a : tab,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFinalTab(newData.data.diet_catergory_tabs);
      setKey(newData.data.diet_catergory_tabs[0].id);
      setSelectedTabs(newData.data.diet_catergory_tabs);
      setTab(a);
      setDietPlanId(location.state.id);
      setCreateLibrary(true);
      if (location.state.id) {
        getEditInfo(location.state.id, newData.data.diet_catergory_tabs[0].id); //secondTime running
        setDietPlanId(location.state.id);
      }
    } else {
      isNotLoading();
      setCreateLibrary(false);
    }
  };


  const handleCreate = async () => {
    if (dietPlanName === "") {
      setValidated(true);
      return
    }
    const data = {
      url: "selected_admin_user_diet_category_tabs",
      body: {
        category_ids: tab,
      },
    };
    const newData = await postMethod(data);

    if (newData.status == 1) {
      isNotLoading();
      setFinalTab(newData.data.diet_catergory_tabs);
      setKey(newData.data.diet_catergory_tabs[0].id);
      setTab(convert(newData.data.diet_catergory_tabs));
      setSelectedTabs(newData.data.diet_catergory_tabs);
      setCreateLibrary(true);
    } else {
      isNotLoading();
      setCreateLibrary(false);
    }
  };


  const updateKey = (keyItem) => {
    setKey(keyItem)
    scrollToTop();

  };

  const handleEditIcon = () => {
    setCreateLibrary(false);
  };

  return (
    <section className="rightContent addLibrary">
      {props.menuList.includes(8.3) ? (
        <>
          <h3 className="mb-5">
            {pageLoaded && location.state && location.state.id ? "Edit" : "Add"}{" "}
            Diet Library{" "}
            {pageLoaded && location.state && location.state.diet_plan_name
              ? `> ${location.state.diet_plan_name}`
              : null}
          </h3>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="position-relative">
              <Col sm="5">
                <Form.Group controlId="dietplanName">
                  <Form.Label className="required">Diet Plan Name (nomenclature) </Form.Label>
                  <Form.Control
                    name="diet_plan_name"
                    type="text"
                    placeholder="Diet Plan Name"
                    value={dietPlanName}
                    onChange={handleInputDiet}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* <Col sm="5">
                <Form.Group controlId="displayName">
                  <Form.Label className="required">Display Name</Form.Label>
                  <Form.Control
                    name="display_name"
                    type="text"
                    placeholder="Display Name"
                    value={displayName}
                    onChange={handleInputDisplayName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
            </Row>
            {/* <Row className="mb-4">
              <Col sm="11">
                <Form.Group
                  controlId="planOverview"
                  className="mt-3 mb-1"
                >
                  <Form.Label className="required">
                    Plan Overview
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="plan_overview"
                    placeholder="Plan Overview"
                    rows={3}
                    value={planOverview}
                    required
                    maxLength="170"
                    onChange={handleInputPlanOverview}
                  />
                  <small className="float-right mt-1">
                    *Remaining character{" "}
                    {planOverview === (undefined || null) ? 170 : 170 - parseInt(planOverview?.length)}
                  </small>

                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col md="9">
                <Form.Group className="" controlId="program-filters">
                  <Form.Label className="required">Choose Diet Tabs</Form.Label>

                  <Dropdown
                    options={tabList}
                    value={selectedTabs}
                    getSelections={getSelections}
                    name="tab_list"
                    isDisabled={createLibrary ? true : false}
                  />
                </Form.Group>
              </Col>
              <Col md="3">
                <div className="d-flex">
                  <div className=" mr-4 edit_dt_library">
                    <FontAwesomeIcon icon={faPen} onClick={handleEditIcon} />
                  </div>
                  <Button
                    variant="primary"
                    className="btn_dt_library"
                    onClick={handleCreate}
                    disabled={!selectedTabs ? true : false}
                  >
                    Create Headers
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>

          {createLibrary ? (
            <div
              className={createLibrary ? null : "tabsDisabled"}
              id="dietPlanForm"
            >
              <Tabs
                id="add-dietLibrary"
                transition={false}
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                {finalTab &&
                  finalTab.length > 0 &&
                  finalTab.map((item, index) => (
                    <Tab
                      eventKey={item.id}
                      key={index}
                      title={item.name}
                    >
                      <AddLibraryTabs
                        showToastFn={showToastFn}
                        updateLibraryId={updateLibraryId}
                        randIdGen={randIdGen}
                        masterHeadMain={masterHeadMain}
                        tabIndex={index}
                        tabId={item.id}
                        tabName={item.name}
                        editID={editID}
                        getEditInfo={getEditInfo}
                        updateKey={updateKey}
                        tabList={finalTab[index]}
                        tabInputs={newArr ? newArr : emptyArr}
                        categoryTabs={tab}
                        showCk={pageLoaded && location.state && location.state.id ? true : false}
                        render={render}
                        dietValidation={dietValidation}
                      />
                    </Tab>
                  ))}
              </Tabs>
            </div>
          ) : null}
          {pageLoaded &&
            finalTab &&
            newArr &&
            newArr.length > newArr.length && (
              <h5>Loading Data... Please wait</h5>
            )}
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}

