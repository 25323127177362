import React, { useState } from "react";
import { Form, Button, Modal, Toast, Row, Col } from "react-bootstrap";
// import { useHistory } from "react-router-dom";

import { isNotLoading, postMethod } from "../Library";

import { Formik } from "formik";
import * as yup from "yup";

export default function ChangePassword(props) {
  const [formData, setFormData] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [sucessChange, setSucessChange] = useState(false);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formSchema = yup.object({
    password: yup.string().required("Please fill in this field"),
    con_password: yup
      .string()
      .required("Please fill in this field")
      .oneOf([yup.ref("password"), null], "Password did'nt match"),
  });

  const changePasswordFn = async (val) => {
    const data = {
      url: "change_password",
      body: {
        password: formData.password,
      },
    };
    const newData = await postMethod(data);
    setShowToast(true);
    setToastMessage(newData.message);
    if (newData.status == 1) {
      setSucessChange(true);
      localStorage.clear();
      window.location.href = "/";
    isNotLoading();

    }
    else{
      isNotLoading()

    }
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={props.handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-box popupform"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={formSchema}
            onSubmit={(values, actions) => {
              if (values) {
                changePasswordFn(values);
              }
            }}
            initialValues={{
              password: "",
              con_password: "",
            }}
          >
            {({
              validateForm,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              dirty,
              isValid,
              isInvalid,
              errors,
            }) => (
              <Form
                noValidate
                className="sideForm mt-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Form.Group as={Row} controlId="newpass">
                  <Form.Label column sm="5" className="required">
                    New Password
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      name="password"
                      autoComplete="off"
                      value={values.password}
                      onChange={(e) => {
                        handleChange(e);
                        handleInput(e);
                      }}
                      isInvalid={
                        errors.password && touched.password ? true : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="confirmpass">
                  <Form.Label column sm="5" className="required">
                    Confirm Password
                  </Form.Label>
                  <Col sm="7">
                    <Form.Control
                      type="text"
                      name="con_password"
                      autoComplete="off"
                      value={values.con_password}
                      onChange={(e) => {
                        handleChange(e);
                        handleInput(e);
                      }}
                      isInvalid={
                        errors.con_password && touched.con_password
                          ? true
                          : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.con_password}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-end mt-5">
                  <Button
                    variant="secondary"
                    onClick={props.handleCloseModal}
                    className="mr-3"
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Update Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body>
            {toastMessage}{". "}
            {sucessChange
              ? "Please login with your new password to continue"
              : null}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
}
