import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Toast,
  InputGroup,
  FormControl,
  
} from "react-bootstrap";
import Table from "../components/tables/table";
import Dropdown from "../components/Dropdown";
import ModalPop from "../components/modals";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  postMethod,
  getMethod,
  AccessDenied,
  findErrors,
  tableDefault,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import moment from "moment";

import _, { set } from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye } from "@fortawesome/free-solid-svg-icons";

import TableSelect from "../components/tables/tableselectNotification";
import ReactPaginate from "react-paginate";
import TableDefaultSelect from "../components/tables/tableDefaultSelect";

export default function NotificationsPage(props) {
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [programArray, setProgramArray] = useState([]);
  const [activityArray, setActivityArray] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [selectTable, showSelectTable] = useState(false);
  const [tableListUser, setTableListUser] = useState([]);
  const [formInputs, setFormInputs] = useState();
  const [key, setKey] = useState();
  const [tableCount, setTableCount] = useState(0);
  const [tableDraw, setTableDraw] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [editId, setEditId] = useState();
  const [filterProgram, setFilterProgram] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState();
  const [disableStatus, setDisableStatus] = useState();
  const [byActivity, setByActivity] = useState();

  const [dateTimePicker, setDateTimePicker] = useState(false);
  const [datepickerError, setDatePickerError] = useState(false);
  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [userOffset, setUserOffset] = useState(1);
  const [userPageCount, setUserPageCount] = useState(0);
  const [userDetails, setUserDetails] = useState();
  const [modalUserShow, setModalUserShow] = useState(false);

  const [userSearchValue, setUserSearchValue] = useState("");
  const [activityFilter, setActivityFilter] = useState([]);
  const [programFilterList, setProgramFilterList] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({
    programs: [],
    status: [],
  });

  const [tableFilter, setTableFilter] = useState();

  const [startDate, setStartDate] = useState(null);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function btnFormatter(cell, row) {
    return (
      <>
        {row.status != 1 ? (
          <Button
            variant="link"
            dataid={cell}
            disabled={row.type == "custom" ? true : false}
            onClick={() => {
              setKey("Edit");
              setEditId(cell);
              setModalShow(true);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
          </Button>
        ) : (
          <Button
            variant="link"
            dataid={cell}
            // disabled={disableStatus == "scheduled" ? true : false}
            onClick={() => {
              setKey("View");
              setEditId(cell);
              setModalShow(true);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        )}
      </>
    );
  }

  function statusFormatter(cell, row) {
    return (
      <>
        <span>{cell == "1" ? "Sent" : "Pending"}</span>
      </>
    );
  }

  function userstatusFormatter(cell, row) {
    return (
      <>
        <span>{cell == "1" ? "Active" : "Inactive"}</span>
      </>
    );
  }

  function dateFormatter(cell, row) {
    let x = cell.split(" ");
    return (
      <>
        <span>{x[0]}</span>
      </>
    );
  }

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "type", text: "Notification Type" },
    {
      dataField: "created_at",
      text: "Notification Created On",
      formatter: dateFormatter,
    },
    {
      dataField: "target_time",
      text: "Target/Sent On",
      formatter: dateFormatter,
    },
    { dataField: "status", text: "Status", formatter: statusFormatter },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  const userColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "first_name", text: "Username",  
    classes: "prf-col position-relative",
    formatter: nameFormatter,
    filterValue: (cell, row) => formatterNameFilter(cell, row), },
    { dataField: "email", text: "Email ID" },
    { dataField: "program_name", text: "Program Name" },
    { dataField: "current_week", text: "Week" },
    { dataField: "is_active", text: "Status", formatter: userstatusFormatter },
  ];
  function formatterNameFilter(cell, row) {
    return cell;
  }
  function nameFormatter(cell, row) {
    return (
      <Button variant="link btnLinkUs" dataid={row.id} onClick={getUserDetails}>
        {cell}
        {row.is_suggest == 1 && <span className="pertext">Performer</span>}
      </Button>
    );
  }
  const getUserDetails = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const data = {
      url: "user_insight",
      body: {
        user_id: e.target.getAttribute("dataid"),
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setUserDetails({
        ...newData.data.insights,
        ...newData.data.program,
        ...newData.data.user,
        week: newData.data.userCurrentWeek,
      });
      
      setModalUserShow(true);
      // setUserDetails(newData);
    }
    else{
      isNotLoading();

    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  const getTableList = async (url) => {
    let obj = {
      search: { value: searchValue },
      // equipment_id: parseInt(equi_name)== null ? '':parseInt(equi_name),
      length: 10,
      start: offset == 1 ? 0 : (offset - 1) * 10,
      draw: 10,
    };

    let data = {
      url: url,
      body: obj,
    };
    let x = await postMethod(data);
    setDisableStatus(x.status);
    if (x.status == 1 && x.data) {
      isNotLoading();
      setTableList(x.data.notification_lists);
      // setTotal(x.data.recordsTotal)
      setPageCount(Math.ceil(x.data.recordsTotal / perPage));
    } else if (x.status === false) {
      handleFalseStatus(x);
      isNotLoading();
    } else {
      setTableList([]);
      setPageCount(0);
      isNotLoading();
    }
  };

  const getTableListUser = async (filter) => {
    const data = {
      url: "user_name_list_for_notification",
      body: {
        length: 10,
        draw: 10,
        start: userOffset == 1 ? 0 : (userOffset - 1) * 10,
        search: { value: userSearchValue },
      },
    };
    if (filter) {
      data.body = {
        ...data.body,
        ...filter,
      };
    }
    let x = await postMethod(data);
    if (x.status == 1 && x.data) {
      isNotLoading();
      setTableListUser(x.data.mobile_user_name_list);
      setUserPageCount(Math.ceil(x.data.recordsTotal / perPage));
      setTableCount(x.data.recordsTotal)
    } else {
      isNotLoading();
      setTableListUser([]);
      setUserPageCount(0);
    }
  };

  const setDefaultFormInputs = () => {
    setFormInputs({
      type: "",
      title: "",
      message: "",
      user_ids: ["all"],
      select_all:false,
      selected_program: filterProgram,
    });
  };

  const getEditInfo = async (id) => {
    const data = {
      url: "admin_edit_notification",
      body: {
        user_id: id,
      },
    };

    let newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      // setSelectedProgram(newData.data.selected_program);
      setDisableStatus(newData.data.status === 1 ? true : false);
      setFormInputs(newData.data);
      if (newData.data.type == "scheduled") {
        setDateTimePicker(true);
        setStartDate(new Date(newData.data.target_time));
      } else {
        setDateTimePicker(false);
        setStartDate(null);
      }
      if (newData.data.user_ids.includes("all")) {
        showSelectTable(false);
      } else {
        showSelectTable(true);
      }
    } else {
      showToastFn(newData.message);
      isNotLoading();
    }

    // setFormInputs({
    //   type: "",
    //   title: "",
    //   message: "",
    //   user_ids: [],
    // });
  };

  // const handleTableFilter = (e) => {
  //   setUserOffset(1);
  //   setTableFilter((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   setFilterProgram(e.target.value);
  //   setFormInputs((prev) => ({
  //     ...prev,
  //     selected_program: value,
  //   }));
  // };

  useEffect(() => {
    // if (tableFilter) {
    //
    // }
    getTableListUser(tableFilter);
  }, [userOffset, selectTable]);

  useEffect(() => {
    getTableListUser(tableFilter);
    setUserOffset(1);

  }, [tableFilter,userSearchValue])
  
  const getOptions = async () => {
    const data = {
      url: "dropdown_for_user_name_list_notification",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      // setDropdownOptions({
      //   programs: newData.data.programs,
      //   // status: newData.data.is_active,
      // });
      setProgramFilterList(newData.data.programs)
    }
  };

  const getFilters = async () => {
    const data = {
      url: "get_notification_filter",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      // setDropdownOptions({
      //   // programs: newData.data.programs,
        
      //   status: newData.data.by_activity,
      // });
      setActivityFilter(newData.by_activity)
      console.log(newData.by_activity,'test')
    }
  };

  useEffect(() => {
    if (pageLoaded) {
      getTableList("manage_notification_list");
      getTableListUser();
      getOptions();
      getFilters();

      setDefaultFormInputs();
    }
  }, [pageLoaded, setPageLoaded]);

  useEffect(() => {
    getTableList("manage_notification_list");
  }, [offset, searchValue]);

  useEffect(() => {
    if (editId) {
      getEditInfo(editId);
    }
  }, [editId, setEditId]);

  useEffect(() => {
    if (modalShow == false) {
      setEditId();
      setValidated(false);
      showSelectTable(false);
      setDateTimePicker(false);
      setTableFilter();
      setUserSearchValue();
    }
    if (modalShow == true) {
      setDefaultFormInputs();
      setUserOffset(1);
    }
  }, [modalShow, setModalShow]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    createNoficationFn();
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (dt) => {
    let date = new Date(dt);
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    const hour = date.getHours();
    const mins = date.getMinutes();
    let val = `${year}-${month}-${day} ${hour}:${mins}`;
    return val;
  };

  const checkTimePast = (formInputs) => {
    let err = true;
    let formTime = new Date(formInputs.target_time).getTime();
    let currTime = new Date().getTime();
    if (formTime >= currTime) {
      setDatePickerError(false);
      err = false;
    } else {
      setDatePickerError(true);
      err = true;
    }
    return err;
  };

  const  handleSelect=(e)=>{
    setFormInputs((prev) => ({
      ...prev,
      select_all:!selectAll
    }));
    setSelectAll(!selectAll)

      }
      const showAllRecords = () => {
        setTableDraw(tableCount);
      };

  const createNoficationFn = async () => {
    let errors = true;

    let reqFields = _.pick(formInputs, "type", "title", "message");
    errors = findErrors(reqFields);

    // checkTimePast(formInputs);

    if (
      errors == false
      //  &&
      // formInputs.user_ids &&
      // formInputs.user_ids.length > 0
    ) {
      let dtErr = false;
      let result = {};
      var dataInput={
        ...formInputs,
        program_id:programArray,
        by_activity:activityArray,
      }
      const data = {
        url: "admin_add_or_update_notification",
        body: dataInput,
      };
      if (editId) {
        data.body.notification_id = editId;
      }
      if (formInputs.type == "scheduled") {
        if (startDate) {
          data.body.target_time =moment(startDate).format("YYYY-MM-DD HH:mm:ss");
          dtErr = false;
        } else {
          data.body.target_time = null;
          dtErr = true;
        }
        result = { ...formInputs };
      } else {
        dtErr = false;
        data.body.target_time = null;
        result = _.pickBy(formInputs, (v) => !_.isUndefined(v) && !_.isNull(v));
      }

      data.body = { ...result };

      if (!dtErr) {
        let timeCheck = true;
        if (data.body.type == "scheduled") {
          timeCheck = checkTimePast(formInputs);
        } else {
          timeCheck = false;
        }

        if (!timeCheck) {
          const newData = await postMethod(data);
          showToastFn(newData.message);
          if (newData.status == 1) {
            isNotLoading();
            getTableList("manage_notification_list");
            closeModal();
            setSelectedProgram()
            setByActivity()
            setSelectAll(false)
          } else {
            isNotLoading();
          }
        }
      }
    }
  };

  

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name == "type") {
      if (value == "scheduled") {
        let dt = new Date();
        setStartDate(dt);
        setDateTimePicker(true);
        setFormInputs((prev) => ({
          ...prev,
          target_time:  moment(dt).format("YYYY-MM-DD HH:mm:ss"),
        }));

      } else {
        setDateTimePicker(false);
        setStartDate(null);
        setFormInputs((prev) => ({
          ...prev,
          target_time: null,
        }));
      }
    }
  };

  const handleSelectiveUsers = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setByActivity();
    setSelectedProgram();
    if (value == "all") {
      setFormInputs((prev) => ({
        ...prev,
        [name]: ["all"],
      }));
      showSelectTable(false);
    } else {
      setFormInputs((prev) => ({
        ...prev,
        [name]: [...selectedRow],
      }));
      showSelectTable(true);
    }
  };

  // useEffect(() => {
  //   console.log(formInputs);
  // }, [formInputs, setFormInputs]);

  const resetDate = () => {
    // console.log("reset date");
    setStartDate();
    setFormInputs((prev) => ({
      ...prev,
      target_time: null,
    }));
    setDatePickerError(false);
  };

  const updateDate = (date) => {
    setStartDate(date);

    setFormInputs((prev) => ({
      ...prev,
      target_time: moment(date).format("YYYY-MM-DD HH:mm:ss"),
    }));

    setDatePickerError(false);
  };

  const getSelectedRows=(data)=>{
    setSelectedRows(data);

  }
  // const getSelectedRow = (data) => {
  //   setSelectedRow(data);
  // };
  const getSelectedRow = (data) => {
    setSelectedRow(data);
    setFormInputs((prev) => ({
      ...prev,
      user_ids: [...data],
    }));
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.id);
      });
      setProgramArray(arr);
    return arr;
  }

  function convertName(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.id);
      });
      setActivityArray(arr);
    return arr;

  }
  
  const getSelections = (data,name) => {
    if(name=="program_id"){
    setSelectedProgram(data);
    setTableFilter((prev) => ({
      ...prev,
      [name]: convert(data),
    }));
    setFormInputs((prev)=>({
      ...prev,
      [name]: convert(data),

    }))
    }else{
setByActivity(data)
setTableFilter((prev) => ({
  ...prev,
  [name]: convertName(data),
}));

setFormInputs((prev)=>({
  ...prev,
  [name]: convert(data),

}))
    }
 
    //     setTableFilter((prev) => ({
    //   ...prev,
    //   [name]: convert(data),
    // }));
  };

  const handleSearchInput = (e) => {
    setSearchValue(e.target.value);
    setOffset(1);
    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };
  const handleUserSearchInput = (e) => {
    setUserSearchValue(e.target.value);
    setUserOffset(1);
    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs();
    setByActivity();
    setSelectedProgram();
  };
  const modalBody = () => {
    return (
      <>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="notificationForm"
        >
          <Form.Label className="required">Notification Type</Form.Label>
          <Form.Group controlId="nofitiationsID">
            <Form.Control
              as="select"
              name="type"
              required
              value={formInputs && formInputs.type}
              onChange={handleInput}
              disabled={key == "View" ? disableStatus : false}
            >
              <option value="">Select a Notification Type</option>
              <option value="custom">Custom Notification</option>
              <option value="scheduled">Scheduled Notification</option>
            </Form.Control>
          </Form.Group>
          <div>
            {dateTimePicker && (
              <Form.Group controlId="targetTimed" className="datePickerBox_Cs">
                <Form.Label className="required">
                  Targeted Date & Time
                </Form.Label>
                <br />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    date ? updateDate(date) : resetDate();
                  }}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeInput
                  required={true}
                  autoComplete="off"
                  placeholderText="MM/dd/yyyy hh:mm AM/PM"
                  minDate={new Date()}
                  disabled={key == "View" ? disableStatus : false}
                />
                {validated && formInputs && formInputs.target_time == null && (
                  <div className="err-feedback">This field is required</div>
                )}

                {datepickerError && formInputs.target_time && (
                  <div className="err-feedback">
                    Please select a future time
                  </div>
                )}
                {/* {JSON.stringify(formInputs.target_time, null, 2)} */}
              </Form.Group>
            )}
          </div>

          <Form.Group controlId="notTitle">
            <Form.Label className="required">Notification Title</Form.Label>
            <Form.Control
              name="title"
              type="text"
              value={formInputs && formInputs.title}
              required
              onChange={handleInput}
              disabled={key == "View" ? disableStatus : false}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="description" className="position-relative">
            <Form.Label className="required">Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="message"
              // maxLength="120"
              required
              value={formInputs && formInputs.message}
              onChange={handleInput}
              disabled={key == "View" ? disableStatus : false}
            />
            {/* <small className="char-len">Max 120 Character</small> */}
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="pb-3">
            <Col md="3">
              <Form.Group controlId="01" className="mb-0">
                <Form.Check
                  type="radio"
                  name="user_ids"
                  required
                  checked={
                    formInputs &&
                    formInputs.user_ids &&
                    formInputs.user_ids.includes("all")
                      ? true
                      : false
                  }
                  value="all"
                  label="Send it to All users"
                  onChange={handleSelectiveUsers}
                  disabled={key == "View" ? disableStatus : false}
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="02" className="mb-0">
                <Form.Check
                  type="radio"
                  name="user_ids"
                  required
                  checked={
                    formInputs &&
                    formInputs.user_ids &&
                    !formInputs.user_ids.includes("all")
                      ? true
                      : false
                  }
                  value="selected_users"
                  label="Send it to Selective users"
                  onChange={handleSelectiveUsers}
                  disabled={key == "View" ? disableStatus : false}
                />
              </Form.Group>
            </Col>
          </Row>
          {/* {JSON.stringify(formInputs, null, 2)} */}

          {validated &&
            formInputs &&
            formInputs.user_ids.length == 0 &&
            !selectTable && (
              <div className="err-feedback">This field is required</div>
            )}

          <div className="margin-top-0" />
          {selectAll?<>
            {selectTable && (
          <TableDefaultSelect
          clickSelect="true"
          data={tableListUser}
              defaultSelect={selectAll}
              columns={userColumns}
          filters={userTableFilters}
          offset={userOffset}
          searchBar="false"
          getSelectedRow={getSelectedRow}
          // disabled={key == "View" ? disableStatus : false}
          total={tableCount}

            /> )}
            </>:
            <>
          {selectTable && (
            <TableSelect
              data={tableListUser}
              defaultSelect={selectAll}
              columns={userColumns}
              filters={userTableFilters}
              showAllRecords={showAllRecords}
              selectedRow={
                editId &&formInputs&&formInputs.user_ids&& !formInputs.user_ids.includes("all")
                  ? formInputs.user_ids
                  : []
              }
              offset={userOffset}
              searchBar="false"
              getSelectedRow={getSelectedRow}
              disabled={key == "View" ? disableStatus : false}
            />
          )}
          </>}
          {selectAll?null:
          (validated &&
            formInputs &&
            formInputs.user_ids.length == 0 &&
            selectTable && (
              <div className="err-feedback mt-pg">
                Please select atleast one user
              </div>
            ))
  }
          <div className="d-flex float-right justify-content-end flex-column ">
            {selectTable && userPageCount > 1 ? (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={userPageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setUserOffset(pageNumber.selected + 1)
                }
                forcePage={userOffset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              />
            ) : null}

            <div className="d-flex justify-content-end  mt-2">
              <Button variant="secondary" onClick={closeModal} className="mr-3">
                Cancel
              </Button>
              {disableStatus && key == "View" ? null : (
                <Button variant="primary" type="submit">
                  {editId ? "Update & Send" : "Send"}
                </Button>
              )}
            </div>
          </div>
        </Form>
      </>
    );
  };

  const tableFilters = () => {
    return (
      <div className="d-flex row justify-content-between w-100 pl-3 mb-4 ">
        <InputGroup className="search-register-user mr-5">
          <FormControl
            placeholder="Search"
            aria-label="search"
            aria-describedby="basic-addon1"
            className="search-regUser-input search-border"
            onChange={handleSearchInput}
          />
        </InputGroup>
      </div>
    );
  };
  const modalUserBody = () => {
    return (
      <>
        <Row className="p-3">
          <Col md="5">
            <strong>Gender</strong>
          </Col>
          <Col md="7">{userDetails?.gender ? userDetails?.gender : "N/A"}</Col>

          <Col md="5">
            <strong>Age</strong>
          </Col>
          <Col md="7">{userDetails?.age ? userDetails?.age : "N/A"}</Col>
          <Col md="5">
            <strong>Height</strong>
          </Col>
          <Col md="7">
            {userDetails?.height
              ? `${userDetails?.height} ${userDetails?.height_sign}`
              : "N/A"}
          </Col>
          <Col md="5">
            <strong>Weight</strong>
          </Col>
          <Col md="7">
            {userDetails?.weight
              ? `${userDetails?.weight} ${userDetails?.weight_sign}`
              : "N/A"}
          </Col>
          <Col md="5">
            <strong>Diet Type</strong>
          </Col>
          <Col md="7">
            {userDetails?.diet_type ? userDetails?.diet_type : "N/A"}
          </Col>
          <Col md="5">
            <strong>Goal</strong>
          </Col>
          <Col md="7">{userDetails?.goal ? userDetails?.goal : "N/A"}</Col>
          <Col md="5">
            <strong>Level</strong>
          </Col>
          <Col md="7">
            {userDetails?.activity_level ? userDetails?.activity_level : "N/A"}
          </Col>
          <Col md="5">
            <strong>Program</strong>
          </Col>
          <Col md="7">
            {userDetails?.program_name ? userDetails?.program_name : "N/A"}
          </Col>
          <Col md="5">
            <strong>Week</strong>
          </Col>
          <Col md="7">{userDetails?.gender ? userDetails?.week : "N/A"}</Col>
          <Col md="5">
            <strong>Health Conditions</strong>
          </Col>
          <Col md="7">
            {userDetails?.conditions ? userDetails?.conditions : "N/A"}
          </Col>
        </Row>
      </>
    );
  };
  const userTableFilters = () => {
    return (
      <>
        <Row>
        <Col md="12" className="d-flex">
          <InputGroup className="search-notification-user mr-5">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleUserSearchInput}
            />
          </InputGroup>
              <Form.Group controlId="program-list"  className="multi_select_notification_dropdown">
                {/* <Form.Label className="required">Recipe Category</Form.Label> */}

                <Dropdown            
                  options={programFilterList}
                  value={selectedProgram}
                  getSelections={getSelections}
                  name="program_id"
                />
              
              </Form.Group>

          <Form.Group
            className="multi_select_notification_dropdown"
            controlId="program-filters"
          >
              <Dropdown            
                  options={activityFilter}
                  value={byActivity}
                  getSelections={getSelections}
                  name="by_activity"
                />
          </Form.Group>
   
      </Col>
      <Col md ="2" className="d-flex align-items-center defaultSelectBox mt-1 ml-3 ">

       <Form.Group
                  className="mb-3 d-flex"
                  controlId="formBasicCheckboxs"
                >
          <Form.Check 
        type={'checkbox'}
        id={`default-checkboxs`}
        checked={selectAll}
        onChange={handleSelect}
        disabled={key == "View" ? disableStatus : false}

      />
      <Form.Label className="pl-2">
                    Select All
                  </Form.Label>
                </Form.Group>
                </Col>
        </Row>

      </>
    );
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.8) ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <h3 className="text-capitalize">Manage Notifications</h3>
            <Button
              className="h-100 ml-auto text-capitalize"
              onClick={() => {
                setModalShow(true);
                setKey("Create");
              }}
            >
              Create Notification
            </Button>
          </div>
          
          <Table
            data={tableList}
            columns={columns}
            filters={tableFilters}
            offset={offset}
            searchBar="false"
          />
          <div className="d-flex float-right justify-content-end">
            {pageCount > 1 ? (
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setOffset(pageNumber.selected + 1)
                }
                forcePage={offset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              />
            ) : null}
          </div>
          <ModalPop
            show={modalShow}
            size="xl"
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead={`${key} Notification`}
          />
           <ModalPop
            show={modalUserShow}
            onHide={() => {
              setModalUserShow(false);
              setUserDetails();
            }}
            modalcontent={modalUserBody()}
            modalhead="User Details"
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
