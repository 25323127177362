import React from "react";
import Select from "react-select";
// import CreatableSelect from "react-select/creatable";
// import _ from "lodash";

// import { postMethod } from "../../Library";

export default function Index(props) {
  const handleChange = (data) => {
    props.getSelections(data, props.name);
    // console.log("selected", data);
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      //   console.log(data);
      data.forEach((item) => {
        finalArr.push({
          value: item.name ? item.name : item.title,
          label: item.name ? item.name : item.title,
          name: item.name ? item.name : item.title,
        });
      });
    }
    return finalArr;
  };

  return (
    <Select
      value={formatSelectOptions(props.value)}
      isMulti
      name={props.name ? props.name : "dropdown"}
      options={props.options ? formatSelectOptions(props.options) : []}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(selectedOption) => handleChange(selectedOption)}
      isDisabled={props.isDisabled}
    />
  );
}
