import React, { useState, useEffect } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import DashCards from "../Cards";
import SearchBox from "../SearchBox";
import ModalPop from "../modals";
import { FAQBlogBody, QuotesBody, FAQGeneralBody, FAQProgramBody, FaqTablelist } from "./contents/dashtabbody";

import { postMethod, handleFalseStatus, isNotLoading, getMethod } from "../../Library";

export default function DashTabs(props) {
  const [key, setKey] = useState("FAQ's");
  const [faqKey, setFaqKey] = useState("General FAQ");
  const [cardData, setCardData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [faqCategory, setFaqCategory] = useState();
  const [blogCategory, setBlogCategory] = useState();
  const [editFaqBlogData, setEditFaqBlogData] = useState();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [disableGeneraltab, setDisableGeneraltab] = useState(false);
  const [disableProgramtab, setDisableProgramtab] = useState(false);
  const [formInputs, setFormInputs] = useState();


  const [filterBy, setFilterBy] = useState({
    faq_filter: "",
    blog_filter: "",
  });

  const getData = async (type) => {
    const data = {
      url:
        type == "FAQ's"
          ? "faq_list"
          : type == "Blogs"
            ? "blog_list"
            : "quote_tip_list",
    };
    const newData = await getMethod(data);
    if (
      newData.status == 1 &&
      newData.data &&
      ((newData.data.faqs && newData.data.faqs.length > 0) ||
        (newData.data.blogs && newData.data.blogs.length > 0) ||
        (newData.data.quote_tips && newData.data.quote_tips.length > 0))
    ) {
      isNotLoading();

      type == "FAQ's"
        ? setCardData(newData.data.faqs)
        : type == "Blogs"
          ? setCardData(newData.data.blogs)
          : setCardData(newData.data.quote_tips);
    } else if (newData.status === false) {
      isNotLoading();

      handleFalseStatus(newData);
    } else {
      setCardData([]);
      isNotLoading();

    }
  };

  const getCategory = async (type) => {
    const data = {
      url: "category_list",
      body: {
        type: type == "FAQ's" ? "faq" : type == "Blogs" ? "blog" : "quote",
      },
    };
    if (type == "FAQ's") {
      const newData = await postMethod(data);
      if (newData.data && newData.data.categories) {
        isNotLoading();
        setFaqCategory(newData.data.categories);
      }
      else {
        isNotLoading();
      }
    }
    if (type == "Blogs") {
      const newData = await postMethod(data);
      if (newData.data && newData.data.categories) {
        setBlogCategory(newData.data.categories);
        isNotLoading();

      }
      else {
        isNotLoading();

      }
    }
  };

  useEffect(() => {
    getData(key);
    getCategory(key);
  }, [key]);

  // search
  const getSearchVal = async (val, e) => {
    var searchValue = val.trim();
    setSearch(searchValue)

    const data = {
      url:
        key == "FAQ's"
          ? `faq_list?search=${searchValue}&filter=${filter}`
          : key == "Blogs"
            ? `blog_list?search=${searchValue}&filter=${filter}`
            : `quote_tip_list?search=${searchValue}&filter=${filter}`,
    };
    const newData = await getMethod(data);
    isNotLoading();

    key == "FAQ's"
      ? setCardData(newData.data.faqs)
      : key == "Blogs"
        ? setCardData(newData.data.blogs)
        : setCardData(newData.data.quote_tips);
  };

  const handleFilter = async (e) => {
    let val = e.target.value;
    let name = e.target.name;
    setFilter(val);
    setFilterBy((prev) => ({
      ...prev,
      [name]: val,
    }));

    var searchValue = ""
    if (val.length > 0) {
      const data = {
        url:
          key == "FAQ's"
            ? `faq_list?search=${search}&filter=${val}`
            : key == "Blogs"
              ? `blog_list?search=${search}&filter=${val}`
              : `quote_tip_list?search=${search}&filter=${val}`,
        body: {
          category_id: val,
        },
      };
      const newData = await getMethod(data);
      isNotLoading();

      key == "FAQ's"
        ? setCardData(newData.data.faqs)
        : key == "Blogs"
          ? setCardData(newData.data.blogs)
          : setCardData(newData.data.quote_tips);
    } else {
      getData(key);
      isNotLoading();

    }
  };

  function TabBody() {
    return (
      <div className="tabBody">
        <DashCards
          type={key}
          showToastFn={props.showToastFn}
          getData={getData}
          cardData={cardData}
          setEditFaqBlogData={(id) => setEditFaqBlogData(id)}
          setModalHead={() => setModalHead("Edit")}
          showModalFn={() => setModalShow(true)}
          showModalView={() => setModalView(true)}
          disableGeneraltab={() => setDisableGeneraltab(true)}
          disableProgramtab={() => setDisableProgramtab(true)}
          faqTabchange={(newKey) => setFaqKey(newKey)}
        />
      </div>
    );
  }
  console.log("faqkey", faqKey)
  const closeModal = () => {
    setModalShow(false);
    setEditFaqBlogData();
    setModalView(false)
    setDisableGeneraltab(false)
    setDisableProgramtab(false)
    setFaqKey("General FAQ")
  };
  const modalBody = () => {
    switch (key) {
      case "FAQ's":
        return (
          <section>
            <Tabs
              id="TabContainer"
              className="mb-4"
              transition={false}
              activeKey={faqKey}
              onSelect={(k) => setFaqKey(k)}
            >
              <Tab eventKey="General FAQ" title="General FAQ" disabled={disableGeneraltab}>
                <FAQGeneralBody
                  type={faqKey}
                  getData={getData}
                  showToastFn={props.showToastFn}
                  editId={editFaqBlogData}
                  categoryList={faqCategory}
                  modalHead={modalHead}
                  onHide={closeModal}
                  activeFaqTab={faqKey == 'General FAQ' ? true : false}
                />
              </Tab>
              <Tab eventKey="Program based" title="Program based" disabled={disableProgramtab}>
                <FAQProgramBody
                  type={faqKey}
                  getData={getData}
                  showToastFn={props.showToastFn}
                  editId={editFaqBlogData}
                  categoryList={faqCategory}
                  modalHead={modalHead}
                  onHide={closeModal}
                  activeFaqTab={faqKey == 'Program based' ? true : false}
                />

              </Tab>
            </Tabs>
          </section>
        );
        break;
      case "Blogs":
        return (
          <FAQBlogBody
            type={key}
            getData={getData}
            showToastFn={props.showToastFn}
            editId={editFaqBlogData}
            categoryList={blogCategory}
            modalHead={modalHead}
            onHide={closeModal}
          />
        );
        break;
      case "Quotes & Tips":
        return (
          <QuotesBody
            getData={getData}
            showToastFn={props.showToastFn}
            modalHead={modalHead}
            editId={editFaqBlogData}
            onHide={closeModal}
          />
        );
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end tabFilters selectfilters">
        {key == "FAQ's" || key == "Blogs" ? (
          <>
            {key == "FAQ's" ? (
              <Form.Group controlId="CtID" className="mw-200 mwx-300 mr-3">
                <Form.Control
                  as="select"
                  name="faq_filter"
                  value={filterBy.faq_filter}
                  onChange={handleFilter}
                >
                  <option value="">Filter By</option>
                  {faqCategory &&
                    faqCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            ) : (
              <Form.Group controlId="CtID2" className="mw-200 mwx-300 mr-3">
                <Form.Control
                  as="select"
                  name="blog_filter"
                  value={filterBy.blog_filter}
                  onChange={handleFilter}
                >
                  <option value="">Filter By</option>
                  {blogCategory &&
                    blogCategory.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
          </>
        ) : null}

        <Button
          className="h-100"
          onClick={() => {
            setModalShow(true);
            setModalHead("Add");
            setDisableGeneraltab(false)
            setDisableProgramtab(false)
          }}
        >
          Add {key}
        </Button>
      </div>
      <Tabs
        id="TabContainer"
        transition={false}
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="FAQ's" title="FAQ's">
          <br />
          <SearchBox getSearchVal={getSearchVal} />
          <div className="searchIcon-minus" />
          <TabBody />
        </Tab>
        <Tab eventKey="Blogs" title="Blogs">
          <br />
          <SearchBox getSearchVal={getSearchVal} />
          <div className="searchIcon-minus" />
          <TabBody />
        </Tab>
        <Tab eventKey="Quotes & Tips" title="Quotes & Tips">
          <br />
          <SearchBox getSearchVal={getSearchVal} />
          <div className="searchIcon-minus" />
          <TabBody />
        </Tab>
      </Tabs>
      <ModalPop
        show={modalShow}
        onHide={closeModal}
        disableEnforceFocus={true}
        modalcontent={modalBody()}
        modalhead={`${modalHead} ${key}`}
      />

    </>
  );
}
