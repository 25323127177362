
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { postMethod, findErrors, copyObj, isNotLoading } from "../../../Library";
import _ from "lodash";
import Editor from "../../../components/editor";
import Dropdown from "../../../components/Dropdown/dietPlan";
import ImageBoxUploadCrop from "../../ImageBox/imageboxuploadcrop";
import Imageboxuploaddietlibrary from "../../ImageBox/imageboxuploaddietlibrary ";
import { Link, useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import index from "../../../components/editor";

export function AddLibraryTabs(props) {
  const [tabInputs, setTabInputs] = useState(props.tabInputs);
  const [validated, setValidated] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [dietLibraryId, setDietLibraryId] = useState();

  const [viewEditor, setViewEditor] = useState();
  const [arrayId, setArrayId] = useState([]);
  const [replacementTxt, setReplacementTxt] = useState([]);
  const [conditionArray, setConditionArray] = useState([]);
  const [ckEditorErrorMeg, setCkEditorErrorMeg] = useState()



  const handleRemoveTabInput = (e, decId, deleteIndex) => {
    e.preventDefault();
    setBtnDisabled(true);
    let index = e.target.getAttribute("dataid");
    let copiedObj = copyObj(tabInputs);
    let values = [...copiedObj];
    values.splice(index, 1);
    setTabInputs(values);
    setBtnDisabled(false);
    const filter = replacementTxt.filter((item) => String(item.id) !== decId);
    setReplacementTxt(filter);

    const deleteDuplicateArray = conditionArray && conditionArray.filter((item, index) => index !== deleteIndex);
    setConditionArray(deleteDuplicateArray);
  };

  const editAddNewFunction = (i, randomID, notes) => {
    setViewEditor();
    let copyArray = [...tabInputs]
    let updatedArrCopy = copyArray.map((item, index) => {
      return { ...item, editor_status: String(index) === String(i) ? false : true }
    })


    setTabInputs(updatedArrCopy);
    setCkEditorErrorMeg();
  }

  const handleAddTabInput = (index) => {
    let copiedObj = copyObj(tabInputs);
    let values = [...copiedObj];
    values.push({
      dish_name: "",
      dish_type: [],
      image_url: "",
      roo_and_notes: "",
      editor_status: false,
      description: "",
      randID: props.randIdGen(),
    });

    setTabInputs(values);
    resetValidation();
    // addDuplicateArray()
    setCkEditorErrorMeg();
  };

  const resetValidation = () => {
    setValidated(false);
  };

  const gettypedData = (data, index) => {
    let copiedObj = copyObj(tabInputs);
    copiedObj[index].roo_and_notes = data;
    setTabInputs([...copiedObj]);
  };

  const gettypedDesc = (e, index) => {
    let copiedObj = copyObj(tabInputs);
    copiedObj[index].description = e.target.value;
    setTabInputs([...copiedObj]);
  };

  const chooseImageFn = (url, ps) => {
    let index = ps.dataid;
    let copiedObj = copyObj(tabInputs);
    copiedObj[index].image_url = url;
    setTabInputs([...copiedObj]);
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.value);
      });
    return arr;
  }

  const getSelections = (data, index) => {
    let arr = convert(data);
    let copiedObj = copyObj(tabInputs);
    copiedObj[index].dish_type = data ? [...arr] : [];
    setTabInputs([...copiedObj]);
  };

  const handleInput = (e) => {
    e.preventDefault();
    const val = e.target.value;
    const name = e.target.name;
    const index = e.target.getAttribute("dataid");
    let copiedObj = copyObj(tabInputs);
    copiedObj[index][name] = val;
    setTabInputs([...copiedObj]);
  };

  const formValidate = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const checkIfEdit = (dt) => {
    let x = {};
    if (props.editID) {
      x = {
        ...dt,
        id: props.editID,
      };
    } else {
      x = { ...dt };
    }
    return x;
  };

  const ckeditorError = () => {
    const isNameValid = tabInputs.every(
      (x, i) =>
        x.editor_status
    );
    return isNameValid
  }

  const checkErrors = (formInputs) => {
    let err = [];
    formInputs.diet_items.forEach((item) => {
      const reqFields = _.pick(item, "dish_name", "image_url", "description");
      let x = findErrors(reqFields);
      if (x) {
        err.push(x);
      }

    });
    return err.length > 0 ? true : false;
  };

  const createInitialDiet = async (formInputs) => {
    const data = {
      url: "diet_library_create_or_update",
      body: formInputs,
    };

    const newData = await postMethod(data);
    props.showToastFn(newData.data.message);
    if (newData.status == 1) {
      isNotLoading();
      props.updateLibraryId(newData.data.diet_library_id);
      props.showToastFn("Saved Successfully...");
      props.updateKey(props.tabIndex);
    } else {
      isNotLoading();
    }
  };


  const createOtherDiet = async (formInputs) => {
    const data = {
      url: "diet_library_create_or_update",
      body: formInputs,
    };

    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      props.showToastFn("Saved Successfully...");
      props.updateKey(props.tabIndex);
    } else {
      isNotLoading();

      props.showToastFn(newData.data.message);
    }
  };

  const handleAddDish = (e) => {
    e.preventDefault();
    formValidate(e);

    let duplicateArray = [...tabInputs]

    const saveEditor = duplicateArray.map((item) => {
      return { ...item, editor_status: true }
    })

    setTabInputs(saveEditor);

    let x = checkIfEdit(props.masterHeadMain);

    const formInputs = {
      ...x,
      diet_items: [...saveEditor],
    };

    let errors = checkErrors(formInputs);
    if (
      !errors &&
      props.masterHeadMain.diet_plan_name &&
      // props.masterHeadMain.display_name &&
      // props.masterHeadMain.plan_overview &&
      props.masterHeadMain.meal_category_id
    ) {
      if (!props.dietValidation()) {
        createDietNew(formInputs);
      }

    } else {
      console.log(
        props.masterHeadMain.diet_plan_name,
        props.masterHeadMain.meal_category_id,
        "sshjhjshj"
      );
    }
  };

  const createDietNew = async (formInputs) => {
    const formSubmission = {
      ...props.masterHeadMain,
      ...formInputs,
      category_ids: props.categoryTabs,
    };

    const data = {
      url: "diet_library_create_or_update",
      body: formSubmission,
    };

    const newData = await postMethod(data);
    props.showToastFn(newData.data.message);
    if (newData.status == 1) {
      isNotLoading();
      props.updateLibraryId(newData.data.diet_library_id);
      setDietLibraryId(newData.data.diet_library_id);
      props.showToastFn("Saved Successfully...");
      props.updateKey(newData.data.next_category_id)
      props.getEditInfo(newData.data.diet_library_id, newData.data.next_category_id)
      setValidated(false);
    } else {
      isNotLoading();
    }
  };

  const dishTypeOptions = [
    { name: "Optional", id: 1 },
    { name: "Compulsory", id: 2 },
    { name: "Protein", id: 3 },
    { name: "Carbs", id: 4 },
  ];

  const formatSelectValues = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item,
          label: item,
          name: item,
        });
      });
    }
    return finalArr;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setTabInputs(props.tabInputs);
  }, [props.tabInputs, props])

  const filterSeleted = (id) => {
    const filter = arrayId.filter((item) => String(item) !== id)
    setArrayId(filter);
    const replacementFilter = replacementTxt.filter((item) => String(item.id) !== id);
    setReplacementTxt(replacementFilter);
  }

  return (
    <>
      {tabInputs &&
        tabInputs.map((item, index, arr) => (
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            valse={item?.id ? item.id : item.randID}
            key={item?.id ? item.id : item.randID}
          >
            <Row className="mb-3">
              <Col md="3">
                <Form.Group
                  controlId={`${props.tabName}-dishname-${item?.id ? item.id : item.randID
                    }`}
                  className="mwx-400 mb-0 w-100"
                >
                  <Form.Label className="required">Dish Name</Form.Label>
                  <Form.Control
                    name="dish_name"
                    type="text"
                    value={item.dish_name}
                    placeholder="Dish Name"
                    dataid={index}
                    onChange={handleInput}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                {props.tabList.name != "On Waking" && (
                  <Form.Group
                    controlId={`${props.tabName}-dishtype--${item?.id ? item.id : item.randID
                      }`}
                    className="customSelect mwx-400 mt-3 w-100"
                  >
                    <Form.Label>Select a Dish Type</Form.Label>

                    <Dropdown
                      options={dishTypeOptions}
                      value={
                        item?.dish_type?.length > 0
                          ? formatSelectValues(item.dish_type)
                          : item.dish_type
                      }
                      name={index}
                      getSelections={getSelections}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col md="5" className="p-0">
                <div className="ctImg h-100 d-flex">
                  <div className="imageBoxContainer flex-column justify-content-start w-100">
                    <Imageboxuploaddietlibrary
                      folder="dietlib"
                      as_w="4"
                      as_h="3"
                      dataid={index}
                      position="left"
                      data={chooseImageFn}
                      image_url={item.image_url}
                      showImage="false"
                      tabIds={props.randIdGen()}
                    />

                    {validated && item.image_url == "" && (
                      <div className="err-feedback text-center w-100">
                        This field is required
                      </div>
                    )}
                  </div>
                </div>
              </Col>
              <Col md="4">
                <Form.Group className="mwx-400 mb-2 w-100">
                  <Form.Label></Form.Label>
                </Form.Group>
                {props.tabList.name != "On Waking" && (
                  <div className="ml-auto d-flex align-items-center">
                    {index == 0 && arr.length == 1 && (
                      <Button
                        className="ml-auto no-wrap"
                        variant="primary"
                        type="button"
                        disabled={
                          item.dishName == "" ||
                            item.image_url == "" ||
                            item.description == ""
                            ? true
                            : false
                        }
                        onClick={() => handleAddTabInput(index)}

                      >
                        Add New
                      </Button>
                    )}
                    {arr.length > 0 && arr.length - 1 != index && (
                      <>
                        <Button
                          className="ml-auto"
                          variant="secondary"
                          dataid={index}
                          type="button"
                          name={props.tabName + " " + index}
                          id={props.tabName + "btn" + index}
                          disabled={btnDisabled}
                          onClick={(e) => handleRemoveTabInput(e, tabInputs[index].randID, index)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {arr.length - 1 == index && index != 0 && (
                      <>
                        <Button
                          className="ml-auto no-wrap"
                          onClick={() => handleAddTabInput(index)}
                          type="button"
                          disabled={
                            item.dishName == "" ||
                              item.image_url == "" ||
                              item.description == ""
                              ? true
                              : false
                          }
                        >
                          Add New
                        </Button>
                        <span className="pl-0 pr-0" style={{ width: "1rem" }}>
                          <hr className="dark" />
                        </span>
                        <Button
                          dataid={index}
                          variant="secondary"
                          name={props.tabName + " " + index}
                          id={props.tabName + "btn" + index}
                          type="button"
                          disabled={btnDisabled}
                          onClick={(e) => handleRemoveTabInput(e, tabInputs[index].randID, index)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </Col>
            </Row>

            <div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Form.Label className="mb-0">Replacement/Others</Form.Label>

                {tabInputs[index].editor_status ? (<div>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => editAddNewFunction(index)}
                  >
                    Edit
                  </Button>
                </div>) : (
                  ""
                )}
              </div>

              {tabInputs[index].editor_status ? (
                <div className="ReplacementOthers-desc">
                  {ReactHtmlParser(item.roo_and_notes)}
                </div>
              ) : (
                <>
                  <Editor
                    data={gettypedData}
                    index={index}
                    text={item && item.roo_and_notes ? item.roo_and_notes : ""}
                  />
                </>
              )}
            </div>

            <Form.Group
              controlId={`${props.tabName}-description--${item?.id ? item.id : item.randID
                }`}
              className="mt-3 position-relative"
            >
              <Form.Label className="required">Description</Form.Label>

              <Form.Control
                as="textarea"
                rows={3}
                required
                maxLength="120"
                value={item && item.description ? item.description : ""}
                onChange={(e) => gettypedDesc(e, index)}
              />
              <small className="char-len">Max 120 Character</small>

              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Form.Group>
            {arr.length > 1 && <hr />}
            <br />
          </Form>
        ))}

      <div className="d-flex justify-content-end">
        <Link
          to="/manage-diet-library"
          className="no-wrap mr-3 btn btn-secondary"
        >
          Cancel
        </Link>
        <Button
          className="no-wrap"
          variant="primary"
          type="button"
          onClick={handleAddDish}
        >
          Save
        </Button>
      </div>
    </>
  );
}
