import React, { useState, useEffect } from "react";
import { Button, Form, Toast } from "react-bootstrap";
// import { Formik } from "formik";
// import * as yup from "yup";

import {
  postMethod,
  postMethodFormData,
  AccessDenied,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import ModalPop from "../components/modals";
// import ImageBoxUpload from "../components/ImageBox/imageboxupload";

// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function WorkoutOnboardingPage(props) {
  const [tableListHabit, setTableListHabit] = useState([]);
  const [tableListStep, setTableListStep] = useState([]);
  const [tableListWater, setTableListWater] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [modalType, setModalType] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [videoInputs, setVideoInputs] = useState(1);
  const [formDirty, setFormDirty] = useState(false);
  const [validated, setValidated] = useState(false);
  const [habitMediasImg, setHabitMediasImg] = useState([]);
  const [habitMediasVideo, setHabitMediasVideo] = useState([]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const habitFormInit = {
    title: "",
    description: "",
    image_urls: [],
    video_urls: [""],
  };

  const otherFormInit = {
    title: "",
    description: "",
    out_of_value: "",
    image_url: "",
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getContents();
    }
  }, [pageLoaded, setPageLoaded]);

  const showToastFn = (msg) => {
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
    setShowToast(true);
  };

  const handleInput = (e, idx) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = JSON.parse(JSON.stringify(formInputs));
    form[name] = value;
    setFormInputs(form);
  };

  const handleInputVideo = (e, idx) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = JSON.parse(JSON.stringify(formInputs));
    let video_urls = form.video_urls;
    video_urls[idx] = value;
    setFormInputs((prev) => ({ ...prev, video_urls: [...video_urls] }));
  };

  const handleVideoInit = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    setFormInputs((prev) => ({
      ...prev,
      [name]: [value],
    }));
  };

  const handleAddVideo = () => {
    // console.log(videoInputs);
    return Array.from(Array(videoInputs), (e, i) => {
      return (
        <div className="my-3 col-sm-6" key={i}>
          <Form.Group
            controlId={`video_urls-${i}`}
            className="position-relative"
          >
            <Form.Label className="required">Video Url</Form.Label>
            <Form.Control
              name="video_urls"
              type="text"
              required
              // value={item?.video_urls}
              // onChange={(e) => handleInput(e, index)}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      );
    });
  };

  useEffect(() => {
    handleAddVideo();
    // console.log("im running");
  }, [videoInputs, setVideoInputs]);

  const handleMultiUploads = async (e) => {
    e.preventDefault();
    const name = e.target.name;
    let file = e.target.files;
    let len = file.length;
    let urls = [];
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        let formDataBody = new FormData();
        formDataBody.append("image_url", file[i]);
        const data = {
          url: "image_upload",
          body: formDataBody,
        };
        const newData = await postMethodFormData(data);
        if (newData.status == 1) {
      isNotLoading();
      urls.push(newData.data.image_url);
        } else {
          // console.log(file[i].name);
      isNotLoading();
      let msg = `Error uploading file ${file[i].name}. ${newData.message}`;
          showToastFn(msg);
        }
      }
      let prevUrls = formInputs.image_urls ? formInputs.image_urls : [];
      // console.log(prevUrls, urls);
      setFormInputs((prev) => ({
        ...prev,
        image_urls: [...prevUrls, ...urls],
      }));
    }
  };

  const rmvImage = (idx) => {
    // console.log(idx);
    let form = Object.assign({}, formInputs);
    // let form = JSON.parse(JSON.stringify(formInputs));
    let imgArr = form.image_urls;
    imgArr.splice(idx, 1);
    setFormInputs({ ...form });
  };

  const rmvVideo = (idx) => {
    // console.log(idx);
    let form = JSON.parse(JSON.stringify(formInputs));
    let videoArr = form.video_urls;
    videoArr.splice(idx, 1);
    setFormInputs({ ...form });
  };

  const chooseImageFn = (url) => {
    let form = JSON.parse(JSON.stringify(formInputs));
    form.image_url = url;
    setFormInputs(form);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    setFormDirty(true);

    if (form.checkValidity() == true) {
      addNewContent();
    }
  };

  // getContents data
  const getContents = async () => {
    const bd = {
      id: "",
    };

    const habit = {
      url: "list_habit_of_the_day",
      body: { ...bd },
    };
    const water = {
      url: "list_stepcount",
      body: { ...bd },
    };
    const step = {
      url: "list_water_intake",
      body: { ...bd },
    };
    const tlHabit = await postMethod(habit);
    const tlStep = await postMethod(water);
    const tlWater = await postMethod(step);
    // console.log("habit ", tlHabit.data.habit_of_the_day_list);
    if (tlHabit.status === 1) {
      isNotLoading();
      setTableListHabit(tlHabit.data.habit_of_the_day_list);
    } else if (tlHabit.status === false) {
      isNotLoading();
      handleFalseStatus(tlHabit);
    }
    else{
      isNotLoading();
    }
    if (tlStep.status === 1) {
      isNotLoading();
      setTableListStep(tlStep.data.stepcount_list);
    }
    else{
      isNotLoading();
    }
    if (tlWater.status === 1) {
      isNotLoading();
      setTableListWater(tlWater.data.water_intake_list);
    }
    else{
      isNotLoading();
    }
  };

  // add new equipmeny
  const addNewContent = async () => {
    const data = {
      url: "",
      body: { ...formInputs },
    };
    if (modalType == "habit") {
      data.url = "create_or_update_habit_of_the_day";
    } else {
      data.body.description = "description";
      data.body.title = "title";
      data.url =
        modalType == "water"
          ? "create_or_update_water_intake"
          : "create_or_update_stepcount";
      // }
    }
    if (data.url) {
      const newData = await postMethod(data);
      showToastFn(newData.message);
      if (newData.status === 1) {
        closeModal();
        getContents();
        isNotLoading();
      }
      else{
        isNotLoading();
      }
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setValidated(false);
    setFormDirty(false);
    setFormInputs();
    // setHabitMediasImg([]);
    // setHabitMediasVideo([]);
    setModalHead();
    setModalType();
  };

  // editContents
  const editContents = (data, type) => {
    // console.log("content ", data);
    if (data) {
      setFormInputs(JSON.parse(JSON.stringify(data[0])));
    }
  };

  const listHead = (data, type, title) => {
    // console.log(data, type, title);
    return (
      <>
        <div className="d-flex align-items-center mb-3 justify-content-between">
          <h5 className="text-capitalize mb-0">{title}</h5>
          {data?.length == 0 && (
            <Button
              className="h-100"
              onClick={() => {
                setModalShow(true);
                setModalHead("Add");
                setModalType(type);
              }}
            >
              Add <span className="text-capitalize">{type}</span>
            </Button>
          )}
        </div>
      </>
    );
  };

  const listBody = (data, type) => {
    return (
      <>
        {data?.length > 0 ? (
          data?.map((x, i) => {
            return (
              <div className="mb-5 bg-white p-3 rounded" key={i}>
                <div className="row">
                  <div className="col-md-7">
                    <h4>{x.title}</h4>
                    <p className="mt-3 mb-0">{x.description}</p>
                  </div>
                  <div className="col-md-4">
                    {type != "habit" && (
                      <img
                        className="w-100 rounded onboardImg"
                        alt="on-boarding-content-img"
                        src={x?.image_url}
                      />
                    )}
                  </div>
                  <div className="col-md-1">
                    <Button
                      variant="link"
                      onClick={() => {
                        setModalShow(true);
                        setModalType(type);
                        editContents(data, type);
                        setModalHead("Edit");
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </div>
                  <div className="col-md-12">
                    {type == "habit" ? (
                      <div className="row flex-wrap">
                        {x?.image_urls?.length > 0 &&
                          x?.image_urls?.map((x, i) => {
                            return (
                              <div
                                key={i}
                                className="multiImageBox text-align-center my-3 col-sm-3"
                              >
                                <img
                                  src={x}
                                  className="img-fluid"
                                  title={`Image-${i}`}
                                  alt="image"
                                />
                              </div>
                            );
                          })}
                        {x?.video_urls?.length > 0 &&
                          x?.video_urls?.map((x, i) => {
                            return (
                              <div
                                key={i}
                                className="multiImageBox my-3 col-sm-3"
                              >
                                <iframe
                                  src={`${x}`}
                                  width="100%"
                                  height="100%"
                                  title={`Image-${i}`}
                                  alt="image"
                                ></iframe>
                              </div>
                            );
                          })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No data found...</p>
        )}
      </>
    );
  };

  const modalBody = () => {
    return (
      <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
        <div className="row align-items-center">
          <div className="col-md-8">
            {modalType == "habit" && (
              <Form.Group controlId="name">
                <Form.Label className="required">Title</Form.Label>
                <Form.Control
                  name="title"
                  type="text"
                  required
                  value={formInputs?.title}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {(modalType == "water" || modalType == "step") && (
              <Form.Group controlId="out_of_value">
                <Form.Label className="required">Out of value</Form.Label>
                <Form.Control
                  name="out_of_value"
                  type="text"
                  required
                  value={formInputs?.out_of_value}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </div>
          {/* <div className="col-md-4">
            {modalType != "habit" && (
              <div className="d-flex align-items-center">
                <ImageBoxUpload
                  data={chooseImageFn}
                  image_url={formInputs ? formInputs?.image_url : ""}
                />
                {formDirty && !formInputs?.image_url ? (
                  <div className="err-feedback">This field is required</div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
         */}
        </div>
        <div>
          {modalType == "habit" && (
            <>
              <Form.Group controlId="description" className="position-relative">
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={3}
                  maxLength="600"
                  required
                  value={formInputs?.description}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="image_urls" className="position-relative">
                <Form.Label className="required">Upload Images</Form.Label>
                <Form.Control
                  type="file"
                  name="image_urls"
                  accept="image/*"
                  multiple
                  onChange={handleMultiUploads}
                />
                {formDirty &&
                (!formInputs?.image_urls ||
                  formInputs?.image_urls.length == 0) ? (
                  <div className="err-feedback">This field is required</div>
                ) : null}
              </Form.Group>

              <div className="row">
                {formInputs?.image_urls &&
                  formInputs?.image_urls.map((x, i) => {
                    return (
                      <div key={i} className="multiImageBox Img my-3 col-sm-3">
                        <img
                          src={x}
                          className="img-fluid"
                          title={`Image-${i}`}
                          alt="image"
                        />
                        <span className="close" onClick={() => rmvImage(i)}>
                          &#10006;
                        </span>
                      </div>
                    );
                  })}
              </div>

              <Form.Label className="required my-3">Upload Videos</Form.Label>
              <div className="row">
                {formInputs?.video_urls &&
                  formInputs?.video_urls.map((item, i, arr) => {
                    return (
                      <div className="col-sm-6 videoBoxs" key={i}>
                        <Form.Group
                          controlId={`video_urls-${i}`}
                          className="position-relative"
                        >
                          <Form.Label className="required">
                            Video Url
                          </Form.Label>
                          <Form.Control
                            name="video_urls"
                            type="text"
                            required
                            value={item}
                            onChange={(e) => handleInputVideo(e, i)}
                          />
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                          {arr.length > 1 && (
                            <span className="close" onClick={() => rmvVideo(i)}>
                              &#10006;
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    );
                  })}
              </div>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setFormInputs((prev) => ({
                    ...prev,
                    video_urls: [...prev.video_urls, ""],
                  }))
                }
              >
                <u>Add Video</u>
              </span>
            </>
          )}
        </div>

        <div className="d-flex justify-content-end mt-5">
          <Button variant="secondary" onClick={closeModal} className="mr-3">
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {modalHead == "Add" ? "Add" : "Update"}
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.9) ? (
        <>
          <h3 className="text-capitalize mb-5">
            Manage Workout onBoarding content
          </h3>
          {/* habit */}
          <div className="habitBox">
            <div className="d-flex align-items-center mb-3 justify-content-between">
              <h5 className="text-capitalize mb-0">Habit of the day</h5>
              {tableListHabit?.length == 0 && (
                <Button
                  className="h-100"
                  onClick={() => {
                    setModalShow(true);
                    setModalHead("Add");
                    setModalType("habit");
                    setFormInputs({ ...habitFormInit });
                  }}
                >
                  Add Habit
                </Button>
              )}
            </div>
            {tableListHabit?.length > 0 ? (
              tableListHabit?.map((x, i) => {
                return (
                  <div className="mb-5 bg-white pt-3 px-3 pb-0 rounded" key={i}>
                    <div className="row">
                      <div className="col-md-11">
                        <h4>{x.title}</h4>
                        <p className="mt-3 mb-0">{x.description}</p>
                      </div>
                      <div className="col-md-1">
                        <Button
                          variant="link"
                          onClick={() => {
                            setModalShow(true);
                            setModalType("habit");
                            editContents(tableListHabit, "habit");
                            setModalHead("Edit");
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </div>
                      <div className="col-md-12">
                        <div className="row flex-wrap">
                          {x?.image_urls?.length > 0 &&
                            x?.image_urls?.map((x, i) => {
                              return (
                                <div key={i} className="multiImageBox prev m-3">
                                  <img
                                    src={x}
                                    className="img-fluid"
                                    title={`Image-${i}`}
                                    alt="image"
                                  />
                                </div>
                              );
                            })}
                          {x?.video_urls?.length > 0 &&
                            x?.video_urls?.map((x, i) => {
                              return (
                                <div key={i} className="multiImageBox prev m-3">
                                  <iframe
                                    src={`${x}?color=ef0800&title=0&byline=0&portrait=0" width="160" height="90" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen`}
                                    style={{ outline: "none", border: "none" }}
                                  ></iframe>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No data found...</p>
            )}
          </div>

          {/* water */}
          <div className="waterBox">
            <div className="d-flex align-items-center mb-3 justify-content-between">
              <h5 className="text-capitalize mb-0">Water Count</h5>
              {tableListWater?.length == 0 && (
                <Button
                  className="h-100"
                  onClick={() => {
                    setModalShow(true);
                    setModalHead("Add");
                    setModalType("water");
                    setFormInputs({ ...otherFormInit });
                  }}
                >
                  Add Water
                </Button>
              )}
            </div>

            {tableListWater?.length > 0 ? (
              tableListWater?.map((x, i) => {
                return (
                  <div className="mb-5 bg-white p-3 rounded" key={i}>
                    <div className="row align-items-center">
                      <div className="col-md-11">
                        {/* <h4>{x.title}</h4> */}
                        <p className="mt-0 mb-0">
                          Water Count: {x.out_of_value}
                        </p>
                      </div>
                      {/* <div className="col-md-4">
                        <img
                          className="w-100 rounded onboardImg"
                          alt="on-boarding-content-img"
                          src={x?.image_url}
                        />
                      </div> */}
                      <div className="col-md-1">
                        <Button
                          variant="link"
                          onClick={() => {
                            setModalShow(true);
                            setModalType("water");
                            editContents(tableListWater, "water");
                            setModalHead("Edit");
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No data found...</p>
            )}
          </div>

          {/* step */}
          <div className="stepBox">
            <div className="d-flex align-items-center mb-3 justify-content-between">
              <h5 className="text-capitalize mb-0">Step Count</h5>
              {tableListStep?.length == 0 && (
                <Button
                  className="h-100"
                  onClick={() => {
                    setModalShow(true);
                    setModalHead("Add");
                    setModalType("step");
                    setFormInputs({ ...otherFormInit });
                  }}
                >
                  Add Step
                </Button>
              )}
            </div>
            {tableListStep?.length > 0 ? (
              tableListStep?.map((x, i) => {
                return (
                  <div className="mb-5 bg-white p-3 rounded" key={i}>
                    <div className="row align-items-center">
                      <div className="col-md-11">
                        {/* <h4>{x.title}</h4> */}
                        <p className="mt-0 mb-0">
                          Step Count: {x.out_of_value}
                        </p>
                      </div>
                      {/* <div className="col-md-4">
                        <img
                          className="w-100 rounded onboardImg"
                          alt="on-boarding-content-img"
                          src={x?.image_url}
                        />
                      </div> */}
                      <div className="col-md-1">
                        <Button
                          variant="link"
                          onClick={() => {
                            setModalShow(true);
                            setModalType("step");
                            editContents(tableListStep, "step");
                            setModalHead("Edit");
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No data found...</p>
            )}
          </div>

          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>

          <ModalPop
            show={modalShow}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead={`${modalHead} ${modalType}`}
          />
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
