import React, { useState, useEffect } from "react";
import Editor from "../components/editor";

import { postMethod, AccessDenied, handleFalseStatus, isNotLoading } from "../Library";

import { Button, Toast } from "react-bootstrap";

export default function PrivacyPage(props) {
  const [readOnly, setReadOnly] = useState(true);
  const [formInputs, setFormInputs] = useState({
    description: "",
    type: "privacy-policy",
  });
  const [showToast, setShowToast] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const gettypedData = (data) => {
    setFormInputs((prev) => ({
      ...prev,
      description: data,
    }));
  };

  const enableEditor = (data) => {
    setReadOnly(false);
  };
  const disableEditor = (data) => {
    setReadOnly(true);
    getData("content_list", 1);
  };

  const updateData = async () => {
    const data = {
      url: "content_create_or_update",
      body: formInputs,
    };
    const newData = await postMethod(data);
    if(newData.status == 1){
    isNotLoading();
    showToastFn(newData.message);
    disableEditor();}
    else {
      isNotLoading();
    }
  };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const getData = async (url, id) => {
    const data = {
      url: url,
      body: {
        id: id,
        type: "privacy-policy",
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setFormInputs((prev) => ({
        ...prev,
        description: newData.data.contents[0].description,
        id: newData.data.contents[0].id,
      }));
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    } else {
      showToastFn(newData.message);
      isNotLoading();
    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getData("content_list", 1);
    }
  }, [pageLoaded, setPageLoaded]);

  return (
    <section className="rightContent privacyEditor">
      {props.menuList.includes(9.7) ? (
        <>
          <div className="d-flex align-items-center mb-5">
            <div>
              <h3 className="mb-0">Manage Privacy Policy</h3>
            </div>
            <div className="ml-auto">
              {readOnly ? (
                <Button variant="primary" onClick={enableEditor}>
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    variant="secondary"
                    className="mr-2"
                    onClick={disableEditor}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={updateData}>
                    Save
                  </Button>
                </>
              )}
            </div>
          </div>
          <p className="mb-1">Description</p>
          <Editor
            data={gettypedData}
            text={formInputs.description ? formInputs.description : ""}
            readOnly={readOnly}
          />
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
