import React, { useState, useEffect } from "react";
import Select from "react-select";

import {
  getTableData,
  getMethod,
  postMethod,
  AccessDenied,
  tableDefault,
  isNotLoading,
} from "../Library";
import ReactPaginate from "react-paginate";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../components/Dropdown";
import { ModalPopMd } from "../components/modals";
import { Form, Button, Toast, InputGroup, FormControl,Col,Row } from "react-bootstrap";
import Table from "../components/tables/table";
import { Link } from "react-router-dom";

export default function WeeklyMenuPage(props) {
  const [tableList, setTableList] = useState([]);
  const [programOptions, setProgramOptions] = useState([]);
  const [programValues, setProgramValues] = useState([]);
  // const [editId, setEditId] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  // const [validated, setValidated] = useState(false);
  const [weekOptions, setWeekOptions] = useState([]);
  const [filterWeek, setFilterWeek] = useState();
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedWeeks, setSelectedWeeks] = useState();
  const [duplicateID, setDuplicateID] = useState();
  const [weekList, setWeekList] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [programId, setProgramId] = useState([]);
  const [programVal, setProgramVal] = useState([]);
  const [perPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [selectWeeks, setSelectWeeks] = useState([]);

  // const [workoutMasters, setWorkoutMasters] = useState({
  //   menu_plan_name: "Test Plan",
  //   master_program_ids: [1],
  //   program_ids: [1],
  //   program_category_ids: [1],
  //   program_level_ids: [1],
  //   choose_week: "week 1",
  //   choose_program_type: 1,
  // });

  // const [formInputs, setFormInputs] = useState({
  //   name: "",
  //   categories: [],
  //   short_description: "",
  //   description: "",
  //   image_url: "",
  // });

  const tableColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "menu_plan_name", text: "Menu Name" },
    { dataField: "program_name", text: "Program Name" },
    { dataField: "choose_week", text: "Week" },
    { dataField: "diet_type", text: "Diet Type" },
    { dataField: "id", text: "Action", classes: "colNoWrap", formatter: buttonFormatter },
  ];

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  // const editClient = (id) => {
  //   getProgramData("master_program_list", id);
  // };

  // const getProgramData = async (url, id) => {
  //   const data = {
  //     url: url,
  //     body: {
  //       id: id,
  //     },
  //   };

  //   const newData = await postMethod(data);
  //   if (newData.status == 1) {
  //     // set form inputs
  //   } else {
  //     showToastFn(newData.message);
  //   }
  // };

  const openModal = (e, a) => {
    console.log(a, "addddddddd");
    const id = e.target.getAttribute("dataid");

    setProgramId(a);
    setDuplicateID(id);
    setModalShow(true);
  };
  const handleSelectInputs = (data) => {
    // console.log("im running handleseelctinputs");
    setProgramValues(data);
    console.log(data, "daaaa");
    if (data && data.length > 0) {
      let arr = [];
      data.forEach((item) => {
        arr.push(item.id);
      });
      // handle other option if multiple
      // if (data.length > 1) {
      //   // resetOptions();
      //   setWorkoutMasters((prev) => ({
      //     ...prev,
      //     program_ids: [...arr],
      //     choose_program_type: 1,
      //   }));
      //   // getMultipleWeekList(arr);
      // } else {
      //   // resetOptions();
      //   let program_id = data[0].id;
      //   // getProgramCategory("choose_workout_program_category", program_id);
      //   setWorkoutMasters((prev) => ({
      //     ...prev,
      //     program_ids: [program_id],
      //     choose_program_type: 0,
      //   }));
      // }
      setProgramId(arr);
    } else {
      setProgramId([]);

      // resetOptions();
    }
  };

  const selectOptionsAll = () => {
    if (
      (programValues && programValues.length) !=
      (programOptions && programOptions.length)
    ) {
      let arr = [];
      programOptions.forEach((item) => {
        arr.push(item.id);
      });
      // setWorkoutMasters((prev) => ({
      //   ...prev,
      //   program_ids: [...arr],
      //   choose_program_type: 1,
      //   choose_week: "",
      // }));
      setProgramValues(programOptions);
      // setCategoryOptions([]);
      // setLevelOptions([]);
      // setWeekOptions([]);
      // getMultipleWeekList(arr);
    }
  };
  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        arr.push(item.value);
      });
    return arr;
  }
  const getSelections = (data) => {
    setSelectedOptions(data);
    setSelectedWeeks(convert(data));
  };
  const getSelectionProgram = (data) => {
    setProgramValues(data);
    if (data && data.length > 0) {
      let arr = [];
      data.forEach((item) => {
        arr.push(item.id);
      });
     
      setProgramVal(arr);
    } else {
      setProgramVal([]);

      // resetOptions();
    }
  };

  const getWeekList = async () => {
    const data = {
      url: "static_content_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setWeekList(arr);
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setSelectedWeeks();
    setSelectedOptions();
      setProgramVal()
      setProgramValues()
      setDuplicateID();
  };

  const dupWeeklyMenu = async () => {
    const data = {
      url: "weekly_recipe_plan_duplicate",
      body: {
        id: duplicateID,
        choose_week: selectedWeeks,
        program_id :programVal
      },
    };
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      resetFilters();
    }
    else{
      isNotLoading();

    }
  };

  const resetFilters = async (e) => {
    getTableList();
  };
  useEffect(() => {
    ProgramWeeks();
  }, [programVal]);

  const ProgramWeeks = async () => {
    const data = {
      url: "multy_program_weeks",
      body: {
        program_id: programVal,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setSelectWeeks(arr);
    } else if (newData.status == 0) {
      setSelectWeeks([]);
      isNotLoading();
      showToastFn("selected program weeks doesn't match");
    } else {
      setSelectWeeks([]);
      isNotLoading();

    }
  };

  const getChooseProgram = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.data && newData.data.programs) {
      isNotLoading();
      setProgramOptions(formatSelectOptions(newData.data.programs));
    }else{
      isNotLoading();
      
    }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          ...item,
          name: item.program_name ? item.program_name : item.label,
          value: item.program_name ? item.program_name : item.label,
          label: item.program_name ? item.program_name : item.label,
        });
      });
    }
    return finalArr;
  };
  // const ProgramWeeks = async () => {
  //   const data = {
  //     url: "program_weeks",
  //     body: {
  //       program_id: programId,
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   if (newData.status == 1) {
  //     let arr = newData.data.program_weeks.map((item) => ({
  //       name: item,
  //     }));
  //     setSelectWeeks(arr);
  //   } else {
  //     setSelectWeeks([]);
  //   }
  // };

  const modalBody = () => {
    return (
      <>
        <div>
       
              <Form.Group>
            <Form.Label className="required">Choose Program(s)</Form.Label>
            <Dropdown
              getSelections={getSelectionProgram}
              name="week-list"
              options={programOptions}
              isDisabled={false}
              value={programValues}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">Choose Week(s)</Form.Label>
            <Dropdown
              getSelections={getSelections}
              name="week-list"
              options={selectWeeks}
              isDisabled={false}
              value={selectedOptions}
            />
          </Form.Group>
          <small className="mt-3">
            Note: This will create a duplicate menu plan for the above selected
            week(s) & program(s).
          </small>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={dupWeeklyMenu}
            disabled={!selectedOptions || selectedOptions?.length == 0}
          >
            Submit
          </Button>
        </div>
      </>
    );
  };

  function buttonFormatter(cell, row) {
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-weekly-menu/add-weekly-menu`,
            state: {
              id: row.id,
              editAccess: true,
            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        |
        <Button
          variant="link"
          dataid={cell}
          id={row.id}
          onClick={(e) => {
            openModal(e, row.program_ids);
          }}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
        |
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            trashClient(cell);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  function trashClient(id) {
    confirmAlert({
      title: `Confirm Delete?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "weekly_recipe_plan_delete",
              body: {
                id: id,
              },
            };
            const newData = await postMethod(data);
            showToastFn(newData.message);
           
            if (newData.status == 1) {
              // getLists();
              isNotLoading();
              getTableList();
            }
            else{
              isNotLoading();

            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }
  const handleSearchInput = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
      setOffset(1);
    }, 1000);

    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };
  const tableFilters = () => {
    return (
      <>
        <div className="d-flex justify-content-start">
          <InputGroup className="search-register-users">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleSearchInput}
            />
          </InputGroup>
        </div>
        <Form.Group className="mwx-300 w-100 mb-0" controlId="choose-filters">
          <Form.Control
            as="select"
            name="weekOptions"
            // value={recipeListDietType}
            onChange={chooseWeekFilter}
          >
            <option value="">Filter By Week</option>
            {weekOptions &&
              weekOptions.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </>
    );
  };

  const getTableList = async () => {
    let tableList = await getTableListFilter();
    if (tableList && tableList.recipe_list) {
      setTableList(tableList.recipe_list);
    }
  };

  const chooseWeekFilter = (e) => {
    setFilterWeek(e.target.value);
    // getTableListFilter(e.target.value);
    setOffset(1);
  };

  const getTableListFilter = async (choose_week) => {
    const data = {
      url: "weekly_recipe_plan_list",
      body: {
        search: { value: searchValue },
        length: 10,
        start: offset == 1 ? 0 : (offset - 1) * 10,
        draw: 10,
        choose_week: filterWeek,
      },
    };
    const newData = await postMethod(data);
    if (newData && newData.data.recipe_list) {
      isNotLoading();
      // console.log(newData.data.recordsTotal)
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      setTableList(newData.data.recipe_list);
    }
    else{
      isNotLoading();

    }
  };

  const getWeekOptions = async () => {
    const data = {
      url: "wrp_week_filter_list",
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let data = newData.data.weeks;
      // if (data) {
      //   let wkarr = [];
      //   for (let i = 1; i <= data; i++) {
      //     wkarr.push({ value: i, name: `week ${i}` });
      //   }
      //   setWeekOptions(wkarr);
      // }
      setWeekOptions(data);
    }
    else{
      isNotLoading();

    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // do somehting
      getTableList();
      getWeekOptions();
      getWeekList();
      getChooseProgram("choose_workout_master_program")
    }
  }, [pageLoaded, setPageLoaded, searchValue, offset, filterWeek]);

  return (
    <section className="rightContent">
      {props.menuList.includes(8.2) ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <h3>Manage Menu Plan</h3>
            <Link
              variant="primary"
              className="ml-auto btn btn-primary"
              to="/manage-weekly-menu/add-weekly-menu"
            >
              Add Menu Plan
            </Link>
          </div>

          <Table
            data={tableList}
            columns={tableColumns}
            filters={tableFilters}
            searchBar="false"
            offset={offset}
          />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              // onPageActive={onActivePage}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
          <ModalPopMd
            show={modalShow}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead="Duplicate Menu Plan"
          />
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
