import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { getMethod, isNotLoading, postMethod } from "../../Library";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ModalPop from "../modals";

export default function DashCards(props) {

  const [modal, setModal] = useState(false)
  const [data, setData] = useState([])
  const [editData, setEditData] = useState([])


  function createMarkup(data) {
    return { __html: data };
  }
  function editFn(e) {
    let id = e.target.getAttribute("dataid");
    props.showModalFn();
    props.setModalHead("Edit");
    // props.setEditFaqBlogData(id);

    getEditShow(id);
  }

  const getEditShow = async (id) => {
    props.setEditFaqBlogData(id);
    props.faqTabchange("Program based")
    props.faqTabchange("General FAQ")
    const data = {
      url: props.type == "FAQ's" ? `faq_list?id=${id}`
        : props.type == "Blogs"
          ? `blog_list?id=${id}`
          : `quote_tip_list?id=${id}`,
      body: {
        id: id,
      },
    };
    const newData = await getMethod(data);
    if (newData.status === 1) {
      isNotLoading()
      if (props.type == "FAQ's") {
        setEditData(newData.data.faqs[0])
        if (newData.data.faqs[0].category_id !== null) {
          props.disableProgramtab()
          props.faqTabchange("General FAQ")
        } else {
          props.disableGeneraltab()
          props.faqTabchange("Program based")
        }
        // if (newData.data.faqs[0].program_id !== null) {
        //   props.disableGeneraltab()
        //   props.faqTabchange("Program based")
        // }


      }
    } else {
      isNotLoading()
      console.log(newData.message)
    }
  }
  function viewFn(e) {
    let id = e.target.getAttribute("dataid");
    setModal(true)
    console.log(id)
    getView(id)
    // props.setEditFaqBlogData(id);
  }
  const getView = async (id) => {
    const data = {
      url:
        props.type == "FAQ's"
          ? `faq_list?id=${id}`
          : props.type == "Blogs"
            ? `blog_list?id=${id}`
            : `quote_tip_list?id=${id}`,
      // body: {
      //   id: id,
      // },
    };
    const newData = await getMethod(data);
    if (newData.status === 1) {
      isNotLoading()
      if (props.type == "FAQ's") {
        setData(newData.data.faqs[0])
      } else if (props.type == "Blogs") {
        setData(newData.data.blogs[0])
      } else {
        setData(newData.data.quote_tips[0])
      }
    } else {
      isNotLoading()
      console.log(newData.message)
    }
  }
  const closeModal = () => {
    setModal(false)
    setData([])
  };
  // useEffect(() => {
  // console.log(props,"faq")
  // }, [])


  const deleteData = async (data) => {
    const newData = await postMethod(data);
    if (newData.status === 1) {
      isNotLoading()
      props.showToastFn(newData.message);
      props.getData(props.type);
    }
    else {
      isNotLoading()

    }
  };

  const deleteFn = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url:
        props.type == "FAQ's"
          ? "faq_delete"
          : props.type == "Blogs"
            ? "blog_delete"
            : "quote_tip_delete",
      body: {
        id: id,
      },
    };

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteData(data),
        },
        {
          label: "No",
          onClick: () =>
            console.log("Clicked No"),
        },
      ],
    });
  };
  const modalBody = () => {

    return (
      <>
        <h4 className="mb-2">
          <b className="text-capitalize">
            {data.title ? data.title : null}
          </b>
        </h4>
        <div
          className="cards_div"
          dangerouslySetInnerHTML={createMarkup(data.description)}
        />
      </>
    )
  };
  return (
    <>
      {props.cardData && props.cardData.length > 0 ? (
        props.cardData.map((item, index) => (
          <Card className="mb-3" key={index}>
            <Card.Body>
              <Row className="align-items-start">
                <Col md="8">
                  {props.type == "Quotes & Tips" ? (
                    <>
                      <p className="mb-0">
                        <b>Quote: </b> <span>{item.quote_description}</span>
                      </p>

                      <p className="mb-0 mt-2">
                        <b>Tip: </b>
                        <span>{item.tip_description}</span>
                      </p>

                    </>
                  ) : (
                    props.type == "FAQ's" ?
                      <>
                        <p className="mb-0">
                          <b>Name : </b> <span> {item?.title}</span>
                        </p>
                        {
                          item?.category_id !== null ?
                            <p className="mb-0 mt-2">
                              <b>Category Name : </b>
                              <span> {item?.categories?.name}</span>
                            </p>
                            :
                            <p className="mb-0 mt-2">
                              <b>program Name : </b>
                              <span> {item?.programs?.program_name}</span>
                            </p>
                        }
                        {/* {
                          item?.program_id !== null ?
                            <p className="mb-0 mt-2">
                              <b>program Name : </b>
                              <span> {item?.programs?.program_name}</span>
                            </p>
                            : ''
                        } */}


                      </>
                      :
                      <>
                        <p className="mb-0">
                          <b className="text-capitalize">
                            {item.title ? `${item.title} ` : null}
                          </b>
                        </p>
                      </>
                  )}
                </Col>
                <Col md="4" className="text-right">
                  {props.type == "FAQ's" ?
                    <>
                      {item?.category_id !== null ?
                        <Badge className="commonFaqTag mr-1">Common to all</Badge>
                        :
                        <Badge className="programFaqTag mr-1">Program based</Badge>}
                    </>
                    : null}
                  {props.type == "Quotes & Tips" ? null :
                    <Button variant="link" className="mt-1" dataid={item.id} onClick={viewFn}>
                      <FontAwesomeIcon icon={faEye} />
                    </Button>}
                  <Button variant="link" className="mt-1" dataid={item.id} onClick={editFn}>
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                  <Button variant="link" className="mt-1" dataid={item.id} onClick={deleteFn}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))
      ) : (

        null
      )}
      <ModalPop
        show={modal}
        onHide={closeModal}
        disableEnforceFocus={true}
        modalcontent={modalBody()}
        modalhead={`View ${props.type}`}
      />
    </>
  );
}
