import React, { useState, useEffect } from "react";

import { getTableData, postMethod, findErrors, AccessDenied, isNotLoading } from "../Library";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { Form, Button, Toast } from "react-bootstrap";
import Table from "../components/tables/table";
// import Dropdown from "../components/Dropdown";
import { DropdownCreatable } from "../components/Dropdown";
import ModalPop from "../components/modals";
import ImageBoxUpload from "../components/ImageBox/imageboxupload";

export default function ProgramCategoryPage(props) {
  const [tableList, setTableList] = useState([]);
  const [editId, setEditId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryValue, setCategoryValue] = useState();
  const [formInputs, setFormInputs] = useState({
    name: "",
    categories: [],
    short_description: "",
    description: "",
    image_url: "",
  });

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const getProgramData = async (url, id) => {
    const data = {
      url: url,
      body: {
        id: id,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let categories = convert(newData.data.master_programs[0].categories);
      setFormInputs((prev) => ({
        ...prev,
        name: newData.data.master_programs[0].name,
        short_description: newData.data.master_programs[0].short_description,
        description: newData.data.master_programs[0].description,
        image_url: newData.data.master_programs[0].image_url,
        id: newData.data.master_programs[0].id,
        categories: categories,
      }));
      setCategoryValue(newData.data.master_programs[0].categories);
    } else {
      isNotLoading();
      showToastFn(newData.message);
    }
  };

  const editClient = (id) => {
    getProgramData("master_program_list", id);
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const chooseImageFn = (url) => {
    let form = { ...formInputs };
    form.image_url = url;
    setFormInputs(form);
  };

  function buttonFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setEditId(cell);
            editClient(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            trashClient(cell);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  function trashClient(id) {
    confirmAlert({
      title: `Confirm Delete?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: "master_program_delete",
              body: {
                id: id,
              },
            };
            const newData = await postMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              isNotLoading();
              getLists();
            }
            else{
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }

  function categoryFormatter(cell, row) {
    let arr = [];
    if (cell && cell.length > 0) {
      cell.forEach((element) => {
        arr.push(element.name);
      });
    }
    return <p>{arr && arr.length > 0 ? arr.join(", ") : "NA"}</p>;
  }

  function categoryFilter(cell, row) {
    let arr = [];
    if (cell && cell.length > 0) {
      cell.forEach((element) => {
        arr.push(element.name);
      });
    }
    return arr.toString();
  }

  const tableColumns = [
    { dataField: "sno", text: "S.No", searchable: false },
    { dataField: "name", text: "Program Name" },
    {
      dataField: "categories",
      text: "Categories",
      formatter: categoryFormatter,
      filterValue: (cell, row) => categoryFilter(cell, row),
    },
    {
      dataField: "id",
      text: "Action",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  const getLists = async () => {
    let x = await getTableData("master_program_list");
    if (x && x.master_programs) {
      setTableList(x.master_programs);
    }
  };

  // const getCategories = async () => {
  //   const data = {
  //     url: "category_list",
  //     body: {
  //       type: "program",
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   if (newData.data && newData.data.categories) {
  //     // console.log(newData.data.categories);
  //     setCategoryOptions(newData.data.categories);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    createProgramFn(formInputs);
  };

  const createProgramFn = async (formInputs) => {
    // console.log(formInputs);
    const data = {
      url: "pgm_category_create_or_update",
      body: {
        ...formInputs,
      },
    };

    const reqFields = _.pick(
      formInputs,
      "name",
      "short_description",
      "description",
      "image_url"
    );

    const errors = findErrors(reqFields);
    if (!errors && formInputs.categories.length > 0) {
      // console.log(data);
      const newData = await postMethod(data);
      
      showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        setformDefaults();
        setEditId();
        setModalShow(false);
        getLists();
      }
      else{
        isNotLoading();
      }
    }
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        let arrn = _.omit(item, ["__isNew__"]);
        arr.push({ ...arrn, name: arrn.label });
      });
    return arr;
  }

  const getSelections = (data) => {
    setCategoryValue(data);
    let arr = convert(data);
    setFormInputs((prev) => ({
      ...prev,
      categories: [...arr],
    }));
  };

  const modalBody = () => {
    return (
      <>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-8">
              <Form.Group controlId="title">
                <Form.Label className="required">Program Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  required
                  value={formInputs.name}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This Field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="categories">
                <Form.Label className="required">Category</Form.Label>
                <DropdownCreatable
                  getSelections={getSelections}
                  name="categories"
                  value={categoryValue}
                />
                {validated &&
                  formInputs &&
                  formInputs.categories.length == 0 && (
                    <div className="err-feedback">This field is required</div>
                  )}
              </Form.Group>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <ImageBoxUpload
                data={chooseImageFn}
                image_url={formInputs.image_url ? formInputs.image_url : ""}
              />
              {validated &&
                (formInputs.image_url == null ||
                  formInputs.image_url == "") && (
                  <div className="err-feedback">This field is required</div>
                )}
            </div>
          </div>

          <Form.Group
            controlId="shortdescription"
            className="position-relative"
          >
            <Form.Label className="required">Short Description</Form.Label>
            <Form.Control
              as="textarea"
              name="short_description"
              rows={3}
              maxLength="60"
              required
              value={formInputs.short_description}
              onChange={handleInput}
            />
            <small className="char-len">
              {60 - formInputs.short_description.length} characters left
            </small>
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="description" className="position-relative">
            <Form.Label className="required">Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              rows={3}
              maxLength="180"
              required
              value={formInputs.description}
              onChange={handleInput}
            />
            <small className="char-len">
              {180 - formInputs.description.length} characters left
            </small>
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                setModalShow(false);
                setformDefaults();
                setEditId();
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {editId ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </>
    );
  };

  // useEffect(() => {
  //   console.log(formInputs);
  // }, [setFormInputs, formInputs]);

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getLists();
      // getCategories();
    }
  }, [pageLoaded, setPageLoaded]);

  const setformDefaults = () => {
    // console.log("settingdefault");
    setFormInputs({
      name: "",
      categories: [],
      short_description: "",
      description: "",
      image_url: "",
    });
    setCategoryValue();
  };

  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.1) ? (
        <>
          <div className="d-flex align-items-center mb-5">
            <div>
              <h3 className="mb-0">Manage Program & Category</h3>
            </div>
            <div className="ml-auto">
              <Button
                variant="primary"
                onClick={() => {
                  setModalShow(true);
                }}
              >
                Add
              </Button>
            </div>
          </div>
          <Table
            data={tableList}
            columns={tableColumns}
            filters={tableFilters}
          />
          <ModalPop
            show={modalShow}
            onEnter={() => {
              setValidated(false);
            }}
            onHide={() => {
              setformDefaults();
              setEditId();
              setModalShow(false);
            }}
            modalcontent={modalBody()}
            modalhead={`${editId ? "Edit" : "Add"} Program & Category`}
          />
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
