import React, { useState, useEffect } from "react";
import {
  getTableDataGet,
  postMethod,
  getMethod,
  findErrors,
  AccessDenied,
  tableDefault,
  isNotLoading,
} from "../Library";
import { Form, Row, Col, Button, Tabs, Tab, Toast } from "react-bootstrap";

import Stepper from "react-stepper-horizontal";

import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

import TableSelect from "../components/tables/tableselectWeeklyMenu";

import Select from "react-select";
import AlertRedirect from "../components/alert";

export default function WeeklyMenuAdd(props) {
  const [validated, setValidated] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [overview, setOverview] = useState([]);
  const [week, setWeek] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [tableListRecipe, setTableListRecipe] = useState([]);
  const [editDataUpdated, setEditDataUpdated] = useState(false);
  const [inputReadonly, setInputReadonly] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [recipeListDietType, setRecipeListDietType] = useState("");
  const [programId, setProgramId] = useState([]);

  const [workoutMasters, setWorkoutMasters] = useState({
    menu_plan_name: "",
    program_ids: [],
    choose_week: "",
    choose_program_type: 0,
    diet_type: "",
  });

  const [formInputsMon, setFormInputsMon] = useState();
  const [formInputsTue, setFormInputsTue] = useState();
  const [formInputsWed, setFormInputsWed] = useState();
  const [formInputsThu, setFormInputsThu] = useState();
  const [formInputsFri, setFormInputsFri] = useState();
  const [formInputsSat, setFormInputsSat] = useState();
  const [formInputsSun, setFormInputsSun] = useState();

  const [programOptions, setProgramOptions] = useState([]);
  const [programValues, setProgramValues] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();
  const [levelOptions, setLevelOptions] = useState();
  // const [weekData, setWeekData] = useState();
  const [weekOptions, setWeekOptions] = useState();

  const [tabList, setTabList] = useState([]);
  const [key, setKey] = useState();

  const history = useHistory();
  const location = useLocation();

  const recipeColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Recipe Name" },
  ];

  const tableFilters = () => {
    return (
      <>
        <Form.Group className="mwx-300 w-100 mb-0" controlId="choose-filters">
          <Form.Control
            as="select"
            name="dietTypeOptions"
            value={recipeListDietType}
            onChange={ChooseDietFilter}
          >
            <option value="">Filter By Diet Type</option>
            {filterOptions &&
              filterOptions.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </>
    );
  };

  // const getInputRow = (data) => {
  //   console.log(data);
  //   return [];
  // };

  const getSelectedRow = (data, index, catId) => {
    const stateSel = sections[currentPage].stateSel;
    stateSel((prev) => ({
      ...prev,
      recipe_categories: {
        ...prev.recipe_categories,
        [index]: {
          recipe_category_id: catId,
          recipe_ids: [...data],
        },
      },
    }));
  };

  const handleInput = (e) => {
    setWorkoutMasters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // const getMultipleWeekList = async (master_program_ids) => {
  //   const data = {
  //     url: "wrp_select_all_week_lists",
  //     body: {
  //       master_program_ids: [...master_program_ids],
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   if (newData.status == 1) {
  //     getWeekList(newData.data.weeks);
  //   }
  // };

  // const getWeekList = (data) => {
  //   if (data) {
  //     let wkarr = [];
  //     for (let i = 1; i <= data; i++) {
  //       wkarr.push({ value: i, name: `week ${i}` });
  //     }
  //     if (wkarr.length == 1) {
  //       wkarr[0].name = "PT week 1";
  //     }
  //     if (wkarr.length >= 2) {
  //       wkarr[0].name = "PT week 1";
  //       wkarr[1].name = "PT week 2";
  //     }
  //     setWeekOptions(wkarr);
  //   }
  // };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const checkIfEdit = async () => {
    if (location.state) {
      const data = {
        url: "weekly_recipe_plan_by_id",
        body: {
          id: location.state.id,
        },
      };
      const newData = await postMethod(data);
      if (newData.status == 1) {
        isNotLoading();
        const cpDt = newData.data.weekly_recipe_plan;
        const cpDtResult = _.omit(cpDt, ["weekly_recipe_items"]);
        setWorkoutMasters(cpDtResult);
        setWeek(cpDtResult.choose_week);
        console.log(cpDtResult, "hhhhhh");
        var a = cpDtResult.program_ids;
        setProgramId(a);
        console.log(workoutMasters.choose_week);
        let programs = newData.data.weekly_recipe_plan.program_ids;
        let arr = [];
        programs.forEach((item) => {
          let x = programOptions.filter((prg) => prg.id == item);
          arr.push(x[0]);
        });
        setProgramValues(arr);
        // getMultipleWeekList(programs);

        setFormInputsMon(
          newData.data.weekly_recipe_plan.weekly_recipe_items.monday
        );
        setFormInputsTue(
          newData.data.weekly_recipe_plan.weekly_recipe_items.tuesday
        );
        setFormInputsWed(
          newData.data.weekly_recipe_plan.weekly_recipe_items.wednesday
        );
        setFormInputsThu(
          newData.data.weekly_recipe_plan.weekly_recipe_items.thursday
        );
        setFormInputsFri(
          newData.data.weekly_recipe_plan.weekly_recipe_items.friday
        );
        setFormInputsSat(
          newData.data.weekly_recipe_plan.weekly_recipe_items.saturday
        );
        setFormInputsSun(
          newData.data.weekly_recipe_plan.weekly_recipe_items.sunday
        );
        setEditDataUpdated(true);
        setInputReadonly(true);
      } else {
        isNotLoading();
        // window.alert("Oops! Unable to load data. Try again later!!");
        // window.location.href = "/manage-weekly-menu";
      }
    } else {
      isNotLoading();
      // console.log("add weekly menu");
      setFormInputsMon({
        day: "monday",
        recipe_categories: {},
      });
      setFormInputsTue({
        day: "tuesday",
        recipe_categories: {},
      });
      setFormInputsWed({
        day: "wednesday",
        recipe_categories: {},
      });
      setFormInputsThu({
        day: "thursday",
        recipe_categories: {},
      });
      setFormInputsFri({
        day: "friday",
        recipe_categories: {},
      });
      setFormInputsSat({
        day: "saturday",
        recipe_categories: {},
      });
      setFormInputsSun({
        day: "sunday",
        recipe_categories: {},
      });
    }
  };

  const getChooseProgram = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.data && newData.data.programs) {
      isNotLoading();
      setProgramOptions(formatSelectOptions(newData.data.programs));
    } else {
      isNotLoading();
    }
  };

  const ChooseDietFilter = async (e) => {
    let val = e.target.value;
    // getRecipeListDietType(key, val);
    setRecipeListDietType(val);
    getRecipeListID(key, val);
  };

  const ChooseCategoryFn = (e) => {
    let program_id =
      e.target.options[e.target.selectedIndex].getAttribute("program_id");

    setWorkoutMasters((prev) => ({
      ...prev,
      program_ids: [parseInt(program_id)],
      choose_week: "",
    }));

    // if (program_id) {
    //   getProgramLevels("choose_workout_program_level", program_id);
    // } else {
    //   setLevelOptions([]);
    //   setWeekOptions([]);
    // }
  };

  // const getProgramLevels = async (url, id) => {
  //   const data = {
  //     url: url,
  //     body: {
  //       program_ids: [id],
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   if (
  //     newData.data &&
  //     newData.data.length > 0 &&
  //     newData.data[0].program_levels.length > 0
  //   ) {
  //     let arr = [];
  //     let weekarr = [];
  //     newData.data[0].program_levels.forEach((item) => {
  //       arr.push({
  //         weeks: item.weeks,
  //         name: item.name,
  //         id: item.id,
  //       });
  //       // weekarr.push({ name: item.name, weeks: item.weeks });
  //     });
  //     // setWeekData(weekarr);
  //     setLevelOptions(arr);
  //   } else {
  //     setLevelOptions([]);
  //   }
  // };

  // const ChooseLevelFn = (e) => {
  //   let id = e.target.options[e.target.selectedIndex].getAttribute("dataid");
  //   let weeks = e.target.options[e.target.selectedIndex].getAttribute("weeks");
  //   setWorkoutMasters((prev) => ({
  //     ...prev,
  //     program_level_ids: [parseInt(id)],
  //   }));
  //   if (weeks) {
  //     getWeekList(weeks);
  //   } else {
  //     setWeekOptions([]);
  //     setWorkoutMasters((prev) => ({
  //       ...prev,
  //       choose_week: "",
  //     }));
  //   }
  // };

  const handleChallengeName = (e) => {
    setWorkoutMasters((prev) => ({
      ...prev,
      menu_plan_name: e.target.value,
    }));
  };

  const selectOptionsAll = () => {
    if (
      (programValues && programValues.length) !=
      (programOptions && programOptions.length)
    ) {
      let arr = [];
      programOptions.forEach((item) => {
        arr.push(item.id);
      });
      setWorkoutMasters((prev) => ({
        ...prev,
        program_ids: [...arr],
        choose_program_type: 1,
        choose_week: "",
      }));
      setProgramValues(programOptions);
      // setCategoryOptions([]);
      // setLevelOptions([]);
      // setWeekOptions([]);
      // getMultipleWeekList(arr);
    }
  };

  const handleSelectInputs = (data) => {
    // console.log("im running handleseelctinputs");
    setProgramValues(data);
    console.log(data, "daaaa");
    if (data && data.length > 0) {
      let arr = [];
      data.forEach((item) => {
        arr.push(item.id);
      });
      // handle other option if multiple
      if (data.length > 1) {
        // resetOptions();
        setWorkoutMasters((prev) => ({
          ...prev,
          program_ids: [...arr],
          choose_program_type: 1,
        }));
        // getMultipleWeekList(arr);
      } else {
        // resetOptions();
        let program_id = data[0].id;
        // getProgramCategory("choose_workout_program_category", program_id);
        setWorkoutMasters((prev) => ({
          ...prev,
          program_ids: [program_id],
          choose_program_type: 0,
        }));
      }
      setProgramId(arr);
    } else {
      setProgramId([]);

      resetOptions();
    }
  };

  const resetOptions = () => {
    // set program category to none
    // set program level to none
    // setCategoryOptions([]);
    // setLevelOptions([]);
    // setWeekOptions([]);
    setWorkoutMasters((prev) => ({
      ...prev,
      program_ids: [],
      choose_program_type: 0,
      choose_week: "",
    }));
  };

  const getProgramCategory = async (url, id) => {
    // console.log(id);
    const data = {
      url: url,
      body: {
        master_program_ids: [id],
      },
    };
    const newData = await postMethod(data);
    // console.log(newData);

    if (newData.data && newData.data.length > 0) {
      isNotLoading();
      let arr = [];
      newData.data.forEach((item) => {
        arr.push({
          name: item.categories.name,
          program_category_ids: item.categories.id,
          program_ids: item.id,
        });
      });
      setCategoryOptions(arr);
    } else {
      isNotLoading();
    }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          ...item,
          name: item.program_name ? item.program_name : item.label,
          value: item.program_name ? item.program_name : item.label,
          label: item.program_name ? item.program_name : item.label,
        });
      });
    }
    return finalArr;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    checkFormErrors();
  };

  const checkFormErrors = () => {
    const reqFields = _.pick(
      workoutMasters,
      "menu_plan_name",
      "choose_week",
      "diet_type"
    );

    let reqFieldsArr = _.pick(workoutMasters, "program_ids");

    if (workoutMasters.choose_program_type == 1) {
      reqFieldsArr = _.pick(workoutMasters, "program_ids");
    }
    // removed program_level_id

    const errors = findErrors(reqFields);
    const errorsArr = findErrors(reqFieldsArr);
    let errorForm = validateCurrentPage();

    if (!errors && !errorsArr && !errorForm) {
      // console.log("All set to submit");
      let formSubmit = {
        ...workoutMasters,
        weekly_recipe_items: {
          monday: {
            ...formInputsMon,
          },
          tuesday: {
            ...formInputsTue,
          },
          wednesday: {
            ...formInputsWed,
          },
          thursday: {
            ...formInputsThu,
          },
          friday: {
            ...formInputsFri,
          },
          saturday: {
            ...formInputsSat,
          },
          sunday: {
            ...formInputsSun,
          },
        },
      };
      createWeeklyMenu(formSubmit);
    }
  };

  const createWeeklyMenu = async (body) => {
    const data = {
      url: "weekly_recipe_plan_create_or_update",
      body: body,
    };
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      AlertRedirect(
        "Meal Saved Successfully !!",
        "Redirect back to Meal Recommendation ?",
        history,
        "manage-weekly-menu"
      );
    }
  };

  const sections = [
    { title: "Monday", stateSel: setFormInputsMon, stateVal: formInputsMon },
    { title: "Tuesday", stateSel: setFormInputsTue, stateVal: formInputsTue },
    { title: "Wednesday", stateSel: setFormInputsWed, stateVal: formInputsWed },
    { title: "Thursday", stateSel: setFormInputsThu, stateVal: formInputsThu },
    { title: "Friday", stateSel: setFormInputsFri, stateVal: formInputsFri },
    { title: "Saturday", stateSel: setFormInputsSat, stateVal: formInputsSat },
    { title: "Sunday", stateSel: setFormInputsSun, stateVal: formInputsSun },
  ];

  const getTabs = async () => {
    const data = {
      url: "list_recipe_category_with_over_view",
    };
    const newData = await getMethod(data);
    if (newData.data && newData.data.recipe_category_list) {
      setTabList(newData.data.recipe_category_list);
      setKey(newData.data.recipe_category_list[0].id);
      getRecipeListID(newData.data.recipe_category_list[0].id);
      // console.log(newData.data.recipe_category_list);
    }
  };

  const validateCurrentPage = () => {
    let error = true;
    const stateVal = sections[currentPage].stateVal.recipe_categories;
    let arr = [];
    for (let x in stateVal) {
      if (
        Array.isArray(stateVal[x]?.recipe_ids) &&
        stateVal[x]?.recipe_ids?.length > 0
      ) {
        arr.push(stateVal[x].recipe_ids.length);
      }
    }
    if (arr.length > 0) {
      error = false;
    } else {
      error = true;
    }
    return error;
  };

  const scrollToForm = () => {
    let el = document.getElementById("weekForm");
    let topPos = el.offsetTop;
    if (topPos > 100)
      window.scrollTo({
        top: topPos - 10,
        left: 0,
        behavior: "smooth",
      });
  };

  const nextPage = () => {
    let error = validateCurrentPage();

    if (!error) {
      setCurrentPage((prev) => prev + 1);
      setKey(tabList[0].id);
      getRecipeListID(tabList[0].id);
      scrollToForm();
    } else {
      showToastFn("Please select atleast one Recipe from a Category!!");
    }
  };

  const prevPage = () => {
    // let error = validateCurrentPage();
    // if (!error) {
    setCurrentPage((prev) => prev - 1);
    setKey(tabList[0].id);
    getRecipeListID(tabList[0].id);
    scrollToForm();
    // }
  };

  const getRecipeListID = async (id, diet_type_id) => {
    const data = {
      url: "wrp_category_recipe_items_with_select",
      body: {
        length: tableDefault,
        start: 0,
        draw: tableDefault,
        category_id: parseInt(id),
        diet_type_id: diet_type_id ? diet_type_id : "",
        search: { value: "" },
        weekly_recipe_menu_id: location.state ? location.state.id : null,
        day:
          currentPage == 0
            ? "monday"
            : currentPage == 1
            ? "tuesday"
            : currentPage == 2
            ? "wednesday"
            : currentPage == "3"
            ? "thursday"
            : currentPage == "4"
            ? "friday"
            : currentPage == "5"
            ? "saturday"
            : "sunday",
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data.recipe_list) {
      isNotLoading();
      setTableListRecipe(newData.data.recipe_list);
    } else {
      isNotLoading();
    }
  };

  const overView = async (id, diet_type_id) => {
    const data = {
      url: "weekly_recipe_plan_by_id_over_view",
      body: {
        id: location.state ? location.state.id : null,
        day:
          currentPage == 0
            ? "monday"
            : currentPage == 1
            ? "tuesday"
            : currentPage == 2
            ? "wednesday"
            : currentPage == "3"
            ? "thursday"
            : currentPage == "4"
            ? "friday"
            : currentPage == "5"
            ? "saturday"
            : "sunday",
        // draw: tableDefault,
        // category_id: parseInt(id),
        // diet_type_id: diet_type_id ? diet_type_id : "",
        // search: { value: "" },
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data.weekly_recipe_plan) {
      setOverview(newData.data.weekly_recipe_plan);
    } else {
      isNotLoading();
    }
  };

  // useEffect(() => {
  //   if (recipeListDietType) {
  //     getRecipeListID(key, recipeListDietType);
  //   } else {
  //     getRecipeListID(key);
  //   }
  // }, [setRecipeListDietType, recipeListDietType]);

  // const getRecipeListDietType = async (catId, diet_type_id) => {
  //   const data = {
  //     url: "wrp_category_recipe_items",
  //     body: {
  //       length: 1000,
  //       start: 0,
  //       draw: 1000,
  //       category_id: parseInt(catId),
  //       diet_type_id: diet_type_id,
  //       search: { value: "" },
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   if (newData.status == 1 && newData.data.recipe_list) {
  //     setTableListRecipe(newData.data.recipe_list);
  //   }
  // };

  const getFilterList = async () => {
    let x = await getTableDataGet("list_recipe_diet_type");
    if (x && x.recipe_diet_type_list) {
      setFilterOptions(x.recipe_diet_type_list);
    }
  };

  // const getWeekList = async () => {
  //   const data = {
  //     url: "static_content_list",
  //   };
  //   const newData = await getMethod(data);
  //   if (newData.status == 1) {
  //     setWeekOptions(newData.data.program_weeks);
  //   }
  // };

  const ProgramWeeks = async () => {
    const data = {
      url: "multy_program_weeks",
      body: {
        program_id: programId,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setWeekOptions(newData.data.program_weeks);
    } else if (newData.status == 0) {
      isNotLoading();
      setWeekOptions([]);
      showToastFn("selected program weeks doesn't match");
    } else {
      isNotLoading();
      setWeekOptions([]);
    }
  };

  useEffect(() => {
    ProgramWeeks();
  }, [programId]);

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // do somehting
      getChooseProgram("choose_workout_master_program");
      getTabs();
      getFilterList();
      // checkIfEdit();
    }
  }, [pageLoaded, setPageLoaded]);

  // useEffect(() => {
  //   if (workoutMasters && editDataUpdated) {
  //     getProgramCategory(
  //       "choose_workout_program_category",
  //       workoutMasters.master_program_ids[0]
  //     );
  //     // getProgramLevels(
  //     //   "choose_workout_program_level",
  //     //   workoutMasters.program_ids[0]
  //     // );
  //     setEditDataUpdated(false);
  //   }
  // }, [setWorkoutMasters, workoutMasters, setEditDataUpdated, editDataUpdated]);

  useEffect(() => {
    if (programOptions.length > 0) {
      checkIfEdit();
      overView();
    }
  }, [programOptions, setProgramOptions]);

  useEffect(() => {
    if (programOptions.length > 0) {
      overView();
    }
  }, [currentPage]);

  // useEffect(() => {
  //   console.log("formInputsMon", formInputsMon);
  // }, [setFormInputsMon, formInputsMon]);

  // useEffect(() => {
  //   console.log("formInputsTue", formInputsTue);
  // }, [setFormInputsTue, formInputsTue]);

  // useEffect(() => {
  //   console.log("master", workoutMasters);
  // }, [workoutMasters, setWorkoutMasters]);
  return (
    <section className="rightContent">
      {props.menuList.includes(8.2) ? (
        <>
          <h3>
            {location.state && location.state.id ? "Edit" : "Add"} Menu Plan
          </h3>
          {/* <Table
            data={tableList}
            columns={tableColumns}
            filters={tableFilters}
          /> */}
          <Form
            noValidate
            validated={validated}
            id="weekForm"
            className="challenge_form"
          >
            <Row className="mt-5">
              <Col sm="4">
                <Form.Label className="required">Menu Plan Name</Form.Label>
                <Form.Control
                  type="text"
                  name="menu_plan_name"
                  required
                  onChange={handleChallengeName}
                  value={workoutMasters.menu_plan_name}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col sm="4" className={inputReadonly ? "ReadOnly" : ""}>
                <Form.Group
                  className="customSelect w-100"
                  controlId="choose-program"
                >
                  <Form.Label className="required">
                    Choose Program(s)
                  </Form.Label>
                  <Select
                    value={programValues}
                    isMulti
                    options={programOptions}
                    className="basic-multi-select new"
                    classNamePrefix="select"
                    onChange={(selectedOption) =>
                      handleSelectInputs(selectedOption)
                    }
                  />
                  {programOptions && programOptions.length > 1 ? (
                    <u
                      className="font-14 mt-1 cursor-pointer d-inline selectAllBtn"
                      onClick={selectOptionsAll}
                    >
                      Select All
                    </u>
                  ) : null}
                  {workoutMasters.program_ids.length == 0 && validated && (
                    <div className="err-feedback">This field is required</div>
                  )}
                </Form.Group>
              </Col>
              {/* <Col sm="4">
                <Form.Label className="required">Program Category</Form.Label>
                <Form.Control
                  as="select"
                  name="programcategory"
                  required
                  value={
                    workoutMasters.program_category_ids.length > 0
                      ? workoutMasters.program_category_ids[0]
                      : ""
                  }
                  disabled={
                    workoutMasters.choose_program_type == 1 || inputReadonly
                      ? true
                      : false
                  }
                  onChange={ChooseCategoryFn}
                >
                  <option value="">Select Category</option>
                  {categoryOptions &&
                    categoryOptions.map((item, index) => (
                      <option
                        key={index}
                        value={item.program_category_ids}
                        program_id={item.program_ids}
                      >
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Col> */}

              {/* <Col sm="4">
                <Form.Label className="required">Program Level</Form.Label>
                <Form.Control
                  as="select"
                  name="programlevel"
                  id="programlevel"
                  required
                  value={
                    workoutMasters.program_level_ids.length > 0
                      ? workoutMasters.program_level_ids
                      : ""
                  }
                  disabled={
                    workoutMasters.choose_program_type == 1 || inputReadonly
                      ? true
                      : false
                  }
                  onChange={ChooseLevelFn}
                >
                  <option value="">Select Level</option>
                  {levelOptions &&
                    levelOptions.map((item, index) => (
                      <option
                        key={index}
                        levelname={item.name}
                        weeks={item.weeks}
                        value={item.id}
                        dataid={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Col> */}

              <Col sm="3">
                <Form.Label className="required">Choose A Week</Form.Label>
                <Form.Control
                  as="select"
                  name="choose_week"
                  required
                  value={
                    workoutMasters.choose_week
                      ? workoutMasters.choose_week
                      : week
                      ? week
                      : ""
                  }
                  disabled={inputReadonly ? true : false}
                  onChange={handleInput}
                >
                  <option value="">Select Week</option>
                  {weekOptions &&
                    weekOptions.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                </Form.Control>
                {/* {JSON.stringify(weekOptions, null, 2)} */}
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Col>
              <Col sm="3">
                <Form.Label className="required">Choose A Diet Type</Form.Label>
                <Form.Control
                  as="select"
                  name="diet_type"
                  required
                  value={
                    workoutMasters.diet_type ? workoutMasters.diet_type : ""
                  }
                  disabled={inputReadonly ? true : false}
                  onChange={handleInput}
                >
                  <option value="">Select Diet Type</option>
                  {filterOptions &&
                    filterOptions.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                {/* {JSON.stringify(weekOptions, null, 2)} */}
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>

          {/* <h6 className="stepForm mt-5">Add Recipes</h6> */}
          <Form.Label className="required mt-0">Add Recipes</Form.Label>

          <div id="stepForm" className="stepForm mt-0">
            <Stepper
              steps={sections}
              activeStep={currentPage}
              activeColor="black"
              defaultBarColor="rgba(0, 0, 0, 0.1)"
              completeBarColor="black"
            />
          </div>

          {sections.map(
            (itemMain, indexMain) =>
              currentPage == indexMain && (
                <Tabs
                  id={`add-weeklyMenu-${itemMain}-${currentPage}`}
                  className="mt-3 mt-ng"
                  key={indexMain}
                  transition={false}
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                    getRecipeListID(k);
                    setRecipeListDietType("");
                  }}
                >
                  {itemMain.stateVal &&
                    tabList.map((item, index) => (
                      <Tab eventKey={item.id} key={index} title={item.name}>
                        <>
                          {item.name == "Overview" ? null : (
                            <TableSelect
                              data={tableListRecipe}
                              columns={recipeColumns}
                              filters={tableFilters}
                              // selectedRow={[]}
                              // selectedRow={getInputRow(itemMain.stateVal, item.name)}
                              selectedRow={
                                itemMain.stateVal.recipe_categories &&
                                itemMain.stateVal.recipe_categories[item.name]
                                  ? itemMain.stateVal.recipe_categories[
                                      item.name
                                    ].recipe_ids
                                  : []
                              }
                              header="Action"
                              index={item.name}
                              catId={item.id}
                              getSelectedRow={getSelectedRow}
                            />
                          )}

                          {item.name == "Overview" ? (
                            <>
                              {/* <div className="row">
                            <div className="col-4">
                          <h3 className="mt-5 mb-2">Pre Breakfast</h3>
                          {overview.Pre_Breakfast?
                          <>
                          {overview&&overview.Pre_Breakfast&&overview.Pre_Breakfast.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </>:<div>NO Data FOUND</div>}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Breakfast</h3>
                          {overview&&overview.Breakfast&&overview.Breakfast.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Morning Snack</h3>
                          {overview&&overview.Morning_Snack&&overview.Morning_Snack.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Lunch</h3>
                          {overview&&overview.Lunch&&overview.Lunch.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Evening_Snack</h3>
                          {overview&&overview.Evening_Snack&&overview.Evening_Snack.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Dinner</h3>
                          {overview.Dinner?
                          <>
                          {overview&&overview.Dinner&&overview.Dinner.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </>:<div>NO Data FOUND</div>}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Post Dinner</h3>
                          {overview&&overview.Post_Dinner&&overview.Post_Dinner.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                          <div className="col-4">
                          <h3 className="mt-5 mb-2">Dessert</h3>
                          {overview&&overview.Dessert&&overview.Dessert.map((item,index)=>(
                            <li className="mt-2">{item.recipes_name}</li>
                          ))}
                          </div>
                   
                          </div> */}
                              <div className="row">
                                {overview &&
                                  overview.map((item, index) => (
                                    <div className="col-4 mt-5">
                                      <h5>{item.recipe_category_name}</h5>
                                      {item.recipes_data.map((a, index) => (
                                        <li>{a.recipes_name}</li>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : null}
                        </>
                      </Tab>
                    ))}
                </Tabs>
              )
          )}

          <div className="mainCtrls d-flex justify-content-end mt-5">
            <div className="ml-auto pt-3">
              <Button
                variant="secondary"
                disabled={currentPage == 0}
                onClick={prevPage}
                className="mr-3"
              >
                Prev Day
              </Button>
              {currentPage == 6 ? (
                <Button
                  variant="primary"
                  type="submit"
                  name="submit"
                  onClick={handleSubmit}
                  className="mr-3"
                >
                  Save Menu
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={nextPage}
                  disabled={currentPage == 6}
                  className="mr-3"
                >
                  Next Day
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
