import React, { useState, useEffect } from "react";
import AdminUsersTabs from "../components/Tabs/adminuserstabs";
import { Toast } from "react-bootstrap";

import { AccessDenied } from "../Library";

export default function AdminUsersPage(props) {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  useEffect(() => {
    props.getMenuList();
  }, []);

  return (
    <section className="rightContent">
      {props.menuList.includes(2) ? (
        <AdminUsersTabs showToastFn={showToastFn} />
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
