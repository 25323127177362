import React, { useState, useEffect } from "react";
import { Form, Toast, Button } from "react-bootstrap";

import Table from "../components/tables/table";
// import TableSelectSingle from "../components/tables/tableselectsingle";

import {
  postMethod,
  getMethod,
  AccessDenied,
  getTableDataGet,
  isNotLoading,
  postTableDataGet,
} from "../Library";

import ModalPop from "../components/modals";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

export default function CuisinesPage(props) {
  const [showToast, setShowToast] = useState(false);
  const [validated, setValidated] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [tableListCuisine, setTableListCuisine] = useState([]);
  const [tableListDiet, setTableListDiet] = useState([]);
  const [tableListCategory, setTableListCategory] = useState([]);
  const [tableMealList, setTableMealList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [formInputs, setFormInputs] = useState();
  const [editId, setEditId] = useState("");
  const [catType, setType] = useState("");
  const [editDataLoaded, setEditDataLoaded] = useState(false);
  console.log(props.menuList.includes(9.3) + "props value");
  console.log(props.menuList.includes(9.11) + "props value");

  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    checkFormErrors();
  };

  const checkFormErrors = () => {
    if (formInputs.name) {
      createCategory(formInputs, catType);
    }
  };

  const createCategory = async (values, type) => {
    let submitInputs = { ...values };
    if (editId) {
      submitInputs = {
        ...values,
        id: editId,
      };
    }
    const data = {
      url:
        type == "Diet Type"
          ? "create_or_update_recipe_diet_type"
          : type == "Category"
            ? "create_or_update_recipe_category"
            : type == "Meal Category" ? "create_or_update_meal_categories" : "create_or_update_recipe_cuisine",
      body: submitInputs,
    };
    // console.log(data);
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      if (type == "Cuisine") {
        getTableListCuisines();
      } else if (type == "Diet Type") {
        getTableListDiet();
      } else if (type == "Meal Category") {
        getTableMealCategory();
      }
      else {
        getTableListCategory();
      }
      closeModal();
    } else {
      isNotLoading();
      window.alert("Oops! Error Occured. Please try again!!");
      closeModal();
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs();
    setEditDataLoaded(false);
    setEditId();
    setValidated(false);
    // setType("");
  };

  const handleProgStatus = async (e) => {
    let stat = e.target.value;
    let id = e.target.getAttribute("dataid");
    let prgName = e.target.getAttribute("dataname");
    const data = {
      url: `update_meal_categories_status/${id}`,

    };
    confirmAlert({
      title: `${stat == "1" ? "Unpublish" : "Publish"
        } ${prgName} Meal Category`,
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await getMethod(data);
            if (newData.status == 1) {
              isNotLoading();
              showToastFn(newData.message);
              getTableMealCategory();
            }
            else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const modalBody = () => {
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="column">
          <div className="col-md-8">
            <Form.Group controlId="name">
              <Form.Label className="required">Name</Form.Label>
              <Form.Control
                name="name"
                type="text"
                required
                value={formInputs && formInputs.name}
                onChange={handleInput}
              />
              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-5">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
              // setModalHead("Add");
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {modalHead == "Add" ? "Add" : "Update"}
          </Button>
        </div>
      </Form>
    );
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setModalHead("Edit");
            setType(row.type);
            editclient(cell, row.type);
            setEditId(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button
          variant="link"
          dataid={row.id}
          type={row.type}
          onClick={trashWorkout}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        {/* |
        <Form.Check
          className="ml-2 d-inline-block"
          type="switch"
          name="is_nutritionist"
          id={`toggle-switch-prog-${row.id}`}
          dataid={row.id}
          dataname={row.name}
          value={row?.is_active}
          checked={row?.is_active == 1 ? true : false}
          // onChange={handleProgStatus}
        /> */}

      </>
    );
  }

  function btnMealFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setModalHead("Edit");
            setType(row.type);
            editclient(cell, row.type);
            setEditId(cell);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button
          variant="link"
          dataid={row.id}
          type={row.type}
          onClick={trashWorkout}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
        |
        <Form.Check
          className="ml-2 d-inline-block"
          type="switch"
          name="is_nutritionist"
          id={`toggle-switch-prog-${row.id}`}
          dataid={row.id}
          dataname={row.name}
          value={row?.is_active}
          checked={row?.is_active == 1 ? true : false}
          onChange={handleProgStatus}
        />

      </>
    );
  }


  function checkBoxFormatter(cell, row) {
    return (
      <>
        <Form.Group controlId="makeTopTrendBox">
          <Form.Check
            type="checkbox"
            label="Checkbox"
            name={row.id}
            catname={row.name}
            trend={cell}
            onChange={makeTopTrendFn}
            checked={cell == 0 ? false : true}
          />
        </Form.Group>
      </>
    );
  }

  const makeTopTrendFn = (e) => {
    let id = e.target.name;
    let name = e.target.getAttribute("catname");
    let trend = e.target.getAttribute("trend");
    const data = {
      url: "create_or_update_recipe_category",
      body: {
        id: id,
        top_trend: trend == 0 ? 1 : 0,
      },
    };

    confirmAlert({
      title: `Confirm ${trend == 0 ? "Update" : "Remove"} as Top Trend ?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);

            showToastFn(newData.message);
            if (newData.status === 1) {
              isNotLoading();
              getTableListCategory();
            }
            else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const tableFilters = () => {
    return <> </>;
  };

  const CuisineColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Cuisine Name" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  const dietColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Diet Type" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];
  const mealCategory = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Category Name" },
    { dataField: "id", text: "Action", formatter: btnMealFormatter },
  ];
  const CategoryColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Category Name" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
    {
      dataField: "top_trend",
      text: "Make it as top trend",
      formatter: checkBoxFormatter,
    },
  ];

  // const getSelectedRow = (data) => {
  //   setSelectedRow(data);
  //   setFormInputs((prev) => ({
  //     ...prev,
  //     user_ids: [...data],
  //   }));
  //   console.log(data);
  // };

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    let type = e.target.getAttribute("type");
    const data = { url: '' }
    if (type == "Diet Type") {
      data.url = `delete_recipe_diet_type/${id}`
    }
    else if (type == "Category") {
      data.url = `delete_recipe_category/${id}`
    }
    else if (type == "Meal Category") {
      data.url = `delete_meal_categories/${id}`
    } else {
      data.url = `delete_recipe_cuisine/${id}`
    }
    // const data = {
    //   url:
    //     type == "Diet Type"
    //       ? `delete_recipe_diet_type/${id}`
    //       : type == "Category"
    //       ? `delete_recipe_category/${id}`
    //       : `delete_recipe_cuisine/${id}`,
    // };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await getMethod(data);
            showToastFn(newData.message);
            if (newData.status === 1) {
              if (type == "Cuisine") {
                getTableListCuisines();
              } else if (type == "Diet Type") {
                getTableListDiet();
              } else if (type == "Meal Category") {
                getTableMealCategory();
              }
              else {
                getTableListCategory();
              }
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  const tempFn = async (id, url, dataType) => {
    const data = {
      url: `${url}/${id}`,
    };
    const newData = await getMethod(data);
    // showToastFn(newData.message);
    if (newData.status == 1) {
      if (dataType == "Meal Category") {
        setFormInputs(newData.data);
        setEditDataLoaded(true);
      } else {
        setFormInputs(newData.data[dataType]);
        setEditDataLoaded(true);
      }
    } else {
      showToastFn(newData.message);
    }
  };

  const editclient = (id, type) => {
    if (type == "Cuisine") {
      tempFn(id, "view_recipe_cuisine", "recipe_cuisines");
    } else if (type == "Diet Type") {
      tempFn(id, "view_recipe_diet_type", "recipe_diet_type");
    } else if (type == "Meal Category") {
      tempFn(id, "edit_meal_categories_by_id", "Meal Category");

    }
    else {
      tempFn(id, "view_recipe_category", "recipe_category");
    }
  };

  const getTableListCuisines = async () => {
    let x = await getTableDataGet("list_recipe_cuisine");
    if (x && x.recipe_cuisines_list) {
      setTableListCuisine(x.recipe_cuisines_list);
    } else {
      setTableListCuisine([]);
    }
  };

  const getTableListDiet = async () => {
    let x = await getTableDataGet("list_recipe_diet_type");
    if (x && x.recipe_diet_type_list) {
      setTableListDiet(x.recipe_diet_type_list);
    } else {
      setTableListDiet([]);
    }
  };

  const getTableListCategory = async () => {
    let x = await getTableDataGet("list_recipe_category");
    if (x && x.recipe_category_list) {
      setTableListCategory(x.recipe_category_list);
    } else {
      setTableListCategory([]);
    }
  };
  const getTableMealCategory = async () => {
    let x = await getTableDataGet("admin_user_diet_category_tabs");
    console.log(x, 'data')
    if (x && x.diet_catergory_tabs) {
      setTableMealList(x.diet_catergory_tabs);
    } else {
      setTableMealList([]);
    }
  };

  useEffect(() => {
    if (pageLoaded) {
      // do something
      getTableListCuisines();
      getTableListDiet();
      getTableListCategory();
      getTableMealCategory();
    }
  }, [pageLoaded, setPageLoaded]);

  useEffect(() => {
    // console.log("form ", formInputs);
  }, [formInputs, setFormInputs]);

  return (
    <section className="rightContent">
      {props.menuList.includes(9.3) ? (
        <>
          <div className="cuisinesTab mb-5">
            <div className="d-flex align-items-center mt-ng justify-content-between mb-3">
              <h3 className="text-capitalize">Manage Cuisines</h3>
              <Button
                className="h-100"
                onClick={() => {
                  setType("Cuisine");
                  setModalShow(true);
                  setModalHead("Add");
                }}
              >
                Add new
              </Button>
            </div>
            <Table
              data={tableListCuisine}
              columns={CuisineColumns}
              filters={tableFilters}
            />
          </div>
          <div className="dietTab mb-5">
            <div className="d-flex align-items-center mt-ng justify-content-between mb-3">
              <h3 className="text-capitalize">Manage Diet Type</h3>
              <Button
                className="h-100"
                onClick={() => {
                  setType("Diet Type");
                  setModalShow(true);
                  setModalHead("Add");
                }}
              >
                Add new
              </Button>
            </div>
            <Table
              data={tableListDiet}
              columns={dietColumns}
              filters={tableFilters}
            />
          </div>
          <div className="categoriesTab mb-0">
            <div className="d-flex align-items-center mt-ng justify-content-between mb-3">
              <h3 className="text-capitalize">Recipe Category</h3>
              <Button
                className="h-100"
                onClick={() => {
                  setType("Category");
                  setModalShow(true);
                  setModalHead("Add");
                }}
              >
                Add new
              </Button>
            </div>
            <Table
              data={tableListCategory}
              columns={CategoryColumns}
              filters={tableFilters}
            />
          </div>
          <div className="categoriesTab mb-0">
            <div className="d-flex align-items-center mt-ng justify-content-between mb-3">
              <h3 className="text-capitalize">Meal Category</h3>
              <Button
                className="h-100"
                onClick={() => {
                  setType("Meal Category");
                  setModalShow(true);
                  setModalHead("Add");
                }}
              >
                Add new
              </Button>
            </div>
            <Table
              data={tableMealList}
              columns={mealCategory}
              filters={tableFilters}
            />
          </div>

          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} ${catType}`}
          />
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
