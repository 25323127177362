import React, { useState, useEffect, useRef ,useImperativeHandle } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { Button } from "react-bootstrap";
import { tableDefault } from "../../Library";

const TableSelect = React.forwardRef((props, ref, offset) => {
  // const TableSelect = (props) => {
  const { SearchBar } = Search;
  const refBtn = useRef(null); 
  const [selectRowId, setSelectRowId] = useState([]);
  const [showButton, setShowButton] = useState(true);
  const [defaultSelect, setDefaultSelect] = useState(false);
    
  const columns = props.columns;
  const filters = props.filters ? props.filters() : null;
  const searchBar = props.searchBar ? props.searchBar : null;
  const clickSelect = props.clickSelect ? false : true;
  const selectVal = Array.from({length: props.total}, (_, i) => i + 1)
  const rmvDuplicate = (arr) => {
    let uniqueArr = arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
    return uniqueArr;
  };


  const selectRows = {
    mode: "checkbox",
    clickToSelect: clickSelect,
        style: { background: "#eee" },
    onSelect: (row, isSelect) => {
      console.log(row,'select')
      if (isSelect) {
        setSelectRowId((prev) => [...prev, row.id]);
        console.log(selectRowId)
      } else {
        var index = selectRowId.indexOf(row.id);
        if (index !== -1) {
          const dup = [...selectRowId];
          dup.splice(index, 1);
          setSelectRowId([...dup]);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      console.log(isSelect,'select')

      if (isSelect) {
        rows.forEach((item) => {
          setSelectRowId((prev) => [...prev, item.id]);
        });
      } else {
        setSelectRowId([]);
      }
    },
  };

  const resetSelectRow = () => {
    // console.log("i ran");
    setSelectRowId([]);
  };

  useImperativeHandle(ref, () => ({
    resetSelectRow: resetSelectRow,
  }));

  useEffect(() => {
   
    if (selectRowId && selectRowId.length > 0) {
      props.getSelectedRow(rmvDuplicate(selectRowId));
      // alert('pass')
    } else {
      props.getSelectedRow([]);
      refBtn.current.selectionContext.selected = [];

    }
    console.log(props,'test')
  }, [selectRowId, setSelectRowId]);

  useEffect(() => {
    setSelectRowId([]);
    setDefaultSelect(props.defaultSelect)
    console.log(props,defaultSelect,'propsNon')
    console.log(props)
  }, [props.defaultSelect]);

  const pagintnoptions = {
    // pageStartIndex: 0,
    sizePerPage: 15,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  const formatDatas = (initArr) => {
    let sn = 0;
    let filteredarray = [];
  
    initArr.forEach((element) => {
      const offVal = ((props.offset-1)*10)+(sn+1);
      const offsetValue = (props.offset ? offVal : sn+1 )
      
      filteredarray.push({
        ...element,
        sno: offsetValue,
        
        // action: element.id,
      });
      sn++;
    });
    return filteredarray;
  };

  const data = formatDatas(props.data);

  const showAllRecords = () => {
    props.showAllRecords();
    setShowButton(false);
  };

  // console.log("searchbar ", typeof props.tableCount);

  return (
    <div className="customTable mt-5">
      <ToolkitProvider keyField="sno" data={data} columns={columns} search>
        {(props) => (
          <div>
            <div className="d-flex align-items-center justify-content-end mb-4">
              <div
                className={`w-100 d-flex align-items-center ${
                  filters ? "justify-content-between" : "justify-content-end"
                }`}
              >
                {searchBar && searchBar == "false" ? (
                  <></>
                ) : (
                  <div className="mr-3">
                    <SearchBar {...props.searchProps} />
                  </div>
                )}

                {filters ? filters : null}
              </div>
            </div>
   
            <BootstrapTable
              // noDataIndication="No Results Found"
              bordered={false}
              ref={refBtn}
              {...props.baseProps}
              selectRow={selectRows}
              bootstrap4={true}
              // pagination={paginationFactory(pagintnoptions)}
              headerWrapperClasses="thead-dark"
              bodyClasses="tableBody"
              wrapperClasses="table-responsive customScroll"
              autoResetSelectedRows="true"
            />
          </div>
        )}
      </ToolkitProvider>
      {/* {props.tableCount && props.tableCount >= tableDefault && showButton ? (
        <div className="text-left mt-4">
          <Button variant="link" onClick={showAllRecords}>
            <u>Show All Records</u>
          </Button>
        </div>
      ) : null} */}
    </div>
  );
  // };
});

export default TableSelect;
