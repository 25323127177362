import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Editor from "../../../components/editor";

import { postMethod, tableDefault, findErrors, isNotLoading, getMethod } from "../../../Library";
import _ from "lodash";

import Dropdown from "../../Dropdown";
// import ImageBoxUpload from "../../ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../../ImageBox/imageboxuploadcrop";

export function ExerciseBody(props) {
  const [validated, setValidated] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [formInputs, setFormInputs] = useState({
    name: "",
    video_url: "",
    vimeo_download_link: "",
    description: "",
    image_url: "",
  });
  const [typedData, setTypedData] = useState("");

  const [equipmentOptions, setEquipmentOptions] = useState();
  const [altExerciseOptions, setAltExerciseOptions] = useState();
  const [equipmentsValue, setEquipmentsValue] = useState();
  const [altExerciseValue, setAltExerciseValue] = useState();

  const editId = props.editId;

  // if (!editId) {
  //   setIsLoading(false);
  // } else {
  //   console.log("loading dd");
  // }

  const gettypedData = (data) => {
    setTypedData(data);
  };

  const getSelections = (data, name) => {
    // console.log("selected", name, data);
    if (name == "equipments") {
      setEquipmentsValue(data);
    }
    if (name == "alternate_exercise") {
      setAltExerciseValue(data);
    }
  };

  // const postMethod;

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      // console.log(1);
    }

    setValidated(true);

    addExercise();
  };

  // function convert(name, data) {
  //   let arr = {};
  //   data &&
  //     data.forEach((item, index) => {
  //       arr[`${name}[${index}]`] = item.id;
  //     });
  //   return arr;
  // }

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        arr.push(item.id);
      });
    return arr;
  }

  const addExercise = async () => {
    let eqparr = convert(equipmentsValue);
    let altarr = convert(altExerciseValue);

    const body = {
      name: formInputs.name,
      video_url: formInputs.video_url,
      vimeo_download_link: formInputs.vimeo_download_link,
      description: formInputs.description,
      image_url: formInputs.image_url,
      id: editId ? editId : "",
      equipment_ids: eqparr,
      alternative_exercise_ids: altarr,
    };

    const data = {
      url: "exercise_create_or_update",
      body: body,
    };

    const reqFields = _.pick(
      body,
      "name",
      "video_url",
      "vimeo_download_link",
      "description"
    );

    const errors = findErrors(reqFields);
    if (!errors) {
      // console.log("hello");
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getLists();
      }
      else{
        isNotLoading();

      }
    }
  };

  const handleInputs = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const chooseImageFn = (url) => {
    let form = { ...formInputs };
    form.image_url = url;
    setFormInputs(form);
  };

  // const dropdownOptions = [
  //   { value: "chocolate", label: "Chocolate", id: "1" },
  //   { value: "strawberry", label: "Strawberry", id: "2" },
  //   { value: "vanilla", label: "Vanilla", id: "3" },
  // ];

  const getEquipmentList = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.status == 1 && newData.data) {
      // isNotLoading();
      setEquipmentOptions(newData.data.equipments);
    }
    // else{
    //   isNotLoading();

    // }
  };

  const getAltExerciseList = async (url) => {
    const data = {
      url: url,
      body: {
        length: tableDefault,
        start: 0,
        draw: tableDefault,
      },
    };
    const newData = await postMethod(data);
    // console.log(newData);
    if (newData.status == 1 && newData.data) {
      // isNotLoading();
      setAltExerciseOptions(newData.data.exercises);
    }
    // else{
    //   isNotLoading();

    // }
  };

  const getExerciseInfo = async (id, url) => {
    const data = {
      url: url,
      body: {
        id: id,
      },
    };
    const newData = await postMethod(data);
    // console.log(newData);
    if (newData.status == 1 && newData.data) {
      isNotLoading();
      let x = newData.data[0];
      let arr = {
        ...newData.data[0],
        video_url: x.video_url.iso_video_url,
      };
      // console.log(arr);
      setFormInputs(arr);
      setEquipmentsValue(x.equipments);
      setAltExerciseValue(x.alternative_exercises);
      setIsLoading(false);
    }
    else{
      isNotLoading();

    }
  };

  useEffect(() => {
    setIsLoading(true)
    getEquipmentList("equipment_list");
    getAltExerciseList("list_exercise");
  }, []);

  useEffect(() => {
    if (
      editId &&
      equipmentOptions &&
      equipmentOptions.length > 0 &&
      altExerciseOptions &&
      altExerciseOptions.length > 0
    ) {
      getExerciseInfo(editId, "fetch_exercise_by_id");
    }
  }, [
    setEquipmentOptions,
    setAltExerciseOptions,
    equipmentOptions,
    altExerciseOptions,
  ]);

  // useEffect(() => {
  //   setIsLoading(false);
  // }, [
  //   setFormInputs,
  //   setEquipmentOptions,
  //   setAltExerciseOptions,
  //   equipmentOptions,
  //   altExerciseOptions,
  // ]);

  // return isloading == false ? (
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col md="7">
            <Form.Group controlId="title">
              <Form.Label className="required">Title</Form.Label>
              <Form.Control
                name="name"
                type="text"
                required
                value={formInputs ? formInputs.name : ""}
                onChange={handleInputs}
              />
              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            {/* <Form.Label></Form.Label> */}
            {/* <ImageBoxUpload
              data={chooseImageFn}
              image_url={formInputs ? formInputs.image_url : ""}
            /> */}
            <ImageBoxUploadCrop
              data={chooseImageFn}
              folder="exercise"
              as_w="4"
              as_h="3"
              dataid=""
              image_url={formInputs ? formInputs.image_url : ""}
            />
            {/* {validated &&
              formInputs &&
              (formInputs.image_url == null || formInputs.image_url == "") && (
                <div className="err-feedback">This field is required</div>
              )} */}
          </Col>
        </Row>
        <Form.Group controlId="equipments">
          <Form.Label>Equipments</Form.Label>
          <Dropdown
            options={equipmentOptions}
            value={equipmentsValue}
            getSelections={getSelections}
            name="equipments"
          />
          {/* {validated && !equipmentsValue && (
            <div className="err-feedback">This Field is required</div>
          )} */}
        </Form.Group>

        <Form.Group controlId="url">
          <Form.Label className="required">Video URL (MP4)</Form.Label>
          <Form.Control
            name="video_url"
            type="text"
            required
            value={formInputs ? formInputs.video_url : ""}
            onChange={handleInputs}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="url">
          <Form.Label className="required">Vimeo Download Link</Form.Label>
          <Form.Control
            name="vimeo_download_link"
            type="text"
            required
            value={formInputs ? formInputs.vimeo_download_link : ""}
            onChange={handleInputs}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="description" className="position-relative">
          <Form.Label className="required">Description</Form.Label>
          {/* <Editor
          data={gettypedData}
          text={formInputs ? formInputs.description : ""}
        /> */}
          <Form.Control
            as="textarea"
            name="description"
            rows={3}
            // maxLength="70"
            required
            value={formInputs ? formInputs.description : ""}
            onChange={handleInputs}
          />
          {/* <small className="char-len">
            {formInputs?.description?.length ? 70 - formInputs.description.length : 70} characters
            left
          </small> */}
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="alternate">
          <Form.Label>Add Alternative Exercises</Form.Label>
          <Dropdown
            options={altExerciseOptions}
            value={altExerciseValue}
            getSelections={getSelections}
            name="alternate_exercise"
          />
        </Form.Group>

        <div className="d-flex justify-content-end mt-5">
          <Button variant="secondary" onClick={props.onHide} className="mr-3">
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {props.modalType == "Add" ? "Add" : "Update"}
          </Button>
        </div>
      </Form>
    </>
  );
}
