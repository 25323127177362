import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faSearchPlus,
  faSearchMinus,
  faSyncAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import ModalPop from "../modals";

import { isNotLoading, postMethodFormData } from "../../Library";
import ToolTipBox from "../ToolTipBox";

export default function ImageBoxUploadCrop(props) {
  const [imageUrl, setImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [err, setErr] = useState();
  const [preShow, setPreShow] = useState(false);

  const [image, setImage] = useState("");
  const [cropper, setCropper] = useState();
  const [cropDetail, setCropDetail] = useState();
  const [aspectRatio, setAspectRatio] = useState();

  const cropperRef = useRef(null);
  const inputFileBtn = useRef(null);

  const position = props?.position ? props.position : null;

  const handelPre = () => {
    setPreShow(!preShow);
  };

  const handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    openModal();
  };

  const uploadImage = async (formDataBody) => {
    // url: "nws_image_upload",
    const data = {
      url: "upload_jpg_image",
      body: formDataBody,
    };
    closeModal();

    const newData = await postMethodFormData(data);
    setUploading(false);
    if (newData.status == 1) {
    isNotLoading();

      let url = newData.data.image_url;
      if (url) {
        setImageUrl(url);
        props.data(url, props);
      }
      setErr();
    } else {
      setErr(JSON.stringify(newData.message));
    isNotLoading();

    }
  };
 
  const previewModal = ()=>{

    return(
      <>
      <div className="text-center popupImage">
      {imageUrl && <img src={imageUrl} alt="image" />}
      </div>
      </>
    )
  }

  const handleAspectRatio = (e) => {
    let width = aspectRatio?.width;
    let height = aspectRatio?.height;
    if (e.target.name == "fixed") {
      width = e.target.getAttribute("width");
      height = e.target.getAttribute("height");
    } else if (e.target.name == "width") {
      width = e.target.value;
    } else if (e.target.name == "height") {
      height = e.target.value;
    }
    // console.log(width, height);
    setAspectRatio({
      width: parseInt(width),
      height: parseInt(height),
    });
  };

  const getCropData = async () => {
    setUploading(true);
    if (typeof cropper !== "undefined") {
      setErr();
      cropper
        .getCroppedCanvas({
          // width: parseInt(cropperRef.current.cropper.cropBoxData.width),
          // height: parseInt(cropperRef.current.cropper.cropBoxData.height),
          width:props?.as_w?props.as_w:parseInt(cropperRef.current.cropper.cropBoxData.width),
          height:props?.as_h?props?.as_h:parseInt(cropperRef.current.cropper.cropBoxData.height),
          maxWidth: 1600,
          maxHeight: 1600,
          minWidth: 300,
          minHeight: 180,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toBlob(
          function (blob) {
            // console.log("blog", blob);
            let formDataBody = new FormData();
            formDataBody.append("image_url", blob);
            // formDataBody.append("folder_name", props.folder);
            // formDataBody.append("img_w", props?.rc_w);
            // formDataBody.append("img_h", props?.rc_h);
            uploadImage(formDataBody);
          },
          "image/jpeg",
          1
        );

      //
    } else {
      setUploading(false);
      window.alert(
        "Image crop app error. Please refresh the page and try again."
      );
    }
  };

  const onCrop = (e) => {
    setCropDetail({
      ...cropperRef.current.cropper.cropBoxData,
    });
  };

  const modalBody = () => {
    return (
      <>
        <div className="position-relative">
          <Cropper
            style={{ height: 500, width: "100%" }}
            // zoomTo={1}
            initialAspectRatio={1}
            preview=".crop-img-preview"
            src={image}
            viewMode={1}
            guides={true}
            minCropBoxHeight={20}
            minCropBoxWidth={20}
            background={true}
            responsive={true}
            cropBoxResizable={false}
            autoCropArea={1}
            crop={onCrop}
            ref={cropperRef}
            aspectRatio={
              props?.as_w && props?.as_h
                ? parseInt(props.as_w) / parseInt(props.as_h)
                : null
            }
            checkOrientation={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <div className="crop-img-preview" />
          {cropDetail && (
            <>
              {/* <div className="crop-data">
                {`W:${parseInt(cropDetail?.width)} H:${parseInt(
                  cropDetail?.height
                )}`}
              </div> */}
              <div className="cropZoomBtn">
                <ToolTipBox text="Zoom In" placement="right">
                  <Button
                    variant="link"
                    onClick={() => {
                      cropper.zoom(0.25);
                    }}
                  >
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </Button>
                </ToolTipBox>

                <ToolTipBox text="Zoom Out" placement="right">
                  <Button
                    variant="link"
                    onClick={() => {
                      cropper.zoom(-0.25);
                    }}
                  >
                    <FontAwesomeIcon icon={faSearchMinus} />
                  </Button>
                </ToolTipBox>

                <ToolTipBox text="Reset" placement="right">
                  <Button
                    variant="link"
                    onClick={() => {
                      cropper.reset();
                    }}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} />
                  </Button>
                </ToolTipBox>
              </div>
            </>
          )}
        </div>
        {/* <div className="mt-3">
          Select Image aspect ratio:{" "}
          <small>
            (Note: This will convert the resolution of the above selected
            portion of the image)
          </small>
          <div className="d-flex my-2 cstimg align-items-center">
            <div className="col-2">
              <Button
                variant="link"
                className="mt-3 p-0"
                width="1080"
                height="1620"
                name="fixed"
                active={
                  aspectRatio?.width == 1080 && aspectRatio?.height == 1620
                }
                onClick={handleAspectRatio}
              >
                1080 x 1620
              </Button>
            </div>
            <div className="col-2">
              <Button
                className="mt-3 p-0"
                variant="link"
                width="1080"
                height="1080"
                active={
                  aspectRatio?.width == 1080 && aspectRatio?.height == 1080
                }
                name="fixed"
                onClick={handleAspectRatio}
              >
                1080 x 1080
              </Button>
            </div>
            <div className="col-2">
              <Button
                className="mt-3 p-0"
                variant="link"
                width="1280"
                height="720"
                active={
                  aspectRatio?.width == 1280 && aspectRatio?.height == 720
                }
                name="fixed"
                onClick={handleAspectRatio}
              >
                1280 x 720
              </Button>
            </div>
            <div className="col-6">
              <small className="mb-2">Custom Image Size:</small>
              <Row>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    name="width"
                    placeholder="Width"
                    onChange={handleAspectRatio}
                  />
                </Col>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    name="height"
                    placeholder="Height"
                    onChange={handleAspectRatio}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div> */}

        {/* {props?.rc_w && props?.rc_h && (
          <small>
            Note: Recommended size (WxH) is {props?.rc_w} x {props?.rc_h}
          </small>
        )} */}
        <small>
          Note: Please make sure that the main content is at the center with
          enough head and leg room.
        </small>

        <div className="d-flex mt-4">
          <Button
            variant="primary"
            className="mr-3"
            disabled={uploading}
            onClick={getCropData}
          >
            Upload
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Discard
          </Button>
        </div>
      </>
    );
  };

  const closeModal = () => {
    setModalShow(false);
    setImage();
    setCropper();
    setAspectRatio();
    setCropDetail();
    inputFileBtn.current.value = "";
  };

  const openModal = () => {
    setModalShow(true);
  };

  useEffect(() => {
    if (props?.image_url) {
      // console.log("image url", props.image_url, imageUrl);
      setImageUrl(props.image_url);
    } else {
      setImageUrl("");
    }
  }, [props.image_url]);

  return (
    <>
      <div
        className={
          position == "left"
            ? "imgUploadBox d-flex align-items-center justify-content-center mwx-400"
            : "imgUploadBox d-flex align-items-center justify-content-center mwx-400 ml-auto mr-auto"
        }
      >{props.showImage&&props.showImage=="false"?null:<>
        {imageUrl && <img src={imageUrl} alt="image" />}</>}
        <input
          type="file"
          className="w-100"
          name="avatar"
          accept="image/*"
          ref={inputFileBtn}
          onChange={handleChange}
        />
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        {/* {err && !uploading && (
            <small className="err-feedback mt-4">This Field is required</small>
          )} */}
        {uploading && <small>Uploading Image...</small>}
      </div>
      {props.preview&&props.preview==="false"?null:
      <div className="w-100">
      {imageUrl && <div className="text-center pt-2 cursor-pointer" onClick={handelPre}>
        Preview <FontAwesomeIcon icon={faEye} />
      </div>}</div>}
      {err && !uploading && (
        <small className="err-feedback text-center w-100 position-relative">
          Upload Error: {err}
        </small>
      )}

      <ModalPop
        show={modalShow}
        onHide={closeModal}
        modalcontent={modalBody()}
        modalhead="Preview Image"
      />
      <ModalPop
        size="md"
        class={"modal-padding"}
        show={preShow}
        onHide={handelPre}
        modalcontent={previewModal()}
        modalhead="Preview Image"
      />
    </>
  );
}
