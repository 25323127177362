import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import Dashboard from "../assets/images/dashboard.svg";
import Admin from "../assets/images/admin.svg";
import Registered from "../assets/images/users.svg";
import Nutritionist from "../assets/images/nutritionist.svg";
import NutritionMeal from "../assets/images/nutritionfood.svg";
import Programs from "../assets/images/programs.svg";
import Exercise from "../assets/images/exercise.svg";
import Workout from "../assets/images/workout.svg";
import Content from "../assets/images/content.svg";
import { Button, Accordion } from "react-bootstrap";

export default function SideBar(props) {

  const manageNutritionMeal = [8.1, 8.2, 8.3, 8.4]
  const manageContent = [9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 9.12]

  useEffect(() => {
    props.getMenuList();
  }, []);

  return (
    <>
      <aside>
        <div className="sidebar nav-collapse ">
          {props.menuList ? (
            <ul>
              {props.menuList.includes(1) ? (
                <li>
                  <NavLink to="/dashboard" activeClassName="active">
                    <img src={Dashboard} alt="Dashboard" className="mr-3" />
                    <span>Dashboard</span>
                  </NavLink>
                </li>
              ) : null}
              {props.menuList.includes(2) ? (
                <li>
                  <NavLink to="/manage-admin-users" activeClassName="active">
                    <img
                      src={Admin}
                      alt="Manage Admin Users"
                      className="mr-3"
                    />
                    <span>Manage Admin Users</span>
                  </NavLink>
                </li>
              ) : null}
              {props.menuList.includes(3) ? (
                <li>
                  <NavLink
                    to="/manage-registered-users"
                    activeClassName="active"
                  >
                    <img
                      src={Registered}
                      alt="Manage Registered Users"
                      className="mr-3"
                    />
                    <span>Manage Registered Users</span>
                  </NavLink>
                </li>
              ) : null}

              {props.menuList.includes(4) ? (
                <li>
                  <NavLink to="/manage-nutritionist" activeClassName="active">
                    <img
                      src={Nutritionist}
                      alt="Manage Nutritionist"
                      className="mr-3"
                    />
                    <span>Manage Nutritionist</span>
                  </NavLink>
                </li>
              ) : null}
              {props.menuList.includes(5) ? (
                <li>
                  <NavLink to="/manage-programs" activeClassName="active">
                    <img
                      src={Programs}
                      alt="Manage Programs"
                      className="mr-3"
                    />
                    <span>Manage Programs</span>
                  </NavLink>
                </li>
              ) : null}
              {props.menuList.includes(6) ? (
                <li>
                  <NavLink to="/manage-exercise" activeClassName="active">
                    <img
                      src={Exercise}
                      alt="Manage Exercise"
                      className="mr-3"
                    />
                    <span>Manage Exercise</span>
                  </NavLink>
                </li>
              ) : null}
              {props.menuList.includes(7) ? (
                <li>
                  <NavLink to="/manage-workout" activeClassName="active">
                    <img src={Workout} alt="Manage Workout" className="mr-3" />
                    <span>Manage Workout</span>
                  </NavLink>
                </li>
              ) : null}

              {
                props.menuList.some(item => manageNutritionMeal.includes(item))
                  ? (
                    <li>
                      <Accordion className="active">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <img
                            src={NutritionMeal}
                            alt="Manage Nutrition and Meal"
                            className="mr-3"
                          />
                          Manage Nutrition and Meal
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <ul className="sidebarSubMenu">
                            {props.menuList.includes(8.1) ? (
                              <li>
                                <NavLink
                                  to="/manage-recipes"
                                  activeClassName="active"
                                >
                                  Manage Recipes
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(8.2) ? (
                              <li>
                                <NavLink
                                  to="/manage-weekly-menu"
                                  activeClassName="active"
                                >
                                  Manage Menu Plan
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(8.3) ? (
                              <li>
                                <NavLink
                                  to="/manage-diet-library"
                                  activeClassName="active"
                                >
                                  Manage Diet Library
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(8.4) ? (
                              <li>
                                <NavLink
                                  to="/manage-diet-plan"
                                  activeClassName="active"
                                >
                                  Assign Diet Plan
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(8.4) ? (
                              <li>
                                <NavLink
                                  to="/manage-guidelines"
                                  activeClassName="active"
                                >
                                  Manage Guidelines
                                </NavLink>
                              </li>
                            ) : null}
                          </ul>
                        </Accordion.Collapse>
                      </Accordion>
                    </li>
                  ) : null}
              {
                props.menuList.some(item => manageContent.includes(item))
                  ? (
                    <li>
                      <Accordion className="active">
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                          <img
                            src={Content}
                            alt="Manage Content"
                            className="mr-3"
                          />
                          <span>Manage Content</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <ul className="sidebarSubMenu">
                            {props.menuList.includes(9.2) ? (
                              <li>
                                <NavLink to="/equipments" activeClassName="active">
                                  Equipments
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.3) ? (
                              <li>
                                <NavLink
                                  to="/cuisines-diet-type"
                                  activeClassName="active"
                                >
                                  Cuisines & Diet Type
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.11) ? (
                              <li>
                                <NavLink
                                  to="/workout-category"
                                  activeClassName="active"
                                >
                                  Workout Category
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.4) ? (
                              <li>
                                <NavLink
                                  to="/faq-blog-category"
                                  activeClassName="active"
                                >
                                  FAQ & Blog Category
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.5) ? (
                              <li>
                                <NavLink
                                  to="/onboarding-content"
                                  activeClassName="active"
                                >
                                  Onboarding Content
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.12) ? (
                              <li>
                                <NavLink to="/promotional-content" activeClassName="active">
                                  Promotional Content
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.9) ? (
                              <li>
                                <NavLink
                                  to="/workout-onboarding"
                                  activeClassName="active"
                                >
                                  Water & Steps Count
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.6) ? (
                              <li>
                                <NavLink
                                  to="/terms-conditions"
                                  activeClassName="active"
                                >
                                  Terms & Condition
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.7) ? (
                              <li>
                                <NavLink
                                  to="/privacy-policy"
                                  activeClassName="active"
                                >
                                  Privacy Policy
                                </NavLink>
                              </li>
                            ) : null}
                            {props.menuList.includes(9.8) ? (
                              <li>
                                <NavLink
                                  to="/notifications"
                                  activeClassName="active"
                                >
                                  Notifications
                                </NavLink>
                              </li>
                            ) : null}
                             {props.menuList.includes(9.13) ? (
                              <li>
                                <NavLink
                                  to="/mobile-app-history"
                                  activeClassName="active"
                                >
                                  App Update History
                                </NavLink>
                              </li>
                            ) : null}
                          </ul>
                        </Accordion.Collapse>
                      </Accordion>
                    </li>
                  ) : null}
            </ul>
          ) : (
            <p className="text-center">
              You don't have privilege. Please Contact your admin
            </p>
          )}
        </div>
      </aside>
    </>
  );
}
