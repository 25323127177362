import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Accordion } from "react-bootstrap";

import { postMethod, findErrors, isNotLoading } from "../../../Library";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export function RoleBody(props) {
  const [formInputs, setFormInputs] = useState({
    name: "",
    access_privilege: [],
    is_nutritionist: 0,
    reporting_to: "",
  });

  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    if (
      formInputs.name &&
      formInputs.access_privilege.length > 0 &&
      formInputs.reporting_to
    ) {
      const data = {
        url: props.editId ? "update_role" : "add_role",
        body: formInputs,
      };
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
    isNotLoading();
        props.onHide();
        props.getLists();
      }
      else{
        isNotLoading();

      }
    }
  };

  const handleInput = (e) => {
    // console.log(e.target.name, e.target.value);
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAccessPrev = (e) => {
    let val = parseFloat(e.target.value);
    if (e.target.checked == true) {
      setFormInputs((prev) => ({
        ...prev,
        access_privilege: [...prev.access_privilege, val],
      }));
    } else {
      const dupArr = [...formInputs.access_privilege];
      let index = dupArr.indexOf(val);
      if (index !== -1) {
        dupArr.splice(index, 1);
        setFormInputs((prev) => ({
          ...prev,
          access_privilege: dupArr,
        }));
      }
    }
  };

  async function checkIfEdit() {
    if (props.editId) {
      const data = {
        url: "edit_role",
        body: {
          role_id: props.editId,
        },
      };
      const newData = await postMethod(data);
      // console.log(newData.data);
      if (newData.data && newData.data.role) {
        isNotLoading();


        setFormInputs(() => ({
          ...newData.data.role,
          role_id: props.editId,
        }));
      }
      else{
        isNotLoading();

      }
    }
  }

  useEffect(() => {
    checkIfEdit();
  }, []);

  return (
    <Form
      noValidate
      validated={validated}
      className="userNoSelect"
      onSubmit={handleSubmit}
    >
      <Form.Group as={Row} controlId="role_name">
        <Form.Label column sm="3" className="required">
          Role Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="name"
            type="text"
            required
            placeholder="Role Name"
            value={formInputs.name}
            onChange={handleInput}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="access_prevelages">
        <Form.Label column sm="3" className="required">
          Access Privilege
        </Form.Label>
        <Col sm="9">
          <Form.Group controlId="01" className="mb-0">
            <Form.Check
              name="dashboard"
              checked={formInputs.access_privilege.includes(1) ? true : false}
              value="1"
              label="Dashboard"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="02" className="mb-0">
            <Form.Check
              name="admin_users"
              checked={formInputs.access_privilege.includes(2) ? true : false}
              value="2"
              label="Manage Admin Users"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="03" className="mb-0">
            <Form.Check
              value="3"
              checked={formInputs.access_privilege.includes(3) ? true : false}
              label="Manage Registered Users"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="04" className="mb-0">
            <Form.Check
              value="4"
              checked={formInputs.access_privilege.includes(4) ? true : false}
              label="Manage Nutritionist"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="05" className="mb-0">
            <Form.Check
              value="5"
              checked={formInputs.access_privilege.includes(5) ? true : false}
              label="Manage Programs"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="06" className="mb-0">
            <Form.Check
              value="6"
              checked={formInputs.access_privilege.includes(6) ? true : false}
              label="Manage Exercises"
              onChange={handleAccessPrev}
            />
          </Form.Group>
          <Form.Group controlId="07" className="mb-0">
            <Form.Check
              value="7"
              checked={formInputs.access_privilege.includes(7) ? true : false}
              label="Manage Workout"
              onClick={handleAccessPrev}
            />
          </Form.Group>

          <Accordion>
            <Accordion.Toggle
              as={Button}
              variant="link"
              className="d-block p-0"
              eventKey="0"
            >
              <Form.Group controlId="08" className="mb-0">
                <Form.Label className="mb-0 mt-2">
                  <span className="pr-2">Manage Nutrition and Meal</span>
                  <FontAwesomeIcon className="font-14" icon={faChevronDown} />
                </Form.Label>
              </Form.Group>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="ml-4">
                <Form.Group controlId="09" className="mb-0">
                  <Form.Check
                    value="8.1"
                    checked={
                      formInputs.access_privilege.includes(8.1) ? true : false
                    }
                    label="Manage Receipes"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="10" className="mb-0">
                  <Form.Check
                    value="8.2"
                    checked={
                      formInputs.access_privilege.includes(8.2) ? true : false
                    }
                    label="Manage Menu Plan"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="11" className="mb-0">
                  <Form.Check
                    value="8.3"
                    checked={
                      formInputs.access_privilege.includes(8.3) ? true : false
                    }
                    label="Manage Diet Library"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="12" className="mb-0">
                  <Form.Check
                    value="8.4"
                    label="Assign Diet Plan"
                    checked={
                      formInputs.access_privilege.includes(8.4) ? true : false
                    }
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="131" className="mb-0">
                  <Form.Check
                    value="8.5"
                    label="Manage Guidelines"
                    checked={
                      formInputs.access_privilege.includes(8.5) ? true : false
                    }
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              variant="link"
              className="d-block p-0"
              eventKey="1"
            >
              <Form.Group controlId="13" className="mb-0 mt-2">
                <Form.Label className="mb-0">
                  <span className="pr-2">Manage Content</span>
                  <FontAwesomeIcon className="font-14" icon={faChevronDown} />
                </Form.Label>
              </Form.Group>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div className="ml-4">
                {/* <Form.Group controlId="14" className="mb-0">
                  <Form.Check
                    value="9.1"
                    checked={
                      formInputs.access_privilege.includes(9.1) ? true : false
                    }
                    label="Program & Category"
                    onChange={handleAccessPrev}
                  />
                </Form.Group> */}
                <Form.Group controlId="15" className="mb-0">
                  <Form.Check
                    value="9.2"
                    checked={
                      formInputs.access_privilege.includes(9.2) ? true : false
                    }
                    label="Equipments"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="16" className="mb-0">
                  <Form.Check
                    value="9.3"
                    checked={
                      formInputs.access_privilege.includes(9.3) ? true : false
                    }
                    label="Cuisines & Diet Plan"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="17" className="mb-0">
                  <Form.Check
                    value="9.4"
                    checked={
                      formInputs.access_privilege.includes(9.4) ? true : false
                    }
                    label="FAQ & Blog Category"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="18" className="mb-0">
                  <Form.Check
                    value="9.5"
                    checked={
                      formInputs.access_privilege.includes(9.5) ? true : false
                    }
                    label="Onboarding Content"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="22" className="mb-0">
                  <Form.Check
                    value="9.9"
                    checked={
                      formInputs.access_privilege.includes(9.9) ? true : false
                    }
                    label="Water & Steps Count"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="19" className="mb-0">
                  <Form.Check
                    value="9.6"
                    checked={
                      formInputs.access_privilege.includes(9.6) ? true : false
                    }
                    label="Terms & Condition"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="20" className="mb-0">
                  <Form.Check
                    value="9.7"
                    checked={
                      formInputs.access_privilege.includes(9.7) ? true : false
                    }
                    label="Privacy Policy"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
                <Form.Group controlId="21" className="mb-0">
                  <Form.Check
                    value="9.8"
                    checked={
                      formInputs.access_privilege.includes(9.8) ? true : false
                    }
                    label="Notifications"
                    onChange={handleAccessPrev}
                  />
                </Form.Group>
              </div>
            </Accordion.Collapse>
          </Accordion>

          {validated == true && formInputs.access_privilege.length == 0 && (
            <div className="err-feedback mt-2">
              Please select atleast one role
            </div>
          )}
        </Col>
      </Form.Group>

      <Form.Group
        as={Row}
        className="align-items-center"
        controlId="nutritionist_acc"
      >
        <Form.Label column sm={3} className="required1">
          Nutritional Panel
        </Form.Label>
        {/* <pre>{JSON.stringify(formInputs, null, 2)}</pre> */}
        <Col sm="9">
          <Form.Check
            className="d-inline-block"
            type="switch"
            name="is_nutritionist"
            id="toggle-switch-nut"
            value={formInputs?.is_nutritionist == "0" ? 1 : 0}
            checked={formInputs?.is_nutritionist == "0" ? false : true}
            onChange={handleInput}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="reporting_to">
        <Form.Label column sm={3} className="required">
          Reporting To
        </Form.Label>
        <Col sm="9">
          {/* {JSON.stringify(formInputs.reporting_to, null, 2)}
          <br />
          {JSON.stringify(props.reportingList, null, 2)} */}
          <Form.Control
            as="select"
            name="reporting_to"
            required
            value={formInputs.reporting_to ? formInputs.reporting_to : ""}
            onChange={handleInput}
          >
            <option value="">Select a User</option>
            {props.reportingList &&
              props.reportingList.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <div className="d-flex justify-content-end mt-5">
        <Button variant="secondary" onClick={props.onHide} className="mr-3">
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          {props.editId ? "Update" : "Save"} Role
        </Button>
      </div>
    </Form>
  );
}

export function UserBody(props) {
  const [validated, setValidated] = useState(false);

  const [emailOld, setEmailOld] = useState();
  const [emailEdited, setEmailEdited] = useState(false);
  const [formError, setFormError] = useState(true);

  const [formInputs, setFormInputs] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    password: "",
    role_id: "",
  });

  function generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity()) {
      editupdateUser();
    }
  };

  const editupdateUser = async () => {
    const data = {
      url: props.editId ? "update_user" : "add_admin_user",
      body: formInputs,
    };

    let errors = true;

    if (props.editId) {
      let reqFields = _.pick(
        formInputs,
        "first_name",
        "mobile",
        "email",
        "role_id"
      );
      errors = findErrors(reqFields);
    } else {
      let reqFields = _.pick(
        formInputs,
        "first_name",
        "mobile",
        "email",
        "password",
        "role_id"
      );
      errors = findErrors(reqFields);
    }
    setFormError(errors);

    if (
      errors == false &&
      JSON.stringify(formInputs.mobile).replace(/"/g, "").length == 10
    ) {
      const newData = await postMethod(data);
      props.showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        props.onHide();
        props.getLists();
        if (emailEdited) {
          localStorage.clear();
          window.location.href = "/";
        }
      }
      else{
        isNotLoading();

      }
    }
  };

  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    let currEmail = JSON.parse(localStorage.getItem("user"));
    if (formInputs && formInputs.email && currEmail) {
      if (formInputs.email != emailOld && currEmail.email == emailOld) {
        setEmailEdited(true);
      }
    }
    console.log("frmip ", formInputs);
  }, [setFormInputs, formInputs]);

  const getEditData = async () => {
    if (props.editId) {
      const data = {
        url: "edit_user",
        body: {
          user_id: props.editId,
        },
      };
      const newData = await postMethod(data);
      if (newData.data && newData.data.user) {
        isNotLoading();
        setFormInputs(() => ({
          ...newData.data.user,
          user_id: props.editId,
          // password: generatePassword(),
        }));
        setEmailOld(newData.data.user.email);
      }
      else{
        isNotLoading();

      }
    }
  };

  useEffect(() => {
    if (!props.editId) {
      setFormInputs((prev) => ({
        ...prev,
        password: generatePassword(),
      }));
    }
    getEditData();
  }, []);

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {props.editId != 1 && (
        <Form.Group as={Row} controlId="role">
          <Form.Label column sm={3} className="required">
            Role
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="select"
              name="role_id"
              required
              value={formInputs.role_id ? formInputs.role_id : ""}
              onChange={handleInput}
            >
              <option value="">Select a Role</option>
              {props.roleList &&
                props.roleList.map(
                  (item, index) =>
                    item.status == 1 && (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    )
                )}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      )}
      <Form.Group as={Row} controlId="first_name">
        <Form.Label column sm="3" className="required">
          Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="first_name"
            type="text"
            required
            placeholder="User Name"
            value={formInputs.first_name ? formInputs.first_name : ""}
            onChange={handleInput}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      {/* <Form.Group as={Row} controlId="last_name">
        <Form.Label column sm="3" className="required">
          Last Name
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="last_name"
            type="text"
            // value={values.assign_user}
            // onChange={handleInput}
          />
        </Col>
      </Form.Group> */}

      <Form.Group as={Row} controlId="email">
        <Form.Label column sm="3" className="required">
          Email ID
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="email"
            type="email"
            placeholder="example@somemail.com"
            pattern="[a-zA-Z0-9!#$%^&*_+-.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            required
            value={formInputs.email ? formInputs.email : ""}
            onChange={handleInput}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} controlId="phone_no">
        <Form.Label column sm="3" className="required">
          Phone Number
        </Form.Label>
        <Col sm="9">
          <Form.Control
            name="mobile"
            type="tel"
            pattern="[0-9]{10}"
            placeholder="9876543210"
            required
            value={formInputs.mobile ? formInputs.mobile : ""}
            onChange={handleInput}
          />
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      {!props.editId && (
        <Form.Group as={Row} controlId="password">
          <Form.Label column sm="3" className="required">
            Password
          </Form.Label>
          <Col sm="9">
            <Form.Control
              name="password"
              type="text"
              autoComplete="off"
              required
              value={formInputs.password ? formInputs.password : ""}
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      )}

      <div className="d-flex justify-content-end mt-5">
        <Button variant="secondary" onClick={props.onHide} className="mr-3">
          Cancel
        </Button>
        <Button variant="primary" type="submit">
          {props.editId ? "Update" : "Save"} User
        </Button>
      </div>
    </Form>
  );
}
