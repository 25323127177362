import React from "react";
import { Modal } from "react-bootstrap";

export default function ModalPop(props) {
  const enteredModal = () => {
    // console.log("modal open");
  };
  // console.log(props.modalSize);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEntered={props.onEnter ? props.onEnter : enteredModal}
      size={props.size? props.size :"lg"}
      backdrop="static"
      keyboard={false}
      enforceFocus={props?.disableEnforceFocus ? false : true}
      dialogClassName={props.modalSize ? props.modalSize : "auto"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-capitalize">{props.modalhead}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={props.class?props.class:""}>{props.modalcontent}</Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export function ModalPopMd(props) {
  const enteredModal = () => {
    // console.log("modal open");
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEntered={props.onEnter ? props.onEnter : enteredModal}
      size="md"
      backdrop="static"
      keyboard={false}
      dialogClassName={props.modalSize ? props.modalSize : "auto"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="text-capitalize">{props.modalhead}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={props.class?props.class:""}>{props.modalcontent}</Modal.Body>
    </Modal>
  );
}
