import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
const Buttons = ({ id,children, loading,onKeyPress, disabled, className, type, onClick }) => {
  return (
    <button
      onKeyPress={onKeyPress}
      id={id}
      type={type}
      className={`btn f-14 fw-700 ${className}`}
      disabled={loading || disabled}
      onClick={onClick}
     
    >
      {!loading && <span>{children}</span>}
      {loading &&<FontAwesomeIcon className="f-30 spinner" icon={faSpinner} /> }
    </button>
  );
};
export default Buttons;