import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Select as AntSelect } from "antd";
import "antd/dist/antd.css";
import Select from "react-select";
import ImageBoxUploadCrop from "../ImageBox/imageboxuploadcrop";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

// import autosize from "autosize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DropdownSingle from "../Dropdown/singledropdown";
import Editor from "../../components/editor";

import {
  postMethod,
  getMethod,
  copyObj,
  handleFalseStatus,
  isNotLoading,
} from "../../Library";

import ToolTipBox from "../ToolTipBox";
import Buttons from "../Button/button";

export default function ChallengeWorkoutForm(props) {
  const { Option } = AntSelect;

  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [masterID, setMasterId] = useState();
  const [workoutMasters, setWorkoutMasters] = useState({
    workout_category_name: "Challenge Workout",
    workout_type: "challenge",
    program_ids: [],
    program_week: "", // empty for challenge based
    habit_week: "", // empty for challenge based
    video_url: "", // empty for challenge based
    image_url: "", // empty for challenge based
    small_image_url: "",
    workout_name: "", // doubt - guess for quick workout
    choose_program_type: 0, // if all program chosen then value is 1
  });

  const [totalDays, setTotalDays] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [countDays, setCountDays] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [displayDays, setdisplayDays] = useState([]);

  const [workoutInputs, setWorkoutInputs] = useState({
    workouts: {
      workout_master_id: "",
      workout_day: 1,
      challenge_name: "",
      challenge_start_date: "",
      challenge_end_date: "",
      description: "",
      guidelines: "",
      image_url: "",
      small_image_url: "",
      workout_type: "challenge",
    },
    workout_wks: [
      {
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      },
    ],
  });

  const [dateInputs, setDateInputs] = useState({
    challenge_start_date: null,
    challenge_end_date: null,
  });
  const [programOptions, setProgramOptions] = useState([]);
  const [programValues, setProgramValues] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState();
  const [cC, setCC] = useState(false);

  const [editDataUpdated, setEditDataUpdated] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [exerciseWorkout, setExerciseWorkout] = useState([]);
  const [exerciseStrech, setExerciseStrech] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [diffDays, setDiffDays] = useState(0);
  const [Week, setWeek] = useState(1);

  const history = useHistory();

  useEffect(() => {
    splitDay();
  }, [totalDays, currentWeek]);

  function rmvIds(day) {
    if (day) {
      delete day.workouts.id;
      let warmup = day.workout_warmups;
      if (warmup.length > 0) {
        delete warmup[0].id;
        if (warmup[0].workout_warmup_exercises.length > 0) {
          warmup[0].workout_warmup_exercises.map((item) => {
            delete item.id;
            return item;
          });
        }
      }
      let workout_ct = day.workout_wks;
      if (workout_ct.length > 0) {
        workout_ct.map((obj) => {
          delete obj.id;
          obj.workout_wk_exercises.map((item) => {
            delete item.id;
            return item;
          });
          return obj;
        });
      }

      let workout_stretchs = day.workout_stretchs;
      if (workout_stretchs.length > 0) {
        delete workout_stretchs[0].id;
        if (workout_stretchs[0].workout_stretch_exercises.length > 0) {
          workout_stretchs[0].workout_stretch_exercises.map((item) => {
            delete item.id;
            return item;
          });
        }
      }
    }
  }

  const checkFormatDays = (day) => {
    let data = Object.assign({}, day);

    if (data.workout_wks.length == 0) {
      data.workout_wks.push({
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
    }
    return data;
  };

  useEffect(() => {
    calculateDate();
  }, [dateInputs]);
  useEffect(() => {
    setMaster();
  }, [masterID]);

  const checkData = async () => {
    if (props.editData || masterID) {
      const data = {
        url: "workout_by_id",
        body: {
          workout_master_id: masterID
            ? masterID
            : props.editData
              ? props.editData.workout_master_id
              : "",
          workout_day: currentPage ? currentPage : 1,
          workout_type: "challenge",
        },
      };
      const newData = await postMethod(data);
      if (newData.status == 1) {
        isNotLoading();
        setStatus(newData.data.workout_masters.workout_master_entry_status)
        let frmInptsMn = newData?.data;
        let frmInpts = newData?.data?.day_1;
        let commonChallenge = newData.data.day_1.workouts.make_common_challenge
          ? true
          : false;
        setCC(commonChallenge);
        let dtInpts = checkFormatDays(newData?.data?.day_1);
        let commonChallenges = newData.data.day_1.workouts.make_common_challenge
          ? true
          : false;
        setCC(commonChallenges);
        let programs = frmInptsMn.workout_masters.program_ids;
        let arr = [];
        programs.forEach((item) => {
          let x = programOptions.filter((prg) => prg.id == item);
          arr.push(x[0]);
        });
        setProgramValues(arr);

        setDateInputs({
          challenge_start_date: new Date(
            frmInpts.workouts.challenge_start_date
          ),
          challenge_end_date: new Date(frmInpts.workouts.challenge_end_date),
        });

        if (props.editData?.duplicate) {
          rmvIds(dtInpts);
          delete frmInptsMn.workout_masters.id;
        }
        setWorkoutInputs(dtInpts);
        setWorkoutMasters(frmInptsMn.workout_masters);
        setEditDataUpdated(true);
        setLoaded(true);
      } else {
        isNotLoading();

      }
    }
  };

  const checkIfEdit = async () => {
    if (props.editData && props.editData.workout_master_id) {
      const data = {
        url: "workout_by_id",
        body: {
          workout_master_id: props.editData.workout_master_id,
          workout_day: currentPage ? currentPage : 1,
          workout_type: "challenge",
        },
      };
      const newData = await postMethod(data);
      if (newData.status == 1) {
        isNotLoading();
        setStatus(newData.data.workout_masters.workout_master_entry_status)

        let frmInptsMn = newData?.data;
        let frmInpts = newData?.data?.day_1;
        let dtInpts = checkFormatDays(newData?.data?.day_1);

        let commonChallenge = newData.data.day_1.workouts.make_common_challenge
          ? true
          : false;
        setCC(commonChallenge);

        let programs = frmInptsMn.workout_masters.program_ids;
        let arr = [];
        programs.forEach((item) => {
          let x = programOptions.filter((prg) => prg.id == item);
          arr.push(x[0]);
        });
        setProgramValues(arr);

        setDateInputs({
          challenge_start_date: new Date(
            frmInpts.workouts.challenge_start_date
          ),
          challenge_end_date: new Date(frmInpts.workouts.challenge_end_date),
        });

        if (props.editData?.duplicate) {
          rmvIds(dtInpts);
          delete frmInptsMn.workout_masters.id;
        }
        setWorkoutInputs(dtInpts);
        setWorkoutMasters(frmInptsMn.workout_masters);
        setEditDataUpdated(true);
        setLoaded(true);
      } else {
        isNotLoading();

      }
    } else {
      setLoaded(true);
    }
  };

  const getChooseProgram = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.data && newData.data.programs) {
      isNotLoading();

      setProgramOptions(formatSelectOptions(newData.data.programs));
    }
    else {
      isNotLoading();

    }
  };

  const getExerciseList = async (url) => {
    const data = {
      url: url,
    };

    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setExerciseList(newData.data);
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  };


  const handleChallengeName = (e) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        challenge_name: e.target.value,
      },
    }));
  };


  const chooseImageFn = (url) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        image_url: url,
      },
    }));
  };

  const chooseSmallImageFn = (url) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        small_image_url: url,
      },
    }));
  };


  const getGuidelines = (data) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        guidelines: data,
      },
    }));
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();

    if (chkDt > 0) {
      let val = formatDates(date);
      setWorkoutInputs((prev) => ({
        ...prev,
        workouts: {
          ...prev.workouts,
          [name]: val,
        },
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
    }
  };

  const calculateDate = () => {
    if (dateInputs.challenge_end_date && dateInputs.challenge_start_date) {
      const diffInMs =
        new Date(dateInputs.challenge_end_date) -
        new Date(dateInputs.challenge_start_date);
      let d = diffInMs / (1000 * 60 * 60 * 24);
      const diffInDays = d + 1;
      setTotalCount(diffInDays);
      let weeks = parseInt(diffInDays / 7);
      let days = diffInDays % 7;
      setDiffDays(diffInDays);
      const a = Array.from(Array(diffInDays).keys());
      setCountDays(a);

      if (days) {
        const totalWeeks = weeks + 1;
        const arrayDays = Array.from(Array(totalWeeks).keys());
        setTotalDays(arrayDays);
      } else {
        const totalWeeks = weeks;
        const arrayDays = Array.from(Array(totalWeeks).keys());
        setTotalDays(arrayDays);
      }
    }
  };

  const handleWeeks = (e) => {
    const weekValue = parseInt(e.target.value);
    setCurrentWeek(weekValue);
  };

  const splitDay = () => {
    let start = parseInt(currentWeek) * 7;
    let end = start + 7;

    let displayDate = countDays.slice(start, end);
    setdisplayDays(displayDate);
  };

  const changeFormSteps = (e) => {
    e.preventDefault();
    const dayValue = parseInt(e.target.value) + 1;
    setCurrentPage(dayValue);
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        workout_day: dayValue,
        workout_master_id: masterID
          ? masterID
          : props.editData
            ? props.editData.workout_master_id
            : "",
      },
    }));
  };

  const setMaster = () => { };

  const nextDay = (offset) => {
    if (dateInputs.challenge_start_date) {
      let x = new Date(
        dateInputs.challenge_start_date.getTime() + 24 * offset * 60 * 60 * 1000
      );
      return x;
    }
  };

  const selectOptionsAll = () => {
    if (
      (programValues && programValues.length) !=
      (programOptions && programOptions.length)
    ) {
      let arr = [];
      programOptions.forEach((item) => {
        arr.push(item.id);
      });
      setWorkoutMasters((prev) => ({
        ...prev,
        choose_program_type: 1,
        program_ids: [...arr],
      }));
      setProgramValues(programOptions);
      setCategoryOptions([]);
    }
  };

  const handleSelectInputs = (data) => {

    setProgramValues(data);
    if (data && data.length > 0) {
      let arr = [];
      data.forEach((item) => {
        arr.push(item.id);
      });
      // handle other option if multiple
      if (data.length > 1) {
        setWorkoutMasters((prev) => ({
          ...prev,
          program_ids: [...arr],
          choose_program_type: 1,
        }));
      } else {
        let program_id = data[0].id;
        setWorkoutMasters((prev) => ({
          ...prev,
          choose_program_type: 0,
          program_ids: [program_id],
        }));
      }
    } else {
      resetOptions();
    }
  };

  const resetOptions = () => {
    // set program category to none
    // set program level to none
    // setCategoryOptions([]);
    // setLevelOptions([]);
    setWorkoutMasters((prev) => ({
      ...prev,
      program_ids: [],
      choose_program_type: 0,
    }));
  };

  const getProgramCategory = async (url, id) => {
    const data = {
      url: url,
      body: {
        master_program_ids: [id],
      },
    };
    const newData = await postMethod(data);

    if (newData.data && newData.data.length > 0) {
      isNotLoading();
      let arr = [];
      newData.data.forEach((item) => {
        arr.push({
          name: item.categories.name,
          program_category_ids: item.categories.id,
          program_ids: item.id,
        });
      });
      setCategoryOptions(arr);
    }
    else {
      isNotLoading();


    }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          ...item,
          name: item.program_name ? item.program_name : item.label,
          value: item.program_name ? item.program_name : item.label,
          label: item.program_name ? item.program_name : item.label,
        });
      });
    }
    return finalArr;
  };

  //   warmup code start
  const handleWarmupDesc = (e) => {
    const values = { ...workoutInputs };
    values.workout_warmups[0].description = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };
  const handleWarmupImage = (url) => {
    const values = { ...workoutInputs };
    values.workout_warmups[0].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_warmup = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...workoutInputs };
    values.workout_warmups[0].workout_warmup_exercises[index][name] = val;
    setWorkoutInputs({ ...workoutInputs });
  };
  const handleCC = (e) => {
    setCC(e.target.checked);
  };
  const handleExerciseSelect_warmup = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };

    values.workout_warmups[0].workout_warmup_exercises[index][name] = selname;

    values.workout_warmups[0].workout_warmup_exercises[index].exercise_id =
      value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleAddWarmupFields = (index) => {
    const values = { ...workoutInputs };
    const singleObj = values.workout_warmups[0].workout_warmup_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_warmups[0].workout_warmup_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_warmup_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveWarmupFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = copyObj(workoutInputs);
    values.workout_warmups[0].workout_warmup_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   warmup code end

  //   workout code start
  const handleWorkoutInput = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    const values = { ...workoutInputs };
    values.workouts.workout_day = currentPage;
    values.workout_wks[index][name] = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleWorkoutInnerImage = (url, ps) => {
    let index = ps.dataid;
    const values = { ...workoutInputs };
    values.workout_wks[index].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseSelect_workout = (e, i, indx, n, SelectedName, id) => {
    let name = n;
    let parent = i;
    let child = indx;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = selname;
    values.workout_wks[parent].workout_wk_exercises[child].exercise_id = value;
    values.workout_wks[parent].workout_master_id = masterID
      ? masterID
      : props.editData
        ? props.editData.workout_master_id
        : "";
    values.workout_wks[parent].workout_wk_exercises[child].workout_master_id =
      masterID
        ? masterID
        : props.editData
          ? props.editData.workout_master_id
          : "";
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_workout = (e) => {
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    let parent = e.target.getAttribute("parentid");
    let child = e.target.getAttribute("childid");

    const values = { ...workoutInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = val;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleAddWorkoutInnerFields = (e, indx) => {
    let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");
    const values = { ...workoutInputs };
    const singleObj =
      values.workout_wks[parentIndex].workout_wk_exercises[indx];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_wks[parentIndex].workout_wk_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_wk_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveWorkoutInnerFields = (e) => {
    let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");

    const values = { ...workoutInputs };
    values.workout_wks[parentIndex].workout_wk_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };

  const handleAddWorkoutFields = (index) => {
    const values = { ...workoutInputs };
    const singleObj = values.workout_wks[index];
    const singlrArr = singleObj.workout_wk_exercises;
    const finalArr = singlrArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    if (
      finalArr &&
      singleObj.circuit_name &&
      singleObj.image_url &&
      singleObj.circuit_round
    ) {
      values.workout_wks.push({
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveWorkoutFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...workoutInputs };
    values.workout_wks.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   workout code end

  //   stretch code start
  const handleStretchDesc = (e) => {
    const values = { ...workoutInputs };
    values.workout_stretchs[0].description = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleStretchImage = (url) => {
    const values = { ...workoutInputs };
    values.workout_stretchs[0].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseSelect_stretch = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = selname;

    values.workout_stretchs[0].workout_stretch_exercises[index].exercise_id =
      value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_stretch = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = val;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleAddStretchFields = (e, index) => {
    const values = { ...workoutInputs };
    const singleObj =
      values.workout_stretchs[0].workout_stretch_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_stretchs[0].workout_stretch_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_stretch_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveStretchFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   stretch code end

  const checkIfDateValid = (fromDate, toDate) => {
    let output = false;
    if (fromDate && toDate) {
      let frmDt = new Date(fromDate).getTime();
      let toDt = new Date(toDate).getTime();
      if (toDt > frmDt) {
        output = true;
      } else {
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  };

  // check validation
  function checkFormDirty(values, dirtyForms) {
    values.workout_wks.forEach((x, i) => {
      let status = false;
      if (
        x?.circuit_name?.length > 0 ||
        x?.description?.length > 0 ||
        x?.image_url?.length > 0 ||
        x?.circuit_round > 0
      ) {
        status = true;
      }
      let isAny = [];
      x.workout_wk_exercises.every((x, i) => {
        let isAny =
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0;
        if (isAny) {
          status = true;
        }
      });
      if (status) {
        spliceFn(dirtyForms, "workout_ct");
        dirtyForms.push("workout_ct");
      }
    });
  }

  function spliceFn(dirtyForms, name) {
    let x = dirtyForms.length;
    if (x > 0) {
      for (let i = 0; i < x; i++) {
        let index = dirtyForms.indexOf(name);
        if (index !== -1) {
          dirtyForms.splice(index, 1);
        }
      }
    }
  }

  function removeFormDirty(isWorkoutValid, dirtyForms) {
    if (isWorkoutValid) {
      spliceFn(dirtyForms, "workout_ct");
    }
  }

  const handleSubmit = (event, val) => {
    event.preventDefault();
    setValidated(true);
    if (totalCount == currentPage) {
      checkMaster(val);
    }
    else if (cC && props.editData && props.editData.workout_master_id) {
      checkMaster(val);
    }
    else {
      checkFormErrors(val);
    }
  };
  const handleDraft = (val) => {
    // event.preventDefault();
    setValidated(true);
    if (totalCount == currentPage) {
      checkMaster(val);
    } else {
      checkFormErrors(val);
    }
  };
  const checkMaster = async (val) => {
    setBtnLoading(true)
    const body = {
      workout_master_id: masterID ? masterID : props.editData.workout_master_id,
      make_common_challenge: cC ? 1 : 0
    };
    const data = {
      url: "check_publish_challenge",
      body: body,
    };

    const newData = await postMethod(data);
    if (newData.addable) {
      checkFormErrors(val);
      isNotLoading();
      setStatus('published')
      setBtnLoading(false)

    } else {
      isNotLoading();
      setBtnLoading(false)
      props.showToastFn(newData.message);
    }
    //  checkData();
  };
  const checkFormErrors = (val) => {
    const values = { ...workoutInputs };
    const dirtyForms = [];
    const masters = { ...workoutMasters };
    const workOutArr = values.workout_wks;
    const isNameValid = values.workout_wks.every(
      (x, i) =>
        x.circuit_name.length > 0 &&
        x?.image_url.length > 0 &&
        x?.circuit_round > 0 &&
        x?.description != null &&
        x?.description.length > 0
    );
    var isAll = [];
    workOutArr.forEach((e, i) => {
      e.workout_wk_exercises.forEach((obj) => isAll.push(obj));
    });
    const finalWorkOut = isAll.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    const isWorkoutValid = finalWorkOut && isNameValid;

    const wrkOutName = values.workouts.challenge_name;
    const wrkOutGuidelines = values.workouts.guidelines;
    const wrkOutImage = values.workouts.image_url;
    const wrkOutSmallImage = values.workouts.small_image_url;
    const wrkOutFromDate = values.workouts.challenge_start_date;
    const wrkOutToDate = values.workouts.challenge_end_date;
    const wrkOutDateValidation = checkIfDateValid(wrkOutFromDate, wrkOutToDate);
    const isMainWorkoutValid =
      wrkOutName.length > 0 &&
      // wrkOutDescription.length > 0 &&
      wrkOutImage?.length > 0 &&
      wrkOutSmallImage.length > 0 &&
      wrkOutGuidelines &&
      wrkOutDateValidation;

    const choose_program_type = masters.choose_program_type;
    const masterId = masters.master_program_ids;
    const prgId = masters.program_ids;
    const prgCatID = masters.program_category_ids;

    let isPageNameValid = prgId?.length > 0;
    const valuee = { ...workoutInputs };
    let urlImg = valuee.workout_wks[0].image_url;
    let des = valuee.workout_wks.description;
    if (
      wrkOutName &&
      // wrkOutDescription &&
      wrkOutGuidelines &&
      wrkOutImage !== "" &&
      wrkOutSmallImage !== "" &&
      wrkOutDateValidation &&
      urlImg &&
      isWorkoutValid
    ) {
      createWorkoutFn(val);
    } else {
      props.showToastFn("Please checkout all fields");
    }

    if (isPageNameValid && isMainWorkoutValid) {
      if (isWorkoutValid) {
        checkFormDirty(values, dirtyForms);
        removeFormDirty(isWorkoutValid, dirtyForms);
        if (dirtyForms.length == 0) {
          createWorkoutFn(val);
        } else {
          let msgString = `Oops! Please check
          ${dirtyForms.includes("workout_main") ? "Workout " : ""}
          ${dirtyForms.includes("workout_ct") ? "Workouts " : ""}
          form and update accordingly
          `;
          props.showToastFn(msgString);
        }
      } else {
        props.showToastFn(
          "Please fill atleast one of the workout form (WarmUp/Workout/Stretch) completely"
        );
      }
    } else {
      // props.showToastFn("Please fill the required challenge workout details");
    }
  };

  const createWorkoutFn = async (val) => {
    let body = {
      workout_masters: {
        ...workoutMasters,
        workout_master_entry_status: val == 'draft' ? 'unpublished' : status ? status : totalCount !== currentPage ? 'unpublished' : 'published'
      },
      make_common_challenge: cC,
      days: [{ ...workoutInputs }],
    };
    const data = {
      url: "workout_create_or_update",
      body: body,
    };
    const newData = await postMethod(data);
    props.showToastFn(newData.message);

    if (newData.status == 1) {
      isNotLoading();
      setMasterId(newData.resp_data.workout_masters.id);

      if (currentPage == totalCount) {
        changePublishChallenge();
      }
      if (val === "draft") {
        history.push({
          pathname: '/manage-workout/',
          appState: {
            name: "challenge",
          }
        });
      }
      if (cC) {
        history.push({
          pathname: '/manage-workout/',
          appState: {
            name: "challenge",
          }
        });
      }
    }
    else {
      isNotLoading();
    }
    checkData();
  };
  const changePublishChallenge = async () => {
    const body = {
      workout_master_id: masterID ? masterID : props.editData.workout_master_id,
    };
    const data = {
      url: "change_publish_challenge",
      body: body,
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();

      history.push({
        pathname: '/manage-workout/',
        appState: {
          name: "challenge",
        }
      });
    }
    else {
      isNotLoading();

    }
  };

  useEffect(() => {
    getChooseProgram("choose_workout_master_program");
    getExerciseList("workout_exercise_list");

    // checkIfEdit();
  }, []);

  useEffect(() => {
    if (programOptions.length > 0) {
      checkIfEdit();
    }
  }, [programOptions, setProgramOptions]);

  useEffect(() => {
    checkData();
    // getGuidelines();
  }, [currentPage, masterID]);

  return (
    <>
      {/* {loaded ? ( */}
      <Form noValidate onSubmit={handleSubmit} className="challenge_form">
        <Row className="mb-3">
          <Col
            sm="4"
            className={
              props.editData && !props.editData?.edit ? "ReadOnly" : ""
            }
          ></Col>
        </Row>
        <div className="row">
          <div className="col-md-8">
            <Row className="align-items-center">
              <Col sm="6">
                <Form.Group controlId="channlengeName">
                  <Form.Label className="required">Challenge Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="challenge_name"
                    required
                    className="mwx-400"
                    onChange={handleChallengeName}
                    value={workoutInputs.workouts.challenge_name}
                    readOnly={props.editData && props.editData.eye ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                  {(!workoutInputs?.workouts?.challenge_name ||
                    workoutInputs?.workouts?.challenge_name.length == 0) &&
                    validated && (
                      <div className="err-feedback">This field is required</div>
                    )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="pl-3">
              <Form.Group controlId="channlengeDuration">
                <Form.Label className="required">Duration</Form.Label>
                <Row>
                  <Col className="datePickerBox_Wk w-100 mwx-200">
                    <DatePicker
                      onChange={(date) =>
                        selectUploadDt(date, "challenge_start_date")
                      }
                      selected={
                        dateInputs.challenge_start_date
                          ? dateInputs.challenge_start_date
                          : null
                      }
                      minDate={new Date()}
                      maxDate={dateInputs.challenge_end_date ? dateInputs.challenge_end_date : ''}
                      name="from"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="From Date (Start)"
                      autoComplete="off"
                      disabled={status == "published" ? true : false}
                    />
                  </Col>

                  <Col className="datePickerBox_Wk w-100 mwx-200">
                    <DatePicker
                      onChange={(date) =>
                        selectUploadDt(date, "challenge_end_date")
                      }
                      selected={
                        dateInputs.challenge_end_date
                          ? dateInputs.challenge_end_date
                          : null
                      }
                      minDate={nextDay(1)}
                      name="from"
                      dateFormat="yyyy/MM/dd"
                      placeholderText="To Date (End)"
                      autoComplete="off"
                      disabled={
                        status == "published"
                          ? true
                          :
                          dateInputs.challenge_start_date
                            ? false
                            : true
                      }
                    />
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3 d-flex"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        type="checkbox"
                        checked={cC}
                        onChange={handleCC}
                        disabled={status == "published" ? true : currentPage == 1 ? false : true}
                      />
                      <Form.Label className="mt-3 pl-2">
                        Make this workout common for the challenge
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>

                {validated &&
                  !checkIfDateValid(
                    workoutInputs.workouts.challenge_start_date,
                    workoutInputs.workouts.challenge_end_date
                  ) && <div className="err-feedback">Invalid date combination</div>}
              </Form.Group>
            </Row>
          </div>
          <div className="col-md-4">
            <Row className="align-items-center">

              <Col sm="12">
                <Form.Label className="required">
                  Big image
                </Form.Label>
                <small>(420*240)</small>
                <ImageBoxUploadCrop
                  data={chooseImageFn}
                  folder="challenge"
                  rc_w="420"
                  rc_h="240"
                  as_w="420"
                  as_h="240"
                  dataid=""
                  image_url={
                    workoutInputs.workouts.image_url
                      ? workoutInputs.workouts.image_url
                      : ""
                  }
                />

                {!workoutInputs.workouts.image_url && validated && (
                  <div className="err-feedback text-center">
                    This field is required
                  </div>
                )}
              </Col>
              <Col sm="12" className="mt-3">
                <Form.Label className="required">
                  Small image
                </Form.Label>
                <small>(350*180)</small>
                <ImageBoxUploadCrop
                  data={chooseSmallImageFn}
                  folder="challenge"
                  rc_w="350"
                  rc_h="180"
                  as_w="350"
                  as_h="180"
                  dataid=""
                  image_url={
                    workoutInputs.workouts.small_image_url
                      ? workoutInputs.workouts.small_image_url
                      : ""
                  }
                />

                {!workoutInputs.workouts.small_image_url && validated && (
                  <div className="err-feedback text-center">
                    This field is required
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>

        <Form.Group controlId="description" className="position-relative">
          <Form.Label className="required">
            Add Guidelines/ Rules for the challenge
          </Form.Label>
          <Editor
            text={workoutInputs.workouts.guidelines}
            data={getGuidelines}
            readOnly={props.editData && props.editData.eye ? true : false}
          />
        </Form.Group>
        {totalCount ? (
          <>
            {cC ? null : (
              <div className="totalWeekContent">
                <div className="d-flex weekContent">
                  {totalDays &&
                    totalDays.length > 0 &&
                    totalDays.map((item, index) => (
                      <Row key={index} className="weeksTab">
                        <div className="d-flex position-relative weekTab">
                          <Col>
                            <button
                              type="button"
                              className={
                                currentWeek == index
                                  ? "colorWeekBtn"
                                  : "weekButton"
                              }
                              onClick={handleWeeks}
                              currentbtn={
                                currentWeek == index ? "true" : "false"
                              }
                              value={index}
                            >
                              Week{item + 1}
                            </button>
                          </Col>
                        </div>
                      </Row>
                    ))}
                </div>

                <Row className="stepNumbersBox mt-5 mb-3">
                  {displayDays &&
                    displayDays.map((item, idx) => (
                      <Col key={idx}>
                        <Row className="flex-column position-relative stepNumbers">
                          <Col className="num text-center">
                            <Button
                              onClick={changeFormSteps}
                              value={item}
                              currentbtn={
                                currentPage == currentWeek * 7 + (idx + 1)
                                  ? "true"
                                  : "false"
                              }
                              variant="link"
                            >
                              {idx + 1}
                            </Button>
                          </Col>
                          <Col className="txt">{"DAY" + (item + 1)}</Col>
                        </Row>
                      </Col>
                    ))}
                </Row>
              </div>
            )}
          </>
        ) : null}
        {/* end of warmup */}
        <div className="workoutBox">
          <h5 className="text-active mb-4">Workouts</h5>
          {workoutInputs.workout_wks.map((item, index, arr) => (
            <div key={index}>

              {/* ------Add Circuit------- */}

              <Row className="mb-4 mt-2">
                <Col sm="12" className="mt-3">
                  <div className="d-flex align-items-center mt-4">
                    <div className="d-flex align-items-center">
                      <Form.Group
                        controlId={`circuit_name_${index}-01`}
                        className="mw-250 mb-0 position-relative"
                      >
                        <Form.Control
                          name="circuit_name"
                          type="text"
                          placeholder="Enter Circuit Name"
                          dataid={index}
                          value={item.circuit_name}
                          required
                          onChange={handleWorkoutInput}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* ------Add Circuit------- */}

                      <span className="pl-0 pr-0" style={{ width: "30px" }}>
                        <hr className="dark" />
                      </span>
                      <Form.Group
                        controlId={`circuit_round_${index}-01`}
                        className="mw-100 mb-0 position-relative"
                      >
                        <ToolTipBox text="Circuit Rounds" placement="right">
                          <Button variant="link" className="p-0">
                            <Form.Control
                              name="circuit_round"
                              type="number"
                              min="1"
                              max="100"
                              placeholder="Round"
                              dataid={index}
                              value={item.circuit_round}
                              required
                              onChange={handleWorkoutInput}
                              readOnly={props.editData && props.editData.eye ? true : false}

                            />
                          </Button>
                        </ToolTipBox>
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <span className="w-100 pl-0 pr-0">
                      <hr className="dark" />
                    </span>
                    {index == 0 && arr.length == 1 && (
                      <Button
                        className="ml-auto no-wrap"
                        onClick={() => handleAddWorkoutFields(index)}
                        readOnly={props.editData && props.editData.eye ? true : false}

                      >
                        Add Circuit
                      </Button>
                    )}
                    {arr.length > 0 && arr.length - 1 != index && (
                      <>
                        <Button
                          className="ml-auto"
                          dataid={index}
                          onClick={handleRemoveWorkoutFields}
                          variant="secondary"
                          readOnly={props.editData && props.editData.eye ? true : false}

                        >
                          Delete
                        </Button>
                      </>
                    )}
                    {arr.length - 1 == index && index != 0 && (
                      <>
                        <Button
                          className="ml-auto no-wrap"
                          onClick={() => handleAddWorkoutFields(index)}
                        >
                          Add Circuit
                        </Button>
                        <span className="pl-0 pr-0" style={{ width: "1rem" }}>
                          <hr className="dark" />
                        </span>
                        <Button
                          className="ml-auto"
                          dataid={index}
                          onClick={handleRemoveWorkoutFields}
                          variant="secondary"
                        >
                          Delete
                        </Button>
                      </>
                    )}

                    {/* ------Add Circuit------- */}
                  </div>
                </Col>

                <Col sm="7">
                  <Form.Group
                    controlId={`workoutshortdes-01`}
                    className="mt-3 mb-3"
                  >
                    <Form.Label className="required">Add Short Description/Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Add Short Description/Notes"
                      rows={3}
                      readOnly={
                        props.editData && props.editData.editAccess === false
                          ? true
                          : false
                      }
                      dataid={index}
                      value={item.description ? item.description : ""}
                      required
                      onChange={handleWorkoutInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>


                </Col>
                <Col sm="5" className="img-challenge">
                  <Form.Label className="required">Circuit Image</Form.Label>
                  <ImageBoxUploadCrop
                    folder="challenge"
                    as_w="4"
                    as_h="3"
                    data={handleWorkoutInnerImage}
                    dataid={index}
                    image_url={item.image_url ? item.image_url : ""}
                    readOnly={props.editData && props.editData.eye ? true : false}

                  />
                </Col>
              </Row>

              {item.workout_wk_exercises &&
                item.workout_wk_exercises.map((item, indx, arr) => (
                  <Form.Row
                    className="align-items-center form-m-0 mb-3"
                    key={indx}
                  >
                    <Col md="4">
                      <Form.Group
                        controlId={`workout-m-exercise-${indx}-${index}-01`}
                        className="w-100"
                      >
                        <AntSelect
                          showSearch
                          name="exercise_name"
                          placeholder="Select Exercises"
                          dataid={index}
                          // readOnly={props.editData&&props.editData.eye?true:false}
                          value={
                            item.exercise_id != ""
                              ? item.exercise_id
                              : "Select Exercise"
                          }
                          optionFilterProp="children"
                          className={
                            props.editData &&
                              props.editData.eye === true
                              ? "readOnly"
                              : "select_filter"
                          }
                          onChange={(e, option) => {
                            handleExerciseSelect_workout(
                              e,
                              index,
                              indx,
                              "exercise_name",
                              option.selname,
                              option.value
                            );
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {exerciseList.map((item, index) => (
                            <AntSelect.Option
                              value={item.id}
                              selname={item.name}
                              key={index}
                            >
                              {item.name}
                            </AntSelect.Option>
                          ))}
                        </AntSelect>

                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-reps${indx}-${index}-01`}
                      >
                        <Form.Control
                          type="number"
                          name="reps"
                          min="1"
                          placeholder="Reps"
                          parentid={index}
                          childid={indx}
                          required
                          disabled={
                            item.es || item.el || item.sec ? true : false
                          }
                          value={item.reps && item.reps !== null ? item.reps : ""}
                          onChange={handleExerciseInput_workout}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-es${indx}-${index}--01`}
                      >
                        <Form.Control
                          type="number"
                          name="es"
                          min="1"
                          placeholder="E/S"
                          parentid={index}
                          required
                          disabled={
                            item.sec || item.el || item.reps ? true : false
                          }
                          value={item.es && item.es !== null ? item.es : ""}
                          childid={indx}
                          onChange={handleExerciseInput_workout}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group controlId={`workout-m-el${indx}${index}--01`}>
                        <Form.Control
                          type="number"
                          name="el"
                          min="1"
                          placeholder="E/L"
                          parentid={index}
                          childid={indx}
                          value={item.el && item.el !== null ? item.el : ""}
                          required
                          disabled={
                            item.es || item.sec || item.reps ? true : false
                          }
                          onChange={handleExerciseInput_workout}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-secs-${index}-${indx}-01`}
                      >
                        <Form.Control
                          type="number"
                          name="sec"
                          min="1"
                          placeholder="Secs"
                          parentid={index}
                          childid={indx}
                          value={item.sec && item.sec !== null ? item.sec : ''}
                          required
                          disabled={
                            item.es || item.el || item.reps ? true : false
                          }
                          onChange={handleExerciseInput_workout}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-break${index}-${indx}-01`}
                      >
                        <Form.Control
                          type="number"
                          name="break_time"
                          min="1"
                          placeholder="Break Time"
                          parentid={index}
                          childid={indx}
                          value={item.break_time}
                          onChange={handleExerciseInput_workout}
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                      </Form.Group>
                    </Col>
                    <Col md="1" className="text-right">
                      {indx == 0 && arr.length == 1 && (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          dataid={indx}
                          supdataid={index}
                          onClick={(e) => handleAddWorkoutInnerFields(e, indx)}
                          className="font-20"
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                      )}
                      {arr.length > 0 && arr.length - 1 != indx && (
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          dataid={indx}
                          supdataid={index}
                          onClick={handleRemoveWorkoutInnerFields}
                          className="font-20"
                          readOnly={props.editData && props.editData.eye ? true : false}

                        />
                      )}
                      {arr.length - 1 == indx && indx != 0 && (
                        <>
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            dataid={indx}
                            supdataid={index}
                            readOnly={props.editData && props.editData.eye ? true : false}

                            onClick={(e) =>
                              handleAddWorkoutInnerFields(e, indx)
                            }
                            className="font-20 mr-2"
                          />
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            dataid={indx}
                            supdataid={index}
                            onClick={handleRemoveWorkoutInnerFields}
                            readOnly={props.editData && props.editData.eye ? true : false}

                            className="font-20"
                          />
                        </>
                      )}
                    </Col>
                  </Form.Row>
                ))}

              <hr />
            </div>
          ))}
        </div>

        <div className="mainCtrls d-flex mt-5">
          <div className="float-left">
            {/* <Button
              variant="secondary"
              onClick={() => {
                handleDraft('draft')
              }}
              className="mr-3"
              readOnly={props.editData && props.editData.eye ? true : false}
            >
              Save Draft
            </Button> */}
            <Buttons
               variant="secondary"
               onClick={() => {
                 handleDraft('draft')
               }}
               className="mr-3  btn-secondary"
               loading={btnLoading}
               disabled={props.editData && props.editData.eye ? true : btnLoading?btnLoading: false}>
              Save Draft
            </Buttons>
          </div>
          <div className="ml-auto ">
            <Button
              variant="secondary"
              onClick={() => {
                history.push({
                  pathname: '/manage-workout/',
                  appState: {
                    name: "challenge",
                  }
                });
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              name="submit"
              className="mr-3"
              readOnly={props.editData && props.editData.eye ? true : false}

            >
              {totalCount == currentPage || cC ? "Publish" : "Save"}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}
