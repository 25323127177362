import React, { useState, useEffect } from "react";
import DashTabs from "../components/Tabs/dashtabs";
// import DashBoardCards from "../components/DashboardCard";
import { Toast } from "react-bootstrap";

// import { useLocation } from "react-router-dom";

// import { MenuAccess } from "../Library";

import { AccessDenied } from "../Library";

export default function DashboardPage(props) {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // const location = useLocation();

  // MenuAccess(location.pathname);

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  useEffect(() => {
    props.getMenuList();
  }, []);

  return (
    <section className="rightContent">
      {props.menuList.includes(1) ? (
        <>
          {/* <DashBoardCards />
          <br /> */}
          <DashTabs showToastFn={showToastFn} />
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
