import React, { useState, useEffect } from "react";
import { Button, Form, Toast, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPlusCircle,
  faTrashAlt,
  faChevronLeft,
  faClock,
  faRunning,
  faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import {
  getMethod,
  postMethod,
  findErrors,
  AccessDenied,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import _ from "lodash";
import Badge from "react-bootstrap/Badge";
import ToolTipBox from "../components/ToolTipBox";
import { Select } from "antd";
import "antd/dist/antd.css";
import Editor from "../components/editor";
import { useLocation, useHistory } from "react-router-dom";
import ImageBoxUpload from "../components/ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../components/ImageBox/imageboxuploadcrop";

import clock from "../assets/images/ic_outline-access.png";
import dumbbells from "../assets/images/fluent_dumbbell.png";
import runner from "../assets/images/healthicons_exercise.png";
import logo from "../assets/images/nourishwithsim.png";
import mobile from "../assets/images/mobileLayout.png";
import backArrow from "../assets/images/backArrow.png";

import simrunChopra1 from "../assets/images/simrunChopra1.jpg";
import Empty from "../assets/images/empty.png";
import simrunChopra2 from "../assets/images/simrunChopra2.jpg";
import simrunChopra3 from "../assets/images/simrunChopra3.jpg";
import simrunChopra4 from "../assets/images/simrunChopra4.jpg";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ModalPop from "../components/modals";
import index from "../components/editor";

export default function AddProgramsPage(props) {
  const [validated, setValidated] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [copy, setCopy] = useState(false);
  const [formDetails, setFormDetails] = useState();
  const [name, setName] = useState("");
  const [nextWeek, setNextWeek] = useState([]);

  const [equipmentsList, setEquipmentsList] = useState({});

  const [formInputs, setFormInputs] = useState({
    listing_page_image1_url: "",
    listing_page_image2_url: "",
    image_url: "",
    activity_level: "",
    goal: "",
    from_week: "",
    to_week: "",
    description: "",
    program_name: "",
    out_of_water: "",
    out_of_step: "",
    limited_edition: false,
    is_copy: false,
    equipments: [
      {
        id: "",
        equipment_id: "",
        instruction: [
          {
            title: "",
          },
        ],
      },
    ],
    image_overlay_description: "",
  });

  const [editData, setEditData] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [goalActivityOptions, setGoalActivityOptions] = useState();

  const [equipmentOptions, setEquipmentOptions] = useState();

  const [fromWeek, setFromWeek] = useState("");
  const [id, setId] = useState("");
  const [programWeeks, setProgramWeeks] = useState([]);
  const [preview, setPreview] = useState(false);
  const [previewProgram, setPreviewProgram] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const location = useLocation();
  const history = useHistory();

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const chooseImageFn = (data, ps) => {
    const name = ps.dataid;
    setFormInputs((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  const filterCategories = (data, id) => {
    let results = data.filter(function (obj) {
      if (obj.id == id) {
        return obj;
      }
    });
    return results && results.length > 0 ? results[0].categories : [];
  };

  const gettypedData = (data) => {
    setFormInputs((prev) => ({
      ...prev,
      description: data,
    }));
  };

  const handleInput = (e) => {
    let value = e.target.value;
    const name = e.target.name;
    if (name == "out_of_step" || name == "out_of_water") {
      if (typeof parseInt(value) != "number" && parseInt(value) < 0) {
        value = 0;
        return false;
      }
    }
    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name == "master_program_id") {
      if (value) {
        let id = parseInt(value);
        let categories = filterCategories(formDetails, id);
        setFormInputs((prev) => ({
          ...prev,
          category_id: "",
        }));
        setCategoryOptions(categories);
      } else {
        setCategoryOptions([]);
      }
    }
  };

  const handleProgramInput = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    const values = { ...formInputs };
    values.equipments[index][name] = e.target.value;
    if (values.equipments[index][name] === "") {
      values.equipments[index].instruction = [{ title: "" }];
    }

    setFormInputs({ ...values });
  };

  const handleAddProgramInnerFields = (e, indx) => {
    let parentIndex = e.target.getAttribute("supdataid");
    const values = { ...formInputs };
    const singleObj = values.equipments[parentIndex].instruction[indx];
    if (singleObj.title) {
      values.equipments[parentIndex].instruction.push({
        title: "",
      });
      setFormInputs(values);
    }
  };

  const handleRemoveProgramInnerFields = (e) => {
    let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");

    const values = { ...formInputs };
    values.equipments[parentIndex].instruction.splice(index, 1);
    setFormInputs(values);
  };

  const handleInstructionInputProgram = (e) => {
    let name = e.target.name;

    let parent = e.target.getAttribute("parentid");
    let child = e.target.getAttribute("childid");

    let val = e.target.value ? e.target.value : "";
    const values = { ...formInputs };
    values.equipments[parent].instruction[child][name] = val;
    setFormInputs({ ...formInputs });
  };

  const handleAddProgramFields = (index) => {
    const values = { ...formInputs };
    const singleObj = values.equipments[index];
    const singlrArr = singleObj.instruction;
    const finalArr = singlrArr.every((x, i) => {
      const isAny = x.title;
      return isAny;
    });

    if (finalArr && singleObj.equipment_id) {
      values.equipments.push({
        equipment_id: "",
        instruction: [
          {
            title: "",
          },
        ],
      });
      setFormInputs(values);
    }
  };

  const handleRemoveWorkoutFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...formInputs };
    values.equipments.splice(index, 1);
    setFormInputs(values);
  };


  const removeEquipment = async (e, equipmentId) => {
    const inputs = {
      id: equipmentId,
    };
    const data = {
      url: "program_equipment_delete",
      body: inputs,
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      showToastFn(newData.message);
      getEquipmentList(location.state.id);
    } else if (newData.status === 0) {
      isNotLoading();
      showToastFn(newData.message);
    }
  };

  const getEquipmentList = async (programId) => {
    const inputs = {
      id: programId,
    };
    const data = {
      url: "program_list_by_id",
      body: inputs,
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let copyData = { ...newData.data };

      if (copyData.equipments.length === 0) {
        copyData = {
          ...copyData,
          equipments: [
            {
              id: "",
              equipment_id: "",
              instruction: [
                {
                  title: "",
                },
              ],
            },
          ],
        };
      }

      if (location.state.is_copy) {
        setEquipmentsList({ ...copyData, is_copy: true });
        delete copyData.id;
        checkIfEdit({ ...copyData, is_copy: true, old_program_id: programId });
      } else {
        setEquipmentsList({ ...copyData, is_copy: false });
        checkIfEdit({ ...copyData, is_copy: false });
      }

    } else if (newData.status === 0) {
      isNotLoading();
    }
  };

  const handleSubmit = (event, type) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      savePrograms(type);
    }
  };

  const programSaveAlert = (data) => {
    confirmAlert({
      title: "Program saved successfully",
      message: "Redirect back to programs ?",
      buttons: [
        {
          label: "Ok",
          onClick: () => {
            history.push(`/manage-programs/`);
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const savePrograms = async (type) => {
    const data = {
      url: "program_create_or_update",
      body: formInputs,
    };

    const reqFields = _.pick(
      formInputs,
      "program_name",
      "description",
      "image_url",
      "from_week",
      "to_week",
      "goal",
      "activity_level",
      "listing_page_image1_url",
      "listing_page_image2_url",
      "equipments",
      "image_overlay_description"
    );


    const errors = findErrors(reqFields);
    if (!errors) {
      const newData = await postMethod(data);
      showToastFn(newData.message);
      if (newData.status == 1) {
        isNotLoading();
        programSaveAlert(newData);
      } else {
        isNotLoading();
      }
    }
  };

  const getPrograms = async () => {
    const data = {
      url: "master_program_list",
    };
    const newData = await postMethod(data);
    if (newData.data && newData.data.master_programs) {
      isNotLoading();
      setFormDetails(newData.data.master_programs);
      checkIfEdit(newData.data.master_programs);
    } else {
      isNotLoading();
    }
  };
  const getProgramWeeks = async (id) => {
    const inputs = {
      program_id: id,
    };
    const data = {
      url: "program_weeks",
      body: inputs,
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setProgramWeeks(newData.data.edit_program_to_weeks);
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    }
  };

  const getNextWeeks = async (id) => {
    const inputs = {
      from_week: formInputs.from_week,
    };
    const data = {
      url: "next_weeks",
      body: inputs,
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setNextWeek(newData.data.to_weeks);
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  };

  const getGoalActivityOptions = async () => {
    const data = {
      url: "static_content_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setGoalActivityOptions(newData.data);
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    }
  };

  const getEquipmentOptions = async () => {
    const data = {
      url: "exercise_equipment_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setEquipmentOptions(newData.data);
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    }
  };

  const handlePre = (a) => {
    setPreview(!preview);
    setName(a);
  };
  const handleProgram = () => {
    setPreviewProgram(!previewProgram);
  };

  const previewModal = () => {
    return (
      <>
        <div className="p-2 total-popup-style">
          <img src={mobile} alt="mobile" className="mobile" />

          <div className="sub-popup-style">
            <div className="text-center">
              <img src={logo} alt="logo" className="logo-style" />
            </div>
            <div className="p-title p-1">
              <div>Recommended programs</div>
            </div>
            <div className="text-center pre-desc">
              Hey User, You have chosen beginner as you level in the previous
              section and we recommend the following programs based on your
              selection
            </div>
            <div className="pre-card">
              <div
                className={
                  name == "listing1"
                    ? "top_mob_img card"
                    : name == "listing2"
                      ? "top_mob_img card blur"
                      : "top_mob_img card bg-dumb"
                }
                style={{
                  backgroundImage: `url(${formInputs.listing_page_image1_url
                    ? formInputs.listing_page_image1_url
                    : simrunChopra1
                    })`,
                }}
              >
                <div className="mask mask-custom">
                  <div className="p-card-title mt-1">Reboot Lite</div>
                  <div>
                    <div className="activity-tracker">
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faClock}
                          className="activity-icon"
                        />
                        <p className="mb-0 x-small-font"> 14week </p>
                      </div>
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faRunning}
                          className="activity-icon"
                        />
                        <p className="mb-0 x-small-font"> Beginner </p>
                      </div>
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faDumbbell}
                          className="activity-icon dubicon"
                        />
                        <p className="mb-0 x-small-font"> Dumbbells </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-card-desc">
                    This program is a low intensity, low impact program and the
                    weight loss results will be more spread out
                  </div>
                  <div className="text-center my-2">
                    <Button className="pre-details-btn">View details</Button>
                  </div>
                </div>
              </div>
              <div className="smallcard"> </div>
            </div>
            <div className="p-title mt-2">
              <div>Intermediate level</div>
            </div>
            <div className="pre-card pre-secondcard">
              <div
                className={
                  name == "listing2"
                    ? "top_mob_img card"
                    : name == "listing1"
                      ? "top_mob_img card blur"
                      : "top_mob_img card bg-dumb"
                }
                style={{
                  backgroundImage: `url(${formInputs.listing_page_image2_url
                    ? formInputs.listing_page_image2_url
                    : simrunChopra1
                    })`,
                }}
              >
                <div className="mask mask-custom">
                  <div className="p-card-title pt-1">Reboot Lite</div>
                  <div>
                    <div className="activity-tracker">
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faClock}
                          className="activity-icon"
                        />
                        <p className="mb-0 x-small-font"> 14week </p>
                      </div>
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faRunning}
                          className="activity-icon"
                        />
                        <p className="mb-0 x-small-font"> Beginner </p>
                      </div>
                      <div className="sub-activity">
                        <FontAwesomeIcon
                          icon={faDumbbell}
                          className="activity-icon dubicon"
                        />
                        <p className="mb-0 x-small-font"> Dumbbells </p>
                      </div>
                    </div>
                  </div>
                  <div class="p-card-desc">
                    This program is a low intensity, low impact program and the
                    weight loss results will be more spread out
                  </div>
                  <div className="text-center my-2">
                    <Button className="pre-details-btn">View details</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const previewModalProgram = () => {
    return (
      <>
        <div className="p-2 total-popup-style program-preview">
          <img src={mobile} alt="mobile" className="mobile" />

          <div className="sub-popup-style">
            <div className="p-back-btn">
              <FontAwesomeIcon icon={faChevronLeft} className="font-18" />
            </div>
            <div className="profile-reboot">
              {formInputs.image_url && (
                <img
                  src={
                    formInputs.image_url ? formInputs.image_url : simrunChopra1
                  }
                  className="profile"
                  alt="thumb"
                />
              )}
            </div>
            <div className="program-bottom-sec">
              <p className="bold mb-0 medium-font">Stronger ( level 2)</p>
              <div className="p-details-1 mb-1">
                <Badge className="mr-1" bg="danger">
                  {" "}
                  <FontAwesomeIcon
                    icon={faClock}
                    className="activity-icon"
                  />{" "}
                  14 weeks{" "}
                </Badge>
                <Badge className="mr-1">
                  {" "}
                  <FontAwesomeIcon
                    icon={faRunning}
                    className="activity-icon"
                  />{" "}
                  Intermediate
                </Badge>
                <Badge bg="danger">
                  {" "}
                  <FontAwesomeIcon
                    icon={faDumbbell}
                    className="activity-icon dubicon"
                  />{" "}
                  Dumbbells
                </Badge>
              </div>
              <div className="p-tab p-2">
                <Row className="small-font">
                  <Col sm="4" className="p-0">
                    {" "}
                    <div className="selectd-p-tab ml-2">Description</div>{" "}
                  </Col>
                  <Col sm="4" className="pt-1">
                    Equipment{" "}
                  </Col>
                  <Col sm="4" className="pt-1">
                    FAQ{" "}
                  </Col>
                </Row>
              </div>
              <p className="mb-1 small-font pt-1 pl-3">
                <ul>
                  <li>Strength & Cardio</li>
                  <li>6 workouts a week </li>
                  <li>30 -60 minute workouts</li>
                </ul>
              </p>
              <p className="mb-2 small-font">
                Summer Sculpt will help you feel the joy of crushing goals as
                you level with training you can do at home with minimal
                equipment. You will be doing a blend of cardio and strength
                based movements that are both rep based and time based.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const checkIfEdit = (data) => {
    if (location.state) {
      setFormInputs(data);
      setId(location.state?.id);
      setEditAccess(!location.state.program_edit);
      setEditData(location.state.id);
      getProgramWeeks(location.state.id);
      setCopy(location.state.is_copy);
    }
  };
  const handlelimitedEditionChange = () => {
    setFormInputs({ ...formInputs, limited_edition: !formInputs.limited_edition })
  }
  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
    return () => {
      setPageLoaded(false);
    };
  }, []);

  useEffect(() => {
    getNextWeeks();
  }, [formInputs?.from_week, setFormInputs]);

  useEffect(() => {
    if (pageLoaded) {
      getGoalActivityOptions();
      getEquipmentOptions();
      getNextWeeks();
    }
  }, [pageLoaded, setPageLoaded]);

  useEffect(() => {
    if (location.state?.id || location.state?.old_program_id) {
      let programid = location.state?.id || location.state?.old_program_id;
      getEquipmentList(programid);
    }
  }, []);

  return (
    <section className="rightContent">
      {props.menuList.includes(5) ? (
        <>
          <h3 className="mb-5">{editData ? "Edit" : "Add"} Program</h3>
          <Form noValidate validated={validated} className="addProgramForm">
            <div className="row">
              <div className="col-md-8">
                <Row>
                  <Col>
                    <Form.Group controlId="program">
                      <Form.Label className="required">Program Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="program_name"
                        value={formInputs.program_name}
                        required
                        onChange={handleInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="position-relative">
                  <Col>
                    <Form.Group controlId="from_week">
                      <Form.Label className="required">From</Form.Label>
                      <Form.Control
                        as="select"
                        name="from_week"
                        disabled={editData}
                        value={formInputs.from_week}
                        required
                        onChange={handleInput}
                      >
                        <option value="">Select From Week</option>
                        {goalActivityOptions?.program_weeks.map(
                          (item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          )
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="to_week">
                      <Form.Label className="required">To</Form.Label>
                      {editData ? (
                        <Form.Control
                          as="select"
                          name="to_week"
                          disabled={formInputs?.from_week === formInputs?.to_week && editData}
                          value={formInputs.to_week}
                          required
                          onChange={handleInput}
                        >
                          <option value="">Select To Week</option>
                          {programWeeks.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <Form.Control
                          as="select"
                          name="to_week"
                          value={formInputs.to_week}
                          required
                          onChange={handleInput}
                        >
                          <option value="">Select To Week</option>
                          {nextWeek.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="position-relative">
                  <Col>
                    <Form.Group controlId="goal">
                      <Form.Label className="required">Goal</Form.Label>
                      <Form.Control
                        as="select"
                        name="goal"
                        disabled={formInputs.program_edit}
                        value={formInputs ? formInputs.goal : ""}
                        required
                        onChange={handleInput}
                      >
                        <option value="">Select a Goal</option>
                        {goalActivityOptions?.goal.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="activity">
                      <Form.Label className="required">
                        Activity Level
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="activity_level"
                        disabled={formInputs.program_edit}
                        value={formInputs ? formInputs.activity_level : ""}
                        required
                        onChange={handleInput}
                      >
                        <option value="">Select a Activity</option>
                        {goalActivityOptions?.activity_level.map(
                          (item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          )
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <small className="prgFeed">
                  {formInputs.program_edit
                    ? "Goal & Activity can't be edited as this is used by Client."
                    : null}
                </small>
                <Row>
                  <Col>
                    <Form.Group controlId="out_of_water">
                      <Form.Label>Water Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="out_of_water"
                        min="1"
                        value={formInputs.out_of_water}
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="out_of_step">
                      <Form.Label>Step Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="out_of_step"
                        value={formInputs.out_of_step}
                        min="1"
                        onChange={handleInput}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="limited_edition" className="mb-0">
                      <Form.Check
                        inline
                        type="checkbox"
                        name="limited_edition"
                        className="ltdEdition"
                        label="Make this program a limited edition"
                        checked={formInputs.limited_edition == 0 ? false : true}
                        onChange={() => handlelimitedEditionChange()}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="col-md-4">
                <div className="mb-5 position-relative">
                  <Form.Label className="required">
                    Listing page image 1
                  </Form.Label>
                  <small>(330*380)</small>

                  <ImageBoxUploadCrop
                    preview="false"
                    data={chooseImageFn}
                    folder="program"
                    rc_w="330"
                    rc_h="380"
                    as_w="330"
                    as_h="380"
                    dataid="listing_page_image1_url"
                    image_url={formInputs?.listing_page_image1_url}
                  />
                  {validated && !formInputs?.listing_page_image1_url && (
                    <div className="err-feedback text-center">
                      This field is required
                    </div>
                  )}
                  {formInputs?.listing_page_image1_url && (
                    <div
                      className="text-center pt-2 cursor-pointer"
                      onClick={() => {
                        handlePre("listing1");
                      }}
                    >
                      Preview <FontAwesomeIcon icon={faEye} />
                    </div>
                  )}
                </div>
                <div className="mb-5 position-relative">
                  <div>
                    <Form.Label className="required">
                      Listing page image 2{" "}
                    </Form.Label>
                    <small>(279*369)</small>
                  </div>
                  <ImageBoxUploadCrop
                    data={chooseImageFn}
                    preview="false"
                    rc_w="279"
                    rc_h="369"
                    as_w="279"
                    as_h="369"
                    folder="program"
                    dataid="listing_page_image2_url"
                    image_url={formInputs?.listing_page_image2_url}
                  />
                  {validated && !formInputs.listing_page_image2_url && (
                    <div className="err-feedback text-center">
                      This field is required
                    </div>
                  )}
                  {formInputs?.listing_page_image2_url && (
                    <div
                      className="text-center pt-2 cursor-pointer"
                      onClick={() => {
                        handlePre("listing2");
                      }}
                    >
                      Preview <FontAwesomeIcon icon={faEye} />
                    </div>
                  )}
                </div>
                <div className="position-relative mb-5">
                  <div>
                    <Form.Label className="required mr-3">
                      Program detail page
                    </Form.Label>
                    <small>(375*291)</small>
                  </div>

                  <ImageBoxUploadCrop
                    data={chooseImageFn}
                    preview="false"
                    rc_w="375"
                    rc_h="291"
                    as_w="375"
                    as_h="291"
                    folder="program"
                    dataid="image_url"
                    image_url={formInputs?.image_url}
                  />
                  {validated && !formInputs.image_url && (
                    <div className="err-feedback text-center">
                      This field is required
                    </div>
                  )}
                  {formInputs?.image_url && (
                    <div
                      className="text-center pt-2 cursor-pointer"
                      onClick={() => {
                        handleProgram();
                        // handlePre("thumbnail");
                      }}
                    >
                      Preview <FontAwesomeIcon icon={faEye} />
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className="workoutBox">
              {formInputs.equipments && formInputs.equipments.length > 0 && (
                <>
                  {formInputs.equipments.map((item, index, arr) => (
                    <div key={index}>
                      <div className="d-flex align-items-center mt-4">
                        <div className="d-flex align-items-center equipment-width">
                          <Form.Group
                            controlId="from_week"
                            className="w-100 mb-0"
                          >
                            <Form.Label className="fw-600">
                              Equipment
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name="equipment_id"
                              dataid={index}
                              value={item.equipment_id}
                              onChange={handleProgramInput}
                            >
                              <option value="">Select Equipment</option>
                              {equipmentOptions?.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>

                        <span className="w-100 pl-0 pr-0 alignment-top-padding">
                          <hr className="dark" />
                        </span>
                        {index == 0 && arr.length == 1 && (
                          <Button
                            className="ml-auto no-wrap alignment-top-margin"
                            onClick={() => handleAddProgramFields(index)}
                          >
                            Add Equipment
                          </Button>
                        )}
                        {arr.length > 0 && arr.length - 1 != index && (
                          <>
                            <Button
                              className="ml-auto alignment-top-margin"
                              dataid={index}
                              onClick={handleRemoveWorkoutFields}
                              variant="secondary"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                        {arr.length - 1 == index && index != 0 && (
                          <>
                            <Button
                              className="ml-auto no-wrap alignment-top-margin"
                              onClick={() => handleAddProgramFields(index)}
                            >
                              Add Equipment
                            </Button>
                            <span
                              className="pl-0 pr-0 alignment-top-padding"
                              style={{ width: "1rem" }}
                            >
                              <hr className="dark" />
                            </span>
                            <Button
                              className="ml-auto alignment-top-margin"
                              dataid={index}
                              onClick={handleRemoveWorkoutFields}
                              variant="secondary"
                            >
                              Delete
                            </Button>
                          </>
                        )}
                      </div>

                      {item.instruction &&
                        item.instruction.map((item, indx, arr) => (
                          <Form.Row
                            className="align-items-end form-m-0 mt-4 mb-4 instr-content"
                            key={indx}
                          >
                            <Col md="10">
                              <Form.Group
                                controlId={`workout-m-break-${indx}-${index}-01`}
                                className="mb-0"
                              >
                                <Form.Label> Instruction </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="title"
                                  min="1"
                                  placeholder={`Instruction point ${parseInt(indx) + 1
                                    }`}
                                  parentid={index}
                                  childid={indx}
                                  value={item.title}
                                  onChange={handleInstructionInputProgram}
                                  disabled={
                                    formInputs.equipments[index].equipment_id
                                      ? false
                                      : true
                                  }
                                  required={
                                    formInputs.equipments[index].equipment_id
                                      ? true
                                      : false
                                  }
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="instr-err-msg"
                                >
                                  This field is required
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col
                              md="1"
                              className={`text-right pb-3 align-self-center pt-5`}
                            >
                              {indx == 0 && arr.length == 1 && (
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  dataid={indx}
                                  supdataid={index}
                                  onClick={(e) =>
                                    handleAddProgramInnerFields(e, indx)
                                  }
                                  className="font-20 cursor-pointer"
                                />
                              )}
                              {arr.length > 0 && arr.length - 1 != indx && (
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  dataid={indx}
                                  supdataid={index}
                                  onClick={handleRemoveProgramInnerFields}
                                  className="font-20 cursor-pointer"
                                />
                              )}
                              {arr.length - 1 == indx && indx != 0 && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    dataid={indx}
                                    supdataid={index}
                                    onClick={(e) =>
                                      handleAddProgramInnerFields(e, indx)
                                    }
                                    className="font-20 mr-2 cursor-pointer"
                                  />
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    dataid={indx}
                                    supdataid={index}
                                    onClick={handleRemoveProgramInnerFields}
                                    className="font-20 cursor-pointer"
                                  />
                                </>
                              )}
                            </Col>
                          </Form.Row>
                        ))}
                    </div>
                  ))}
                </>
              )}

              <hr />

              <Row className="mb-4">
                <Col sm="9">
                  <Form.Group
                    controlId={`workoutshortdes-01`}
                    className="mt-3 mb-1"
                  >
                    <Form.Label className="required">
                      Image overlay description
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="image_overlay_description"
                      placeholder="Add Image Overlay Description"
                      rows={3}
                      value={
                        formInputs && formInputs.image_overlay_description
                          ? formInputs.image_overlay_description
                          : ""
                      }
                      required
                      maxLength="110"
                      onChange={handleInput}
                    />
                    <small className="float-right mt-1">
                      *Remaining character{" "}
                      {formInputs &&
                        formInputs.image_overlay_description === null
                        ? 110
                        : 110 -
                        parseInt(
                          formInputs.image_overlay_description?.length
                        )}
                    </small>

                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="9">
                  <Form.Group
                    controlId="description"
                    className="position-relative"
                  >
                    <Form.Label className="required">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Add Description"
                      rows={4}
                      value={
                        formInputs && formInputs.description
                          ? formInputs.description
                          : ""
                      }
                      required
                      // maxLength="110"
                      onChange={handleInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>

                    {/* <Editor
                      text={formInputs ? formInputs.description : ""}
                      data={gettypedData}
                      readOnly={
                        props.editData && props.editData.eye ? true : false
                      }
                    /> */}

                    {/* {formInputs && !formInputs.description && validated && (
                      <div className="err-feedback">This field is required</div>
                    )} */}
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <div className="d-flex justify-content-end mt-5">
              <Button
                variant="primary"
                type="submit"
                onClick={(e) => handleSubmit(e, "addLevel")}
              >
                {editData ? "Update" : "Save"} Program
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <AccessDenied />
      )}
      <ModalPop
        size="sm"
        class={"modal-padding"}
        show={preview}
        onHide={handlePre}
        modalcontent={previewModal()}
        modalhead="Preview Image"
      />

      <ModalPop
        size="sm"
        class={"modal-padding"}
        show={previewProgram}
        onHide={handleProgram}
        modalcontent={previewModalProgram()}
        modalhead="Preview Image"
      />
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
