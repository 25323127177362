import React from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function Table(props) {
  const { SearchBar } = Search;
  const searchBar = props.searchBar ? props.searchBar : null;

  const pagintnoptions = {
    // pageStartIndex: 0,
    sizePerPage: 15,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };

  const formatDatas = (initArr) => {
    let sn = 0;
    let filteredarray = [];
//  if(props.countPerPage==5){
    initArr.forEach((element) => {
      const offVal = props.countPerPage==5?(((props.offset-1)*5)+(sn+1)):(((props.offset-1)*10)+(sn+1));
      const offsetValue = (props.offset ? offVal : sn+1 )
      
      filteredarray.push({
        ...element,
        sno: offsetValue,
        
        // action: element.id,
      });
      sn++;
    });
  // } else
  // {
  //   initArr.forEach((element) => {
  //     const offVal = ((props.offset-1)*10)+(sn+1);
  //     const offsetValue = (props.offset ? offVal : sn+1 )
      
  //     filteredarray.push({
  //       ...element,
  //       sno: offsetValue,
        
  //       // action: element.id,
  //     });
  //     sn++;
  //   });
  // }
    return filteredarray;
  };
// console.log(props,'props')
  const data = formatDatas(props.data);
  const columns = props.columns;
  const filters = props.filters ? props.filters() : null;

  return (
    <div className="customTable mt-3">
      <ToolkitProvider keyField="sno" data={data} columns={columns} search>
        {(props) => (
          <div>
            <div className="d-flex align-items-center mb-4">
              <div
                className={`w-100 d-flex align-items-center ${
                  filters ? "justify-content-between" : "justify-content-end"
                }`}
              >
                 {searchBar && searchBar == "false" ? (
                  <></>
                ) : (
                  <div className="mr-3 ">
                    <SearchBar {...props.searchProps} />
                  </div>
                )}
                {filters ? filters : null}
              </div>
            </div>
            <BootstrapTable
              // noDataIndication="No Results Found"
              bordered={false}
              {...props.baseProps}
              bootstrap4={true}
              pagination={paginationFactory(pagintnoptions)}
              headerWrapperClasses="thead-dark"
              bodyClasses="tableBody"
              wrapperClasses="table-responsive"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
