
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt, } from "@fortawesome/free-solid-svg-icons";
import ImageBoxUploadCrop from "../../components/ImageBox/imageboxuploadcrop";
import {
  postMethod,
  postMethodFormData,
  getMethod,
  handleFalseStatus,
  findErrors,
  isNotLoading,
} from "../../Library";
import WorkoutFormMain from "./days/workoutformmain";
import AlertRedirect, { AlertBox } from "../alert";

export default React.memo(function ProgramBasedWorkoutForm(props) {
  const [currentPage, setCurrentPage] = useState(0);

  const [validated, setValidated] = useState(false);
  const [updateDraft, setUpdateDraft] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const [programOptions, setProgramOptions] = useState();
  const [weekOptions, setWeekOptions] = useState([]);
  const [formError, setFormError] = useState([]);
  const [programId, setProgramId] = useState();

  const mainWorkoutForm = useRef(null);

  const [exerciseList, setExerciseList] = useState([]);

  const [workoutMasters, setWorkoutMasters] = useState({
    workout_category_name: "Program Based Workout",
    workout_type: "program",
    program_ids: "",
    program_week: "",
    habit_week: "",
    image_urls: "",
    video_urls: [""],
    workout_name: "", // empty for program based
    choose_program_type: 0, // empty for program based
    workout_master_entry_status: "unpublished",
    what_to_do: [
      {
        title: "",
      },
    ],
    benefits: [
      {
        title: "",
      },
    ],
  });

  // defaults

  const workout_stretch_default_form = {
    workout_master_id: "",
    workout_id: "",
    description: "",
    image_url: "",
    workout_stretch_exercises: [
      {
        workout_master_id: "",
        workout_id: "",
        workout_stretch_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      },
    ],
  };

  const workout_circuit_default_form = {
    workout_master_id: "",
    workout_id: "",
    circuit_name: "",
    circuit_round: 1,
    description: "",
    image_url: "",
    workout_wk_exercises: [
      {
        workout_master_id: "",
        workout_id: "",
        workout_wk_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      },
    ],
  };

  const workout_warmup_default_form = {
    workout_master_id: "",
    workout_id: "",
    description: "",
    image_url: "",
    workout_warmup_exercises: [
      {
        workout_master_id: "",
        workout_id: "",
        workout_warmup_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      },
    ],
  };

  const initDefaultForm = (day) => {

    let obj = {
      workouts: {
        workout_day: day,
        workout_master_id: "",
        workout_name: "",
        description: "",
        image_url: "",
        workout_type: "program",
        workout_entry_status: "save draft",
      },
      workout_warmups: [workout_warmup_default_form],
      workout_wks: [workout_circuit_default_form],
      workout_stretchs: [workout_stretch_default_form],
    };
    return JSON.parse(JSON.stringify(obj));
  };

  const [workoutDayOne, setWorkoutDayOne] = useState();
  const [workoutDayTwo, setWorkoutDayTwo] = useState();
  const [workoutDayThree, setWorkoutDayThree] = useState();
  const [workoutDayFour, setWorkoutDayFour] = useState();
  const [workoutDayFive, setWorkoutDayFive] = useState();
  const [workoutDaySix, setWorkoutDaySix] = useState();
  const [workoutDaySeven, setWorkoutDaySeven] = useState();

  const history = useHistory();

  const sections = [
    { title: "Day 1", value: 1, targetState: setWorkoutDayOne },
    { title: "Day 2", value: 2, targetState: setWorkoutDayTwo },
    { title: "Day 3", value: 3, targetState: setWorkoutDayThree },
    { title: "Day 4", value: 4, targetState: setWorkoutDayFour },
    { title: "Day 5", value: 5, targetState: setWorkoutDayFive },
    { title: "Day 6", value: 6, targetState: setWorkoutDaySix },
    { title: "Day 7", value: 7, targetState: setWorkoutDaySeven },
  ];

  const changeFormSteps = (e) => {
    e.preventDefault();
    setCurrentPage(parseInt(e.target.value));
  };

  const scrollToForm = () => {
    let el = document.getElementById("stepForm");
    let topPos = el.offsetTop;
    if (topPos > 100)
      window.scrollTo({
        top: topPos - 100,
        left: 0,
        behavior: "smooth",
      });
  };

  const next = () => {
    const va = validateWorkoutMaster();
    if (va) {
      if (currentPage <= 5) {
        setCurrentPage((prev) => prev + 1);
        scrollToForm();
      }
    } else {
      props.showToastFn(
        "Please fill in the required Program, Week(s) and Habit."
      );
    }
  };

  const prev = () => {
    setCurrentPage((prev) => prev - 1);
    scrollToForm();
  };

  const getExerciseList = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setExerciseList(newData.data);
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  };

  const checkFormatDays = (day) => {
    let data = Object.assign({}, day);
    if (data?.workout_stretchs?.length == 0) {
      data?.workout_stretchs?.push(workout_stretch_default_form);
    }
    if (data?.workout_warmups?.length == 0) {
      data?.workout_warmups?.push(workout_warmup_default_form);
    }
    if (data?.workout_wks?.length == 0) {
      data?.workout_wks?.push(workout_circuit_default_form);
    }
    return data;
  };

  // for duplicate remove the ids
  function rmvIds(day) {
    if (day) {
      // delete main workout id
      delete day?.workouts.id;
      // delete warm up workout id
      let warmup = day.workout_warmups[0];
      if (day.workout_warmups.length > 0) {
        delete warmup?.id;
        if (warmup.workout_warmup_exercises.length > 0) {
          warmup.workout_warmup_exercises.map((item) => {
            delete item?.id;
            return item;
          });
        }
      }
      // delete workout circuits id
      let workout_ct = day.workout_wks;
      if (workout_ct.length > 0) {
        workout_ct.map((obj) => {
          delete obj?.id;
          obj.workout_wk_exercises.map((item) => {
            delete item?.id;
            return item;
          });
          return obj;
        });
      }
      // delete workout stretch id
      let workout_stretchs = day.workout_stretchs[0];
      if (day.workout_stretchs.length > 0) {
        delete workout_stretchs?.id;
        if (workout_stretchs.workout_stretch_exercises.length > 0) {
          workout_stretchs.workout_stretch_exercises.map((item) => {
            delete item?.id;
            return item;
          });
        }
      }
    }
  }

  const setDefaultWorkoutForm = () => {
    sections.forEach((item) => {
      item.targetState(initDefaultForm(item.value));
    });
  };

  useEffect(() => {
    ProgramWeeks();
  }, [programId]);

  const checkIfEdit = async () => {
    setLoaded(false);
    if (props.editData && props.editData.workout_master_id) {
      const data = {
        url: "workout_by_id",
        body: {
          workout_master_id: props.editData.workout_master_id,
        },
      };
      const newData = await postMethod(data);
      if (newData.status == 1) {
        isNotLoading();
        const data = newData.data;
        var a = data.workout_masters.program_ids;
        a = a.toString();

        setProgramId(parseInt(a));
        setWorkoutMasters({
          ...data.workout_masters,
          video_urls:
            !data.workout_masters?.video_urls ||
              data.workout_masters?.video_urls?.length == 0
              ? [""]
              : data.workout_masters?.video_urls,
        });

        // set the form inputs for each day
        fillFormInputs(data, true);
        setLoaded(true);
      } else {
        isNotLoading();
        AlertRedirect(
          "Error!! Unable to load Data",
          "Please try again or if the error persists create a new workout",
          history,
          "manage-workout"
        );
      }
    } else {
      setDefaultWorkoutForm();
      setLoaded(true);
    }
  };

  function fillFormInputs(data, defaultSet) {
    let cpyObj = Object.assign({}, data);

    let day_1 = cpyObj?.day_1;
    let day_2 = cpyObj?.day_2;
    let day_3 = cpyObj?.day_3;
    let day_4 = cpyObj?.day_4;
    let day_5 = cpyObj?.day_5;
    let day_6 = cpyObj?.day_6;
    let day_7 = cpyObj?.day_7;

    if (day_1) {
      setWorkoutDayOne(checkFormatDays(day_1));
    } else {
      if (defaultSet) {
        setWorkoutDayOne(initDefaultForm(1));
      }
    }
    if (day_2) {
      setWorkoutDayTwo(checkFormatDays(day_2));
    } else {
      if (defaultSet) {
        setWorkoutDayTwo(initDefaultForm(2));
      }
    }
    if (day_3) {
      setWorkoutDayThree(checkFormatDays(day_3));
    } else {
      if (defaultSet) {
        setWorkoutDayThree(initDefaultForm(3));
      }
    }
    if (day_4) {
      setWorkoutDayFour(checkFormatDays(day_4));
    } else {
      if (defaultSet) {
        setWorkoutDayFour(initDefaultForm(4));
      }
    }
    if (day_5) {
      setWorkoutDayFive(checkFormatDays(day_5));
    } else {
      if (defaultSet) {
        setWorkoutDayFive(initDefaultForm(5));
      }
    }
    if (day_6) {
      setWorkoutDaySix(checkFormatDays(day_6));
    } else {
      if (defaultSet) {
        setWorkoutDaySix(initDefaultForm(6));
      }
    }
    if (day_7) {
      setWorkoutDaySeven(checkFormatDays(day_7));
    } else {
      if (defaultSet) {
        setWorkoutDaySeven(initDefaultForm(7));
      }
    }
  }

  function createWorkoutFn(day_forminput, status) {
    
    const va = validateWorkoutMaster();
    if (va) {
      workoutMasters.workout_master_entry_status =
        status == "draft" ? "unpublished" : "published";
      day_forminput.workouts.workout_entry_status =
        status == "draft" ? "save draft" : "published";

      const body = {
        workout_masters: {
          ...workoutMasters,
        },
        days: [day_forminput],
      };
      saveUpdateWorkout(body,status);
    }
  }

  async function saveUpdateWorkout(body,status) {
    if(status=='draft'){
      setUpdateDraft(true)
    }
    const data = {
      url: "workout_create_or_update",
      body: body,
    };
    const newData = await postMethod(data);
    props.showToastFn(newData.message);
    if (newData.status == 1) {
      if(status==='draft'){
        setUpdateDraft(false)
      }
      isNotLoading();
      if (currentPage == 6) {
        history.push({
          pathname: '/manage-workout/',
          appState: {
            name: "program",
          }
        });
      } else {
        setWorkoutMasters((prev) => ({
          ...prev,
          id: newData.resp_data.workout_masters.id,
        }));
        if (newData?.resp_data) {
          fillFormInputs(newData.resp_data, false);
        }
        scrollToForm();
      }
    }
    else {
      isNotLoading();
    }
  }

  useEffect(() => {
    getChooseProgram("choose_workout_master_program");
    getExerciseList("workout_exercise_list");
    checkIfEdit();
  }, []);

  const getChooseProgram = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);

    if (newData.data && newData.data.programs) {
      isNotLoading();
      setProgramOptions(newData.data.programs);
    }
    else {
      isNotLoading();
    }
  };

  const gettypedData = (data, index) => {
    setWorkoutMasters((prev) => ({
      ...prev,
      [index]: data,
    }));
  };

  const handleInput = (e) => {
    setWorkoutMasters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const rmvAssets = (idx, key) => {
    let form = Object.assign({}, workoutMasters);
    let cpArr = form[key];
    cpArr.splice(idx, 1);
    setWorkoutMasters({ ...form });
  };

  const handleInputVideo = (e, idx) => {
    e.preventDefault();
    let value = e.target.value;
    let form = Object.assign({}, workoutMasters);
    let video_urls = form.video_urls;
    video_urls[idx] = value;
    setWorkoutMasters((prev) => ({ ...prev, video_urls: [...video_urls] }));
  };

  const addVideoUrl = (e) => {
    e.preventDefault();
    let form = Object.assign({}, workoutMasters);
    form.video_urls.push("");
    setWorkoutMasters(form);
  };

  const handleMultiUploads = async (e) => {
    e.preventDefault();
    const name = e.target.name;
    let file = e.target.files;
    let len = file.length;
    let urls = [];
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        let formDataBody = new FormData();
        formDataBody.append("image_url", file[i]);
        const data = {
          url: "image_upload",
          body: formDataBody,
        };
        const newData = await postMethodFormData(data);
        if (newData.status == 1) {
          isNotLoading();
          urls.push(newData.data.image_url);
        } else {
          isNotLoading();
          let msg = `Error uploading file ${file[i].name}. ${newData.message}`;
          props.showToastFn(msg);
        }
      }
      let prevUrls =
        workoutMasters?.image_urls?.length > 0 ? workoutMasters.image_urls : [];
      setWorkoutMasters((prev) => ({
        ...prev,
        image_urls: [...prevUrls, ...urls],
      }));
    }
  };

  const handleHabbitImage = (url) => {
    let prevUrls = workoutMasters?.image_urls?.length > 0 ? workoutMasters.image_urls : [];
    setWorkoutMasters((prev) => ({
      ...prev,
      image_urls: [url],
    }));
  }

  const ChooseWeekFn = (e) => {
    setWorkoutMasters((prev) => ({
      ...prev,
      program_week: e.target.value,
    }));
  };

  const ChooseProgramFn = (e) => {
    let value = e.target.value;
    setProgramId(value);
    setWorkoutMasters((prev) => ({
      ...prev,
      program_ids: value,
    }));
  };

  const ProgramWeeks = async () => {
    const data = {
      url: "program_weeks",
      body: {
        program_id: programId,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setWeekOptions(newData.data.program_weeks);
    } else {
      isNotLoading();

      setWeekOptions([]);
    }
  };

  const validateWorkoutMaster = () => {
    setValidated(true);
    let errStatus =
      mainWorkoutForm.current.checkValidity() &&
      workoutMasters?.program_week?.length > 0;
    if (!errStatus) {
      props.showToastFn(
        "Please fill in the required Program, Week(s) and Habit."
      );
    }
    return errStatus;
  };

  const handleWhatToDoInputProgram = (e) => {
    let name = e.target.name;
    let child = e.target.getAttribute("childid");
    let val = e.target.value ? e.target.value : "";
    const values = { ...workoutMasters };
    values.what_to_do[child][name] = val;
    setWorkoutMasters({ ...workoutMasters });
  };

  const handleAddWhatToDoFields = (e, index) => {
    const values = { ...workoutMasters };
    workoutMasters?.what_to_do.push({
      title: "",
    });
    setWorkoutMasters(values);
  };

  const handleRemoveWhatToDoFields = (e) => {
    let index = e.target.getAttribute("dataid");

    const values = { ...workoutMasters };
    workoutMasters?.what_to_do.splice(index, 1);
    setWorkoutMasters(values);
  };

  // benefits
  const handleBenefitsInputProgram = (e) => {
    let name = e.target.name;
    let child = e.target.getAttribute("childid");
    let val = e.target.value ? e.target.value : "";
    const values = { ...workoutMasters };
    values.benefits[child][name] = val;
    setWorkoutMasters({ ...workoutMasters });
  };

  const handleAddBenefitsFields = (e, index) => {
    const values = { ...workoutMasters };
    workoutMasters?.benefits.push({
      title: "",
    });
    setWorkoutMasters(values);
  };

  const handleRemoveBenefitsFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...workoutMasters };
    workoutMasters?.benefits.splice(index, 1);
    setWorkoutMasters(values);
  };

  return (
    <div>
      {loaded ? (
        <Form
          noValidate
          validated={validated}
          ref={mainWorkoutForm}
          className="workout_form masterWorkouform"
        >
          <Row>
            <Col sm="6" md="4" lg="4">
              <Form.Label className="required">Choose Program</Form.Label>
              <Form.Control
                as="select"
                name="program"
                required
                value={
                  workoutMasters?.program_ids?.length > 0
                    ? workoutMasters?.program_ids
                    : ""
                }
                disabled={props.editData && !props.editData?.edit}
                onChange={ChooseProgramFn}
              >
                <option value="">Select a program</option>
                {programOptions &&
                  programOptions.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.program_name}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Col>

            <Col sm="6" md="4" lg="4" className="customSelect">
              <Form.Label className="required">Choose a Week</Form.Label>
              <Form.Control
                as="select"
                name="weeks"
                required
                value={
                  workoutMasters.program_week ? workoutMasters.program_week : ""
                }
                onChange={ChooseWeekFn}
                disabled={
                  props.editData && !props.editData?.edit ? true : false
                }
              >
                <option value="">Select a week</option>
                {weekOptions &&
                  weekOptions.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                This field is required
              </Form.Control.Feedback>
            </Col>
          </Row>

          <h5 className="text-active mt-3">Habit of the Week</h5>
          <Form.Group controlId="habit" className="mt-3 mb-3 position-relative">
            <Form.Label className="required">Overview</Form.Label>
            <Form.Control
              as="textarea"
              name="habit_week"
              rows={3}
              readOnly={
                props?.editData && props?.editData?.editAccess === false
                  ? true
                  : false
              }
              required
              value={workoutMasters?.habit_week}
              onChange={handleInput}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
          </Form.Group>

          <div className="habbit-workout-Box mb-5">
            {workoutMasters.what_to_do && workoutMasters.what_to_do.length > 0 && (
              <>
                <Form.Label> What to do* </Form.Label>
                {workoutMasters.what_to_do.map((item, index, arr) => (
                  <div key={index}>
                    <Form.Row
                      className="align-items-end form-m-0 mb-4 instr-content"
                      key={index}
                    >
                      <Col md="10">
                        <Form.Group
                          controlId={`workout-m-break-${index}-${index}-01`}
                          className="mb-0"
                        >

                          <Form.Control
                            type="text"
                            name="title"
                            min="1"
                            placeholder={`Instruction point ${parseInt(index) + 1}`}
                            childid={index}
                            value={item.title}
                            onChange={handleWhatToDoInputProgram}
                            required
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="instr-err-msg"
                          >
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col
                        md="1"
                        className={`text-right pb-3 align-self-center pt-3`}
                      >
                        {index == 0 && arr.length == 1 && (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            dataid={index}
                            supdataid={index}
                            onClick={(e) =>
                              handleAddWhatToDoFields(e, index)
                            }
                            className="font-20 cursor-pointer"
                          />
                        )}
                        {arr.length > 0 && arr.length - 1 != index && (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            dataid={index}
                            supdataid={index}
                            onClick={handleRemoveWhatToDoFields}
                            className="font-20 cursor-pointer"
                          />
                        )}
                        {arr.length - 1 == index && index != 0 && (
                          <>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              dataid={index}
                              supdataid={index}
                              onClick={(e) =>
                                handleAddWhatToDoFields(e, index)
                              }
                              className="font-20 mr-2 cursor-pointer"
                            />
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              dataid={index}
                              supdataid={index}
                              onClick={handleRemoveWhatToDoFields}
                              className="font-20 cursor-pointer"
                            />
                          </>
                        )}
                      </Col>
                    </Form.Row>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* benifits */}
          <div className="habbit-workout-Box mb-5">
            {workoutMasters.benefits && workoutMasters.benefits.length > 0 && (
              <>
                <Form.Label> Benefits* </Form.Label>
                {workoutMasters.benefits.map((item, index, arr) => (
                  <div key={index}>
                    <Form.Row
                      className="align-items-end form-m-0 mb-4 instr-content"
                      key={index}
                    >
                      <Col md="10">
                        <Form.Group
                          controlId={`workout-m-break-${index}-${index}-01`}
                          className="mb-0"
                        >

                          <Form.Control
                            type="text"
                            name="title"
                            min="1"
                            placeholder={`Benefits point ${parseInt(index) + 1}`}
                            childid={index}
                            value={item.title}
                            onChange={handleBenefitsInputProgram}
                            required
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="instr-err-msg"
                          >
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col
                        md="1"
                        className={`text-right pb-3 align-self-center pt-3`}
                      >
                        {index == 0 && arr.length == 1 && (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            dataid={index}
                            supdataid={index}
                            onClick={(e) =>
                              handleAddBenefitsFields(e, index)
                            }
                            className="font-20 cursor-pointer"
                          />
                        )}
                        {arr.length > 0 && arr.length - 1 != index && (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            dataid={index}
                            supdataid={index}
                            onClick={handleRemoveBenefitsFields}
                            className="font-20 cursor-pointer"
                          />
                        )}
                        {arr.length - 1 == index && index != 0 && (
                          <>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              dataid={index}
                              supdataid={index}
                              onClick={(e) =>
                                handleAddBenefitsFields(e, index)
                              }
                              className="font-20 mr-2 cursor-pointer"
                            />
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              dataid={index}
                              supdataid={index}
                              onClick={handleRemoveBenefitsFields}
                              className="font-20 cursor-pointer"
                            />
                          </>
                        )}
                      </Col>
                    </Form.Row>
                  </div>
                ))}
              </>
            )}
          </div>

          <Row>
            <Col sm="5" className="imgBx position-relative">
              <Form.Group controlId="image_url_mst" className="position-relative">
                <Form.Label>Habit (375*291)</Form.Label>
                <ImageBoxUploadCrop
                  folder="program"
                  as_w="375"
                  as_h="291"
                  dataid=""
                  data={handleHabbitImage}
                  image_url={workoutMasters ? workoutMasters?.image_urls : ""}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <Form.Label>
            <label className="mr-0 d-inline-block">Upload Videos</label>
            <Button
              variant="link"
              disabled={
                props?.editData && props?.editData?.editAccess === false
                  ? true
                  : false
              }
              onClick={addVideoUrl}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="font-20" />
            </Button>
          </Form.Label> */}
          {/* <div className="row">
            {workoutMasters?.video_urls?.length > 0 &&
              workoutMasters?.video_urls.map((item, i, arr) => {
                return (
                  <div className="col-sm-4 videoBoxs progForm" key={i}>
                    <Form.Group
                      controlId={`video_urls-${i}`}
                      className="position-relative"
                    >
                      <Form.Control
                        name="video_urls"
                        type="text"
                        placeholder="Video URL"
                        value={item}
                        onChange={(e) => handleInputVideo(e, i)}
                      />
                      {arr.length > 1 && (
                        <span
                          className="close"
                          onClick={() => rmvAssets(i, "video_urls")}
                        >
                          &#10006;
                        </span>
                      )}
                    </Form.Group>
                  </div>
                );
              })}
          </div> */}
        </Form>
      ) : (
        <h5 className="mt-3">Loading... Please wait</h5>
      )}

      <div id="stepForm" className="stepForm mt-3">
        <Row className="stepNumbersBox mt-5 mb-3">
          {sections.map((item, idx) => (
            <Col key={idx}>
              <Row className="flex-column position-relative stepNumbers">
                <Col className="num text-center">
                  <Button
                    onClick={changeFormSteps}
                    value={idx}
                    currentbtn={currentPage == idx ? "true" : "false"}
                    variant="link"
                  >
                    {idx + 1}
                  </Button>
                </Col>
                <Col className="txt">{item.title}</Col>
              </Row>
            </Col>
          ))}
        </Row>

        <div className={currentPage === 0 ? "d-block" : "d-none"}>
          {workoutDayOne ? (
            <WorkoutFormMain
              day={1}
              formInputs={workoutDayOne}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              showToastFn={props.showToastFn}
              updateDraft={updateDraft}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>

        <div className={currentPage === 1 ? "d-block" : "d-none"}>
          {workoutDayTwo ? (
            <WorkoutFormMain
              day={2}
              formInputs={workoutDayTwo}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>

        <div className={currentPage === 2 ? "d-block" : "d-none"}>
          {workoutDayThree ? (
            <WorkoutFormMain
              day={3}
              formInputs={workoutDayThree}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>
        <div className={currentPage === 3 ? "d-block" : "d-none"}>
          {workoutDayFour ? (
            <WorkoutFormMain
              day={4}
              formInputs={workoutDayFour}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>
        <div className={currentPage === 4 ? "d-block" : "d-none"}>
          {workoutDayFive ? (
            <WorkoutFormMain
              day={5}
              formInputs={workoutDayFive}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>
        <div className={currentPage === 5 ? "d-block" : "d-none"}>
          {workoutDaySix ? (
            <WorkoutFormMain
              day={6}
              formInputs={workoutDaySix}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>
        <div className={currentPage === 6 ? "d-block" : "d-none"}>
          {workoutDaySeven ? (
            <WorkoutFormMain
              day={7}
              formInputs={workoutDaySeven}
              exerciseList={exerciseList}
              currentPage={currentPage}
              sections={sections.length}
              editData={props.editData}
              prev={prev}
              next={next}
              setFormErrorState={setFormError}
              createWorkoutFn={createWorkoutFn}
              validateWorkoutMaster={validateWorkoutMaster}
              updateDraft={updateDraft}
              showToastFn={props.showToastFn}
            />
          ) : (
            <h5 className="mt-3">Loading... Please wait</h5>
          )}
        </div>
      </div>
    </div>
  );
});
