import React, { useEffect, useState } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { PrivateRoute } from "./Library";
import Header from "./components/header";
import LoginPage from "./pages/login";
import ForgetpswdPage from "./pages/forgetpswd";
import ResetpswdPage from "./pages/resetpswd";
import NotfoundPage from "./pages/notfound";
import DashboardPage from "./pages/dashboard";
import RecipesPage from "./pages/recipes";
import WeeklyMenuPage from "./pages/weeklymenu";
import WeeklyMenuAdd from "./pages/weeklymenuadd";
import DietLibraryPage from "./pages/dietlibrary";
import DietPlanPage from "./pages/dietplan";
import AdminUsersPage from "./pages/adminusers";
import RegisteredUsersPage from "./pages/registeredusers";
import ExercisePage from "./pages/exercise";
import NutritionistPage from "./pages/nutritionist";
import WorkoutPage from "./pages/workout";
import WorkoutAddPage from "./pages/workoutadd";
import ProgramsPage from "./pages/programs";
import AddProgramsPage from "./pages/addprograms";
import AddProgramsLevelsPage from "./pages/addprogramslevels";
import ContentsPage from "./pages/content";
import EquipmentList from "./pages/equipment";
import CategoryLists from "./pages/categoriesPage";
import OnBoardingContent from "./pages/onBoarding";
import PromotionalContent from "./pages/promotionalcontent";
import PrivacyPolicy from "./pages/privacy";
import TermsConditionPage from "./pages/termscondition";
import ProgramCategoryPage from "./pages/programcategory";
import NotificationsPage from "./pages/notifications";
import AddDietPlan from "./pages/dietPlanAdd";
import DietLibraryAdd from "./pages/dietlibraryadd";
import DietConditions from "./pages/dietconditions";
import CuisinesPage from "./pages/cuisines";
import WorkoutOnboardingPage from "./pages/workoutonboarding";
import PlayerPage from "./pages/player";
import WorkoutCategory from "./pages/workoutcategory";
import AppHistory from "./pages/appHistory";

export default function App() {
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMenuList = () => {
    let menu = JSON.parse(localStorage.getItem("ct8_kn1_Mn"));
    if (menu && menuList.length == 0 && loading) {
      setMenuList(menu);
    }
  };

  useEffect(() => {
    setLoading(true);
    return () => {
      setLoading(false);
      setMenuList([]);
    };
  }, []);

  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <LoginPage getMenuList={getMenuList} {...props} />
            )}
          />
          <Route
            exact
            path="/forgot-password"
            component={withRouter(ForgetpswdPage)}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={withRouter(ResetpswdPage)}
          />
          <Route
            exact
            path="/player/:id/:videoid"
            component={withRouter(PlayerPage)}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(DashboardPage)}
          />
          <PrivateRoute
            exact
            path="/manage-admin-users"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(AdminUsersPage)}
          />
          <PrivateRoute
            exact
            path="/manage-registered-users"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(RegisteredUsersPage)}
          />
          <PrivateRoute
            exact
            path="/manage-nutritionist"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(NutritionistPage)}
          />
          <PrivateRoute
            exact
            path="/manage-programs"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(ProgramsPage)}
          />
          <PrivateRoute
            exact
            path="/manage-programs/add-program/"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(AddProgramsPage)}
          />
          <PrivateRoute
            exact
            path="/manage-programs/add-program/add-levels/:id/:cat"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(AddProgramsLevelsPage)}
          />
          <PrivateRoute
            exact
            path="/manage-exercise"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(ExercisePage)}
          />
          <PrivateRoute
            exact
            path="/manage-workout"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WorkoutPage)}
          />
          <PrivateRoute
            exact
            path="/manage-workout/add-workout/"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WorkoutAddPage)}
          />
          <PrivateRoute
            exact
            path="/manage-recipes"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(RecipesPage)}
          />
          <PrivateRoute
            exact
            path="/manage-weekly-menu"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WeeklyMenuPage)}
          />
          <PrivateRoute
            exact
            path="/manage-weekly-menu/add-weekly-menu/"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WeeklyMenuAdd)}
          />
          <PrivateRoute
            exact
            path="/manage-diet-library"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(DietLibraryPage)}
          />
          <PrivateRoute
            exact
            path="/manage-diet-library/add-diet-library/"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(DietLibraryAdd)}
          />
          <PrivateRoute
            exact
            path="/manage-diet-plan"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(DietPlanPage)}
          />
          <PrivateRoute
            exact
            path="/manage-diet-plan/add-diet-plan"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(AddDietPlan)}
          />
          <PrivateRoute
            exact
            path="/manage-guidelines"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(DietConditions)}
          />
          <PrivateRoute
            exact
            path="/manage-content"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(ContentsPage)}
          />
          <PrivateRoute
            exact
            faq-blog-category
            path="/equipments"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(EquipmentList)}
          />
          <PrivateRoute
            exact
            path="/cuisines-diet-type"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(CuisinesPage)}
          />
              <PrivateRoute
            exact
            path="/workout-category"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WorkoutCategory)}
          />
          <PrivateRoute
            exact
            path="/faq-blog-category"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(CategoryLists)}
          />
          <PrivateRoute
            exact
            path="/onboarding-content"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(OnBoardingContent)}
          />
          <PrivateRoute
            exact
            path="/promotional-content"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(PromotionalContent)}
          />
          <PrivateRoute
            exact
            path="/mobile-app-history"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(AppHistory)}
          />
          <PrivateRoute
            exact
            path="/workout-onboarding"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(WorkoutOnboardingPage)}
          />
          <PrivateRoute
            exact
            path="/privacy-policy"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(PrivacyPolicy)}
          />
          <PrivateRoute
            exact
            path="/terms-conditions"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(TermsConditionPage)}
          />
          <PrivateRoute
            exact
            path="/program-category"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(ProgramCategoryPage)}
          />
          <PrivateRoute
            exact
            path="/notifications"
            menuList={menuList}
            getMenuList={getMenuList}
            component={withRouter(NotificationsPage)}
          />
          {/* remove during production */}

          <Route path="*" component={NotfoundPage} />
        </Switch>
      </Router>
    </>
  );
}

