import React from "react";
import { localUrl } from "./configs";
import { Route, Redirect, useLocation } from "react-router-dom";
import SideBar from "../components/sidebar";

export const PrivateRoute = ({
  component: Component,
  getMenuList,
  menuList,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      CheckToken() ? (
        <>
          <main className="dashboardMain">
            <SideBar menuList={menuList} getMenuList={getMenuList} />
            <Component
              menuList={menuList}
              getMenuList={getMenuList}
              {...props}
            />
          </main>
        </>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export const CheckToken = () => {
  var token = localStorage.getItem("YbpWWDicin_token");
  if (token) {
    return true;
  }
  return false;
};

export const tableDefault = 10000;

const menuList = {
  1: "/dashboard",
  2: "/manage-admin-users",
  3: "/manage-registered-users",
  4: "/manage-nutritionist",
  5: "/manage-programs",
  6: "/manage-exercise",
  7: "/manage-workout",
  8.1: "/manage-recipes",
  8.2: "/manage-weekly-menu",
  8.3: "/manage-diet-library",
  8.4: "/manage-diet-plan",
  9.1: "/program-category",
  9.2: "/equipments",
  9.3: "/cuisines-diet-plan",
  9.4: "/faq-blog-category",
  9.5: "/onboarding-content",
  9.6: "/terms-conditions",
  9.7: "/privacy-policy",
  9.8: "/notifications",
  9.9: "/workout-onboarding",
  9.11: "/workout-category",
  9.12: "/promotional-content",
};

export const MenuAccess = (path) => {
  let userMenu = JSON.parse(localStorage.getItem("ct8_kn1_Mn"));
  let firstMenu = JSON.parse(localStorage.getItem("ct8_kn1_Mn"))[0];
  let findMenuArr = Object.keys(menuList).filter(
    (item) => menuList[item] === path
  );
  if (!userMenu.includes(parseInt(findMenuArr[0]))) {
    window.location.href = menuList[firstMenu];
  }
};

export const MenuRedirect = (first) => {
  return menuList[first];
};

export const MenuRedirectFirst = () => {
  let firstMenu = JSON.parse(localStorage.getItem("ct8_kn1_Mn"))[0];
  return menuList[firstMenu];
};

// api calls

export const isLoading = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};
export const isNotLoading = () => {
  if (
    document.querySelector("#pageLoader") &&
    document.querySelector("#pageLoader").classList.contains("d-block")
  ) {
    document.querySelector("#pageLoader").classList.remove("d-block");
  }
};
const validateStatusCode = async (response) => {
  if (response.status === 403) {
    localStorage.clear();
    const errObj = await response.json();
    alert(errObj.error);
    window.location.href = "/";
  } else {
    isNotLoading();
    return response.json();
  }
};
export const postMethod = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  isLoading();
  // console.log("ApiPost fetch before", data);
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    // isNotLoading();
    return validateStatusCode(response)
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const postMethodNoLoader = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiPost fetch before", data);
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    return validateStatusCode(response)

  } catch (err) {
    handleCatchErr(err);
    // return err;
  }
};

export const postMethodFormData = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiPost fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      body: data.body,
    });
    isNotLoading();
    return validateStatusCode(response)

  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
    // return err;
  }
};

export const putMethod = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiPut fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data.body),
    });
    isNotLoading();
    return validateStatusCode(response)

  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const deleteMethod = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiDelete fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "Delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    });
    isNotLoading();
    
    return validateStatusCode(response)

   } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const getMethod = async (data) => {
  const id = localStorage.getItem("YbpWWDicin_token");
  // console.log("token", id);
  const token = id ? id : "";
  // console.log("ApiGt fetch before", data);
  isLoading();
  try {
    const response = await fetch(localUrl + data.url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json",
      },
    });
    isNotLoading();
    return validateStatusCode(response)

  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const getTableData = async (url) => {
  isLoading();
  const data = {
    url: url,
    body: {
      length: tableDefault,
      start: 0,
      draw: tableDefault,
    },
  };
  try {
    const newData = await postMethod(data);

    if (newData.status == 1 && newData.data) {
      isNotLoading();
      return newData.data;
    } else if (newData.status === false) {
      handleFalseStatus(newData);
      isNotLoading();
    }
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const getTableDataGet = async (url) => {
  isLoading();
  const data = {
    url: url,
    body: {
      length: tableDefault,
      start: 0,
      draw: tableDefault,
    },
  };
  try {
    const newData = await getMethod(data);
    if (newData.status == 1 && newData.data) {
      isNotLoading();
      return newData.data;
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};
export const postTableDataGet = async (url) => {
  isLoading();
  const data = {
    url: url,
    body: {
      length: tableDefault,
      start: 0,
      draw: tableDefault,
    },
  };
  try {
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data) {
      isNotLoading();
      return newData.data;
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const getTableDataDraw = async (url, draw) => {
  isLoading();
  const data = {
    url: url,
    body: {
      length: draw && draw >= tableDefault ? draw : tableDefault,
      start: 0,
      draw: draw && draw >= tableDefault ? draw : tableDefault,
    },
  };
  try {
    const newData = await postMethod(data);
    
    if (newData.status == 1 && newData.data) {
      isNotLoading();
      return newData.data;
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const getTableDatawithFilter = async (url, name, status) => {
  isLoading();
  let obj = {
    length: tableDefault,
    start: 0,
    draw: tableDefault,
  };
  if (name) {
    obj.nutritionist_first_name = name;
  }
  if (status) {
    obj.nutritionist_status = status;
  }
  const data = {
    url: url,
    body: obj,
  };
  try {
    const newData = await postMethod(data);
    isNotLoading();
    if (newData.status == 1 && newData.data) {
      return newData.data;
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    }
  } catch (err) {
    isNotLoading();
    handleCatchErr(err);
  }
};

export const copyObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

// export const copyObjD = (obj) =>
//   obj
//     ? Object.prototype.toString.call(obj) === "[object Object]"
//       ? jQuery.extend(true, {}, obj)
//       : JSON.parse(JSON.stringify(obj))
//     : obj;

// required fields
export const validateInputs = (obj) => {
  const items = Object.keys(obj);
  const errors = {};
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors[key] = key + " is required";
    }
  });
  return errors;
};

export const findErrors = (obj) => {
  console.log(obj,'obj')
  const items = Object.keys(obj);
  const errors = [];
  items.map((key) => {
    const value = obj[key];

    if( key === "equipments"){
      value.map((item,index)=>{

      // if(item.id){
        if(item.equipment_id){
          item.instruction.map((item,index) => {
            if(!item.title){
              errors.push("addTitle")
            }
          })
        }

        
      // } 

      })
    }

    if (!value) {
      errors.push(key);
    }
  });
  // console.log("errors", errors);
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const findErrorsWeek = (obj) => {
  const items = Object.keys(obj);
  const errors = [];
  items.map((key) => {
    const value = obj[key];
    if (!value) {
      errors.push(key);
    } else if (value && value <= 0) {
      errors.push(key);
    }
  });
  // console.log("errors", errors);
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const findErrorsArr = (obj) => {
  const items = Object.keys(obj);
  const errors = [];
  items.map((key) => {
    const value = obj[key];
    if (value.length == 0) {
      errors.push(key);
    }
  });
  // console.log("errors arry", errors);
  if (errors.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const AccessDenied = () => {
  return (
    <div>
      <h3>Oops! Seems like you don't have Access to this page.</h3>
      <h5 className="mt-4 mb-3">
        How to get access to this page ? Check the instructions given below
      </h5>
      <ol className="pl-5" type="1">
        <li>Contact your administrator to revoke your access privileges.</li>
        <li>Logout and Login again using your credentials.</li>
      </ol>
    </div>
  );
};

export const handleCatchErr = (err) => {
  // console.log("error ", err);
  // localStorage.clear();
  // window.alert(err);
  // window.location.href = "/";
};

export const handleFalseStatus = (data) => {
  // console.log("error ", data.error);
  localStorage.clear();
  window.alert(data.error);
  window.location.href = "/";
};
