import React, { Component } from "react";
import ReactPlayer from "react-player";
import { Row, Col } from "react-bootstrap";
// import Table from "./table";
import Duration from "./duration";
import { postMethodNoLoader } from "../../Library";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default class Index extends Component {
  state = {
    url: `https://player.vimeo.com/video/${this.props.videoId}`,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    workoutName: "",
    instructions: "",
  };

  componentDidMount() {
    document.querySelector("body").classList.add("videoPage");
    // document.getElementById("refere").click();

    // this.handlePlay();
    this.getWorkoutDetails();
  }

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  getWorkoutDetails = async () => {
    const data = {
      url: "workout_plan_list",
      body: {
        workout_id: this.props.workoutId,
      },
    };
    const newData = await postMethodNoLoader(data);
    if (newData.status == 1) {
      // console.log(newData);
      
      const workoutName = newData.data[0].workouts.workout_name;
      const instructions = newData.data[0].workouts.description;
      this.setState({ workoutName: workoutName, instructions: instructions });
    }
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    // console.log("onPlay");
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    // console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    // console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    // console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    // console.log("onProgress", state);
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }

    if (state.loaded > 0) {
      if (this.state.muted) {
        // this.setState({ muted: false });
        // document.getElementById("refere").click();
        // this.handlePlay();
      }
    }
  };

  handleEnded = () => {
    // console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    // console.log("onDuration", duration);
    this.setState({ duration });
  };

  //   handleClickFullscreen = () => {
  //     screenfull.request(findDOMNode(this.player));
  //   };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
      workoutName,
      instructions,
    } = this.state;
    const SEPARATOR = " · ";
    return (
      <div className="pageWrapper">
        <div className="player-wrapper">
          <ReactPlayer
            ref={this.ref}
            className="react-player"
            width="100%"
            height="100%"
            url={url}
            pip={pip}
            playing={playing}
            controls={controls}
            light={light}
            loop={loop}
            autoPlay={true}
            byline="false"
            playsinline={true}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onReady={() => console.log("onReady")}
            onStart={() => console.log("onStart")}
            onPlay={this.handlePlay}
            onEnablePIP={this.handleEnablePIP}
            onDisablePIP={this.handleDisablePIP}
            onPause={this.handlePause}
            onBuffer={() => console.log("onBuffer")}
            onSeek={(e) => console.log("onSeek", e)}
            onEnded={this.handleEnded}
            onError={(e) => console.log("onError", e)}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
          />
        </div>
        <Row className="m-0 mt-3 align-items-center">
          <Col xs={8} className="pl-0">
            <h5 className="mb-1">{workoutName}</h5>
            <p className="mb-0">Reps</p>
          </Col>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-end pr-0"
          >
            <CircularProgressbar
              value={(played * 100).toFixed(3)}
              text={
                (duration * (1 - played)).toFixed() > 0
                  ? (duration * (1 - played)).toFixed()
                  : "0"
              }
            />
            {/* <Duration seconds={duration} /> */}
          </Col>
        </Row>

        <Row className="m-0 mt-3 align-items-center">
          <Col className="pl-0">
            <h5>Instructions</h5>
            <p>{instructions}</p>
          </Col>
        </Row>
        {/* <Table /> */}
      </div>
    );
  }
}
