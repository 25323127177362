import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import {
  Button,
  Form,
  Toast,
  Row,
  Col,
  FormControl,
  Tab,
  Tabs,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../components/tables/table";
import ReactPaginate from "react-paginate";

import {
  getMethod,
  postMethod,
  getTableData,
  tableDefault,
  AccessDenied,
  isNotLoading,
} from "../Library";

// import _ from "lodash";

import { ModalPopMd } from "../components/modals";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faEye,
  faPen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../components/Dropdown";
import WorkoutTable from "../components/workoutTable/challengeTable";
import ChallengeTable from "../components/workoutTable/challengeTable";
import ProgramBasedTable from "../components/workoutTable/programBasedTable";
import QuickTable from "../components/workoutTable/quickTable";

export default function WorkoutPage(props) {
  const program_key = "program";
  const quick_key = "quick";
  const challenge_key = "challenge";

  const [key, setKey] = useState(program_key);
  const [tableList, setTableList] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [filterList, setFilterList] = useState();
  const [filterArr, setFilterArr] = useState({
    program_name: "",
    program_category: "",
    goal: "",
    activity_level: "",
  });
  // const [filterArrUpdated, setFilterArrUpdated] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedWeeks, setSelectedWeeks] = useState();
  const [selectedID, setSelectedID] = useState();
  const [duplicateID, setDuplicateID] = useState();
  const [weekOptions, setWeekOptions] = useState();
  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [search, setSearch] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };
  const closeModal = () => {
    setModalShow(false);
    setSelectedWeeks();
    setSelectedOptions();
    setDuplicateID();
    setSelectedID();
  };

  const openModal = (e) => {
    const id = e.target.getAttribute("dataid");
    setDuplicateID(id);
    setModalShow(true);
  };
  useEffect(() => {
    ProgramWeeks();
    if(props.location){
      if(props.location.appState){
    setKey(props.location.appState.name)
      }
    }else{
      setKey("program")
    }
  }, [selectedID]);

  useEffect(() => {
    console.log(selectedWeeks, "weeels");
  }, [selectedWeeks]);

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        arr.push(item.value);
      });
    return arr;
  }
  // const getSelections = (e) => {
  //   let arr = [];
  //   let value = e.target.value;
  //   arr.push(value);
  //   setSelectedOptions(e.target.value);
  //   // console.log(arr, "aaaaaa");
  //   setSelectedWeeks(arr);
  // };
  const getSelections = (data) => {
    setSelectedOptions(data);
    setSelectedWeeks(convert(data));
  };
  // const getWeekList = async () => {
  //   const data = {
  //     url: "static_content_list",
  //   };
  //   const newData = await getMethod(data);
  //   if (newData.status == 1) {
  //     let arr = newData.data.program_weeks.map((item) => ({
  //       name: item,
  //     }));
  //     console.log(arr, "arr");
  //     setWeekOptions(arr);
  //   }
  // };

  const ProgramWeeks = async () => {
    const data = {
      url: "program_weeks",
      body: {
        program_id: selectedID,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setWeekOptions(arr);
    } else {
      setWeekOptions([]);
      isNotLoading();

    }
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };

  const modalBody = () => {
    return (
      <>
        <div>
          <Form.Group>
            <Form.Label className="required">Choose Week(s)</Form.Label>
            <Dropdown
              getSelections={getSelections}
              name="week-list"
              options={weekOptions}
              isDisabled={false}
              value={selectedOptions}
            />

            {/* <Form.Control
              as="select"
              name="week"
              // value={filterArr.goal}
              onChange={getSelections}
            >
              <option value="">Select Week</option>
              {weekOptions &&
                weekOptions.length > 0 &&
                weekOptions.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
            </Form.Control> */}
          </Form.Group>
          <Form.Group>
            <Form.Label className="required">Choose Program</Form.Label>
            <Form.Control
              as="select"
              name="program_id"
              value={selectedID}
              onChange={(e) => {
                setSelectedID(e.target.value);
              }}
            >
              <option value="" disabled>
                Select a Program
              </option>
              {filterList &&
                filterList?.all_program_name?.length > 0 &&
                filterList.all_program_name.map((item, index) => (
                  <option value={item.id} key={index}>
                    {item.program_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <small className="mt-3">
            Note: This will create a duplicate workout based on the above
            selected week(s) and program.
          </small>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={dupWorkout}
            disabled={
              !selectedOptions ||
              selectedOptions?.length == 0 ||
              !selectedID ||
              selectedID?.length == 0
            }
          >
            Submit
          </Button>
        </div>
      </>
    );
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-workout/add-workout/`,
            state: {
              workout_master_id: row.id,
              workout_category_name: row.workout_category_name,
              editAccess: false,
              edit: false,
              duplicate: false,
            },
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
        |
        <Link
          variant="link"
          to={{
            pathname: `/manage-workout/add-workout/`,
            state: {
              workout_master_id: row.id,
              workout_category_name: row.workout_category_name,
              edit: false,
              duplicate: false,
            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        |
        {row.workout_category_name == "Program Based Workout" && (
          <>
            <Button
              variant="link"
              dataid={row.id}
              onClick={(e) => {
                openModal(e);
                setSelectedID(row.program_ids[0]);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Button>
            |
          </>
        )}
        <Button variant="link" dataid={row.id} onClick={trashWorkout}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const dupWorkout = async () => {
    const data = {
      url: "duplicate_workout_by_id",
      body: {
        workout_master_id: parseInt(duplicateID),
        program_week: selectedWeeks,
        program_id: parseInt(selectedID),
      },
    };
    // console.log("dpdata ", data);
    const newData = await postMethod(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();

      closeModal();
      resetFilters();
    }
    else{
      isNotLoading();
    }
  };

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "workout_delete",
      body: {
        id: id,
      },
    };
    // console.log("delete ", id);

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if(newData&&newData.status == 1){
            isNotLoading();

            showToastFn(newData.message);
            getTableList();}
            else{
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "workout_category_name", text: "Workout Category" },
    // { dataField: "program_category_name", text: "Program Category" },
    { dataField: "program_name", text: "Program Name" },
    { dataField: "category_name", text: "Program Category" },
    { dataField: "goal", text: "Goal" },
    { dataField: "activity_level", text: "Activity Level" },
    {
      dataField: "program_week",
      text: "Week",
      classes: "colNoWrap",
    },
    { dataField: "workout_master_entry_status", text: "Status" },
    {
      dataField: "id",
      text: "Action",
      formatter: btnFormatter,
      classes: "colNoWrap text-right",
    },
  ];

  const getTableList = async () => {
    let tableList = await getTableFilterList();
    if (tableList && tableList.workouts) {
      setTableList(tableList.workouts);
    }
    // console.log("loop running 1");
  };
  // const getTableDataList = async (url) => {
  //   isLoading();
  //   const data = {
  //     url: url,
  //     body: {
  //       length: 10,
  //       start: 0,
  //       draw: 10,
  //       // filter: filter,

  //     },
  //   };
  //   try {
  //     const newData = await postMethod(data);
  //     isNotLoading();
  //     if (newData.status == 1 && newData.data) {
  //       return newData.data;
  //     } else if (newData.status === false) {
  //       handleFalseStatus(newData);
  //     }
  //   } catch (err) {
  //     isNotLoading();
  //     handleCatchErr(err);
  //   }
  // };
  const getFilterList = async (filter) => {
    const data = {
      url: "workout_filter_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1 && newData.data) {
      setFilterList(newData.data);
      isNotLoading();

    }
    else{
      isNotLoading();
    }
  };

  const getTableFilterList = async () => {
    const filters = {
      program_name: filterArr.program_name ? filterArr.program_name : "",
      program_category: filterArr.program_category
        ? filterArr.program_category
        : "",
      goal: filterArr.goal ? filterArr.goal : "",
      activity_level: filterArr.activity_level ? filterArr.activity_level : "",
    };
    const data = {
      url: "workout_list",
      body: {
        search: { value: search },
        length: 10,
        start: offset == 0 ? 0 : (offset - 1) * 10,
        draw: 10,
        filter: filters,
      },
    };
    const newData = await postMethod(data);
    // showToastFn(newData.message);
    if (newData.status == 1) {
      if (newData.data.workouts) {
      isNotLoading();
        setTableList(newData.data.workouts);
        setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      } else {
        setTableList([]);
      isNotLoading();

      }
    }
    // console.log("loop running 2");
  };

  const handleFilters = async (e) => {
    const val = e.target.value;
    const name = e.target.name;
    setFilterArr((prev) => ({ ...prev, [name]: val }));
    setOffset(1);
  };

  const resetFilters = async (e) => {
    setFilterArr({
      program_name: "",
      program_category: "",
      goal: "",
      activity_level: "",
    });
    getTableFilterList();
    setOffset(1);
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // getTableList();
      getFilterList();
      // getWeekList();
      getTableFilterList();
    }
  }, [pageLoaded, setPageLoaded, filterArr, offset, search]);
  useEffect(() => {
    console.log(key,'key')
  }, [key])
  
  return (
    <section className="rightContent">
      {props.menuList.includes(7) ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <h3 className="text-capitalize">Manage Workout</h3>
            <Link
              to="/manage-workout/add-workout/"
              className="h-100 ml-auto text-capitalize btn btn-primary"
            >
              Add Workout
            </Link>
          </div>

          <Tabs
            id="TabContainer"
            transition={false}
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey={program_key} title="Program Based Workout">
            {key=="program" ?
              <ProgramBasedTable active={key=="program"?true:false} />:null}
            </Tab>

            <Tab eventKey={challenge_key} title="Challenge Workout">
              {key=="challenge" ?
            <ChallengeTable active={key=="challenge"?true:false}/>:null}
            </Tab>

            <Tab eventKey={quick_key} title="Quick Workout">
            {key=="quick" ?
            <QuickTable active={key=="quick"?true:false}/>:null}
            </Tab>
          </Tabs>

        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
