import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import Editor from "../components/editor";
import Dropdown from "../components/Dropdown/dietPlan";
import {
  postMethod,
  getMethod,
  findErrors,
  AccessDenied,
  tableDefault,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import _ from "lodash";

import { useLocation, useHistory } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import AlertRedirect from "../components/alert";
import DropdownSingle from "../components/Dropdown/singledropdown";


export default function AddProgramsPage(props) {
  const [validated, setValidated] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [staticQuest, setStaticQuest] = useState([]);
  const [masterProgramID, setMasterProgramID] = useState();
  const [dietPlanList, setDietPlanList] = useState();
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedOptionsDietNormal, setSelectedOptionsDietNormal] = useState();
  const [selectedOptionsDietSpecial, setSelectedOptionsDietSpecial] = useState();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [dietPlanName, setDietPlanName] = useState("");

  const [formInputsNormal, setFormInputsNormal] = useState({
    diet_library_id: "",
    plan_type: "normal",
    goal: "",
    gender: "",
    guidelines: "",
    program_id: "",
    program_week: [],
    diet_type: [],
    conditions: "",
    display_name: '',
    plan_overview: '',
  });
  const [formInputsSpecial, setFormInputsSpecial] = useState({
    diet_library_id: "",
    plan_type: "special",
    special_diet_plan_name: "",
    goal: "",
    gender: "",
    diet_type: [],
    conditions: "",
    guidelines: "",
    duration_from: null,
    duration_to: null,
  });

  const [editData, setEditData] = useState();
  const [genderNormal, setGenderNormal] = useState();
  const [genderSpecial, setGenderSpecial] = useState();

  const [programOptions, setProgramOptions] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [weekOptions, setWeekOptions] = useState();

  const [dietValueNormal, setDietValueNormal] = useState();
  const [conditionsValueNormal, setConditionsValueNormal] = useState();
  const [dietValueSpecial, setDietValueSpecial] = useState();
  const [conditionsValueSpecial, setConditionsValueSpecial] = useState();
  const [mealPlan, setMealPlan] = useState();
  const [sevendayProgram, setSevendayProgram] = useState(false);
  const [dateInputs, setDateInputs] = useState({
    duration_from: null,
    v: null,
  });

  const [displayName, setDisplayName] = useState("");
  const [planOverview, setPlanOverview] = useState("");

  const location = useLocation();
  const history = useHistory();

  const handleMealPlan = (e) => {
    setValidated(false);
    setMealPlan(e.target.value);
  };

  const minTwoDigit = (n) => {
    return (n < 10 ? "0" : "") + n;
  };

  const formatDates = (date) => {
    const day = minTwoDigit(date.getDate());
    const month = minTwoDigit(date.getMonth() + 1);
    const year = date.getFullYear();
    let val = `${year}-${month}-${day}`;
    return val;
  };

  const nextDay = (offset) => {
    let x = new Date(new Date().getTime() + 24 * offset * 60 * 60 * 1000);
    return x;
  };

  const selectUploadDt = (dateSel, name) => {
    const date = new Date(`${dateSel}`);
    const chkDt = date.getDate();
    if (chkDt > 0) {
      let val = formatDates(date);
      setFormInputsSpecial((prev) => ({
        ...prev,
        [name]: val,
      }));
      setDateInputs((prev) => ({
        ...prev,
        [name]: dateSel,
      }));
    }
  };


  const handleInputNormal = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    const clearNormalForm = {
      diet_library_id: selectedOptionsDietNormal ? selectedOptionsDietNormal[0].id : '',
      plan_type: "normal",
      goal: "",
      gender: "",
      guidelines: "",
      program_week: [],
      diet_type: [],
      conditions: "",
    };
    var sevendayProgramId = document.querySelector("#normalprogram");
    var isSevendayProgram = sevendayProgramId.options[sevendayProgramId.selectedIndex].getAttribute('sevenday');
    if (parseInt(isSevendayProgram) === 1) {
      setSevendayProgram(true)
      setFormInputsNormal(clearNormalForm)
      setConditionsValueNormal()
    } else {
      setSevendayProgram(false)
    }


    if (name == "program_id") {
      if (value) {
        resetAll();
      } else {
        hardresetAll();
      }
    }
    if (name == "gender") {
      setGenderNormal(value);
      setFormInputsNormal((prev) => ({
        ...prev,
        conditions: "",
      }));
      setConditionsValueNormal([]);
    }
    if (name == "plan_type") {
      setValidated(false);
    }
    if (name == "diet_type") {
      value = [e.target.value];
    }
    setFormInputsNormal((prev) => ({
      ...prev,
      [name]: value,
    }));
  };



  const handleInputSpecial = (e) => {
    let value = e.target.value;
    const name = e.target.name;

    if (name == "gender") {
      setGenderSpecial(value);
      setFormInputsSpecial((prev) => ({
        ...prev,
        conditions: "",
      }));
      setConditionsValueSpecial([]);
    }

    if (name == "diet_type") {
      value = [e.target.value];
    }

    setFormInputsSpecial((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitNormal = (event, type) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    let submiForm = { ...formInputsNormal };

    if (sevendayProgram) {
      setValidated(false);
      const reqFields = _.pick(
        submiForm,
        "diet_library_id",
        "program_id"
      );
      const errors = findErrors(reqFields);
      if (!errors) {
        saveDiet(formInputsNormal);
      } else {
        setValidated(true);
      }
    } else {
      setValidated(true);
      const reqFields = _.pick(
        submiForm,
        "diet_library_id",
        "gender",
        "goal",
        "plan_type",
        "program_id",
        "display_name",
        "plan_overview",
      );

      const errors = findErrors(reqFields);
      if (
        !errors &&
        formInputsNormal?.diet_type?.length > 0 &&
        formInputsNormal?.program_week?.length > 0 &&
        formInputsNormal?.conditions?.length > 0
      ) {
        saveDiet(formInputsNormal);
      } else {
      }
    }


  };

  const checkIfDateValid = (fromDate, toDate) => {
    let output = false;
    if (fromDate && toDate) {
      let frmDt = new Date(fromDate).getTime();
      let toDt = new Date(toDate).getTime();
      if (toDt > frmDt) {
        output = true;
      } else {
        output = false;
      }
    } else {
      output = false;
    }
    return output;
  };

  const handleSubmitSpecial = (event, type) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    let submiForm = { ...formInputsSpecial };
    const reqFields = _.pick(
      submiForm,
      "diet_library_id",
      "gender",
      "goal",
      "plan_type"
    );

    const fromDate = submiForm.duration_from;
    const toDate = submiForm.duration_to;
    const dateValidation = checkIfDateValid(fromDate, toDate); // true if valid

    const errors = findErrors(reqFields);
    if (
      !errors &&
      formInputsSpecial.diet_type.length > 0 &&
      formInputsSpecial.conditions.length > 0 &&
      dateValidation
    ) {
      saveDiet(formInputsSpecial);
    } else {
    }
  };
  useEffect(() => {
    ProgramWeeks();
  }, [formInputsNormal.program_id]);

  const ProgramWeeks = async () => {
    const data = {
      url: "program_weeks",
      body: {
        program_id: formInputsNormal.program_id,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setWeekOptions(arr);
    } else {
      setWeekOptions([]);
      isNotLoading();
    }
  };
  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const saveDiet = async (formInputs) => {
    const data = {
      url: "meal_plan_create_or_update",
      body: formInputs,
    };
    const newData = await postMethod(data);
    showToastFn(newData.message);

    if (newData.status == 1) {
      isNotLoading();
      AlertRedirect(
        "Diet Saved Successfully !!",
        "Redirect back to Diet Plan ?",
        history,
        "manage-diet-plan"
      );
    }
    else {
      isNotLoading();
    }
  };

  const gettypedData = (data, index) => {
    if (index == "normal") {
      setFormInputsNormal((prev) => ({
        ...prev,
        guidelines: data,
      }));
    } else {
      setFormInputsSpecial((prev) => ({
        ...prev,
        guidelines: data,
      }));
    }
  };

  const getDietPlans = async () => {
    const data = {
      url: "diet_library_list",
      body: {
        length: tableDefault,
        start: 0,
        draw: tableDefault,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1 && newData.data && newData.data.diet_library) {
      isNotLoading();
      setDietPlanList(newData.data.diet_library);
    } else if (newData.status === false) {
      handleFalseStatus(newData);
      isNotLoading();
    } else {
      showToastFn(newData.message);
      isNotLoading();
    }
  };

  const getWeekList = async () => {
    const data = {
      url: "static_content_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setWeekOptions(arr);
    }
  };

  const checkIfEdit = () => {
    if (location.state && location.state.id) {
      setEditData(location.state.id);
      getEditData(location.state.id);
    }
  };

  const getEditData = async (id) => {
    const data = {
      url: "meal_plan_by_id",
      body: {
        id: id,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setMealPlan(newData.data.meal_plan.plan_type);
      if (newData.data.meal_plan.plan_type == "normal") {
        if (newData.data.meal_plan.is_free_program === 1) {
          setSevendayProgram(true)
        } else {
          setSevendayProgram(false)
        }
        setFormInputsNormal(newData.data.meal_plan);
        setGenderNormal(newData.data.meal_plan.gender);
        setSelectedOptionsDietNormal(
          formatSelectValuesDiet(newData.data.meal_plan.selected_diet_plan)
        );
        setConditionsValueNormal(
          formatSelectValues(newData.data.meal_plan.conditions)
        );
      } else {
        isNotLoading();
        setFormInputsSpecial(newData.data.meal_plan);
        setDietValueSpecial(
          formatSelectValues(newData.data.meal_plan.diet_type)
        );
        setGenderSpecial(newData.data.meal_plan.gender);
        setSelectedOptionsDietSpecial(
          formatSelectValuesDiet(newData.data.meal_plan.selected_diet_plan)
        );
        setConditionsValueSpecial(
          formatSelectValues(newData.data.meal_plan.conditions)
        );
        setDateInputs({
          duration_from: new Date(newData.data.meal_plan.duration_from),
          duration_to: new Date(newData.data.meal_plan.duration_to),
        });
      }
    }
    else {
      isNotLoading();
    }
  };

  const getQuestions = async () => {
    const data = {
      url: "static_qus_ans",
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setStaticQuest(newData.data.satic_qus_ans);
    }
    else {
      isNotLoading();
    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getQuestions();
      getChooseProgram();
      getDietPlans();
      getWeekList();

      checkIfEdit();
    }
  }, [pageLoaded, setPageLoaded]);


  const getChooseProgram = async () => {
    const data = {
      url: "choose_workout_master_program",
    };
    const newData = await getMethod(data);

    if (newData.data && newData.data.programs) {
      isNotLoading();
      setProgramOptions(newData.data.programs);
    }
    else {
      isNotLoading();
    }
  };


  const getProgramCategory = async (id) => {
    if (id) {
      const data = {
        url: "choose_workout_program_category",
        body: {
          master_program_ids: [id],
        },
      };
      const newData = await postMethod(data);

      if (newData.data && newData.data.length > 0) {
        isNotLoading();
        let arr = [];
        newData.data.forEach((item) => {
          arr.push({
            name: item.categories.name,
            program_category_id: item.categories.id,
            master_program_id: item.id,
          });
        });
        setCategoryOptions(arr);
      } else {
        isNotLoading();
        setCategoryOptions([]);
      }
    }
  };

  function resetWeek() {
    setFormInputsNormal((prev) => ({
      ...prev,
      program_week: [],
    }));
  }

  function resetPrgCat() {
    setFormInputsNormal((prev) => ({
      ...prev,
      program_category_id: "",
    }));
  }

  function resetAll() {
    setFormInputsNormal((prev) => ({
      ...prev,
      program_week: [],
    }));
    setSelectedOptions();
  }

  function hardresetAll() {
    setFormInputsNormal((prev) => ({
      ...prev,
      program_week: [],
    }));
    setSelectedOptions();
    setCategoryOptions([]);
  }

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.value);
      });
    return arr;
  }

  const getSelections = (data) => {
    setSelectedOptions(data);
    setFormInputsNormal((prev) => ({
      ...prev,
      program_week: convert(data),
    }));
  };

  const getSelectionsNormal = (data, name) => {
    if (name == "diet") {
      setDietValueNormal(data);
      let arr = convert(data);
      setFormInputsNormal((prev) => {
        return { ...prev, diet_type: [...arr] };
      });
    }
    if (name == "conditions") {
      setConditionsValueNormal(data);
      let arr = convert(data);
      setFormInputsNormal((prev) => {
        return { ...prev, conditions: [...arr] };
      });
    }
    if (name == "diet_library_id") {
      setSelectedOptionsDietNormal(data);
      setFormInputsNormal((prev) => {
        return { ...prev, [name]: data?.length > 0 ? data[0].id : "" };
      });
    }
  };

  const getSelectionsSpecial = (data, name) => {
    if (name == "diet") {
      setDietValueSpecial(data);
      let arr = convert(data);
      setFormInputsSpecial((prev) => {
        return { ...prev, diet_type: [...arr] };
      });
    }
    if (name == "conditions") {
      setConditionsValueSpecial(data);
      let arr = convert(data);
      setFormInputsSpecial((prev) => {
        return { ...prev, conditions: [...arr] };
      });
    }
    if (name == "diet_library_id") {
      setSelectedOptionsDietSpecial(data);
      setFormInputsSpecial((prev) => {
        return { ...prev, [name]: data?.length > 0 ? data[0].id : "" };
      });
    }
  };

  const handleDietPlan = (e) => {
    let value = e.target.value;
    setFormInputsSpecial((prev) => ({
      ...prev,
      special_diet_plan_name: value,
    }));
  };
  const handleProgramName = (e) => {
    let value = e.target.value;
    setFormInputsSpecial((prev) => ({
      ...prev,
      program_id: value,
    }));
  };
  const formatSelectValues = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          value: item,
          label: item,
          name: item,
        });
      });
    }

    return finalArr;
  };

  const formatSelectValuesDiet = (data) => {
    let finalArr = [];
    if (data && data?.diet_plan_name) {
      finalArr.push({
        value: data?.diet_plan_name,
        label: data?.diet_plan_name,
        name: data?.diet_plan_name,
        id: data?.id,
      });
    }

    return finalArr;
  };

  const handleInputDisplayName = (e) => {
    setDisplayName(e.target.value);
    setFormInputsNormal((prev) => ({
      ...prev,
      display_name: e.target.value,
    }));
  }

  const handleInputPlanOverview = (e) => {
    setPlanOverview(e.target.value);
    setFormInputsNormal((prev) => ({
      ...prev,
      plan_overview: e.target.value,
    }));
  }

  return (
    <section className="rightContent">
      {props.menuList.includes(8.4) ? (
        <>
          <h3 className="mb-5">{editData ? "Edit" : "Add"} Plan</h3>
          <Form noValidate validated={validated}>
            <Row className="mb-4">
              <Col md="2">
                <Form.Group controlId="01" className="mb-0 fontBlacks">
                  <Form.Check
                    type="radio"
                    name="plan_type"
                    required
                    checked={mealPlan == "normal" ? true : false}
                    disabled={editData ? true : false}
                    value="normal"
                    label="Diet Meal Plan"
                    onChange={handleMealPlan}
                  />
                </Form.Group>
              </Col>
              <Col md="8">
                <Form.Group controlId="02" className="mb-0 fontBlacks">
                  <Form.Check
                    type="radio"
                    name="plan_type"
                    required
                    checked={mealPlan == "special" ? true : false}
                    disabled={editData ? true : false}
                    value="special"
                    label="Special meal/plan recommendations"
                    onChange={handleMealPlan}
                  />
                </Form.Group>
              </Col>
            </Row>

            {validated && !mealPlan && (
              <div className="err-feedback">This Field is required</div>
            )}


            <div className={mealPlan == "normal" ? "d-block" : "d-none"}>
              <div className="row mt-3">
                <div className="col-md-4">
                  <Form.Group
                    controlId="diet_library_id"
                    className="customSelect"
                  >
                    <Form.Label className="required">
                      Choose a Diet plan name
                    </Form.Label>
                    <DropdownSingle
                      options={dietPlanList}
                      value={selectedOptionsDietNormal}
                      getSelections={getSelectionsNormal}
                      name="diet_library_id"
                    />
                    {validated && formInputsNormal?.diet_library_id == "" && (
                      <div className="err-feedback">This Field is required</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <Row className="mb-3">
                <Col sm="6" md="4" lg="4">
                  <Form.Label className="required">Choose Program</Form.Label>
                  <Form.Control
                    id="normalprogram"
                    as="select"
                    name="program_id"
                    required
                    value={
                      formInputsNormal.program_id
                        ? formInputsNormal.program_id
                        : ""
                    }
                    disabled={editData ? true : false}
                    onChange={handleInputNormal}
                  >
                    <option value="">Choose Program</option>
                    {programOptions &&
                      programOptions.map((item, index) => (
                        <option key={index} value={item.id} sevenday={item.is_free_program}>
                          {item.program_name}
                        </option>
                      ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Col>



                <Col sm="6" md="4" lg="4" className="customSelect form-group">
                  <Form.Label className="required">Choose Week(s)</Form.Label>
                  {location.state && location.state.id ? (
                    <Form.Control
                      as="select"
                      name="program_week"
                      disabled={editData ? true : false}
                      required
                      value={
                        formInputsNormal.program_week
                          ? formInputsNormal.program_week
                          : ""
                      }
                    >
                      <option value="">Choose Week</option>
                      {weekOptions &&
                        weekOptions.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Control>
                  ) : (
                    <>
                      <Dropdown
                        getSelections={getSelections}
                        name="conditions-list"
                        options={weekOptions}
                        isDisabled={sevendayProgram}
                        value={selectedOptions}
                      />
                      {validated && !sevendayProgram &&
                        formInputsNormal?.program_week?.length == 0 && (
                          <div className="err-feedback">
                            This field is required
                          </div>
                        )}
                    </>
                  )}
                </Col>
              </Row>
              <div className="row">
                {staticQuest &&
                  staticQuest.map((item, index) => (
                    <div className="px-3" key={index}>
                      {(item.question_type == "goal" ||
                        item.question_type == "gender") && (
                          <Form.Group
                            className="mw-200 mr-3"
                            controlId={`ctrl-${index}`}
                          >
                            <Form.Label className="required text-capitalize">
                              {item.question_type}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name={item.question_type}
                              onChange={handleInputNormal}
                              value={
                                formInputsNormal
                                  ? formInputsNormal[item.question_type]
                                  : ""
                              }
                              required
                              disabled={sevendayProgram}
                            >
                              <option value="">
                                Select a {item.question_type}
                              </option>
                              {item.answer
                                ? item.answer.map((item, index) => (
                                  <option value={item.title} key={index}>
                                    {item.title}
                                  </option>
                                ))
                                : genderNormal && genderNormal == "Female"
                                  ? item.female.answer.map((item, index) => (
                                    <option value={item.title} key={index}>
                                      {item.title}
                                    </option>
                                  ))
                                  : genderNormal && genderNormal == "Male"
                                    ? item.male.answer.map((item, index) => (
                                      <option value={item.title} key={index}>
                                        {item.title}
                                      </option>
                                    ))
                                    : null}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      {item.question_type == "diet" && (
                        <Form.Group
                          className="mw-300 mr-3"
                          controlId={`diet-${index}`}
                        >
                          <Form.Label className="required text-capitalize">
                            {item.question_type}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="diet_type"
                            required
                            value={
                              formInputsNormal.diet_type.length > 0
                                ? formInputsNormal.diet_type[0]
                                : ""
                            }
                            onChange={handleInputNormal}
                            disabled={sevendayProgram}
                          >
                            <option value="">
                              Select a {item.question_type}
                            </option>
                            {item.answer?.map((item, index) => (
                              <option key={index} value={item.title}>
                                {item.title}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {item.question_type == "conditions" && (
                        <Form.Group
                          className="mw-300 customSelect"
                          controlId={`conditions-${index}`}
                        >
                          <Form.Label className="required text-capitalize">
                            {item.question_type}
                          </Form.Label>
                          <Dropdown
                            options={
                              genderNormal
                                ? genderNormal == "Male"
                                  ? item.male.answer
                                  : item.female.answer
                                : null
                            }
                            value={conditionsValueNormal}
                            getSelections={getSelectionsNormal}
                            name="conditions"
                            isDisabled={sevendayProgram}
                          />
                          {validated && !sevendayProgram &&
                            (formInputsNormal?.conditions?.length == 0 ||
                              !formInputsNormal?.conditions) && (
                              <div className="err-feedback">
                                This Field is required
                              </div>
                            )}
                        </Form.Group>
                      )}
                    </div>
                  ))}
                <Col sm="5">
                  <Form.Group controlId="displayName">
                    <Form.Label className="required">Display Name</Form.Label>
                    <Form.Control
                      name="display_name"
                      type="text"
                      placeholder="Display Name"
                      value={formInputsNormal.display_name}
                      onChange={handleInputDisplayName}
                      required
                      disabled={sevendayProgram}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </div>

              <Row className="mb-4">
              <Col sm="11">
                <Form.Group
                  controlId="planOverview"
                  className="mt-3 mb-1"
                >
                  <Form.Label className="required">
                    Plan Overview
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="plan_overview"
                    placeholder="Plan Overview"
                    rows={3}
                    value={formInputsNormal?.plan_overview}
                    required
                    maxLength="400"
                    onChange={handleInputPlanOverview}
                    disabled={sevendayProgram}
                  />
                  <small className="float-right mt-1">
                    *Remaining character{" "}
                    {formInputsNormal?.plan_overview === (undefined || null) ? 400 : 400 - parseInt(formInputsNormal?.plan_overview?.length)}
                  </small>

                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            </div>

           
            <div className={mealPlan == "special" ? "d-block" : "d-none"}>
              <Row className="mt-3">
                <Col md="3">
                  <Form.Group
                    controlId="spl-diet_library_id"
                    className="customSelect customSelect_mwx"
                  >
                    <Form.Label className="required">
                      Choose a Diet plan name
                    </Form.Label>

                    <DropdownSingle
                      options={dietPlanList}
                      value={selectedOptionsDietSpecial}
                      getSelections={getSelectionsSpecial}
                      name="diet_library_id"
                    />
                    {validated && formInputsSpecial?.diet_library_id == "" && (
                      <div className="err-feedback">This Field is required</div>
                    )}


                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="spl-diet_duration">
                    <Form.Label className="required">Duration</Form.Label>
                    <Row>
                      <Col className="datePickerBox_Wk w-100 mwx-200">
                        <DatePicker
                          onChange={(date) =>
                            selectUploadDt(date, "duration_from")
                          }
                          selected={
                            dateInputs.duration_from
                              ? dateInputs.duration_from
                              : null
                          }
                          minDate={new Date()}
                          name="from"
                          dateFormat="yyyy/MM/dd"
                          placeholderText="From Date (Start)"
                          autoComplete="off"
                        />
                      </Col>
                      <Col className="datePickerBox_Wk w-100 mwx-200">
                        <DatePicker
                          onChange={(date) =>
                            selectUploadDt(date, "duration_to")
                          }
                          selected={
                            dateInputs.duration_to
                              ? dateInputs.duration_to
                              : null
                          }
                          minDate={nextDay(1)}
                          name="from"
                          dateFormat="yyyy/MM/dd"
                          placeholderText="To Date (End)"
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                    {validated &&
                      !checkIfDateValid(
                        formInputsSpecial.duration_from,
                        formInputsSpecial.duration_to
                      ) && (
                        <div className="err-feedback">
                          Invalid date combination
                        </div>
                      )}
                  </Form.Group>
                </Col>
                <Col md="3 diet_plan_assign">
                  <Form.Label className="required  ">Diet Plan Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="diet_plan_name"
                    placeholder="Diet Plan"
                    required
                    onChange={handleDietPlan}
                    value={
                      formInputsSpecial
                        ? formInputsSpecial.special_diet_plan_name
                        : ""
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <div className="row">
                {staticQuest &&
                  staticQuest.map((item, index) => (
                    <div className="px-3" key={index}>
                      {(item.question_type == "goal" ||
                        item.question_type == "gender") && (
                          <Form.Group
                            className="mw-200 mr-3"
                            controlId={`spl-ctrl-${index}`}
                          >
                            <Form.Label className="required text-capitalize">
                              {item.question_type}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              name={item.question_type}
                              onChange={handleInputSpecial}
                              value={
                                formInputsSpecial
                                  ? formInputsSpecial[item.question_type]
                                  : ""
                              }
                              required
                            >
                              <option value="">
                                Select a {item.question_type}
                              </option>
                              {item.answer
                                ? item.answer.map((item, index) => (
                                  <option value={item.title} key={index}>
                                    {item.title}
                                  </option>
                                ))
                                : genderSpecial && genderSpecial == "Female"
                                  ? item.female.answer.map((item, index) => (
                                    <option value={item.title} key={index}>
                                      {item.title}
                                    </option>
                                  ))
                                  : genderSpecial && genderSpecial == "Male"
                                    ? item.male.answer.map((item, index) => (
                                      <option value={item.title} key={index}>
                                        {item.title}
                                      </option>
                                    ))
                                    : null}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      {item.question_type == "diet" && (
                        <Form.Group
                          className="customSelect mw-300 mr-3"
                          controlId={`spl-diet-${index}`}
                        >
                          <Form.Label className="required text-capitalize">
                            {item.question_type}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="diet_type"
                            required
                            value={
                              formInputsSpecial
                                ? formInputsSpecial.diet_type[0]
                                : ""
                            }
                            onChange={handleInputSpecial}
                          >
                            <option value="">
                              Select a {item.question_type}
                            </option>
                            {item.answer?.map((item, index) => (
                              <option key={index} value={item.title}>
                                {item.title}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {item.question_type == "conditions" && (
                        <Form.Group
                          className="customSelect mw-300"
                          controlId={`spl-conditions-${index}`}
                        >
                          <Form.Label className="required text-capitalize">
                            {item.question_type}
                          </Form.Label>
                          <Dropdown
                            options={
                              genderSpecial
                                ? genderSpecial == "Male"
                                  ? item.male.answer
                                  : item.female.answer
                                : null
                            }
                            value={conditionsValueSpecial}
                            getSelections={getSelectionsSpecial}
                            name="conditions"
                          />
                          {validated &&
                            (formInputsSpecial?.conditions?.length == 0 ||
                              !formInputsSpecial?.conditions) && (
                              <div className="err-feedback">
                                This Field is required
                              </div>
                            )}
                        </Form.Group>
                      )}
                    </div>
                  ))}

                {/* CHOOSE PROGRAM */}

                <Col sm="6" md="4" lg="4">
                  <Form.Label >Choose Program</Form.Label>
                  <Form.Control
                    as="select"
                    name="program_id"
                    value={
                      formInputsSpecial ? formInputsSpecial.program_id : ""
                    }
                    disabled={editData ? true : false}
                    onChange={handleProgramName}
                  >
                    <option value="">Choose Program</option>
                    {programOptions &&
                      programOptions.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.program_name}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </div>


            </div>

            {mealPlan && (
              <div className="d-flex justify-content-end mt-5">
                <Link
                  to="/manage-diet-plan/"
                  className="mr-3 btn btn-secondary"
                >
                  Cancel
                </Link>
                {mealPlan == "normal" && (
                  <Button
                    variant="primary"
                    type="submit"
                    data="normal"
                    onClick={(e) => handleSubmitNormal(e, "addLevel")}
                  >
                    {editData ? "Update" : "Save"}
                  </Button>
                )}
                {mealPlan == "special" && (
                  <Button
                    variant="primary"
                    type="submit"
                    data="special"
                    onClick={(e) => handleSubmitSpecial(e, "addLevel")}
                  >
                    {editData ? "Update" : "Save"}
                  </Button>
                )}
              </div>
            )}

            {!mealPlan && <h5>Choose a meal plan and get started !!</h5>}
          </Form>

          <div className="empty_space30"></div>
          <div className="empty_space30"></div>
          <div className="empty_space30"></div>
          <div className="empty_space30"></div>
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
