import React, { useEffect, useState } from "react";
import { Button, Form, Toast, Row, Col, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../components/tables/table";

import {
  getMethod,
  postMethod,
  getTableData,
  tableDefault,
  AccessDenied,
  isNotLoading,
  isLoading,
} from "../Library";
import _, { isArray } from "lodash";
import {
  faPlus,
  faMinus,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ModalPop, { ModalPopMd } from "../components/modals";
import ReactPaginate from "react-paginate";

export default function ProgramsPage(props) {
  const [tableList, setTableList] = useState([]);
  const [weekData, setWeekData] = useState();
  const [showToast, setShowToast] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [weekModal, setWeekModal] = useState(false);
  const [filterListCategory, setFilterListCategory] = useState();
  const [filterListProgram, setFilterListProgram] = useState();
  const [filterArr, setFilterArr] = useState({});

  const [offset, setOffset] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  // new 

  useEffect(() => {
    if (pageLoaded) {
      getFilterList();
      getDynamicWeeks()
      getTableFilterList();
    }
  }, [pageLoaded, setPageLoaded, filterArr, offset, search]);

  const getTableList = async () => {
    let tableList = await getTableFilterList();
    if (tableList && tableList.program) {
      setTableList(tableList.program);
    }
  };

  const getTableFilterList = async () => {
    const filters = {
      program_name: filterArr.program ? filterArr.program : null,
    };
    const filterResult = _.pickBy(
      filters,
      (v) => !_.isUndefined(v) && !_.isNull(v)
    )
    const data = {
      url: "program_list",
      body: {
        search: { value: search },
        length: 10,
        start: offset == 0 ? 0 : (offset - 1) * 10,
        draw: 10,
        ...filterResult,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      if (newData.data.program) {
        isNotLoading();
        setTableList(newData.data.program);
        setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      } else {
        setTableList([]);
        isNotLoading();
      }
    }
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };


  // end
  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-programs/add-program/`,
            state: {
              id: row.id,
              program_name: row.program_name,
              description: row.description,
              from_week: row.from_week,
              to_week: row.to_week,
              image_url: row.image_url,
              listing_page_image1_url: row.listing_page_image1_url,
              goal: row.goal,
              activity_level: row.activity_level,
              out_of_water: row.out_of_water,
              out_of_step: row.out_of_step,
              program_edit: !row?.program_edit,
              listing_page_image2_url: row.listing_page_image2_url,
              equipments: row.equipments,
              image_overlay_description: row.image_overlay_description,
              is_copy: false,

            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        |
        <Link
          variant="link"
          to={{
            pathname: `/manage-programs/add-program/`,
            state: {
              old_program_id: row.id,
              program_name: row.program_name,
              // category_name: row.category_name,
              description: row.description,
              from_week: row.from_week,
              to_week: row.to_week,
              image_url: row.image_url,
              listing_page_image1_url: row.listing_page_image1_url,
              // icon_image_url: row.icon_image_url,
              goal: row.goal,
              activity_level: row.activity_level,
              out_of_water: row.out_of_water,
              out_of_step: row.out_of_step,
              program_edit: !row?.program_edit,
              listing_page_image2_url: row.listing_page_image2_url,
              equipments: row.equipments,
              image_overlay_description: row.image_overlay_description,
              is_copy: true,

            },
          }}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Link>
        |
        <Form.Check
          className="ml-2 d-inline-block"
          type="switch"
          name="is_nutritionist"
          id={`toggle-switch-prog-${row.id}`}
          dataid={row.id}
          dataname={row.program_name}
          value={row?.program_status}
          checked={row?.program_status == "Published" ? true : false}
          onChange={handleProgStatus}
        />
        |
        <Button variant="link" dataid={row.id} onClick={trashClient} disabled={row?.program_status == "Published" ? true : false}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const handleProgStatus = async (e) => {
    let stat = e.target.value;
    let id = e.target.getAttribute("dataid");
    let prgName = e.target.getAttribute("dataname");
    const data = {
      url: "program_published_or_unpublished",
      body: {
        program_id: parseInt(id),
        program_status: stat == "Published" ? "Unpublished" : "Published",
      },
    };
    confirmAlert({
      title: `${stat == "Published" ? "Unpublish" : "Publish"
        } ${prgName} Program`,
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if (newData.status == 1) {
              isNotLoading();
              showToastFn(newData.message);
              getTableList();
            }
            else {
              isNotLoading();
              showToastFn(newData.message);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const trashClient = async (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "delete_program",
      body: {
        program_id: parseInt(id),
      },
    };
    confirmAlert({
      title: "Delete Program",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if (newData.status == 1) {
              isNotLoading();
              showToastFn(newData.message);
              getTableList();
            }
            else {
              showToastFn(newData.message);
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });

  };

  function btnFormatterLimitedEdition(cell, row, rowIndex) {
    return (
      <>
        <Form.Check
          type="checkbox"
          id={`toggle-switch-prf-${rowIndex}`}
          checked={row.limited_edition == 0 ? false : true}
          onChange={() => limitedEditionStatusChange(row)}
          className="ltdCheckBox"
        />
      </>
    );
  }

  function limitedEditionStatusChange(row) {
    const data = {
      url: `add_limited_edition_in_program`,
      body: {
        program_id: row.id,
        limited_edition: Number(!row.limited_edition)
      },
    };
    confirmAlert({
      title: `Confirm the action`,
      message: `Are you sure to ${row.limited_edition == 0 ? "add" : "remove"
        } ${row.program_name} as limited edition ?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              isNotLoading();
              getTableList();
            } else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "program_name", text: "Program Name" },
    { dataField: "goal", text: "Goal" },
    { dataField: "activity_level", text: "Activity Level" },
    { dataField: "program_status", text: "Status" },
    {
      dataField: "limited_edition",
      text: "Limited Edition",
      formatter: btnFormatterLimitedEdition,
    },
    {
      dataField: "action",
      text: "Action",
      classes: "colNoWrap",
      formatter: btnFormatter,
    },
  ];

  const getFilterList = async () => {
    let catArr = [];
    let progArr = [];
    const data1 = {
      url: "program_name_filter_list",
    };
    const progData = await getMethod(data1);
    if (progData.status == 1 && progData.data) {
      progData.data.forEach((item) => {
        progArr.push({
          id: item.id,
          name: item.program_name,
        });
      });
    }
    setFilterListProgram(progArr);
  };

  const handleWeekModal = () => {
    setWeekModal(true);
  }

  const getDynamicWeeks = async () => {
    const data = {
      url: "dynamic_week_show",
    };
    const newData = await getMethod(data);
    if (newData.status === 1) {
      setWeekData(newData.data)
    }
  };

  const changeWeek = async (val) => {
    const data = {
      url: "dynamic_week_update",
      body: {
        week_key: val
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      getDynamicWeeks();
      showToastFn(newData.message);

    }
    else {
      isNotLoading();
      showToastFn(newData.message);
    }
  };

  const handleFilters = async (e) => {
    const val = e.target.value;
    const name = e.target.name;
    setFilterArr((prev) => ({ ...prev, [name]: val }));
  };

  const closeModal = () => {
    setWeekModal(false)
  };

  const modalBody = () => {
    return (
      <>
        <div className="d-flex p-3 justify-content-center">
          <h5>From Week : <span className="bold">{weekData && weekData.first}</span></h5>
          <h5 className="ml-4">To Week : <span className="bold">{weekData && weekData.last}</span></h5>
          <FontAwesomeIcon icon={faPlus} className="mt-1 ml-4 cursor-pointer" onClick={() => { changeWeek(1) }} />
          <FontAwesomeIcon icon={faMinus} className="mt-1 ml-3 cursor-pointer" onClick={() => { changeWeek(0) }} />

        </div>
      </>
    );
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
    return () => {
      setPageLoaded(false);
    };
  }, []);


  const tableFilters = () => {
    return <></>;
  };


  return (
    <section className="rightContent">
      {props.menuList.includes(5) ? (
        <>
          <div className="d-flex align-items-center mb-5">
            <h3>Manage Programs</h3>
            <div className="ml-auto">
              <Button
                onClick={handleWeekModal}
                className="h-100  text-capitalize btn btn-primary"
              >
                Change Dynamic Week
              </Button>
              <Link
                to="/manage-programs/add-program/"
                className="h-100 ml-2 text-capitalize btn btn-primary"
              >
                Add Program
              </Link>
            </div>
          </div>
          <div className="w-100 d-flex align-items-center justify-content-between">
            <div className="pgm-search">
              <label class="search-label">
                <input
                  type="text"
                  aria-labelledby="search-bar-0-label"
                  class="form-control"
                  placeholder="Search"
                  onChange={handleSearchInput}
                />
              </label>
            </div>
            <div className="d-flex align-items-center">
              <Form.Group controlId="program" className="mb-0 mr-3 mw-200">
                <Form.Control as="select" name="program" onChange={handleFilters}>
                  <option value="">Filter By Program name</option>
                  {filterListProgram &&
                    filterListProgram.map((item, index) => (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>

          <Table
            data={tableList}
            columns={columns}
            filters={tableFilters}
            offset={offset}
            searchBar="false"
          />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
      <ModalPop
        show={weekModal}
        onHide={closeModal}
        modalcontent={modalBody()}
        modalhead="Dynamic Weeks"
      />
    </section>
  );
}
