import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Toast,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Table from "../components/tables/table";
import {
  postMethod,
  getMethod,
  AccessDenied,
  getTableDataGet,
  isNotLoading,
} from "../Library";
import TableSelect from "../components/tables/tableselectDraw";
import ReactPaginate from "react-paginate";
import ModalPop from "../components/modals";
import { confirmAlert } from "react-confirm-alert";
import { set } from "lodash-es";

function WorkoutCategory(props) {
  const [search, setSearch] = useState("");
  const [tableCount, setTableCount] = useState(0);
  const [tableDraw, setTableDraw] = useState(0);
  const [tableList, setTableList] = useState([]);
  const [offset, setOffset] = useState(1);
  const [filter, setFilter] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [editDataLoaded, setEditDataLoaded] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [modalHead, setModalHead] = useState();
  const [catType, setType] = useState("");
  const [validated, setValidated] = useState(false);
  const [categoryid, setCategoryId] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [perPage] = useState(10);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  useEffect(() => {
    props.getMenuList();
  }, []);

  const tableRef = useRef(null);
  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    setOffset(1);
  };
  const handleInput = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const getSelectedRow = (data) => {
    setSelectedRow(data);
  };
  const closeModal = () => {
    setModalShow(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    checkFormErrors();
  };
  const checkFormErrors = () => {
    if (formInputs.workout_category_name) {
      createCategory(formInputs);
    }
  };

  const createCategory = async (values) => {
    let submitInputs = {
      ...values,
      is_featured: "0",
      category_id: categoryid,


    };
    const data = {
      url: "workout_category_create_or_update",
      body: submitInputs,
    };
    // console.log(data);
    const newData = await postMethod(data);
    setShowToast(true);
    setToastMessage(newData.message);
    if (newData.status == 1) {
      isNotLoading();

      closeModal();
      getTableListCategory();
    } else {
      window.alert("Oops! Error Occured. Please try again!!");
      closeModal();
      isNotLoading();
    }
  };
  const getTableListCategory = async () => {
    let submitInputs = {
      length: 10,
      start: offset == 0 ? 0 : (offset - 1) * 5,
      draw: 10,
      search: { value: search },
    };
    const data = {
      url: "workout_category_list",
      body: submitInputs,
    };
    const newData = await postMethod(data);

    if (newData.status == 1) {
      setTableList(newData.data.category_list);
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      setTableCount(newData.data.recordsTotal);
      isNotLoading();

    } else {
      isNotLoading();
      setTableList([]);
      setPageCount(0);
    }
  };

  // useEffect(() => {
  //   // setTimeout(() => {
  //     getTableListCategory();
  //   // }, 2000);
  // }, [offset]);

  useEffect(() => {
    setTimeout(() => {
      getTableListCategory();
    }, 1000);
  }, [search, offset]);

  const modalBody = () => {
    return (
      <>
        {/* <Form onSubmit={handleSubmit}>
          <div>
            <p>Name*</p>
            <InputGroup className="search-register-user mr-5">
              <FormControl
                aria-label="category"
                aria-describedby="basic-addon1"
                className="workout-category"
                name="workout_category_name"
                type="text"
                required
                value={formInputs && formInputs.workout_category_name}
                onChange={handleInput}
              />
            </InputGroup>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
                // setModalHead("Add");
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {modalHead == "Add" ? "Add" : "Save"}
            </Button>
          </div>
        </Form> */}
        <Form onSubmit={handleSubmit}>
          <div className="column">
            <div className="col-md-8">
              <Form.Group controlId="name">
                <Form.Label className="required">Name</Form.Label>
                <Form.Control
                  name="workout_category_name"
                  type="text"
                  required
                  value={formInputs && formInputs.workout_category_name}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
                // setModalHead("Add");
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {modalHead == "Add" ? "Add" : "Save"}
            </Button>
          </div>
        </Form>
      </>
    );
  };
  const columns = [
    { dataField: "sno", text: "S.No" },
    {
      dataField: "workout_category_name",
      text: "Workout Category Name",
    },
    { dataField: "status", text: "Mark as Featured", formatter: btnFormatter },
    { dataField: "id", text: "Action", formatter: btnFormatterAction },
  ];
  const handleAdd = () => {
    setModalHead("Add");
    setModalShow(true);
    setFormInputs({ ...formInputs, workout_category_name: "" });
    setCategoryId("");
  };

  const tempFn = async (id, url, dataType) => {
    const data = {
      url: `edit_workout_category_by_id`,
      body: { workout_category_id: id },
    };
    const newData = await postMethod(data);

    if (newData.status == 1) {
      isNotLoading();
      setFormInputs({
        ...formInputs,
        workout_category_name: newData.data.workout_category_name,
      });
      setCategoryId(newData.data.id);
      setEditDataLoaded(true);
    } else {
      // showToastFn(newData.message);
      isNotLoading();
    }
  };

  const editclient = (id, type) => {
    tempFn(id, "edit_workout_category", "workout_category");
  };
  const FormErrors = () => {
    if (formInputs.name) {
      createCategory(formInputs, catType);
    }
  };

  function btnFormatterAction(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={row.id}
          onClick={() => {
            setModalHead("Edit");
            editclient(row.id);
            setModalShow(true);
            setModalShow(true);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button variant="link" dataid={row.id} onClick={trashWorkout}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const showAllRecords = () => {
    setTableDraw(tableCount);
  };

  const markAsFeatured = (is_active, id) => {
    const data = {
      url: `workout_category_switch_off/${id}`,
    };
    confirmAlert({
      title: `${is_active == 0 ? "Mark as Featured" : "  Remove as Featured"} `,
      message: `${is_active == 0 ? "Are you sure you want to mark this category as featured" : "  Are you sure you want unmark this category as featured"} ?`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await getMethod(data);
            if (newData.status == 1) {
              getTableListCategory();

            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  function btnFormatter(cell, row, rowIndex) {
    return (
      <>
        <Form.Check
          type="switch"
          id={`toggle-switch-${rowIndex}`}
          checked={row.is_featured == 0 ? false : true}
          onChange={() => markAsFeatured(row.is_featured, row.id)}
        />
      </>
    );
  }

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: `delete_workout_category`,
      body: { workout_category_id: id },
    };

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            setShowToast(true);
            setToastMessage(newData.message);
            if (newData.status == 1) {
              isNotLoading();
              getTableListCategory();
            } else {
              isNotLoading();
              console.log("error");
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };
  return (
    <section className="rightContent">
      {props.menuList.includes(9.11) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">Workout category</h3>
            <Button className="h-100" onClick={handleAdd}>
              Add New
            </Button>
          </div>
          <InputGroup className="search-register-user mr-5 mb-3">
            <FormControl
              placeholder="Search"
              aria-label="search"
              aria-describedby="basic-addon1"
              className="search-regUser-input search-border"
              onChange={handleSearchInput}
            />
          </InputGroup>
          <div className="fixedHeightTable">
            <Table
              clickSelect="true"
              data={tableList}
              offset={filter ? offset : offset}
              ref={tableRef}
              tableCount={tableCount}
              showAllRecords={showAllRecords}
              showButton={showButton}
              columns={columns}
              // filters={tableFilters}
              getSelectedRow={getSelectedRow}
              searchBar="false"
            // countPerPage={5}
            />
            <div className="d-flex float-right justify-content-end">
              {/* {filter?null: */}
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setOffset(pageNumber.selected + 1)
                }
                forcePage={offset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              />
              {/* } */}
            </div>
            <div className="toastMessage">
              <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={4000}
                autohide
              >
                <Toast.Body>
                  {toastMessage}{". "}

                </Toast.Body>
              </Toast>
            </div>
          </div>
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
              //   setEditFaqBlogData();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} Workout category`}
          />

        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
export default WorkoutCategory;
