import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faEye, } from "@fortawesome/free-solid-svg-icons";
import ModalPop from "../modals";

import { isNotLoading, postMethodFormData } from "../../Library";

export default function ImageBoxUploadName(props) {
  const [imageUrl, setImageUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [err, setErr] = useState();
  const [preShow, setPreShow] = useState(false);

  const position = props.position ? props.position : null;
  const handelPre = () => {
    setPreShow(!preShow);
  };

  const previewModal = ()=>{

    return(
      <>
      <div className="text-center popupImage">
      {imageUrl && <img src={imageUrl} alt="image" />}
      </div>
      </>
    )
  }

  const uploadImageFn = async (e) => {
    if (e.target.files.length > 0) {
      setErr();
      setUploading(true);
      let formDataBody = new FormData();
      formDataBody.append("image_url", e.target.files[0]);
      const data = {
        url: "image_upload",
        body: formDataBody,
      };
      const newData = await postMethodFormData(data);
      setUploading(false);
      if (newData.status == 1) {
    isNotLoading();

        let url = newData.data.image_url;
        if (url && newData.data) setImageUrl(url);
        props.data(url, props.name);
        setErr();
      } else {
        setErr(newData.message);
    isNotLoading();

      }
    }
  };

  useEffect(() => {
    if (props.image_url) {
      setImageUrl(props.image_url);
    }
  }, [props.image_url]);

  return (
    <>
      <div
        className={
          position == "left"
            ? "imgUploadBox d-flex align-items-center justify-content-center mwx-400"
            : "imgUploadBox d-flex align-items-center justify-content-center mwx-400 ml-auto mr-auto"
        }
      >
        {imageUrl && <img src={imageUrl} alt="image" />}
        <input
          type="file"
          className="w-100"
          name="avatar"
          accept="image/*"
          onChange={uploadImageFn}
        />
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        {/* {err && !uploading && (
          <small className="err-feedback mt-4">This Field is required</small>
        )} */}
        {uploading && <small>Uploading Image...</small>}
      </div>
      {err && !uploading && (
        <small className="err-feedback text-center w-100 position-relative">
          {err}
        </small>
      )}
        {imageUrl && <div className="text-center pt-2 cursor-pointer" onClick={handelPre}>
        Preview <FontAwesomeIcon icon={faEye} />
      </div>}

        <ModalPop
        size="md"
        class={"modal-padding"}
        show={preShow}
        onHide={handelPre}
        modalcontent={previewModal()}
        modalhead="Preview Image"
      />
    </>
  );
}
