
import React, { useEffect, useState } from "react";
import { Button, Form, Toast, InputGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../components/tables/table";

import { postMethod, getTableData, AccessDenied, isNotLoading } from "../Library";
import ReactPaginate from "react-paginate";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ModalPopMd } from "../components/modals";

export default function DietLibraryPage(props) {
  const [tableList, setTableList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [perPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [duplicateID, setDuplicateID] = useState();
  const [duplicateName, setDuplicateName] = useState();

  const [categoryIds, setCategoryIds] = useState([]);
  // const [displayName, setDisplayName] = useState();
  // const [planOverview, setPlanOverview] = useState();

  const closeModal = () => {
    setModalShow(false);
    setDuplicateID();
  };

  const openModal = (e) => {
    const id = e.target.getAttribute("dataid");
    setDuplicateID(id);
    setModalShow(true);
    if (id) {
      var copiedlist = tableList.filter(function (el) {
        return el.id == id;
      });
      setCategoryIds(copiedlist[0].category_ids)
      // setDisplayName(copiedlist[0].display_name)
      // setPlanOverview(copiedlist[0].plan_overview)
    }
  };



  const dupMeal = async (e) => {
    const data = {
      url: "diet_library_duplicate",
      body: {
        diet_library_id: parseInt(duplicateID),
        diet_plan_name: duplicateName,
        category_ids: categoryIds,
        // display_name: displayName,
        // plan_overview: planOverview
      },
    };
    const newData = await postMethod(data);

    
    if (newData.status == 1) {
      showToastFn(newData.data);
      isNotLoading();
      closeModal();
      getLists();
    }
    else {
      isNotLoading();
      showToastFn(newData.message); 

    }
  };

  const modalBody = () => {
    return (
      <>
        <div>
          <Form.Label className="required">Diet Name</Form.Label>
          <Form.Control
            type="text"
            name="dupMealName"
            onChange={(e) => {
              setDuplicateName(e.target.value);
            }}
            autoComplete="off"
          />
          <small className="mt-3">
            Note: This will create a copy of the selected Diet
          </small>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button variant="primary" disabled={!duplicateName} onClick={dupMeal}>
            Submit
          </Button>
        </div>
      </>
    );
  };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-diet-library/add-diet-library/`,
            state: {
              id: cell,
              diet_plan_name: row.diet_plan_name,
              category_ids: row.category_ids,
              // display_name: row.display_name,
              // plan_overview: row.plan_overview
            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        |
        <Button variant="link" dataid={row.id} onClick={openModal}>
          <FontAwesomeIcon icon={faCopy} />
        </Button>
        |
        <Button variant="link" dataid={cell} onClick={trashClient}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }
  const handleSearchInput = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
      setOffset(1);
    }, 1000);
  };

  const trashClient = async (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "diet_library_delete",
      body: {
        id: parseInt(id),
      },
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if (newData.status == 1) {
              isNotLoading();
              showToastFn(newData.message);
              getLists();
            }
            else {
              isNotLoading();
              showToastFn(newData.message);
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });

  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "diet_plan_name", text: "Diet Name" },
    // { dataField: "display_name", text: "Display Name" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];

  const getLists = async () => {
    let tableList = await getTableDatas("diet_library_list");
    if (tableList && tableList.diet_library) {
      setTableList(tableList.diet_library);
    }
  };
  const getTableDatas = async (url) => {
    const data = {
      url: url,
      body: {
        search: { value: searchValue },
        length: 10,
        start: offset == 1 ? 0 : (offset - 1) * 10,
        draw: 10,
      },
    };
    try {
      const newData = await postMethod(data);
      if (newData.status == 1 && newData.data) {
        isNotLoading();
        setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
        return newData.data;

      } else if (newData.status === false) {
        isNotLoading();
      }
    } catch (err) {

      // isNotLoading();
      // handleCatchErr(err);
    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getLists();
    }
  }, [pageLoaded, setPageLoaded, searchValue, offset]);


  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(8.3) ? (
        <>
          <div className="d-flex align-items-center mb-3">
            <h3>Manage Diet Library</h3>
            <Link
              to="/manage-diet-library/add-diet-library/"
              className="h-100 ml-auto text-capitalize btn btn-primary"
            >
              Add Diet Plan
            </Link>
          </div>
          <div className="d-flex justify-content-start">
            <InputGroup className="search-register-users">
              <FormControl
                placeholder="Search"
                aria-label="search"
                aria-describedby="basic-addon1"
                className="search-regUser-input search-border"
                onChange={handleSearchInput}
              />
            </InputGroup>
          </div>
          <Table data={tableList} columns={columns} filters={tableFilters} searchBar="false" offset={offset} />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) =>
                setOffset(pageNumber.selected + 1)
              }
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
          <ModalPopMd
            show={modalShow}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead="Duplicate Diet"
          />
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
