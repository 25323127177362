import React, { useState, useEffect } from "react";

import { Form, Row, Col, Button, Alert } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faTrashAlt,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Select } from "antd";
import "antd/dist/antd.css";

import { copyObj } from "../../../Library";
// import ImageBoxUpload from "../../ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../../ImageBox/imageboxuploadcrop";
import ToolTipBox from "../../ToolTipBox";
import DropdownSingle from "../../Dropdown/singledropdown";
import Buttons from "../../Button/button";
export default function WorkoutFormMain(props) {
  const [formInputs, setFormInputs] = useState();
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(false);
  const [formError, setFormError] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [exercise, setExercise] = useState([]);
  const [exerciseWorkout, setExerciseWorkout] = useState([]);
  const [exerciseStrech, setExerciseStrech] = useState([]);
  useEffect(() => {
    setFormInputs(props.formInputs);
    setLoaded(true);
    // console.log("im rendering in workoutform main");
    return () => {
      setLoaded(false);
    };
  }, [props.formInputs]);

  //    workouts codes start
  const handleWorkoutName = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        workout_name: e.target.value,
      },
    }));
  };

  useEffect(() => {
  setLoadingBtn(props.updateDraft)
  }, [props.updateDraft])

  
  const handleWorkoutImage = (url) => {
    setFormInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        image_url: url,
      },
    }));
  };

  // const gettypedData = (data) => {
  //   setFormInputs((prev) => ({
  //     ...prev,
  //     workouts: {
  //       ...prev.workouts,
  //       description: data,
  //     },
  //   }));
  // };
  const handleWorkoutDesc = (e) => {
    setFormInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        description: e.target.value,
      },
    }));
  };
  //    workouts codes end

  //   warmup code start
  const handleWarmupDesc = (e) => {
    const values = { ...formInputs };
    values.workout_warmups[0].description = e.target.value;
    setFormInputs({ ...formInputs });
  };

  const handleWarmupImage = (url) => {
    const values = { ...formInputs };
    values.workout_warmups[0].image_url = url;
    setFormInputs({ ...formInputs });
  };

  const handleExerciseInput_warmup = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    const values = { ...formInputs };
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    values.workout_warmups[0].workout_warmup_exercises[index][name] = val;
    // console.log("formInputs..", formInputs);
    setFormInputs({ ...formInputs });
  };

  const handleExerciseSelect_warmup = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;
    const values = { ...formInputs };

    values.workout_warmups[0].workout_warmup_exercises[index][name] = selname;

    values.workout_warmups[0].workout_warmup_exercises[index].exercise_id =
      value;
    setFormInputs({ ...formInputs });
  };

  const handleAddWarmupFields = (index) => {
    const values = { ...formInputs };
    const singleObj = values.workout_warmups[0].workout_warmup_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_warmups[0].workout_warmup_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_warmup_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setFormInputs(values);
    }
    // console.log("addFormUpFields", values, index);
  };

  const handleRemoveWarmupFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = copyObj(formInputs);
    // console.log(values.workout_warmups[0].workout_warmup_exercises);
    values.workout_warmups[0].workout_warmup_exercises.splice(index, 1);
    setFormInputs(values);
  };
  //   warmup code end

  //   workout code start
  const handleWorkoutInput = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    const values = { ...formInputs };
    values.workout_wks[index][name] = e.target.value;
    setFormInputs({ ...formInputs });
  };

  const handleWorkoutInnerImage = (url, ps) => {
    let index = ps.dataid;
    const values = { ...formInputs };
    values.workout_wks[index].image_url = url;
    setFormInputs({ ...formInputs });
  };

  const handleExerciseSelect_workout = (e, i, indx, n, SelectedName, id) => {
    let name = n;
    let parent = i;
    let child = indx;
    // console.log(value)
    let selname = SelectedName;
    let value = id;

    const values = { ...formInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = selname;
    values.workout_wks[parent].workout_wk_exercises[child].exercise_id = value;
    setFormInputs({ ...formInputs });
  };

  const handleExerciseInput_workout = (e) => {
    let name = e.target.name;
    let parent = e.target.getAttribute("parentid");
    let child = e.target.getAttribute("childid");
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";

    const values = { ...formInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = val;
    setFormInputs({ ...formInputs });
  };

  const handleAddWorkoutInnerFields = (e, indx) => {
    // let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");
    const values = { ...formInputs };
    // console.log("clicked add", parentIndex, index);
    const singleObj =
      values.workout_wks[parentIndex].workout_wk_exercises[indx];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_wks[parentIndex].workout_wk_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_wk_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setFormInputs(values);
    }
  };

  const handleRemoveWorkoutInnerFields = (e) => {
    let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");

    const values = { ...formInputs };
    values.workout_wks[parentIndex].workout_wk_exercises.splice(index, 1);
    setFormInputs(values);
  };

  const handleAddWorkoutFields = (index) => {
    const values = { ...formInputs };
    const singleObj = values.workout_wks[index];
    const singlrArr = singleObj.workout_wk_exercises;
    const finalArr = singlrArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    // console.log("add new circuit", finalArr, singlrArr, values);
    if (finalArr && singleObj.circuit_name && singleObj.description) {
      values.workout_wks.push({
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
      setFormInputs(values);
    }
  };

  const handleRemoveWorkoutFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...formInputs };
    values.workout_wks.splice(index, 1);
    setFormInputs(values);
  };

  //   workout code end
  //   stretch code start
  const handleStretchDesc = (e) => {
    const values = { ...formInputs };
    values.workout_stretchs[0].description = e.target.value;
    setFormInputs({ ...formInputs });
  };
  const handleStretchImage = (url) => {
    const values = { ...formInputs };
    values.workout_stretchs[0].image_url = url;
    setFormInputs({ ...formInputs });
  };

  const handleExerciseSelect_stretch = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;

    const values = { ...formInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = selname;

    values.workout_stretchs[0].workout_stretch_exercises[index].exercise_id =
      value;

    setFormInputs({ ...formInputs });
  };

  const handleExerciseInput_stretch = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...formInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = val;
    setFormInputs({ ...formInputs });
  };

  const handleAddStretchFields = (e, index) => {
    const values = { ...formInputs };
    const singleObj =
      values.workout_stretchs[0].workout_stretch_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_stretchs[0].workout_stretch_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_stretch_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setFormInputs(values);
    }
  };
  // const getSelections = (data, name) => {
  //   if (name == "select_exercise") {
  //      if(data){

  //      setExercise(data);
  //      let name = data[0].name
  //      let id = data.length>0?data[0].id : '';
  //      const values = { ...formInputs };

  //   values.workout_warmups[0].workout_warmup_exercises[0].exercise_name = name;

  //   values.workout_warmups[0].workout_warmup_exercises[0].exercise_id = id ;
  //   setFormInputs({ ...formInputs });
  //    }
  //    }else if(name == "select_exercise_workouts"){
  //      if(data){
  //        setExerciseWorkout(data);
  //        let name = data[0].name
  //        console.log(name,'name')
  //        let id = data.length>0?data[0].id : '';
  //        const values = { ...formInputs };
  //        values.workout_wks[0].workout_wk_exercises[0].exercise_name = name;
  //        values.workout_wks[0].workout_wk_exercises[0].exercise_id = id
  //        setFormInputs({ ...formInputs });
  //        console.log(formInputs,'wk')
  //      }

  //    }
  //    else if ( name == "select_exercise_strech"){
  //      if(data){
  //        setExerciseStrech(data)

  //        let name = data[0].name

  //        let id = data.length>0?data[0].id : '';
  //        const values = { ...formInputs };
  //        values.workout_stretchs[0].workout_stretch_exercises[0].exercise_name= name;

  //        values.workout_stretchs[0].workout_stretch_exercises[0].exercise_id = id
  //        setFormInputs({ ...formInputs });
  //      }

  //    }
  //  };
  const handleRemoveStretchFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...formInputs };
    values.workout_stretchs[0].workout_stretch_exercises.splice(index, 1);
    setFormInputs(values);
  };
  //   stretch code end

  // buttons
  const prev = (e) => {
    props.prev();
  };
  const next = (e) => {
    props.next();
  };
  // const next = (e) => {
  //   props.next();
  // };

  // append errors
  function appendFormErrors(errorMsg) {
    setFormError((prev) => [...prev, errorMsg]);
  }

  // check validation
  function checkFormDirty(values, dirtyForms) {
    // workout circuit
    // console.log("form ", values);
    values.workout_wks.forEach((x, i) => {
      let status = false;
      if (
        x?.circuit_name?.length > 0 ||
        x?.description?.length > 0 ||
        x?.image_url?.length > 0 ||
        x?.circuit_round > 0
      ) {
        status = true;
      }
      let isAny = [];
      x.workout_wk_exercises.every((x, i) => {
        let isAny =
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0;
        // console.log(isAny);
        if (isAny) {
          status = true;
        }
      });
      if (status) {
        spliceFn(dirtyForms, "workout_ct");
        dirtyForms.push("workout_ct");
      }
    });

    values.workout_stretchs.forEach((x, i) => {
      let status = false;
      let stretchObj = x;
      if (
        stretchObj.description?.length > 0 ||
        stretchObj?.image_url?.length > 0
      ) {
        status = true;
      }

      let stretchArr = stretchObj.workout_stretch_exercises;
      stretchArr.every((x, i) => {
        if (
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0
        ) {
          status = true;
        }
      });
      if (status) {
        spliceFn(dirtyForms, "workout_stretch");
        dirtyForms.push("workout_stretch");
      }
    });

    values.workout_warmups.forEach((x, i) => {
      let status = false;
      let warmupObj = x;
      if (
        warmupObj?.description?.length > 0 ||
        warmupObj?.image_url?.length > 0
      ) {
        status = true;
      }

      let warmupArr = warmupObj.workout_warmup_exercises;
      warmupArr.every((x, i) => {
        if (
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0
        ) {
          status = true;
        }
      });
      if (status) {
        dirtyForms.push("workout_warmup");
      } else {
        spliceFn(dirtyForms, "workout_warmup");
      }
    });

    // let wrkOut = values.workouts;
    // if (
    //   wrkOut.workout_name.length > 0 ||
    //   wrkOut.description.length > 0 ||
    //   wrkOut.image_url.length > 0
    // ) {
    //   spliceFn(dirtyForms, "workout_main");
    //   dirtyForms.push("workout_main");
    // }
  }

  function spliceFn(dirtyForms, name) {
    let x = dirtyForms.length;
    if (x > 0) {
      for (let i = 0; i < x; i++) {
        let index = dirtyForms.indexOf(name);
        if (index !== -1) {
          dirtyForms.splice(index, 1);
        }
      }
    }
  }

  function removeFormDirty(
    isWarmupValid,
    isWorkoutValid,
    isStretchValid,
    dirtyForms
  ) {
    if (isWarmupValid) {
      spliceFn(dirtyForms, "workout_warmup");
    }
    if (isWorkoutValid) {
      spliceFn(dirtyForms, "workout_ct");
    }
    if (isStretchValid) {
      spliceFn(dirtyForms, "workout_stretch");
    }
  }

  function scrollToFirstError(dirtyForms) {
    // workout_main;
    // workout_warmup;
    // workout_ct;
    // workout_stretch;
  }

  // workout cirucit checks
  function checkWorkoutCircuitExcValidation(values) {
    const workOutArr = values.workout_wks;
    var isAll = [];
    workOutArr.forEach((e, i) => {
      e.workout_wk_exercises.forEach((obj) => isAll.push(obj));
    });
    // console.log("all woks esc", isAll);
    const finalWrkOut = isAll.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    return finalWrkOut;
  }
  function checkWorkoutCircuitValidation(values) {
    const isNameValid = values.workout_wks.every(
      (x, i) =>
        x?.circuit_name.length > 0 &&
        x?.description.length > 0 &&
        x?.image_url.length > 0 &&
        x?.circuit_round > 0
    );
    const finalWorkOut = checkWorkoutCircuitExcValidation(values);
    // console.log("workout outp ", finalWorkOut && isNameValid);
    return finalWorkOut && isNameValid;
  }
  // workout cirucit checks

  // workout warmup checks

  function checkStretchExcValidation(values) {
    const stretchObj = values.workout_stretchs[0];
    const stretchArr = stretchObj.workout_stretch_exercises;
    let val = stretchArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    return val;
  }

  function checkWarmupExcValidation(values) {
    const warmupObj = values.workout_warmups[0];
    const warmupArr = warmupObj?.workout_warmup_exercises;
    let val = warmupArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    return val;
  }

  // workout warmup checks

  // check validations

  const handleSubmit = (e, status) => {
    e.preventDefault();
    // e.stopPropagation();
    // const form = e.currentTarget;
    // if (form.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    setValidated(true);
    props.validateWorkoutMaster();
    const values = { ...formInputs };
    const dirtyForms = [];
    setFormError([]);
    // console.log("next page prev", values);

    const isWorkoutValid = checkWorkoutCircuitValidation(values);

    const stretchObj = values.workout_stretchs[0];
    const finalStretch = checkStretchExcValidation(values);
    const isStretchValid =
      finalStretch &&
      stretchObj.description.length > 0 &&
      stretchObj?.image_url.length > 0;

    const warmupObj = values.workout_warmups[0];
    const finalWarmup = checkWarmupExcValidation(values);
    const isWarmupValid =
      finalWarmup &&
      warmupObj.description.length > 0 &&
      warmupObj.image_url.length > 0;

    const wrkOutName = values.workouts.workout_name;
    const wrkOutDescription = values.workouts.description;
    const wrkOutImage = values.workouts.image_url
      ? values.workouts.image_url
      : "";

    const isPageNameValid =
      wrkOutDescription.length > 0 &&
      wrkOutName.length > 0 &&
      wrkOutImage.length > 0;
    if (!isPageNameValid) {
      appendFormErrors("Please fill the required Workout fields");
      setAlert(true);
    }

    checkFormDirty(values, dirtyForms);
    removeFormDirty(isWarmupValid, isWorkoutValid, isStretchValid, dirtyForms);
    // console.log("aft ", dirtyForms);

    // check if atleast one is valid
    if (isWarmupValid || isWorkoutValid || isStretchValid) {
      // console.log("withing valid loop");
      if (dirtyForms.length != 0) {
        // console.log("withing valid dirty loop");
        let msgString = `Please check the
        ${dirtyForms.includes("workout_main") ? "Workout " : ""}
        ${dirtyForms.includes("workout_warmup") ? "Warmup " : ""}
        ${dirtyForms.includes("workout_ct") ? "Workouts Ciruit " : ""}
        ${dirtyForms.includes("workout_stretch") ? "Stretch " : ""}
        form and update accordingly
        `;
        appendFormErrors(msgString);
        setAlert(true);
      }
    } else {
      appendFormErrors(
        "Please fill atleast one of the workout form (WarmUp/Workout/Stretch) completely"
      );
      setAlert(true);
    }

    if (
      isPageNameValid &&
      (isWarmupValid || isWorkoutValid || isStretchValid) &&
      dirtyForms.length == 0
    ) {
      props.createWorkoutFn(formInputs, status);
      // if(status==="draft"){
      //   setShowDraft(false)
      // }
    }

    // go to next page
    // if (props.currentPage != props.sections - 1) {
    //   props.next();
    // } else {
    //   if (formError.length == 0) {
    //     props.handleSubmitDays(formInputs, props.currentPage + 1);
    //   }
    // }
  };

  // useEffect(() => {
  //   if(formError) {
  //     setFormError((prev) => [...prev, formError]);
  //   }
  //   console.log("running")
  // }, [formError, setFormError]);

  return loaded ? (
    <>
      <Form noValidate className="workout_form">
        <Row>
          <Col sm="7">
            <Form.Label className="required">Workout Name</Form.Label>
            <Form.Control
              type="text"
              name="workout_name"
              // required
              onChange={handleWorkoutName}
              value={formInputs.workouts?.workout_name}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
            {validated &&
              formInputs &&
              (formInputs.workouts?.workout_name == null ||
                formInputs.workouts?.workout_name == "") && (
                <div className="err-feedback">This field is required</div>
              )}
          </Col>
          <Col sm="5" className="imgBx position-relative">
            <Form.Label className="required">Workout Image</Form.Label>
            {/* <ImageBoxUpload
              data={handleWorkoutImage}
              image_url={formInputs ? formInputs.workouts?.image_url : ""}
            /> */}
            <ImageBoxUploadCrop
              folder="program"
              as_w="4"
              as_h="3"
              dataid=""
              data={handleWorkoutImage}
              image_url={formInputs ? formInputs.workouts?.image_url : ""}
            />
            {/* {JSON.stringify(item, null, 2)} */}
            {validated &&
              formInputs &&
              (formInputs.workouts?.image_url == null ||
                formInputs.workouts?.image_url == "") && (
                <div className="err-feedback text-center">
                  This field is required
                </div>
              )}
          </Col>
        </Row>
        <Form.Group
          controlId={`instruction-${props.day}`}
          className="mt-3 mb-3 position-relative"
        >
          <Form.Label className="required">
            Add Instructions/Description
          </Form.Label>
          {/* <Editor data={gettypedData} /> */}
          <Form.Control
            as="textarea"
            name="description"
            rows={3}
            // ref={textBox1}
            readOnly={
              props.editData && props.editData.editAccess === false
                ? true
                : false
            }
            maxLength="120"
            // required
            value={formInputs.workouts?.description}
            onChange={handleWorkoutDesc}
          />
          <small className="char-len">Max 120 Character</small>
          {/* <small className="char-len">
            {formInputs && formInputs.workouts.description
              ? 70 - formInputs.workouts.description.length
              : 70}{" "}
            characters left
          </small> */}
          <Form.Control.Feedback type="invalid">
            This field is required
          </Form.Control.Feedback>
          {validated &&
            formInputs &&
            (formInputs.workouts?.description == null ||
              formInputs.workouts?.description == "") && (
              <div className="err-feedback">This field is required</div>
            )}
        </Form.Group>
        {/* end of workout name  */}
        <div className="warmupBox">
          <h5 className="text-active">Warm Up</h5>
          <Row className="mb-4">
            <Col sm="7">
              <Form.Group
                controlId={`warmupshortdes-${props.day}`}
                className="mt-3 mb-3"
              >
                <Form.Label>Add Short Description/Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  readOnly={
                    props.editData && props.editData.editAccess === false
                      ? true
                      : false
                  }
                  rows={3}
                  // ref={textBox2}
                  // required
                  value={formInputs?.workout_warmups[0]?.description}
                  onChange={handleWarmupDesc}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="5">
              <Form.Label>Warm Up Image</Form.Label>
              {/* <ImageBoxUpload
                data={handleWarmupImage}
                image_url={
                  formInputs ? formInputs.workout_warmups[0]?.image_url : ""
                }
              /> */}
              <ImageBoxUploadCrop
                folder="program"
                as_w="4"
                as_h="3"
                dataid=""
                data={handleWarmupImage}
                image_url={
                  formInputs ? formInputs?.workout_warmups[0]?.image_url : ""
                }
              />
              {/* {JSON.stringify(item, null, 2)} */}
              {/* {validated &&
                formInputs &&
                (formInputs.workout_warmups[0]?.image_url == null ||
                  formInputs.workout_warmups[0]?.image_url == "") && (
                  <div className="err-feedback">This field is required</div>
                )} */}
            </Col>
          </Row>
          {formInputs?.workout_warmups[0]?.workout_warmup_exercises.map(
            (item, index, arr) => (
              <Form.Row
                className="align-items-center form-m-0 mb-4"
                key={index}
              >
                <Col md="4">
                  <Form.Group
                    controlId={`warmup-exercise-${index}-${props.day}`}
                    className="w-100"
                  >
                    {/* <Form.Control
                      as="select"
                      name="exercise_name"
                      placeholder="Select Exercises"
                      dataid={index}
                      // required
                      onChange={handleExerciseSelect_warmup}
                      value={item.exercise_id}
                    >
                      <option value="">Select Exercises</option>
                      {props.exerciseList.map((item, index) => (
                        <option value={item.id} selname={item.name} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control> */}
                    <Select
                      showSearch
                      name="exercise_name"
                      placeholder="Select Exercises"
                      dataid={index}
                      value={
                        item.exercise_id != ""
                          ? item.exercise_id
                          : "Select Exercise"
                      }
                      optionFilterProp="children"
                      className={
                        props.editData && props.editData.editAccess === false
                          ? "readOnly"
                          : "select_filter"
                      }
                      onChange={(e, option) => {
                        handleExerciseSelect_warmup(
                          e,
                          index,
                          "exercise_name",
                          option.selname,
                          option.value
                        );
                      }}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {props.exerciseList.map((item, index) => (
                        <Select.Option
                          value={item.id}
                          selname={item.name}
                          key={index}
                          // onChange={optChange}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>

                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`warmup-reps${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="reps"
                      min="1"
                      placeholder="Reps"
                      value={item.reps}
                      dataid={index}
                      // required
                      disabled={item.es || item.el || item.sec ? true : false}
                      onChange={handleExerciseInput_warmup}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`warmup-es${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="es"
                      min="1"
                      value={item.es}
                      placeholder="E/S"
                      dataid={index}
                      required
                      disabled={item.reps || item.el || item.sec ? true : false}
                      onChange={handleExerciseInput_warmup}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`warmup-el${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="el"
                      min="1"
                      value={item.el}
                      placeholder="E/L"
                      dataid={index}
                      required
                      disabled={item.es || item.reps || item.sec ? true : false}
                      onChange={handleExerciseInput_warmup}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`warmup-secs${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="sec"
                      min="1"
                      dataid={index}
                      value={item.sec}
                      placeholder="Secs"
                      required
                      disabled={item.es || item.el || item.reps ? true : false}
                      onChange={handleExerciseInput_warmup}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`warmup-break${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="break_time"
                      min="1"
                      dataid={index}
                      value={item.break_time}
                      placeholder="Break Time"
                      onChange={handleExerciseInput_warmup}
                    />
                  </Form.Group>
                </Col>

                <Col md="1" className="text-right">
                  {index == 0 && arr.length == 1 && (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      dataid={index}
                      disabled={!checkWarmupExcValidation(formInputs)}
                      onClick={() => handleAddWarmupFields(index)}
                      className="font-20"
                    />
                  )}
                  {arr.length > 0 && arr.length - 1 != index && (
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      dataid={index}
                      onClick={handleRemoveWarmupFields}
                      className="font-20"
                    />
                  )}
                  {arr.length - 1 == index && index != 0 && (
                    <>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        disabled={!checkWarmupExcValidation(formInputs)}
                        dataid={index}
                        onClick={() => handleAddWarmupFields(index)}
                        className="font-20 mr-2"
                      />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        dataid={index}
                        onClick={handleRemoveWarmupFields}
                        className="font-20"
                      />
                    </>
                  )}
                </Col>
              </Form.Row>
            )
          )}
        </div>
        {/* end of warmup */}
        <div className="workoutBox">
          <h5 className="text-active mb-4">Workouts</h5>
          {formInputs.workout_wks?.map((item, index, arr) => (
            <div key={index}>
              <div className="d-flex align-items-center mt-4">
                <div className="d-flex align-items-center">
                  <Form.Group
                    controlId={`circuit_name_${index}-${props.day}`}
                    className="mw-250 mb-0 position-relative"
                  >
                    <Form.Control
                      name="circuit_name"
                      type="text"
                      placeholder="Enter Circuit Name"
                      dataid={index}
                      value={item.circuit_name}
                      // required
                      onChange={handleWorkoutInput}
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback> */}
                  </Form.Group>
                  <span className="pl-0 pr-0" style={{ width: "30px" }}>
                    <hr className="dark" />
                  </span>
                  <Form.Group
                    controlId={`circuit_round_${index}-${props.day}`}
                    className="mw-100 mb-0 position-relative"
                  >
                    <ToolTipBox text="Circuit Rounds" placement="right">
                      <Button variant="link" className="p-0">
                        <Form.Control
                          name="circuit_round"
                          type="number"
                          min="1"
                          max="100"
                          placeholder="Round"
                          dataid={index}
                          value={item.circuit_round}
                          // required
                          onChange={handleWorkoutInput}
                        />
                      </Button>
                    </ToolTipBox>

                    {/* <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </div>
                <span className="w-100 pl-0 pr-0">
                  <hr className="dark" />
                </span>
                {index == 0 && arr.length == 1 && (
                  <Button
                    className="ml-auto no-wrap"
                    disabled={!checkWorkoutCircuitValidation(formInputs)}
                    onClick={() => handleAddWorkoutFields(index)}
                  >
                    Add Circuit
                  </Button>
                )}
                {arr.length > 0 && arr.length - 1 != index && (
                  <>
                    <Button
                      className="ml-auto"
                      dataid={index}
                      onClick={handleRemoveWorkoutFields}
                    >
                      Delete
                    </Button>
                  </>
                )}
                {arr.length - 1 == index && index != 0 && (
                  <>
                    <Button
                      className="ml-auto no-wrap"
                      disabled={!checkWorkoutCircuitValidation(formInputs)}
                      onClick={() => handleAddWorkoutFields(index)}
                    >
                      Add Circuit
                    </Button>
                    <span className="pl-0 pr-0" style={{ width: "1rem" }}>
                      <hr className="dark" />
                    </span>
                    <Button
                      className="ml-auto"
                      dataid={index}
                      onClick={handleRemoveWorkoutFields}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </div>

              <Row className="mb-4">
                <Col sm="7">
                  <Form.Group
                    controlId={`workoutshortdes-${index}-${props.day}`}
                    className="mt-3 mb-3"
                  >
                    <Form.Label>Add Short Description/Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      placeholder="Add Short Description/Notes"
                      rows={3}
                      // ref={textBox3}
                      readOnly={
                        props.editData && props.editData.editAccess === false
                          ? true
                          : false
                      }
                      dataid={index}
                      value={item.description}
                      // required
                      onChange={handleWorkoutInput}
                    />
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col sm="5">
                  <Form.Label>Circuit Image</Form.Label>
                  {/* <ImageBoxUpload
                    data={handleWorkoutInnerImage}
                    dataid={index}
                    image_url={item.image_url}
                  /> */}
                  <ImageBoxUploadCrop
                    folder="program"
                    as_w="4"
                    as_h="3"
                    data={handleWorkoutInnerImage}
                    dataid={index}
                    image_url={item.image_url}
                  />
                  {/* {JSON.stringify(item, null, 2)} */}
                  {/* {validated &&
                    (item.image_url == null || item.image_url == "") && (
                      <div className="err-feedback">This field is required</div>
                    )} */}
                </Col>
              </Row>
              {item.workout_wk_exercises &&
                item.workout_wk_exercises.map((item, indx, arr) => (
                  <Form.Row
                    className="align-items-center form-m-0 mb-3"
                    key={indx}
                  >
                    <Col md="4">
                      <Form.Group
                        controlId={`workout-m-exercise-${index}-${indx}-${props.day}`}
                        className="w-100"
                      >
                        {/* <Form.Control
                          as="select"
                          name="exercise_name"
                          placeholder="Select Exercises"
                          parentid={index}
                          childid={indx}
                          // required
                          onChange={handleExerciseSelect_workout}
                          value={item.exercise_id}
                        >
                          <option value="">Select Exercises</option>
                          {props.exerciseList.map((item, index) => (
                            <option
                              value={item.id}
                              selname={item.name}
                              key={index}
                            >
                              {item.name}
                            </option>
                          ))}
                        </Form.Control> */}
                        <Select
                          showSearch
                          name="exercise_name"
                          placeholder="Select Exercises"
                          dataid={index}
                          value={
                            item.exercise_id != ""
                              ? item.exercise_id
                              : "Select Exercise"
                          }
                          optionFilterProp="children"
                          className={
                            props.editData &&
                            props.editData.editAccess === false
                              ? "readOnly"
                              : "select_filter"
                          }
                          onChange={(e, option) => {
                            handleExerciseSelect_workout(
                              e,
                              index,
                              indx,
                              "exercise_name",
                              option.selname,
                              option.value
                            );
                          }}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {props.exerciseList.map((item, index) => (
                            <Select.Option
                              value={item.id}
                              selname={item.name}
                              key={index}
                              // onChange={optChange}
                            >
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                        {/* <DropdownSingle
            options={props.exerciseList}
            value={exerciseWorkout}
            getSelections={getSelections}
            onChange={handleExerciseSelect_workout}
            name="select_exercise_workouts"
            placeholder="Select Exercise"
          /> */}
                        <Form.Control.Feedback type="invalid">
                          This field is required
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-reps${index}-${indx}-${props.day}`}
                      >
                        <Form.Control
                          type="number"
                          name="reps"
                          min="1"
                          placeholder="Reps"
                          parentid={index}
                          childid={indx}
                          // required
                          disabled={
                            item.es || item.el || item.sec ? true : false
                          }
                          value={item.reps}
                          onChange={handleExerciseInput_workout}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-es${index}-${indx}-${props.day}`}
                      >
                        <Form.Control
                          type="number"
                          name="es"
                          min="1"
                          placeholder="E/S"
                          parentid={index}
                          // required
                          disabled={
                            item.sec || item.el || item.reps ? true : false
                          }
                          value={item.es&&item.es!==null?item.es:""}
                          childid={indx}
                          onChange={handleExerciseInput_workout}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-el${index}-${indx}-${props.day}`}
                      >
                        <Form.Control
                          type="number"
                          name="el"
                          min="1"
                          placeholder="E/L"
                          parentid={index}
                          childid={indx}
                          value={item.el&&item.el!==null?item.el:""}
                          // required
                          disabled={
                            item.es || item.sec || item.reps ? true : false
                          }
                          onChange={handleExerciseInput_workout}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-secs${index}-${indx}-${props.day}`}
                      >
                        <Form.Control
                          type="number"
                          name="sec"
                          min="1"
                          placeholder="Secs"
                          parentid={index}
                          childid={indx}
                          value={item.sec&&item.sec!==null?item.sec:''}
                          // required
                          disabled={
                            item.es || item.el || item.reps ? true : false
                          }
                          onChange={handleExerciseInput_workout}
                        />
                        <Form.Control.Feedback type="invalid">
                          Required (any)
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="col-md">
                      <Form.Group
                        controlId={`workout-m-break${index}-${indx}-${props.day}`}
                      >
                        <Form.Control
                          type="number"
                          name="break_time"
                          placeholder="Break Time"
                          min="1"
                          parentid={index}
                          childid={indx}
                          value={item.break_time}
                          onChange={handleExerciseInput_workout}
                        />
                      </Form.Group>
                    </Col>

                    <Col md="1" className="text-right">
                      {indx == 0 && arr.length == 1 && (
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          dataid={indx}
                          supdataid={index}
                          disabled={
                            !checkWorkoutCircuitExcValidation(formInputs)
                          }
                          onClick={(e) => handleAddWorkoutInnerFields(e, indx)}
                          className="font-20"
                        />
                      )}
                      {arr.length > 0 && arr.length - 1 != indx && (
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          dataid={indx}
                          supdataid={index}
                          onClick={handleRemoveWorkoutInnerFields}
                          className="font-20"
                        />
                      )}
                      {arr.length - 1 == indx && indx != 0 && (
                        <>
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            dataid={indx}
                            supdataid={index}
                            disabled={
                              !checkWorkoutCircuitExcValidation(formInputs)
                            }
                            onClick={(e) =>
                              handleAddWorkoutInnerFields(e, indx)
                            }
                            className="font-20 mr-2"
                          />
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            dataid={indx}
                            supdataid={index}
                            onClick={handleRemoveWorkoutInnerFields}
                            className="font-20"
                          />
                        </>
                      )}
                    </Col>
                  </Form.Row>
                ))}

              <hr />
            </div>
          ))}
        </div>
        {/* end of workout */}
        <div className="stretchBox mb-5">
          <h5 className="text-active">Stretch</h5>

          <Row className="mb-4">
            <Col sm="7">
              <Form.Group
                controlId={`stretchshortdes-${props.day}`}
                className="mt-3 mb-3"
              >
                <Form.Label>Add Short Description/Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  // ref={textBox4}
                  readOnly={
                    props.editData && props.editData.editAccess === false
                      ? true
                      : false
                  }
                  // required
                  value={formInputs.workout_stretchs[0]?.description}
                  onChange={handleStretchDesc}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col sm="5">
              <Form.Label>Stretch Image</Form.Label>
              {/* <ImageBoxUpload
                data={handleStretchImage}
                image_url={
                  formInputs ? formInputs.workout_stretchs[0]?.image_url : ""
                }
              /> */}
              <ImageBoxUploadCrop
                folder="program"
                as_w="4"
                as_h="3"
                dataid=""
                data={handleStretchImage}
                image_url={
                  formInputs ? formInputs.workout_stretchs[0]?.image_url : ""
                }
              />
              {/* {JSON.stringify(item, null, 2)} */}
              {/* {validated &&
                formInputs &&
                (formInputs.workout_stretchs[0]?.image_url == null ||
                  formInputs.workout_stretchs[0]?.image_url == "") && (
                  <div className="err-feedback">This field is required</div>
                )} */}
            </Col>
          </Row>

          {formInputs.workout_stretchs[0]?.workout_stretch_exercises.map(
            (item, index, arr) => (
              <Form.Row
                className="align-items-center form-m-0 mb-4"
                key={index}
              >
                <Col md="4">
                  <Form.Group
                    controlId={`stretch-exercise-${index}-${props.day}`}
                    className="w-100"
                  >
                    {/* <Form.Control
                      as="select"
                      name="exercise_name"
                      placeholder="Select Exercises"
                      dataid={index}
                      // required
                      onChange={handleExerciseSelect_stretch}
                      value={item.exercise_id}
                    >
                      <option value="">Select Exercises</option>
                      {props.exerciseList.map((item, index) => (
                        <option value={item.id} selname={item.name} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control> */}
                    <Select
                      showSearch
                      name="exercise_name"
                      placeholder="Select Exercises"
                      dataid={index}
                      value={
                        item.exercise_id != ""
                          ? item.exercise_id
                          : "Select Exercise"
                      }
                      optionFilterProp="children"
                      onChange={(e, option) => {
                        handleExerciseSelect_stretch(
                          e,
                          index,
                          "exercise_name",
                          option.selname,
                          option.value
                        );
                      }}
                      className={
                        props.editData && props.editData.editAccess === false
                          ? "readOnly"
                          : "select_filter"
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {props.exerciseList.map((item, index) => (
                        <Select.Option
                          value={item.id}
                          selname={item.name}
                          key={index}
                          // onChange={optChange}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                    {/* <DropdownSingle
            options={props.exerciseList}
            value={exerciseStrech}
            getSelections={getSelections}
            onChange={handleExerciseSelect_stretch}
            name="select_exercise_strech"
            placeholder="Select Exercise"

          /> */}
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`stretch-reps${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="reps"
                      min="1"
                      placeholder="Reps"
                      dataid={index}
                      // required
                      disabled={item.es || item.el || item.sec ? true : false}
                      value={item.reps}
                      onChange={handleExerciseInput_stretch}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`stretch-es${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="es"
                      min="1"
                      placeholder="E/S"
                      dataid={index}
                      value={item.es}
                      // required
                      disabled={item.reps || item.el || item.sec ? true : false}
                      onChange={handleExerciseInput_stretch}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`stretch-el${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="el"
                      min="1"
                      placeholder="E/L"
                      dataid={index}
                      // required
                      disabled={item.es || item.reps || item.sec ? true : false}
                      value={item.el}
                      onChange={handleExerciseInput_stretch}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`stretch-secs${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="sec"
                      min="1"
                      placeholder="Secs"
                      dataid={index}
                      value={item.sec}
                      // required
                      disabled={item.es || item.el || item.reps ? true : false}
                      onChange={handleExerciseInput_stretch}
                    />
                    <Form.Control.Feedback type="invalid">
                      Required (any)
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="col-md">
                  <Form.Group controlId={`stretch-break${index}-${props.day}`}>
                    <Form.Control
                      type="number"
                      name="break_time"
                      placeholder="Break Time"
                      min="1"
                      dataid={index}
                      value={item.break_time}
                      onChange={handleExerciseInput_stretch}
                    />
                  </Form.Group>
                </Col>

                <Col md="1" className="text-right">
                  {index == 0 && arr.length == 1 && (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      dataid={index}
                      disabled={!checkStretchExcValidation(formInputs)}
                      onClick={(e) => handleAddStretchFields(e, index)}
                      className="font-20"
                    />
                  )}
                  {arr.length > 0 && arr.length - 1 != index && (
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      dataid={index}
                      onClick={handleRemoveStretchFields}
                      className="font-20"
                    />
                  )}
                  {arr.length - 1 == index && index != 0 && (
                    <>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        dataid={index}
                        disabled={!checkStretchExcValidation(formInputs)}
                        onClick={(e) => handleAddStretchFields(e, index)}
                        className="font-20 mr-2"
                      />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        dataid={index}
                        onClick={handleRemoveStretchFields}
                        className="font-20"
                      />
                    </>
                  )}
                </Col>
              </Form.Row>
            )
          )}
        </div>

        {alert && formError && formError?.length > 0 && (
          <div>
            <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
              <Alert.Heading>Action required on the below items:</Alert.Heading>
              <ul>
                {formError.map((err, idx) => (
                  <li key={idx}>{err}</li>
                ))}
              </ul>
            </Alert>
          </div>
        )}

        {/* buttons */}
        <div className="mainCtrls d-flex justify-content-end pt-3">
          <div>
            <Button
              variant="link"
              onClick={prev}
              className="mr-3"
              disabled={props.currentPage == 0 ? true : false}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="font-20" />
            </Button>
            <Button
              variant="link"
              onClick={next}
              disabled={props.currentPage == props.sections - 1}
            >
              <FontAwesomeIcon icon={faArrowRight} className="font-20" />
            </Button>
          </div>
        </div>
        <div className="mainCtrls d-flex align-items-center pt-3">
          {/* <div>
             <Button
              variant="secondary"
              onClick={(e) => {
                handleSubmit(e, "draft");
              }}
              className="mr-3"
            >
              Save Draft
            </Button> 
          </div> */}
          <div>
            <Buttons
            // variant="secondary"
            onClick={(e) => {
              handleSubmit(e, "draft");
            }}
            loading={loadingBtn}
            disabled={loadingBtn}
            className="mr-3 btn btn-secondary"
            >
              Save Draft
            </Buttons>
          </div>
          <div className="ml-auto">
            <Button
              variant="primary"
              // disabled={formError.length > 0}
              onClick={(e) => {
                handleSubmit(e, "publish");
              }}
            >
              Publish
            </Button>
          </div>
        </div>

        <div className="editCtrls d-flex justify-content-end mt-5">
          <div className="ml-auto pt-3">
            <Button
              variant="secondary"
              onClick={prev}
              className="mr-3"
              disabled={props.currentPage == 0 ? true : false}
            >
              Prev
            </Button>
            <Button
              variant="primary"
              onClick={next}
              disabled={props.currentPage == 6 ? true : false}
              // type="submit"
              name="submit"
              className="mr-3"
            >
              Next
            </Button>
          </div>
        </div>
      </Form>
    </>
  ) : (
    <h5 className="mt-3">Loading... Please wait</h5>
  );
}
