import React, { useState, useEffect } from "react";

import {
  getTableData,
  getTableDataGet,
  postMethod,
  getMethod,
  findErrors,
  AccessDenied,
  tableDefault,
  isNotLoading,
} from "../Library";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import ReactPaginate from "react-paginate";

import {
  Form,
  Button,
  Toast,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Table from "../components/tables/table";

import Editor from "../components/editor";
import Dropdown from "../components/Dropdown";

import ModalPop from "../components/modals";
// import ImageBoxUpload from "../components/ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../components/ImageBox/imageboxuploadcrop";

export default function RecipesPage(props) {
  const [tableList, setTableList] = useState([]);
  const [editId, setEditId] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [cuisinesOptions, setCuisinesOptions] = useState([]);
  const [dietOptions, setDietOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [editDataLoaded, setEditDataLoaded] = useState(false);
  const [filterInputs, setFilterInputs] = useState({
    category_id: "",
    diet_type_id: "",
    cuisines_id: "",
  });

  const defIp = {
    name: "",
    description: "",
    image_url: "",
    video_url: "",
    category_id: [],
    diet_type_id: "",
    cuisines_id: "",
  };
  const [formInputs, setFormInputs] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [perPage] = useState(10);

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item, index) => {
        arr.push(item.id);
      });
    return arr;
  }

  const getSelections = (data) => {
    setSelectedOptions(data);
    setFormInputs((prev) => ({
      ...prev,
      category_id: convert(data),
    }));
  };

  const handleFilters = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name;
    setFilterInputs((prev) => ({
      ...prev,
      [name]: value ? value : "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    checkErrors(formInputs);
  };

  const checkErrors = (formData) => {
    let errors = true;
    let reqFields = _.pick(
      formData,
      "name",
      "description",
      "image_url",
      "cuisines_id",
      "diet_type_id"
    );
    errors = findErrors(reqFields);
    if (!errors && formData.category_id.length > 0) {
      createRecipes(formData);
    }
  };

  const createRecipes = async (formData) => {
    // create_or_update_recipe;
    // console.log("formdata", formData);
    const data = {
      url: "create_or_update_recipe",
      body: formData,
    };
    const newData = await postMethod(data);
    showToastFn(newData.message);
    
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      getTableLists();
    }
    else{
      isNotLoading();
    }
  };

  const closeModal = () => {
    setFormInputs(JSON.parse(JSON.stringify(defIp)));
    setEditId();
    setSelectedOptions();
    setModalShow(false);
    // console.log("Im running");
  };

  const openModal = () => {
    setModalShow(true);
    setEditDataLoaded(true);
  };

  const chooseImageFn = (url) => {
    setFormInputs((prev) => ({
      ...prev,
      image_url: url,
    }));
  };

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  function buttonFormatter(cell, row) {
    return (
      <>
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            setEditId(cell);
            setModalShow(true);
            editClient(cell);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button
          variant="link"
          dataid={cell}
          onClick={() => {
            trashClient(cell);
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const editClient = (id) => {
    getRecipeData(`view_recipe/${id}`);
  };

  const getRecipeData = async (url) => {
    const data = {
      url: url,
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      let respData = newData.data.recipe;
      setSelectedOptions(respData.category_id);
      respData.category_id = convert(respData.category_id);
      setFormInputs(respData);
      setEditDataLoaded(true);
    } else {
      showToastFn(newData.message);
    }
  };

  function trashClient(id) {
    confirmAlert({
      title: `Confirm Delete?`,
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const data = {
              url: `delete_recipe/${id}`,
            };
            const newData = await getMethod(data);
            showToastFn(newData.message);
            if (newData.status == 1) {
              closeModal();
              getTableLists();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  }

  const tableColumns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "name", text: "Recipe Name" },
    { dataField: "category_name", text: "Recipe Category" },
    { dataField: "diet_type_name", text: "Diet Type" },
    { dataField: "cuisines_name", text: "Cuisine" },
    { dataField: "id", text: "Action", formatter: buttonFormatter },
  ];

  const tableFilters = () => {
    return <> </>;
  };

  const gettypedData = (data) => {
    setFormInputs((prev) => ({
      ...prev,
      description: data,
    }));
  };

  const modalBody = () => {
    return (
      <>
        <Form
          className={editDataLoaded ? "" : "d-none"}
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col md="8">
              {/* input box */}
              <Form.Group controlId="title">
                <Form.Label className="required">Recipe Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  required
                  value={formInputs && formInputs.name}
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This Field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="videoUrl">
                <Form.Label>Add Recipe Video URL</Form.Label>
                <Form.Control
                  type="text"
                  name="video_url"
                  placeholder="https://example.com"
                  // required
                  value={
                    formInputs && formInputs.video_url
                      ? formInputs.video_url
                      : null
                  }
                  onChange={handleInput}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4" className="mt-3">
              <div className="d-flex align-items-start flex-wrap">
                {/* <ImageBoxUpload
                  data={chooseImageFn}
                  image_url={
                    formInputs && formInputs.image_url
                      ? formInputs.image_url
                      : ""
                  }
                /> */}
                <ImageBoxUploadCrop
                  folder="recipe"
                  as_w="4"
                  as_h="3"
                  dataid=""
                  data={chooseImageFn}
                  image_url={
                    formInputs && formInputs.image_url
                      ? formInputs.image_url
                      : ""
                  }
                />
                {validated &&
                  ((formInputs && formInputs.image_url == null) ||
                    (formInputs && formInputs.image_url == "")) && (
                    <div className="err-feedback text-center">
                      This field is required
                    </div>
                  )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col >
              <Form.Group controlId="recipe-category">
                <Form.Label className="required">Recipe Category</Form.Label>
                {/* <Form.Control
                  as="select"
                  name="category_id"
                  required
                  value={
                    formInputs && formInputs.category_id
                      ? formInputs.category_id
                      : ""
                  }
                  onChange={handleInput}
                >
                  <option value="">Choose Category</option>
                  {categoryOptions &&
                    categoryOptions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback> */}

                <Dropdown
                  options={categoryOptions}
                  value={selectedOptions}
                  getSelections={getSelections}
                  name="category_id"
                />
                {validated && formInputs?.category_id?.length == 0 && (
                  <div className="err-feedback">This Field is required</div>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="recipe-diet">
                <Form.Label className="required">Diet Type</Form.Label>
                <Form.Control
                  as="select"
                  name="diet_type_id"
                  required
                  value={
                    formInputs && formInputs.diet_type_id
                      ? formInputs.diet_type_id
                      : ""
                  }
                  onChange={handleInput}
                >
                  <option value="">Choose Diet Type</option>
                  {dietOptions &&
                    dietOptions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="recipe-cuisines">
                <Form.Label className="required">Cuisines</Form.Label>
                <Form.Control
                  as="select"
                  name="cuisines_id"
                  required
                  value={
                    formInputs && formInputs.cuisines_id
                      ? formInputs.cuisines_id
                      : ""
                  }
                  onChange={handleInput}
                >
                  <option value="">Choose Cuisines</option>
                  {cuisinesOptions &&
                    cuisinesOptions.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="description" className="position-relative">
            <Form.Label className="required">Description</Form.Label>
            <Editor
              text={
                formInputs && formInputs.description
                  ? formInputs.description
                  : ""
              }
              data={gettypedData}
            />
            {formInputs && !formInputs.description && validated && (
              <div className="err-feedback">This field is required</div>
            )}
          </Form.Group>

          <div className="d-flex justify-content-end mt-5 mb-2">
            <Button
              variant="secondary"
              onClick={() => {
                closeModal();
              }}
              className="mr-3"
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {editId ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
        <h3 className={editDataLoaded ? "d-none" : "my-5 mx-3"}>
          Loading Data. Please wait...
        </h3>
      </>
    );
  };

  const handleSearchInput = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
      setOffset(1);
    }, 1000);

    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };

  const getTableListsFilter = async () => {
    const data = {
      url: "list_recipe",
      body: {
        search: { value: searchValue },
        length: 10,
        start: offset == 1 ? 0 : (offset - 1) * 10,
        draw: 10,
        ...filterInputs,
      },
    };
    // console.log(data);
    const newData = await postMethod(data);
    if (newData && newData.data.recipe_list) {
      isNotLoading();
      setPageCount(Math.ceil(newData.data.recordsTotal / perPage));
      setTableList(newData.data.recipe_list);
    }
    else{
      isNotLoading();
    }
  };

  const getTableLists = async () => {
    let x = await getTableListsFilter();
    if (x && x.recipe_list) {
      setTableList(x.recipe_list);
    }
  };

  const getCategoryOptions = async () => {
    let x = await getTableDataGet("list_recipe_category");
    if (x && x.recipe_category_list) {
      setCategoryOptions(x.recipe_category_list);
    }
  };
  const getDietOptions = async () => {
    let x = await getTableDataGet("list_recipe_diet_type");
    if (x && x.recipe_diet_type_list) {
      setDietOptions(x.recipe_diet_type_list);
    }
  };
  const getCuisinesOptions = async () => {
    let x = await getTableDataGet("list_recipe_cuisine");
    if (x && x.recipe_cuisines_list) {
      setCuisinesOptions(x.recipe_cuisines_list);
    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // do somehting
      setFormInputs(JSON.parse(JSON.stringify(defIp)));
      getTableLists();
      getCategoryOptions();
      getDietOptions();
      getCuisinesOptions();
    }
  }, [
    pageLoaded,
    setPageLoaded,
    filterInputs,
    setFilterInputs,
    offset,
    searchValue,
  ]);

  // useEffect(() => {
  //   console.log("form ", formInputs);
  // }, [formInputs, setFormInputs]);

  return (
    <section className="rightContent">
      {props.menuList.includes(8.1) ? (
        <>
          <div className="d-flex align-items-center mb-3 ">
            <h3>Manage Recipes</h3>
            <Button
              variant="primary"
              className="ml-auto"
              onClick={(e) => {
                openModal();
              }}
            >
              Add Recipes
            </Button>
          </div>

          <div className=" align-items-center mt-ng row ">
            <div className="col-8 d-flex">
            <Form.Group
              controlId="recipe-category-filter"
              className="mr-3 mb-2 mwx-400"
            >
              {/* <Form.Label className="required">Recipe Category</Form.Label> */}
              <Form.Control
                as="select"
                name="category_id"
                value={filterInputs.category_id}
                onChange={handleFilters}
              >
                <option value="">Choose Recipe Category</option>
                {categoryOptions &&
                  categoryOptions.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group
              controlId="recipe-diet-filter"
              className="mr-3 mb-2 mwx-400"
            >
              {/* <Form.Label className="required">Diet Type</Form.Label> */}
              <Form.Control
                as="select"
                name="diet_type_id"
                value={filterInputs.diet_type_id}
                onChange={handleFilters}
              >
                <option value="">Choose Diet Type</option>
                {dietOptions &&
                  dietOptions.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>

            <Form.Group
              controlId="recipe-cuisines-filter"
              className="mr-3 mb-2 mwx-400"
            >
              {/* <Form.Label className="required">Cuisines</Form.Label> */}
              <Form.Control
                as="select"
                name="cuisines_id"
                value={filterInputs.cuisines_id}
                onChange={handleFilters}
              >
                <option value="">Choose Cuisines</option>
                {cuisinesOptions &&
                  cuisinesOptions.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            </div>
           <div className="col-4 d-flex justify-content-end" >
            <Button
              variant="secondary"
              className="mr-3 mb-2"
              onClick={() => {
                setFilterInputs({
                  category_id: "",
                  diet_type_id: "",
                  cuisines_id: "",
                });
                getTableLists();
              }}
              disabled={
                filterInputs.category_id != "" ||
                filterInputs.diet_type_id != "" ||
                filterInputs.cuisines_id != ""
                  ? false
                  : true
              }
            >
              Clear
            </Button>
            <Button
              variant="primary"
              className="mb-2"
              onClick={() => {
                getTableListsFilter();
              }}
              disabled={
                filterInputs.category_id != "" ||
                filterInputs.diet_type_id != "" ||
                filterInputs.cuisines_id != ""
                  ? false
                  : true
              }
            >
              Apply Filter
            </Button>
            </div>
          </div>
          <div className="d-flex justify-content-start mt-5">
            <InputGroup className="search-register-users">
              <FormControl
                placeholder="Search"
                aria-label="search"
                aria-describedby="basic-addon1"
                className="search-regUser-input search-border"
                onChange={handleSearchInput}
              />
            </InputGroup>
          </div>
          <Table
            data={tableList}
            columns={tableColumns}
            filters={tableFilters}
            searchBar="false"
            offset={offset}
          />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              // onPageActive={onActivePage}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
          <ModalPop
            show={modalShow}
            onEnter={() => {
              setValidated(false);
            }}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead={`${editId ? "Edit" : "Add"} Recipes`}
            disableEnforceFocus={true}
          />
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
