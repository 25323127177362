import React, { useState, useEffect } from "react";
import { Button, Form, Toast } from "react-bootstrap";
import Table from "../components/tables/table";
import { Formik } from "formik";
import * as yup from "yup";
import { postMethod, postMethodFormData, AccessDenied, handleFalseStatus, isNotLoading, getMethod } from "../Library";
import ModalPop from "../components/modals";
import ImageBoxUploadCrop from "../components/ImageBox/imageboxuploadcrop";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt, faEye } from "@fortawesome/free-solid-svg-icons";

export default function PromotionalContentPage(props) {
  const [validated, setValidated] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [isImage, setFormImage] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [editId, setEditId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  let formDataBody = new FormData();

  function btnFormatter(cell, row) {
    return (
      <>
        <Button variant="link" className="mt-1" dataid={row.id}
          onClick={() => {
            viewPromotionalContent(row);
            setModalShow(true);
            setModalHead("View");
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
        |
        <Button
          variant="link"
          dataid={row.id}
          onClick={() => {
            editPromotionalContent(row);
            setModalShow(true);
            setModalHead("Edit");
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
        |
        <Button variant="link" dataid={row.id} onClick={trashPromotionalContent}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const viewPromotionalContent = (data) => {
    let id = data.id;
    let form = { ...formInputs };
    form["content_name"] = data.content_name;
    form["id"] = data.id;
    if (data.promotional_image_url) {
      form["promotional_image_url"] = data.promotional_image_url;
    }
    setFormInputs(form);
  }
  const trashPromotionalContent = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "delete_promotional_content",
      body: {
        id: id,
      },
    };
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);

            showToastFn(newData.message);
            if (newData.status === 1) {
              isNotLoading();
              getTableList();
            }
            else {
              isNotLoading();
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const columns = [
    { dataField: "sno", text: "S.No", searchable: false },
    { dataField: "content_name", text: "Content Name" },
    {
      dataField: "id",
      text: "Action",
      searchable: false,
      formatter: btnFormatter,
    },
  ];

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getTableList();
      if (editId) {
      } else {
        setFormInputs({
          content_name: "",
          promotional_image_url: "",
        });
      }
    }
  }, [pageLoaded, setPageLoaded]);

  // edit Promotional Content
  const editPromotionalContent = (data) => {
    let form = { ...formInputs };
    form["content_name"] = data.content_name;
    form["id"] = data.id;
    if (data.promotional_image_url) {
      form["promotional_image_url"] = data.promotional_image_url;
    }
    setFormInputs(form);
  };

  // get PromotionalContent Table data
  const getTableList = async () => {
    const data = {
      url: "promotional_content_list",

    };
    let tableList = await getMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setTableList(tableList.data);
    } else if (tableList.status === false) {
      isNotLoading();
      handleFalseStatus(tableList);
    } else if (tableList.status === 0) {
      isNotLoading();
      setTableList([]);
    }
  };

  // form functions
  const formSchema = yup.object({
    content_name: yup.string().required("This field is required"),
  });

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const chooseImageFn = (url) => {
    let form = { ...formInputs };
    form.promotional_image_url = url;
    setFormInputs(form);
    setValidated(true)
  };

  const handleSubmit = () => {
    for (const [key, value] of Object.entries(formInputs)) {
      formDataBody.append(key, value);
    }
    formInputs.promotional_image_url && addNewPromotionalContent();
  };

  // add new promotional content
  const addNewPromotionalContent = async (type) => {
    const data = {
      url: "promotional_content_create_or_update",
      body: formDataBody,
    };
    const newData = await postMethodFormData(data);

    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      getTableList();
    }
    else {
      isNotLoading();
    }
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs({});
    setFormDirty(false);
  };

  const modalBody = () => {
    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          if (values) {
            setFormImage(false);
            handleSubmit();
          } else {
            setFormImage(true);
          }
        }}
        initialValues={{
          content_name: formInputs && formInputs.content_name ? formInputs.content_name : "",
          promotional_image_url:
            formInputs && formInputs.promotional_image_url ? formInputs.promotional_image_url : "",
        }}
      >
        {({
          validateForm,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          dirty,
          isValid,
          isInvalid,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setFormDirty(true);
              setValidated(false)
            }}
          >
            <div className="column">
              <div className="col-md-8">
                <Form.Group controlId="content_name">
                  <Form.Label className="required">Content name</Form.Label>
                  <Form.Control
                    name="content_name"
                    type="text"
                    required
                    value={values.content_name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.content_name && touched.content_name ? true : false}
                    disabled={modalHead == "View" ? true : false}
                    className="promoContentName"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.content_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-6 position-relative">
                <Form.Group controlId="promotional_image_url" className={modalHead == "View" ? "promoImageDisable" : "promoImage"}>
                  <Form.Label className="required">( 375*382)</Form.Label>
                  <ImageBoxUploadCrop
                    folder="promotional"
                    rc_w="375"
                    rc_h="382"
                    as_w="375"
                    as_h="382"
                    dataid=""
                    data={chooseImageFn}
                    image_url={formInputs ? formInputs.promotional_image_url : ""}
                  />
                  {!validated && !formInputs?.promotional_image_url && (
                    <div className="err-feedback text-center my-3">
                      This field is required
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Button
                variant={modalHead == "View" ? "primary" : "secondary"}
                onClick={() => {
                  closeModal();
                }}
                className="mr-3"
              >
                {modalHead == "View" ? "Close" : "Cancel"}
              </Button>
              {
                modalHead != 'View' ?
                  <Button variant="primary" type="submit">
                    {modalHead == "Add" ? "Add" : "Update"}
                  </Button>
                  : ''
              }

            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const tableFilters = () => {
    return <> </>;
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.12) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">Promotional Content </h3>
            <Button
              className="h-100"
              onClick={() => {
                setModalShow(true);
                setModalHead("Add");
                setValidated(true)
              }}
            >
              Add new
            </Button>
          </div>
          <Table data={tableList} columns={columns} filters={tableFilters} />
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} Promotional Content`}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
