import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
// import _ from "lodash";

// import { postMethod } from "../../Library";

export default function Index(props) {
  const handleChange = (data) => {
    props.getSelections(data, props.name);
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      //   console.log(data);
      data.forEach((item) => {
        finalArr.push({
          value: item?.name ? item.name : item?.title,
          label: item?.name ? item.name : item?.title,
          name: item?.name ? item.name : item?.title,
          id: item.id,
        });
      });
    }
    return finalArr;
  };

  return (
    <Select
      value={formatSelectOptions(props.value)}
      isMulti
      name={props.name ? props.name : "dropdown"}
      isDisabled={props?.isDisabled ? true : false}
      options={props.options ? formatSelectOptions(props.options) : []}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(selectedOption) => handleChange(selectedOption)}
      placeholder={props?.placeholder ? props.placeholder : "Select..."}
    />
  );
}

export function DropdownCreatable(props) {
  // const createCategoryFn = async (value) => {
  //   console.log(value);
  //   const data = {
  //     url: "category_create_or_update",
  //     body: {
  //       name: value[0].label,
  //       type: "program",
  //     },
  //   };
  //   const newData = await postMethod(data);
  //   console.log(newData);
  // };

  const handleChange = (value, actionMeta) => {
    props.getSelections(value);
    // console.log(`action: ${actionMeta.action}`);
    // const newCategory = _.filter(value, { __isNew__: true });
    // if (newCategory && newCategory.length > 0) {
    // createCategoryFn(newCategory);
    // props.getSelections(value);
    // console.log("value", value);
    // let result = [];
    // value.forEach((item) => {
    //   let arr = _.omit(item, ["__isNew__"]);
    //   result.push(arr);
    // });
    // console.log("result", result);
    // } else {
    //   props.getSelections(value);
    // }
  };

  const formatSelectOptions = (data) => {
    let finalArr = [];
    if (data && data.length > 0) {
      data.forEach((item) => {
        finalArr.push({
          ...item,
          name: item.name ? item.name : item.label,
          value: item.name ? item.name : item.label,
          label: item.name ? item.name : item.label,
        });
      });
    }
    return finalArr;
  };

  return (
    <CreatableSelect
      value={formatSelectOptions(props.value)}
      isMulti
      name={props.name ? props.name : "dropdown"}
      options={props.options ? formatSelectOptions(props.options) : []}
      noOptionsMessage={() => null}
      placeholder="Type to add category..."
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={handleChange}
    />
  );
}
