import React, { useState, useEffect } from "react";

import { Form, Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

import { isNotLoading, postMethod } from "../Library";

export default function ResetpswdPage() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailAdd, setEmailAdd] = useState();
  const [loginStatus, setLoginStatus] = useState(false);

  const [validated, setValidated] = useState(false);
  const [loginError, setLoginError] = useState();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [validationHead, setValidationHead] = useState({
    id: "",
    email: "",
  });

  let { token } = useParams();

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    let pass = e.target.password.value;

    if (pass) {
      const objdata = {
        password: pass,
        ...validationHead,
      };
      restPass(objdata);
      setLoginError();
      setLoginStatus(true);
    }
  };

  const restPass = async (objdata) => {
    const data = {
      url: "reset_password",
      body: objdata,
    };
    const newData = await postMethod(data);
    // console.log(newData);
    if (newData.status === 1) {
      isNotLoading();
      // console.log(newData);
      setShowSuccess(true);
    } else {
      isNotLoading();
      setLoginStatus(false);
      setLoginError(newData);
    }
  };

  async function checkVerifiction() {
    localStorage.clear();
    const data = {
      url: "check_verification_code",
      body: {
        reset_key: token,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      setValidationHead({
        user_id: newData.data.users.id,
        mail_id: newData.data.users.email,
      });
      setEmailAdd(newData.data.users.email);
    } else {
      isNotLoading();
      showToastFn(newData.message);
    }
  }

  useEffect(() => {
    if (token) {
      checkVerifiction();
    }
  }, []);

  return (
    <>
      <div className="centerForm">
        <Form
          noValidate
          validated={validated}
          className="d-flex align-items-center flex-column resetpassBox"
          onSubmit={handleSubmit}
        >
          <h3 className="text-center mb-5">Reset Password</h3>
          {showSuccess ? (
            <>
              <p className="text-center pl-5 pr-5">
                <span>Success! Your password has been reset.</span>
                <br />
                <br />
                <span>
                  Please login with your email id <br />
                  {emailAdd}
                </span>
              </p>
              <br />
              <Link variant="primary" to="/" className="btn btn-primary">
                Ok
              </Link>
            </>
          ) : (
            <>
              <p className="text-center mb-3">Please enter a new password</p>
              <Form.Group className="w-100 mb-0" controlId="email">
                <Form.Control
                  type="text"
                  name="password"
                  autoComplete="off"
                  required
                  placeholder="Enter your password"
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              {loginError && (
                <p className="text-center mt-2 mb-0 text-capitalize err-feedback">
                  {loginError.message}
                </p>
              )}
              {loginStatus && (
                <small className="text-center mt-2 mb-0 text-capitalize">
                  Verifying...
                </small>
              )}
              <Button variant="primary" type="submit" className="mb-3 mt-4">
                Reset Password
              </Button>
            </>
          )}
        </Form>
      </div>

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </>
  );
}
