import React from "react";
import { useParams } from "react-router-dom";
import Player from "../components/player";
import ReactPlayer from "../components/player/reactplayer";

export default function PlayerPage() {
  const { id, videoid } = useParams();
  return (
    <>
      <ReactPlayer workoutId={id} videoId={videoid} />
      {/* <Player /> */}
    </>
  );
}
