import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import { Button, Form, Toast } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Table from "../components/tables/table";
import { Formik } from "formik";
import * as yup from "yup";

import {
  postMethod,
  postMethodFormData,
  AccessDenied,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import ModalPop from "../components/modals";
// import ImageBoxUpload from "../components/ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../components/ImageBox/imageboxuploadcrop";
import ImageBoxUploadName from "../components/ImageBox/imageboxuploadname";
// import { confirmAlert } from "react-confirm-alert";
// import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

export default function OnBoardingPage(props) {
  const [tableList, setTableList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [modalHead, setModalHead] = useState();
  const [formDirty, setFormDirty] = useState(false);
  const [formInputs, setFormInputs] = useState();
  const [editId, setEditId] = useState("");

  let formDataBody = new FormData();

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getContents();
      if (editId) {
        // console.log("EDITiD", editId);
        // getEditData(props.editId);
      } else {
        setFormInputs({
          title: "",
          image_url: "",
          description: "",
        });
      }
    }
  }, [pageLoaded, setPageLoaded]);

  const showToastFn = (msg) => {
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
    setShowToast(true);
  };

  const formSchema = yup.object({
    title: yup.string().required("This field is required"),
    description: yup.string().required("This field is required"),
  });

  const handleInput = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;
    let form = { ...formInputs };
    form[name] = value;
    setFormInputs(form);
  };

  const chooseImageFn = (url) => {
    let form = { ...formInputs };
    form.image_url = url;
    setFormInputs(form);
  };

  const handleSubmit = () => {
    // console.log("formIn", formInputs);
    for (const [key, value] of Object.entries(formInputs)) {
      formDataBody.append(key, value);
    }
    formDataBody.append("type", "landing-page");
    addNewContent();
  };

  // getContents data
  const getContents = async () => {
    const data = {
      url: "content_list",
      body: {
        id: "",
        type: "landing-page",
      },
    };
    let tableList = await postMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setTableList(tableList.data.contents);
    } else if (tableList.status === false) {
      handleFalseStatus(tableList);
      isNotLoading();
    }
  };

  // add new equipmeny
  const addNewContent = async (type) => {
    const data = {
      url: "content_create_or_update",
      body: formDataBody,
    };
    const newData = await postMethodFormData(data);
    showToastFn(newData.message);
    if (newData.status == 1) {
      closeModal();
      getContents();
      isNotLoading();

    }
    else{
      isNotLoading();

    }
  };

  const closeModal = () => {
    setModalShow(false);
    setFormInputs({});
    setFormDirty(false);
  };

  // editContents
  const editContents = (data) => {
    // console.log("data", data);
    let form = { ...formInputs };
    form["name"] = data.name;
    form["id"] = data.id;
    form["title"] = data.title;
    form["description"] = data.description;
    if (data.image_url) {
      form["image_url"] = data.image_url;
    }
    setFormInputs(form);
    // console.log("data", form);
  };

  const modalBody = () => {
    return (
      <Formik
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          if (values && formInputs.image_url) {
            handleSubmit();
          }
        }}
        initialValues={{
          title: formInputs && formInputs.title ? formInputs.title : "",
          image_url:
            formInputs && formInputs.image_url ? formInputs.image_url : "",
          description:
            formInputs && formInputs.description ? formInputs.description : "",
        }}
      >
        {({
          validateForm,
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          dirty,
          isValid,
          isInvalid,
          errors,
        }) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
              setFormDirty(true);
            }}
          >
            <div className="row">
              <div className="col-md-8">
                <Form.Group controlId="name">
                  <Form.Label className="required">Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    required
                    value={values.title}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleInput(e);
                    }}
                    isInvalid={errors.title && touched.title ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-4 position-relative">
                {/* <ImageBoxUpload
                  data={chooseImageFn}
                  image_url={formInputs ? formInputs.image_url : ""}
                /> */}
                {/* <ImageBoxUploadCrop
                  folder="onboard"
                  as_w="4"
                  as_h="3"
                  dataid=""
                  data={chooseImageFn}
                  image_url={formInputs ? formInputs.image_url : ""}
                /> */}
                <ImageBoxUploadName
                  data={chooseImageFn}
                  // name={index}
                  image_url={formInputs ? formInputs.image_url : ""}
                />


                {formDirty && !formInputs.image_url ? (
                  <div className="err-feedback">This field is required</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <Form.Group controlId="description" className="position-relative">
                <Form.Label className="required">Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  rows={3}
                  maxLength="600"
                  required
                  value={values.description}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    handleInput(e);
                  }}
                  isInvalid={
                    errors.description && touched.description ? true : false
                  }
                />

                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <Button
                variant="secondary"
                onClick={() => {
                  closeModal();
                  // setModalHead("Add");
                }}
                className="mr-3"
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {modalHead == "Add" ? "Add" : "Update"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(9.5) ? (
        <>
          <div className="d-flex align-items-center mb-5 justify-content-between">
            <h3 className="text-capitalize">Manage onBoarding content</h3>
            {tableList && tableList.length < 5 && (
              <Button
                className="h-100"
                onClick={() => {
                  setModalShow(true);
                  setModalHead("Add");
                }}
              >
                Add new
              </Button>
            )}
          </div>
          {tableList.length > 0 ? (
            tableList.map((x, i) => {
              return (
                <div className="mb-5 bg-white p-3 rounded" key={i}>
                  <div className="row">
                    <div className="col-md-7">
                      <h4>{x.title}</h4>
                      <p className="mt-3 mb-0">{x.description}</p>
                    </div>
                    <div className="col-md-4">
                      <img
                        className="w-100 rounded onboardImg"
                        alt="on-boarding-content-img"
                        src={
                          x.image_url
                            ? x.image_url
                            : "https://image.shutterstock.com/image-photo/bright-spring-view-cameo-island-260nw-1048185397.jpg"
                        }
                      />
                    </div>
                    <div className="col-md-1">
                      <Button
                        variant="link"
                        onClick={() => {
                          setModalShow(true);
                          setModalHead("Edit");
                          editContents(x);
                        }}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No data found</p>
          )}
          <ModalPop
            show={modalShow}
            onHide={() => {
              closeModal();
              //   setEditFaqBlogData();
            }}
            modalcontent={modalBody()}
            modalhead={`${modalHead} Content`}
          />
          <div className="toastMessage">
            <Toast
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={4000}
              autohide
            >
              <Toast.Body> {toastMessage} </Toast.Body>
            </Toast>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </section>
  );
}
