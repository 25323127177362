import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import {
  Button,
  Toast,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "../components/tables/table";
// import { Formik } from "formik";
// import * as yup from "yup";

import {
  postMethod,
  getMethod,
  AccessDenied,
  tableDefault,
  handleFalseStatus,
  isNotLoading,
} from "../Library";
import ReactPaginate from "react-paginate";
import { ModalPopMd } from "../components/modals";
import Dropdown from "../components/Dropdown";

// import ImageBoxUpload from "../components/ImageBox/imageboxupload";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCopy, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import DropdownSingle from "../components/Dropdown/singledropdown";

export default function DietPlanPage(props) {
  const [tableList, setTableList] = useState([]);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  // const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [perPage] = useState(10);

  const [selectedOptions, setSelectedOptions] = useState();
  const [selDietOptions, setSelDietOptions] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState();
  const [duplicateID, setDuplicateID] = useState();
  const [weekOptions, setWeekOptions] = useState();
  const [tableFilter, setTableFilter] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({
    dietOptions: [],
    programOptions: [],
  });
  const [conditionValue, setConditionsValue] = useState([]);

  const [filterList, setFilterList] = useState();
  const [programName, setProgramName] = useState("");
  const [dietPlanName, setDietPlanName] = useState("");
  const [goal, setGoal] = useState("");
  const [condition, setCondition] = useState("");
  const [gender, setGender] = useState("");
  const [clear, setClear] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const closeModal = () => {
    setModalShow(false);
    setSelectedWeeks();
    setSelectedOptions();
    setDuplicateID();
  };

  const openModal = (e) => {
    const id = e.target.getAttribute("dataid");
    setDuplicateID(id);
    setModalShow(true);
  };

  function convert(data) {
    let arr = [];
    data &&
      data.forEach((item) => {
        arr.push(item.value);
      });
    return arr;
  }
  const getSelections = (data, name) => {
    if (name == "week-list") {
      setSelectedOptions(data);
      setSelectedWeeks(convert(data));
    } else if (name == "diet_plan_name") {
      setSelDietOptions(data);
      setOffset(1);
      // console.log(data);
      setTableFilter((prev) => ({
        ...prev,
        [name]: data?.length > 0 ? data[0].value : "",
      }));
    } else if (name == "conditions") {
      console.log(data)
      setConditionsValue(data);
      setTableFilter((prev) => ({
        ...prev,
        conditions:
          data?.length > 0
            ? data.map((item) => {
              return item.value;
            })
            : "",
      }));
    } else if (name == "goal") {
      setGoal(data);
      setTableFilter((prev) => ({
        ...prev,
        [name]: data,
      }));
    } else if (name == "gender") {
      setGender(data);
      setTableFilter((prev) => ({
        ...prev,
        [name]: data,
      }));
    }
  };

  const dupMeal = async () => {
    const data = {
      url: "duplicate_meal_plan_by_id",
      body: {
        diet_plan_id: parseInt(duplicateID),
        program_week: selectedWeeks,
      },
    };
    createMealPl(data);
  };

  const dupSplMeal = async (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "duplicate_meal_plan_by_id",
      body: {
        diet_plan_id: parseInt(id),
        program_week: [],
      },
    };
    createMealPl(data);
  };

  const createMealPl = async (body) => {
    const newData = await postMethod(body);

    showToastFn(newData.message);
    if (newData.status == 1) {
      isNotLoading();
      closeModal();
      getTableList();
    } else {
      isNotLoading();
    }
  };

  const getWeekList = async () => {
    const data = {
      url: "static_content_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      let arr = newData.data.program_weeks.map((item) => ({
        name: item,
      }));
      setFilterList(newData.data);

      setWeekOptions(arr);
    }
  };

  const getOptions = async () => {
    const data = {
      url: "drop_down_for_meal_plan_list",
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setDropdownOptions({
        dietOptions: newData.data.diet_library,
        programOptions: newData.data.programs,
      });
    }
  };

  const modalBody = () => {
    return (
      <>
        <div>
          <Form.Label className="required">Choose Week(s)</Form.Label>
          <Dropdown
            getSelections={getSelections}
            name="week-list"
            options={weekOptions}
            isDisabled={false}
            value={selectedOptions}
          />
          <small className="mt-3">
            Note: This will create a duplicate Diet Plan for the above week(s)
          </small>
        </div>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              closeModal();
            }}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={dupMeal}
            disabled={!selectedOptions || selectedOptions?.length == 0}
          >
            Submit
          </Button>
        </div>
      </>
    );
  };

  function btnFormatter(cell, row) {
    return (
      <>
        <Link
          variant="link"
          to={{
            pathname: `/manage-diet-plan/add-diet-plan`,
            state: {
              id: cell,
            },
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Link>
        {/* |
        {row.plan_type == "normal" ? (
          <Button variant="link" dataid={row.id} onClick={openModal}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        ) : (
          <Button variant="link" dataid={row.id} onClick={dupSplMeal}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
        )} */}
        |
        <Button variant="link" dataid={cell} onClick={trashWorkout}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </>
    );
  }

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  const trashWorkout = (e) => {
    let id = e.target.getAttribute("dataid");
    const data = {
      url: "meal_plan_delete",
      body: {
        id: id,
      },
    };
    // const newData = await postMethod(data);
    // console.log(newData);
    // console.log("delete ", id);

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const newData = await postMethod(data);
            if (newData.status == 1) {
              isNotLoading();
              showToastFn(newData.message);
              getTableList();
            } else {
              isNotLoading();
              showToastFn(newData.message);
            }
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const columns = [
    { dataField: "sno", text: "S.No" },
    { dataField: "diet_plan_name", text: "Diet plan name" },
    { dataField: "program_name", text: "Program" },
    { dataField: "goal", text: "Goal" },
    { dataField: "gender", text: "Gender" },
    { dataField: "conditions", text: "Conditions" },
    // { dataField: "program_level_name", text: "Level" },
    { dataField: "program_week", text: "Week" },
    { dataField: "display_name", text: "Display Name" },
    { dataField: "id", text: "Action", formatter: btnFormatter },
  ];
  const handleSearchInput = (e) => {
    setTimeout(() => {
      setSearchValue(e.target.value);
      setOffset(1);
    }, 1000);

    // setTimeout(() => {
    // getTableList('getTableList');
    // }, 1500);
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      getTableList(tableFilter);
      getWeekList();
      getOptions();
    }
  }, [pageLoaded, setPageLoaded, offset, searchValue, clear]);

  // getFaqTable data
  const getTableList = async (filter) => {
    const data = {
      url: "meal_plan_list",
      body: {
        search: { value: searchValue },
        length: 10,
        program_name: programName ? programName : "",
        diet_plan_name: selDietOptions ? selDietOptions : "",
        start: offset == 1 ? 0 : (offset - 1) * 10,
        draw: 10,
        // ...filter,
      },
    };
    if (filter) {
      data.body = {
        ...data.body,
        ...filter,
      };
    }
    let tableList = await postMethod(data);
    if (tableList.status === 1) {
      isNotLoading();
      setPageCount(Math.ceil(tableList.data.recordsTotal / perPage));
      setTableList(tableList.data.workouts);
    } else if (tableList.status === false) {
      isNotLoading();
      handleFalseStatus(tableList);
    }
  };
  const clearFilter = () => {
    setProgramName("");
    setGender("");
    setGoal("");
    setConditionsValue({});
    setSelDietOptions(
      selDietOptions?.length > 0 ? (selDietOptions.length = 0) : []
    );
    setTableFilter({});
    setOffset(1);
    setClear(!clear);

    // getTableList({});
  };

  const handleTableFilter = (e) => {
    setProgramName(e.target.value);
    // setTableFilter((prev) => ({
    //   ...prev,
    //   [e.target.name]: e.target.value,
    // }));
  };

  // useEffect(() => {
  //   if (tableFilter) {
  //     getTableList(tableFilter);
  //     console.log(tableFilter,'filter')
  //   }
  // }, [tableFilter, setTableFilter]);

  // useEffect(() => {
  //   getTableList();
  //   setOffset(1);
  // }, [clear]);

  const applyFilter = () => {
    if (tableFilter) {
      getTableList(tableFilter);
      setOffset(1);

    }
  };

  const tableFilters = () => {
    return (
      <>
        <div className="d-block w-100 justify-content-between">
          <div className="d-flex w-100  flex-wrap mb-4">
            <Row>
              <Col>
                <Form.Group
                  className="mwx-300 w-100 mb-0 mr-3"
                  controlId="program-filters"
                >
                  <Form.Control
                    as="select"
                    name="program_name"
                    onChange={handleTableFilter}
                    value={programName}
                  >
                    <option value="">Filter By Program</option>
                    {dropdownOptions.programOptions.map((item, index) => (
                      <option key={index} value={item.program_name}>
                        {item.program_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mwx-300 w-100 mb-0 mr-3"
                  controlId="goal"
                >
                  <Form.Control
                    as="select"
                    name="goal"
                    value={goal}
                    onChange={(e) => getSelections(e.target.value, "goal")}
                  >
                    <option value="">Filter By Goal</option>
                    {filterList?.goal.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mw-300 customSelect text-left"
                  controlId={`conditions`}
                >
                  <Dropdown
                    options={filterList?.conditions.map((item, index) => {
                      return { key: index, title: item };
                    })}
                    value={conditionValue}
                    getSelections={getSelections}
                    name="conditions"
                    placeholder="Filter By conditions"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mwx-300 w-100 mb-0 mr-3"
                  controlId="gender"
                >
                  <Form.Control
                    as="select"
                    name="gender"
                    value={gender}
                    onChange={(e) => getSelections(e.target.value, "gender")}
                  >
                    <option value="">Filter By Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group
              className="mwx-300 w-100 mb-0 customSelect customSelect_mwx text-left"
              controlId="diet-filters"
            >
              <DropdownSingle
                options={dropdownOptions.dietOptions}
                value={selDietOptions}
                getSelections={getSelections}
                name="diet_plan_name"
                placeholder="Filter By Diet Plan"
              />
            </Form.Group>

            <Col className="d-flex justify-content-start">
              <Button
                type="reset"
                variant="secondary"
                className="mb-2 mr-3"
                // disabled={
                //   programName!=="" || tableFilter?.diet_plan_name!==""
                //   ||tableFilter?.goal!==""||
                //   tableFilter?.gender!==""||
                //   tableFilter?.condition!==""
                //   ?false:true
                // }
                // disabled={
                //   programName!=="" || tableFilter.goal!==""
                //   ?false:true
                // }
                // disabled={
                //   filterInputs.nutritionist_first_name != "" ||
                //   filterInputs.nutritionist_last_name != "" ||
                //   filterInputs.program_id != "" ||
                //   filterInputs.from_date != "" ||
                //   filterInputs.to_date != "" ||
                //   filterInputs.program_start_date != "" ||
                //   filterInputs.status != ""
                //     ? false
                //     : true
                // }
                disabled={
                  programName !== "" || Object.entries(tableFilter).length > 0
                    ? false
                    : true
                }
                onClick={clearFilter}
              >
                Clear
              </Button>

              <Button
                type="submit"
                className="mb-2"
                // disabled={
                //   filterInputs.nutritionist_first_name != "" ||
                //   filterInputs.nutritionist_last_name != "" ||
                //   filterInputs.program_id != "" ||
                //   filterInputs.from_date != "" ||
                //   filterInputs.to_date != "" ||
                //   filterInputs.program_start_date != "" ||
                //   filterInputs.status != ""
                //     ? false
                //     : true
                // }
                disabled={
                  programName !== "" || Object.entries(tableFilter).length > 0
                    ? false
                    : true
                }

                onClick={applyFilter}
              >
                Apply Filter
              </Button>
            </Col>
          </div>
          <div className="d-flex justify-content-start">
            <InputGroup className="search-dietplan">
              <FormControl
                placeholder="Search"
                aria-label="search"
                aria-describedby="basic-addon1"
                className="search-regUser-input search-border"
                onChange={handleSearchInput}
              />
            </InputGroup>
          </div>
        </div>
      </>
    );
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(8.4) ? (
        <>
          <div className="d-flex align-items-center mb-3 justify-content-between">
            <h3 className="text-capitalize">Assign Diet Plan</h3>
            <Link
              to="/manage-diet-plan/add-diet-plan"
              className="btn btn-primary"
            >
              Add new
            </Link>
          </div>
          <Table
            data={tableList}
            columns={columns}
            filters={tableFilters}
            searchBar="false"
            offset={offset}
          />
          <div className="d-flex float-right justify-content-end">
            <ReactPaginate
              previousLabel={"prev"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(pageNumber) => setOffset(pageNumber.selected + 1)}
              forcePage={offset - 1}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              // onPageActive={onActivePage}
              className="pagination active"
              activeClassName={"active"}
            />
          </div>
          {/* <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(pageNumber) =>
                  setOffset(pageNumber.selected + 1)
                }
                forcePage={offset - 1}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                // onPageActive={onActivePage}
                className="pagination active"
                activeClassName={"active"}
              /> */}
          <ModalPopMd
            show={modalShow}
            onHide={closeModal}
            modalcontent={modalBody()}
            modalhead="Duplicate Meal Plan"
          />
        </>
      ) : (
        <AccessDenied />
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
