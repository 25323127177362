import React, { useState, useEffect } from "react";
// import SideBar from "../components/sidebar";
import { Button, Form, Col, Toast } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Editor from "../components/editor";
import ImageBoxUploadName from "../components/ImageBox/imageboxuploadname";

import {
  postMethod,
  findErrors,
  findErrorsWeek,
  AccessDenied,
  handleFalseStatus,
  isNotLoading,
} from "../Library";

import { useParams, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import _ from "lodash";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function AddProgramsLevelsPage(props) {
  const [validated, setValidated] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [levels, setLevels] = useState(0);
  const [dirty, setDirty] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [programName, setProgramName] = useState();

  let { id, cat } = useParams();

  const history = useHistory();

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      setToastMessage(JSON.stringify(msg, null, 2));
    }
  };

  // program_levels_create_or_update

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
    return () => {
      setPageLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      if (id && cat) {
        getLevels();
      } else {
        history.push("/add-program/");
      }
    }
  }, [pageLoaded, setPageLoaded]);

  const getLevels = async () => {
    const data = {
      url: "program_create_level",
      body: {
        program_id: id,
        category_id: cat,
      },
    };
    const newData = await postMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      if (newData.data.length > 0) {
        let data = newData.data[0].program_levels;
        let inputFields = [];
        if (data.length > 0) {
          data.map((item, index) => {
            inputFields.push({
              levelName: item.name,
              noOfWeeks: item.weeks,
              graceWeekTime: item.grace_weeks,
              description: item.short_description,
              image_url: item.image_url,
              id: item.id,
            });
          });
          setInputFields(inputFields);
          setProgramName(newData.data[0].master_program_name);
        } else {
          isNotLoading();
          setInputFields([
            {
              levelName: "",
              noOfWeeks: "",
              graceWeekTime: "",
              description: "",
              image_url: "",
              id: "",
            },
          ]);
        }
      } else {
        history.push(`/manage-programs/`);
      }
    } else if (newData.status === false) {
      handleFalseStatus(newData);
    }
  };

  useEffect(() => {
    setLevels(inputFields.length - 1);
  }, [inputFields, setInputFields]);

  // const gettypedData = (data, index) => {
  //   const values = [...inputFields];
  //   values[index].description = data;
  //   setInputFields(values);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    setDirty(true);

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);
    // console.log("inputFields", inputFields);

    formLevelInputFields(inputFields);
  };

  const formLevelInputFields = async (inputs) => {
    let sendArr = [];
    let finalArr = inputs.map((elem, index) => {
      return {
        id: elem.id,
        program_id: id,
        name: elem.levelName,
        weeks: elem.noOfWeeks,
        grace_weeks: elem.graceWeekTime,
        short_description: elem.description,
        image_url: elem.image_url,
      };
    });

    sendArr.push({ levels: finalArr });
    // console.log(sendArr[0]);

    const data = {
      url: "program_levels_create_or_update",
      body: sendArr[0],
    };

    // checkerror
    let errArr = [];
    sendArr[0].levels.forEach((element) => {
      const reqFields = _.pick(
        element,
        "program_id",
        "name",
        "weeks",
        "grace_weeks",
        "image_url",
        "short_description"
      );
      const errors = findErrors(reqFields);
      errArr.push(errors);
    });

    if (errArr.includes(true)) {
      // console.log("prg form error");
    } else {
      let weekErr = [];
      sendArr[0].levels.forEach((element) => {
        const reqFields_week = _.pick(element, "weeks", "grace_weeks");
        const errors_week = findErrorsWeek(reqFields_week);
        weekErr.push(errors_week);
      });
      if (!weekErr.includes(true)) {
        const newData = await postMethod(data);
        if (newData.status == 1) {
          isNotLoading();
          confirmAlert({
            title: "Success",
            message: "Go back to manage programs ?",
            buttons: [
              {
                label: "Yes",
                onClick: () => {
                  history.push(`/manage-programs/`);
                },
              },
              {
                label: "No",
                // onClick: () => console.log("Clicked No"),
              },
            ],
          });
        } else {
          isNotLoading();
          showToastFn(newData.message);
        }
      }
    }
  };

  const handleInputChange = (e, index) => {
    e.preventDefault();
    const values = [...inputFields];

    let name = e.target.name;
    let val = e.target.value;
    values[index][name] = val;
    setInputFields(values);

    // console.log("inputFields", inputFields);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      levelName: "",
      noOfWeeks: "",
      graceWeekTime: "",
      description: "",
      image_url: "",
    });
    setInputFields(values);
    setLevels((prev) => prev + 1);
    resetValidation();
  };

  const resetValidation = () => {
    setValidated(false);
    setDirty(false);
  };

  const handleRemoveFields = (e) => {
    let index = e.target.getAttribute("dataid");
    let id = e.target.getAttribute("levelid");

    confirmAlert({
      title: "Confirm delete ?",
      message: "Are you sure to delete this level and save ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // delete api will be called here
            let values = inputFields;
            // let values = [...inputFields];
            values.splice(index, 1);
            setInputFields(values);
            setLevels((prev) => prev - 1);
            deleteLevels(id, "delete_program_level_only");
          },
        },
        {
          label: "No",
          // onClick: () => console.log("Clicked No"),
        },
      ],
    });
  };

  const deleteLevels = async (id, url) => {
    const data = {
      url: url,
      body: {
        id: id,
      },
    };
    const newData = await (data);
    // console.log(newData);
    showToastFn(newData.message);
  };

  const chooseImageFn = (url, index) => {
    let form = [...inputFields];
    form[index].image_url = url;
    setInputFields(form);
    // console.log(inputFields);
  };

  return (
    <section className="rightContent">
      {props.menuList.includes(5) ? (
        <>
          <h3 className="mb-5">
            {/* <Link to="/manage-programs/add-program/" className="text-underline"> */}
            <span className="mr-1">
              {programName ? programName : "Add Programs"}
            </span>
            {/* </Link> */}
            <FontAwesomeIcon icon={faChevronRight} />
            <span> Levels</span>
          </h3>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="addLevels"
          >
            {inputFields.map((item, index) => (
              <div key={index}>
                <div className="d-flex align-items-center mb-4">
                  <h3 className="no-wrap m-0">Level {index + 1}</h3>
                  <span className="w-100 pl-3 pr-0">
                    <hr className="dark" />
                  </span>

                  {levels == 0 && (
                    <Button
                      className="ml-auto no-wrap"
                      onClick={handleAddFields}
                    >
                      Add Level {levels + 2}
                    </Button>
                  )}
                  {index == levels && levels !== 0 && (
                    <Button
                      className="ml-auto no-wrap"
                      onClick={handleAddFields}
                    >
                      Add Level {levels + 2}
                    </Button>
                  )}
                  {levels > 0 && (
                    <>
                      <span className="pl-0 pr-0" style={{ width: "1rem" }}>
                        <hr className="dark" />
                      </span>
                      <Button
                        className="ml-auto"
                        dataid={index}
                        levelid={item.id}
                        onClick={handleRemoveFields}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
                <Form.Row>
                  <Col md="3">
                    <Form.Group controlId={`level${index}`}>
                      <Form.Label className="required">Level Name</Form.Label>
                      <Form.Control
                        name="levelName"
                        type="text"
                        value={item.levelName}
                        required
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="2">
                    <Form.Group controlId={`weeks${index}`}>
                      <Form.Label className="required">No.Of Weeks</Form.Label>
                      <Form.Control
                        name="noOfWeeks"
                        type="number"
                        min="1"
                        max="53"
                        value={item.noOfWeeks}
                        required
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="3">
                    <Form.Group controlId={`graceweek${index}`}>
                      <Form.Label className="required">
                        Grace Week Time
                      </Form.Label>
                      <Form.Control
                        name="graceWeekTime"
                        type="number"
                        min="1"
                        max="53"
                        value={item.graceWeekTime}
                        required
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                    <Form.Label className="required">
                      Choose an image
                    </Form.Label>
                    <ImageBoxUploadName
                      data={chooseImageFn}
                      name={index}
                      image_url={item ? item.image_url : ""}
                    />
                    {/* {JSON.stringify(item, null, 2)} */}
                    {validated &&
                      item &&
                      (item.image_url == null || item.image_url == "") && (
                        <div className="err-feedback">
                          This field is required
                        </div>
                      )}
                  </Col>
                </Form.Row>

                <Form.Group
                  controlId={`description${index}`}
                  className="position-relative"
                >
                  <Form.Label className="required">
                    Add Short Description
                  </Form.Label>
                  {/* <Editor
                data={gettypedData}
                index={index}
                text={item.description}
              />
              {dirty && inputFields[index].description == "" && (
                <div className="err-feedback">This field is required</div>
              )} */}
                  <Form.Control
                    as="textarea"
                    name="description"
                    rows={3}
                    maxLength="70"
                    required
                    value={item.description}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  <small className="char-len">
                    {item.description && item.description
                      ? 70 - item.description.length
                      : 70}{" "}
                    characters left
                  </small>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mb-5 mt-4">
                  <hr />
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-end mt-5">
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
