import React, { useState, useEffect } from "react";
import { Form, Toast } from "react-bootstrap";
import ChallengeWorkoutForm from "../components/WorkoutForms/challengeworkoutform";
import ProgramBasedWorkoutForm from "../components/WorkoutForms/programbasedworkoutform";
import QuickWorkoutForm from "../components/WorkoutForms/quickworkoutform";

import { AccessDenied } from "../Library";

import { useLocation } from "react-router-dom";
import NewChallengeForm from "../components/WorkoutForms/newChallenge";

export default function WorkoutAddPage(props) {
  const [workoutCategory, setWorkoutCategory] = useState("");
  // const [workoutCategory, setWorkoutCategory] = useState("ProgramBasedWorkout");

  const [pageLoaded, setPageLoaded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastFn = (msg) => {
    setShowToast(true);
    if (typeof msg == "string") {
      setToastMessage(msg);
    } else {
      if (typeof msg == "string") {
        setToastMessage(msg);
      } else {
        setToastMessage(JSON.stringify(msg, null, 2));
      }
    }
  };

  const location = useLocation();

  const handleInput = (e) => {
    setWorkoutCategory(e.target.value);
  };

  const checkIfEdit = () => {
    if (location.state) {
      // console.log("edit workout");
      // console.log("state", location.state);
      setWorkoutCategory(location.state.workout_category_name);

      if (location.state && location.state.editAccess == false) {
        document.querySelector(".rightContent").classList.add("ReadOnly");
      }
    } else {
      // console.log("add workout");
    }
  };

  useEffect(() => {
    props.getMenuList();
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      checkIfEdit();
    }
  }, [pageLoaded, setPageLoaded]);

  return (
    <section className="rightContent">
      {props.menuList.includes(7) ? (
        <>
          <div className="d-flex align-items-center mb-5">
            <h3 className="text-capitalize">
              {location.state && location.state.editAccess == false
                ? "View Workout"
                : location.state && location.state.workout_category_name
                ? "Edit Workout"
                : "Add Workout"}
            </h3>
          </div>

          {/* {workoutCategory == "" && <></>} */}

          <Form.Group controlId="CtID" className="mw-200 max-300">
            <Form.Label>Workout Category</Form.Label>
            <Form.Control
              as="select"
              name="filter"
              disabled={
                location.state && location.state.workout_category_name
                  ? true
                  : false
              }
              value={workoutCategory}
              onChange={handleInput}
            >
              <option value="">Choose Category</option>
              <option value="Challenge Workout">Challenge Workout</option>
              <option value="Quick Workout">Quick Workout</option>
              <option value="Program Based Workout">
                Program Based Workout
              </option>
            </Form.Control>
          </Form.Group>
          <br />

          {workoutCategory == "" && (
            <h5>Choose a workout category and get started !!</h5>
          )}
          {workoutCategory == "Program Based Workout" && (
            <ProgramBasedWorkoutForm
              showToastFn={showToastFn}
              editData={location.state}
            />
          )}
          {workoutCategory == "Quick Workout" && (
            <QuickWorkoutForm
              showToastFn={showToastFn}
              editData={location.state}
            />
          )}
          {workoutCategory == "Challenge Workout" && (
            <ChallengeWorkoutForm
              showToastFn={showToastFn}
              editData={location.state}
            />
            // <NewChallengeForm
            // showToastFn={showToastFn}
            // editData={location.state}/>
          )}
        </>
      ) : (
        <AccessDenied />
      )}

      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body> {toastMessage} </Toast.Body>
        </Toast>
      </div>
    </section>
  );
}
