import React, { useEffect, useState } from "react";
import { Form, Row, Col, Toast, Button } from "react-bootstrap";
// import Editor from "../../components/editor";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
// import ImageBoxUpload from "../ImageBox/imageboxupload";
import ImageBoxUploadCrop from "../ImageBox/imageboxuploadcrop";
// import _ from "lodash";
import DropdownSingle from "../Dropdown/singledropdown";
import { Select } from "antd";
// import autosize from "autosize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import mobile from "../../assets/images/mobileLayout.png";
import backArrow from "../../assets/images/backArrow.png";
import logo from "../../assets/images/nourishwithsim.png";
import simrunChopra3 from "../../assets/images/simrunChopra3.jpg";
import homeIcon from "../../assets/images/home@1x.png";
import programIcon from "../../assets/images/program@1x.png";
import workoutIcon from "../../assets/images/workout@1x.png";
import mealIcon from "../../assets/images/meals@1x.png";
import viewMore from "../../assets/images/viewMore.png";
import rightArrow from "../../assets/images/rightArrow.png";
import colorHeart from "../../assets/images/colorHeart.png";
import notification from "../../assets/images/notification.png";
import heart from "../../assets/images/heart.png";
import circleRightArrow from "../../assets/images/circleRightArrow.png";


import {
  faCloudUploadAlt,
  faSearchPlus,
  faSearchMinus,
  faSyncAlt,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import ModalPop from "../modals";

import {
  postMethod,
  copyObj,
  handleFalseStatus,
  getMethod,
  isNotLoading,
} from "../../Library";
import ToolTipBox from "../ToolTipBox";
import Buttons from "../Button/button";
import { set } from "lodash";
import ImageBoxFreeCrop from "../ImageBox/Imageboxfreecrop";

export default function QuickWorkoutForm(props) {
  const { Option } = Select;
  const [validated, setValidated] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [status, setStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [workoutMasters, setWorkoutMasters] = useState({
    workout_category_name: "Quick Workout",
    workout_type: "quick",
    choose_program_type: 0,
  });
  const [exercise, setExercise] = useState([]);
  const [exerciseWorkout, setExerciseWorkout] = useState([]);
  const [exerciseStrech, setExerciseStrech] = useState([]);
  const [categoryLists, setCategoryLists] = useState([]);
  const [workoutInputs, setWorkoutInputs] = useState({
    workouts: {
      workout_master_id: "",
      workout_day: 1,
      workout_name: "",
      description: "",
      image_url: "",
      workout_type: "quick",
      is_featured: true,
      workout_category_id: "",
    },
    workout_warmups: [
      {
        workout_master_id: "",
        workout_id: "",
        description: "",
        image_url: "",
        workout_warmup_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_warmup_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      },
    ],
    workout_wks: [
      {
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      },
    ],
    workout_stretchs: [
      {
        workout_master_id: "",
        workout_id: "",
        description: "",
        image_url: "",
        workout_stretch_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_stretch_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      },
    ],
  });

  // const textBox1 = useRef(null);
  // const textBox2 = useRef(null);
  // const textBox3 = useRef(null);
  // const textBox4 = useRef(null);

  // useEffect(() => {
  //   autosize(textBox1.current);
  //   autosize(textBox2.current);
  //   autosize(textBox3.current);
  //   autosize(textBox4.current);
  // }, []);

  const [editDataUpdated, setEditDataUpdated] = useState(false);
  const [exerciseList, setExerciseList] = useState([]);

  const [showPopup, setShowpopup] = useState(false);

  const handlePre = () => {
    // alert("hi")
    setShowpopup(!showPopup);
  };

  const history = useHistory();

  const checkFormatDays = (day) => {
    let data = Object.assign({}, day);
    if (data.workout_stretchs.length == 0) {
      data.workout_stretchs.push({
        workout_master_id: "",
        workout_id: "",
        description: "",
        image_url: "",
        workout_stretch_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_stretch_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
    }
    if (data.workout_warmups.length == 0) {
      data.workout_warmups.push({
        workout_master_id: "",
        workout_id: "",
        description: "",
        image_url: "",
        workout_warmup_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_warmup_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
    }
    if (data.workout_wks.length == 0) {
      data.workout_wks.push({
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
    }
    return data;
  };

  function rmvIds(day) {
    if (day) {
      delete day.workouts.id;
      let warmup = day.workout_warmups;
      if (warmup.length > 0) {
        delete warmup[0].id;
        if (warmup[0].workout_warmup_exercises.length > 0) {
          warmup[0].workout_warmup_exercises.map((item) => {
            delete item.id;
            return item;
          });
        }
      }
      let workout_ct = day.workout_wks;
      if (workout_ct.length > 0) {
        workout_ct.map((obj) => {
          delete obj.id;
          obj.workout_wk_exercises.map((item) => {
            delete item.id;
            return item;
          });
          return obj;
        });
      }

      let workout_stretchs = day.workout_stretchs;
      if (workout_stretchs.length > 0) {
        delete workout_stretchs[0].id;
        if (workout_stretchs[0].workout_stretch_exercises.length > 0) {
          workout_stretchs[0].workout_stretch_exercises.map((item) => {
            delete item.id;
            return item;
          });
        }
      }
    }
  }

  const checkIfEdit = async () => {
    if (props.editData && props.editData.workout_master_id) {
      const data = {
        url: "workout_by_id",
        body: {
          workout_master_id: props.editData.workout_master_id,
        },
      };
      const newData = await postMethod(data);
      // console.log(newData.data.workout_masters);
      if (newData.status == 1) {
        isNotLoading();
        let frmInpts = checkFormatDays(newData.data.day_1);
        let wrkMast = newData.data.workout_masters;

        if (props.editData?.duplicate) {
          rmvIds(frmInpts);
          delete wrkMast.id;
        }

        setWorkoutInputs(frmInpts);

        setWorkoutMasters(wrkMast);
        setEditDataUpdated(true);
        setLoaded(true);
      } else {
        isNotLoading();

        window.alert("Oops! Unable to load data. Try again later!!");
        window.location.href = "/manage-workout";
      }
    } else {
      setLoaded(true);
    }
  };

  const getExerciseList = async (url) => {
    const data = {
      url: url,
    };

    const newData = await getMethod(data);
    if (newData.status == 1) {
      isNotLoading();
      // console.log(newData.data);
      setExerciseList(newData.data);
    } else if (newData.status === false) {
      isNotLoading();
      handleFalseStatus(newData);
    }
  };

  const handleWorkoutName = (e) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        workout_name: e.target.value,
      },
    }));
  };

  const chooseImageFn = (url) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        image_url: url,
      },
    }));
  };

  const gettypedData = (e) => {
    const name = e.target.name;
    const val = e.target.value;

    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        [name]: val,
      },
    }));
  };

  //   warmup code start
  const handleWarmupDesc = (e) => {
    const values = { ...workoutInputs };
    values.workout_warmups[0].description = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleWarmupImage = (url) => {
    const values = { ...workoutInputs };
    values.workout_warmups[0].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_warmup = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...workoutInputs };
    values.workout_warmups[0].workout_warmup_exercises[index][name] = val;
    // console.log("workoutInputs..", workoutInputs);
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseSelect_warmup = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };

    values.workout_warmups[0].workout_warmup_exercises[index][name] = selname;

    values.workout_warmups[0].workout_warmup_exercises[index].exercise_id =
      value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleAddWarmupFields = (index) => {
    const values = { ...workoutInputs };
    const singleObj = values.workout_warmups[0].workout_warmup_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_warmups[0].workout_warmup_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_warmup_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
    // console.log("addFormUpFields", values, index);
  };

  const handleRemoveWarmupFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = copyObj(workoutInputs);
    // console.log(values.workout_warmups[0].workout_warmup_exercises);
    values.workout_warmups[0].workout_warmup_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   warmup code end

  //   workout code start
  const handleWorkoutInput = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    const values = { ...workoutInputs };
    values.workout_wks[index][name] = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleWorkoutInnerImage = (url, ps) => {
    let index = ps.dataid;
    const values = { ...workoutInputs };
    values.workout_wks[index].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseSelect_workout = (e, i, indx, n, SelectedName, id) => {
    let name = n;
    let parent = i;
    let child = indx;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = selname;
    values.workout_wks[parent].workout_wk_exercises[child].exercise_id = value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_workout = (e) => {
    let name = e.target.name;
    let parent = e.target.getAttribute("parentid");
    let child = e.target.getAttribute("childid");
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...workoutInputs };
    values.workout_wks[parent].workout_wk_exercises[child][name] = val;
    setWorkoutInputs({ ...workoutInputs });
  };

  // const getSelections = (data, name) => {
  //   if (name == "select_exercise") {
  //      if(data){

  //      setExercise(data);
  //      let name = data[0].name
  //      let id = data.length>0?data[0].id : '';
  //      const values = { ...workoutInputs };
  //      values.workout_stretchs[0].workout_stretch_exercises[0].exercise_name = name;

  //      values.workout_stretchs[0].workout_stretch_exercises[0].exercise_id = id
  //        // e.target.value;
  //      setWorkoutInputs({ ...workoutInputs });
  //    }
  //    }else if(name == "select_exercise_workouts"){
  //      if(data){
  //        setExerciseWorkout(data);
  //        let name = data[0].name
  //        console.log(name,'name')
  //        let id = data.length>0?data[0].id : '';
  //        const values = { ...workoutInputs };
  //        values.workout_wks[0].workout_wk_exercises[0].exercise_name  = name;
  //        values.workout_wks[0].workout_wk_exercises[0].exercise_id = id
  //          // e.target.value;
  //        setWorkoutInputs({ ...workoutInputs });
  //        console.log(workoutInputs,'wk')
  //      }

  //    }
  //    else if ( name == "select_exercise_strech"){
  //      if(data){
  //        setExerciseStrech(data)

  //        let name = data[0].name

  //        let id = data.length>0?data[0].id : '';
  //        const values = { ...workoutInputs };
  //        values.workout_stretchs[0].workout_stretch_exercises[0].exercise_name= name;

  //   values.workout_stretchs[0].workout_stretch_exercises[0].exercise_id = id
  //          // e.target.value;
  //        setWorkoutInputs({ ...workoutInputs });
  //      }

  //    }
  //  };

  const handleAddWorkoutInnerFields = (e, indx) => {
    // let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");
    const values = { ...workoutInputs };
    // console.log("clicked add", parentIndex, index);
    const singleObj =
      values.workout_wks[parentIndex].workout_wk_exercises[indx];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_wks[parentIndex].workout_wk_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_wk_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveWorkoutInnerFields = (e) => {
    let index = e.target.getAttribute("dataid");
    let parentIndex = e.target.getAttribute("supdataid");

    const values = { ...workoutInputs };
    values.workout_wks[parentIndex].workout_wk_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };

  const handleAddWorkoutFields = (index) => {
    const values = { ...workoutInputs };
    const singleObj = values.workout_wks[index];
    const singlrArr = singleObj.workout_wk_exercises;
    const finalArr = singlrArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    // console.log("add new circuit", finalArr, singlrArr, values);
    if (
      finalArr &&
      singleObj.circuit_name &&
      singleObj.description &&
      singleObj.image_url &&
      singleObj.circuit_round
    ) {
      values.workout_wks.push({
        workout_master_id: "",
        workout_id: "",
        circuit_name: "",
        circuit_round: 1,
        description: "",
        image_url: "",
        workout_wk_exercises: [
          {
            workout_master_id: "",
            workout_id: "",
            workout_wk_id: "",
            exercise_id: "",
            exercise_name: "",
            reps: "",
            es: "",
            el: "",
            sec: "",
            break_time: "",
          },
        ],
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveWorkoutFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...workoutInputs };
    values.workout_wks.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   workout code end
  //   stretch code start
  const handleStretchDesc = (e) => {
    const values = { ...workoutInputs };
    values.workout_stretchs[0].description = e.target.value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleStretchImage = (url) => {
    const values = { ...workoutInputs };
    values.workout_stretchs[0].image_url = url;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseSelect_stretch = (e, i, n, SelectedName, id) => {
    let name = n;
    let index = i;
    let selname = SelectedName;
    let value = id;

    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = selname;

    values.workout_stretchs[0].workout_stretch_exercises[index].exercise_id =
      value;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleExerciseInput_stretch = (e) => {
    let index = e.target.getAttribute("dataid");
    let name = e.target.name;
    let val =
      e.target.value && parseInt(e.target.value) > 0 ? e.target.value : "";
    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises[index][name] = val;
    setWorkoutInputs({ ...workoutInputs });
  };

  const handleAddStretchFields = (e, index) => {
    const values = { ...workoutInputs };
    const singleObj =
      values.workout_stretchs[0].workout_stretch_exercises[index];
    const isAny =
      singleObj.el || singleObj.reps || singleObj.es || singleObj.sec;
    if (singleObj.exercise_name && isAny) {
      values.workout_stretchs[0].workout_stretch_exercises.push({
        workout_master_id: "",
        workout_id: "",
        workout_stretch_id: "",
        exercise_id: "",
        exercise_name: "",
        reps: "",
        es: "",
        el: "",
        sec: "",
        break_time: "",
      });
      setWorkoutInputs(values);
    }
  };

  const handleRemoveStretchFields = (e) => {
    let index = e.target.getAttribute("dataid");
    const values = { ...workoutInputs };
    values.workout_stretchs[0].workout_stretch_exercises.splice(index, 1);
    setWorkoutInputs(values);
  };
  //   stretch code end

  // check validation
  function checkFormDirty(values, dirtyForms) {
    // workout circuit
    // console.log("form ", values);
    values.workout_wks.forEach((x, i) => {
      let status = false;
      if (
        x?.circuit_name?.length > 0 ||
        x?.description?.length > 0 ||
        x?.image_url?.length > 0 ||
        x?.circuit_round > 0
      ) {
        status = true;
      }
      let isAny = [];
      x.workout_wk_exercises.every((x, i) => {
        let isAny =
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0;
        // console.log(isAny);
        if (isAny) {
          status = true;
        }
      });
      if (status) {
        spliceFn(dirtyForms, "workout_ct");
        dirtyForms.push("workout_ct");
      }
    });

    values.workout_stretchs.forEach((x, i) => {
      let status = false;
      let stretchObj = x;
      if (
        stretchObj?.description?.length > 0 ||
        stretchObj?.image_url?.length > 0
      ) {
        status = true;
      }

      let stretchArr = stretchObj?.workout_stretch_exercises;
      stretchArr.every((x, i) => {
        if (
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0
        ) {
          status = true;
        }
      });
      if (status) {
        spliceFn(dirtyForms, "workout_stretch");
        dirtyForms.push("workout_stretch");
      }
    });

    values.workout_warmups.forEach((x, i) => {
      let status = false;
      let warmupObj = x;
      if (
        warmupObj?.description?.length > 0 ||
        warmupObj?.image_url?.length > 0
      ) {
        status = true;
      }

      let warmupArr = warmupObj?.workout_warmup_exercises;
      warmupArr.every((x, i) => {
        if (
          x?.es?.length > 0 ||
          x?.el?.length > 0 ||
          x?.reps?.length > 0 ||
          x?.sec?.length > 0 ||
          x?.exercise_name?.length > 0
        ) {
          status = true;
        }
      });
      if (status) {
        dirtyForms.push("workout_warmup");
      } else {
        spliceFn(dirtyForms, "workout_warmup");
      }
    });
  }

  function spliceFn(dirtyForms, name) {
    let x = dirtyForms.length;
    if (x > 0) {
      for (let i = 0; i < x; i++) {
        let index = dirtyForms.indexOf(name);
        if (index !== -1) {
          dirtyForms.splice(index, 1);
        }
      }
    }
  }

  function removeFormDirty(
    isWarmupValid,
    isWorkoutValid,
    isStretchValid,
    dirtyForms
  ) {
    if (isWarmupValid) {
      spliceFn(dirtyForms, "workout_warmup");
    }
    if (isWorkoutValid) {
      spliceFn(dirtyForms, "workout_ct");
    }
    if (isStretchValid) {
      spliceFn(dirtyForms, "workout_stretch");
    }
  }
  /*------------------------- Preview Popup Content ----------------------------- */
  const previewModalProgram = () => {
    return (
      <>
        <div className="p-2">
          <img src={mobile} alt="mobile" className="mobile" />

          <div className="workout-popup-style">
            <div className="workout-popup-header left-spacing-header">
              <img src={backArrow} alt="backButton" className="backArrow" />
              <div className="log-text">
                <img src={logo} alt="brand-logo" className="brand-logo" />
                <p className="mb-0 sec-medium-font"> Reboot Program </p>
              </div>
              <img
                src={notification}
                alt="NOTIFICATION"
                className="notification-icon-style"
              />
            </div>

            <div className="left-spacing">
              <p className="mb-0 large-font bold"> Featured </p>
              <div className="img_mob_div">
                <img
                  src={
                    workoutInputs.workouts.image_url
                      ? workoutInputs.workouts.image_url
                      : ""
                  }
                  className={"featured_img"}
                  alt="thumb"
                />
                <div className="mb-0 feature-desc-txt small-font">
                  <p className="mb-0 bold"> Yoga Time ! </p>
                  <span className="start-now-content">
                    <p className="mb-0 normal-txt"> start Now </p>
                    <img src={circleRightArrow} alt="circleRightArrow" className="circleRightArrow"/>
                  </span>
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-0 large-font bold"> Favorites </p>
                  <img
                    src={viewMore}
                    alt="View More"
                    className="view-more-icon"
                  />
                </div>
              </div>

              <div className="favorites-tab mb-2">
                <div className="favorites-sub-tab">
                  <img
                    src={simrunChopra3}
                    className={"favorite_img"}
                    alt="thumb"
                  />
                  <span>
                    <p className="mb-0 x-medium-font bold pl-2"> Running </p>
                    <p className="small-font pl-2 mb-2"> 12 workouts </p>
                  </span>
                </div>
                <div className="favorites-sub-tab">
                  <img
                    src={simrunChopra3}
                    className={"favorite_img"}
                    alt="thumb"
                  />
                  <span>
                    <p className="mb-0 x-medium-font bold pl-2"> Running </p>
                    <p className="mb-2 small-font pl-2"> 12 workouts </p>
                  </span>
                </div>
              </div>

              <div>
                <div className="d-flex justify-content-between align-items-center ">
                  <p className="mb-0 large-font bold"> Mobility </p>
                  <img
                    src={viewMore}
                    alt="View More"
                    className="view-more-icon"
                  />
                </div>
              </div>

              <div className="mobility-tab mb-1">
                <div className="mobility-picture">
                  <img
                    src={simrunChopra3}
                    className={"mobility_img"}
                    alt="thumb"
                  />
                  <p className="mb-0 top-mobility-txt small-font x-small-font">
                    {" "}
                    new{" "}
                  </p>
                </div>
                <div className="mobility-picture">
                  <img
                    src={simrunChopra3}
                    className={"mobility_img"}
                    alt="thumb"
                  />
                  <p className="mb-0 top-mobility-txt small-font x-small-font">
                    {" "}
                    new{" "}
                  </p>
                </div>
              </div>

              <div className="mobility-bottom-navbar small-font">
                <div className="footer-style">
                  <img src={homeIcon} alt="HOME" />
                  <p className="mb-0"> Home </p>
                </div>
                <div className="footer-style">
                  <img src={programIcon} alt="PROGRAM" />
                  <p className="mb-0"> Program </p>
                </div>
                <div className="footer-style">
                  <img src={workoutIcon} alt="WORKOUT" />
                  <p className="mb-0"> Workout </p>
                </div>
                <div className="footer-style">
                  <img src={mealIcon} alt="NUTRITION" />
                  <p className="mb-0"> Nutrition </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*--------------------- Another screen ------------------------*/}
        {/* <div className="p-2">
        <img src={mobile} alt="mobile" className="mobile"/>

        <div className="workout-popup-style">

          <div className="workout-popup-header left-spacing-header">
            <img src={backArrow} alt="backButton" className="backArrow"/>
            <div className="log-text">
            <img src={logo} alt="brand-logo" className="brand-logo"/>
            <p className="mb-0 sec-medium-font"> Reboot Program </p>
            </div>
            <img src={notification} alt="NOTIFICATION" className="notification-icon-style"/>
          </div>

          <div className="left-spacing">
            <div className="mobility-workout">
              <p className="mb-0 large-font bold"> Mobility </p>
              <p className="mb-0 small-font"> 12 Workouts </p>
            </div>

            <div className="main-full-stretch-tabs">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      <p className="mb-0 new-mobility-txt"> New </p>
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>

            <div className="main-full-stretch-tabs">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={colorHeart} alt="colorHeart" className="colorHeart"/>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>

            <div className="main-full-stretch-tabs">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      <p className="mb-0 new-mobility-txt"> New </p>
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>
            <div className="main-full-stretch-tabs">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={colorHeart} alt="colorHeart" className="colorHeart"/>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>

            <div className="main-full-stretch-tabs mb-1">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>  

            <div className="mobility-bottom-navbar small-font">
               <div className="footer-style">
                  <img src={homeIcon} alt="HOME"/>
                  <p className="mb-0"> Home </p>
               </div>
               <div className="footer-style">
                  <img src={programIcon} alt="PROGRAM"/>
                  <p className="mb-0"> Program </p>
               </div>
               <div className="footer-style">
                  <img src={workoutIcon} alt="WORKOUT"/>
                  <p className="mb-0"> Workout </p>
               </div>
               <div className="footer-style">
               <img src={mealIcon} alt="NUTRITION"/>
               <p className="mb-0"> Nutrition </p>  
               </div>
            </div>
          </div>

        </div>
        </div> */}

        {/*----------------- Upper Body Stretch -------------------- */}
        {/* <div className="p-2">
        <img src={mobile} alt="mobile" className="mobile"/>

        <div className="workout-popup-style">

          <div className="workout-popup-header left-spacing-header">
            <img src={backArrow} alt="backButton" className="backArrow"/>
            <div className="log-text">
            <img src={logo} alt="brand-logo" className="brand-logo"/>
            <p className="mb-0 sec-medium-font"> Reboot Program </p>
            </div>
            <img src={notification} alt="NOTIFICATION" className="notification-icon-style"/>
          </div>

          <div className="left-spacing">
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0 large-font bold"> Upper Body Stretch  </p>
              <img src={heart} alt="colorHeart" className="colorHeart"/>
            </div>
            <div className="small-font">
              <p className="mb-0"> CIrcuit 1 : Exercise 2 </p>
              <p className="mb-0"> CIrcuit 2 : Exercise 2 </p>
            </div>
            <div>
              <p className="equipment-txt large-font mb-1"> Equipment needed </p>
              <div className="map-icon">
                map icon
              </div>
              <p className="small-font"> Mat </p>
            </div>
            <div className="start-workout-txt w-100">
              <p className="mb-0 workout-txt-button small-font"> Start Workout </p>
            </div>


          
            <div className="main-full-stretch-tabs">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>
            <div className="main-full-stretch-tabs upper-body-margin">
              <div className="full-stretch-tab">
              <img
                  src={simrunChopra3}
                  className={"fullStretch_img"}
                  alt="thumb"
                />
                <div>
                  <span className="d-flex align-items-center ">
                      <p className="mb-0 x-medium-font bold"> Full Stretch </p>
                      
                  </span>
                  <p className="mb-0 sec-medium-font exercise-txt"> 10 Exercise </p>
                </div>
                <img src={rightArrow} alt="RightArrow" className="right-arrow-icon-style"/>
              </div>

            </div>    

            <div className="mobility-bottom-navbar small-font">
               <div className="footer-style">
                  <img src={homeIcon} alt="HOME"/>
                  <p className="mb-0"> Home </p>
               </div>
               <div className="footer-style">
                  <img src={programIcon} alt="PROGRAM"/>
                  <p className="mb-0"> Program </p>
               </div>
               <div className="footer-style">
                  <img src={workoutIcon} alt="WORKOUT"/>
                  <p className="mb-0"> Workout </p>
               </div>
               <div className="footer-style">
               <img src={mealIcon} alt="NUTRITION"/>
               <p className="mb-0"> Nutrition </p>  
               </div>
            </div>

          </div>

        </div>
        </div> */}
      </>
    );
  };

  const handleSubmit = (event, val) => {
    event.preventDefault();
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();

    // }
    setValidated(true);
    checkFormErrors();
  };
  const handleSubmitDraft = () => {
    setValidated(true);
    checkFormErrors("draft");
  };
  const checkFormErrors = (val) => {
    const values = { ...workoutInputs };
    const dirtyForms = [];
    const workOutArr = values.workout_wks;
    const isNameValid = values.workout_wks.every(
      (x, i) =>
        x.circuit_name.length > 0 &&
        x.description.length > 0 &&
        x?.image_url.length > 0 &&
        x?.circuit_round > 0
    );
    var isAll = [];
    workOutArr.forEach((e, i) => {
      e.workout_wk_exercises.forEach((obj) => isAll.push(obj));
    });
    // console.log("all woks esc", isAll);
    const finalWorkOut = isAll.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    const isWorkoutValid = finalWorkOut && isNameValid;

    const stretchObj = values.workout_stretchs[0];
    const stretchArr = stretchObj.workout_stretch_exercises;
    const finalStretch = stretchArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    const isStretchValid =
      finalStretch &&
      stretchObj.description.length > 0 &&
      stretchObj?.image_url.length > 0;

    const warmupObj = values.workout_warmups[0];
    const warmupArr = warmupObj.workout_warmup_exercises;
    const finalWarmup = warmupArr.every((x, i) => {
      const isAny = x.es || x.el || x.reps || x.sec;
      return isAny && x.exercise_name;
    });
    const isWarmupValid =
      finalWarmup &&
      warmupObj.description.length > 0 &&
      warmupObj?.image_url.length > 0;

    const wrkOutName = values.workouts.workout_name;
    const wrkOutDescription = values.workouts.description;
    const wrkOutImage = values.workouts.image_url;
    const wrkOutCategoryName = values.workouts.workout_category_id;
    const isPageNameValid =
      wrkOutDescription.length > 0 &&
      wrkOutName.length > 0 &&
      wrkOutImage.length > 0 &&
      wrkOutCategoryName !== "";

    // console.log("page status", isWarmupValid, isWorkoutValid, isStretchValid);

    if (isPageNameValid) {
      if (isWorkoutValid || isStretchValid || isWarmupValid) {
        checkFormDirty(values, dirtyForms);
        // console.log("bfr ", dirtyForms);
        removeFormDirty(
          isWarmupValid,
          isWorkoutValid,
          isStretchValid,
          dirtyForms
        );
        // console.log("aft ", dirtyForms);
        if (dirtyForms.length == 0) {
          createWorkoutFn(val);
        } else {
          let msgString = `Oops! Please check 
            ${dirtyForms.includes("workout_warmup") ? "Warmup " : ""}
            ${dirtyForms.includes("workout_ct") ? "Workouts " : ""}
            ${dirtyForms.includes("workout_stretch") ? "Stretch " : ""}
            form and update accordingly
          `;
          // scrollToFirstError(dirtyForms);
          props.showToastFn(msgString);
        }
      } else {
        props.showToastFn(
          "Please fill atleast one of the workout form (WarmUp/Workout/Stretch) completely"
        );
      }
    } else {
      props.showToastFn("Please fill the required quick workout details");
    }
  };

  const createWorkoutFn = async (val) => {
    setBtnLoading(true)
    let body = {
      workout_masters: {
        ...workoutMasters,
        workout_master_entry_status:
          val == "draft" ? "unpublished" : "published",
      },
      days: [{ ...workoutInputs }],
    };
    // console.log("form submit", body);
    const data = {
      url: "workout_create_or_update",
      body: body,
    };
    // console.log(data);

    const newData = await postMethod(data);
    props.showToastFn(newData.message);
    setToastMessage(newData.message);
    if (newData.status == 1) {
      setBtnLoading(false)
      setToastMessage(newData.message);
      isNotLoading();
      history.push({
        pathname: "/manage-workout/",
        appState: {
          name: "quick",
        },
      });
    } else {
      isNotLoading();
      setBtnLoading(false)
    }
  };

  const getCategoryNameList = async () => {
    let body = {
      is_featured: "",
      workout_category_id: "",
    };
    const data = {
      url: "get_workout_category_list",
      body: body,
    };
    const newData = await getMethod(data);
    if (newData.status == 1) {
      setCategoryLists(newData.data);
    }
    // setCategoryLists
  };

  useEffect(() => {
    getExerciseList("workout_exercise_list");
    checkIfEdit();
  }, []);
  useEffect(() => {
    getCategoryNameList();
  }, []);

  const userFeaturedChange = () => {
    let active = workoutInputs.workouts.is_featured === false ? true : false;
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        is_featured: active,
      },
    }));
  };

  const setSelectedID = (id) => {
    setWorkoutInputs((prev) => ({
      ...prev,
      workouts: {
        ...prev.workouts,
        workout_category_id: id,
      },
    }));
  };
  // useEffect(() => {
  //   console.log(workoutInputs);
  // }, [workoutInputs, setWorkoutInputs]);

  return (
    <>
      {loaded ? (
        <Form noValidate onSubmit={handleSubmit} className="challenge_form">
          <Row>
            <Col md="4">
              <Form.Group>
                <Form.Label
                  controlId="workout_category_id"
                  className="required"
                >
                  Category Name
                </Form.Label>
                <Form.Control
                  as="select"
                  name="workout_category_id"
                  value={workoutInputs.workouts.workout_category_id}
                  // disabled={props.editData && !props.editData?.edit}
                  onChange={(e) => {
                    setSelectedID(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Select a Category Name
                  </option>
                  {categoryLists &&
                    categoryLists.length > 0 &&
                    categoryLists.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.workout_category_name}
                      </option>
                    ))}
                </Form.Control>
                {(!workoutInputs?.workouts?.workout_category_id ||
                  workoutInputs?.workouts?.workout_category_id == "") &&
                  validated && (
                    <div className="err-feedback">This field is required</div>
                  )}
              </Form.Group>
            </Col>
            {/* <Col md="1"></Col>
            <Col md="4" className="d-flex align-items-center ">
              <Form.Check
                type="switch"
                id="toggle-switch"
                className="ml-50"
                checked={
                  workoutInputs.workouts.is_featured == false ? false : true
                }
                onChange={() => userFeaturedChange()}
              />
              <Form.Label controlId="is_featured">
                Make this workout as a featured
              </Form.Label>
            </Col> */}
          </Row>

          <Row>
            <Col md="6">
              <Form.Group controlId="workout_name" className="mt-3 mb-3">
                <Form.Label className="required">Workout Name</Form.Label>
                <Form.Control
                  type="text"
                  name="workout_name"
                  className="mwx-400"
                  required
                  onChange={handleWorkoutName}
                  value={workoutInputs.workouts.workout_name}
                />
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
                {(!workoutInputs?.workouts?.workout_name ||
                  workoutInputs?.workouts?.workout_name.length == 0) &&
                  validated && (
                    <div className="err-feedback">This field is required</div>
                  )}
              </Form.Group>
            </Col>
            <Col md="6">
              {/* <ImageBoxUpload
                image_url={
                  workoutInputs.workouts.image_url
                    ? workoutInputs.workouts.image_url
                    : ""
                }
                data={chooseImageFn}
                // requiredImage={requiredImage}
              /> */}

              {/* <div
                className="text-center pt-2 cursor-pointer"
                onClick={() => {
                  handlePre();
                }}
              >
                Preview <FontAwesomeIcon icon={faEye} />
              </div> */}

              <ImageBoxFreeCrop
                data={chooseImageFn}
                folder="quick"
                as_w="212"
                as_h="184"
                dataid=""
                image_url={
                  workoutInputs.workouts.image_url
                    ? workoutInputs.workouts.image_url
                    : ""
                }
              />
              {/* <img src={ workoutInputs.workouts.image_url
                    ? workoutInputs.workouts.image_url
                    : ""}/> */}

              {!workoutInputs.workouts.image_url && validated && (
                <div className="err-feedback text-center">
                  This field is required
                </div>
              )}
            </Col>
          </Row>

          <Form.Group controlId="description" className="mt-3 mb-3">
            <Form.Label className="required">
              Add Instruction/Description
            </Form.Label>
            {/* <Editor
            data={gettypedData}
            text={
              workoutInputs.workouts.description
                ? workoutInputs.workouts.description
                : ""
            }
            index="description"
          />
          {!workoutInputs.workouts.description && validated && (
            <div className="err-feedback">This field is required</div>
          )} */}
            <Form.Control
              as="textarea"
              name="description"
              placeholder="Add Short Description/Notes"
              rows={3}
              // ref={textBox1}
              readOnly={
                props.editData && props.editData.editAccess === false
                  ? true
                  : false
              }
              // dataid="description"
              value={workoutInputs.workouts.description}
              required
              onChange={gettypedData}
            />
            <Form.Control.Feedback type="invalid">
              This field is required
            </Form.Control.Feedback>
            {(!workoutInputs?.workouts?.description ||
              workoutInputs?.workouts?.description.length == 0) &&
              validated && (
                <div className="err-feedback">This field is required</div>
              )}
          </Form.Group>

          {/* end of workout main */}

          {/* end of warmup */}
          <div className="workoutBox">
            <h5 className="text-active mb-4">Workouts</h5>
            {workoutInputs.workout_wks.map((item, index, arr) => (
              <div key={index}>
                <div className="d-flex align-items-center mt-4">
                  <div className="d-flex align-items-center">
                    <Form.Group
                      controlId={`circuit_name_${index}-01`}
                      className="mw-250 mb-0 position-relative"
                    >
                      <Form.Control
                        name="circuit_name"
                        type="text"
                        placeholder="Enter Circuit Name"
                        dataid={index}
                        value={item.circuit_name}
                        required
                        onChange={handleWorkoutInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <span className="pl-0 pr-0" style={{ width: "30px" }}>
                      <hr className="dark" />
                    </span>
                    <Form.Group
                      controlId={`circuit_round_${index}-01`}
                      className="mw-100 mb-0 position-relative"
                    >
                      <ToolTipBox text="Circuit Rounds" placement="right">
                        <Button variant="link" className="p-0">
                          <Form.Control
                            name="circuit_round"
                            type="number"
                            min="1"
                            max="100"
                            placeholder="Round"
                            dataid={index}
                            value={item.circuit_round}
                            required
                            onChange={handleWorkoutInput}
                          />
                        </Button>
                      </ToolTipBox>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <span className="w-100 pl-0 pr-0">
                    <hr className="dark" />
                  </span>
                  {index == 0 && arr.length == 1 && (
                    <Button
                      className="ml-auto no-wrap"
                      onClick={() => handleAddWorkoutFields(index)}
                    >
                      Add Circuit
                    </Button>
                  )}
                  {arr.length > 0 && arr.length - 1 != index && (
                    <>
                      <Button
                        className="ml-auto"
                        dataid={index}
                        onClick={handleRemoveWorkoutFields}
                        variant="secondary"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                  {arr.length - 1 == index && index != 0 && (
                    <>
                      <Button
                        className="ml-auto no-wrap"
                        onClick={() => handleAddWorkoutFields(index)}
                      >
                        Add Circuit
                      </Button>
                      <span className="pl-0 pr-0" style={{ width: "1rem" }}>
                        <hr className="dark" />
                      </span>
                      <Button
                        className="ml-auto"
                        dataid={index}
                        onClick={handleRemoveWorkoutFields}
                        variant="secondary"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>

                <Row className="mb-4">
                  <Col sm="7">
                    <Form.Group
                      controlId={`workoutshortdes-01`}
                      className="mt-3 mb-3"
                    >
                      <Form.Label>Add Short Description/Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        placeholder="Add Short Description/Notes"
                        rows={3}
                        // ref={textBox3}
                        readOnly={
                          props.editData && props.editData.editAccess === false
                            ? true
                            : false
                        }
                        dataid={index}
                        value={item.description}
                        required
                        onChange={handleWorkoutInput}
                      />
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm="5">
                    <Form.Label className="ml-3">Circuit Image</Form.Label>
                    {/* <ImageBoxUpload
                      data={handleWorkoutInnerImage}
                      dataid={index}
                      image_url={item.image_url}
                    /> */}
                    <ImageBoxFreeCrop
                      folder="quick"
                      as_w="424"
                      as_h="368"
                      data={handleWorkoutInnerImage}
                      dataid={index}
                      image_url={item.image_url}
                    />
                    {/* {JSON.stringify(item, null, 2)} */}
                    {/* {validated &&
                    (item.image_url == null || item.image_url == "") && (
                      <div className="err-feedback">This field is required</div>
                    )} */}
                  </Col>
                </Row>

                {item.workout_wk_exercises &&
                  item.workout_wk_exercises.map((item, indx, arr) => (
                    <Form.Row
                      className="align-items-center form-m-0 mb-3"
                      key={indx}
                    >
                      <Col md="4">
                        <Form.Group
                          controlId={`workout-m-exercise-${indx}-${index}-01`}
                          className="w-100"
                        >
                          {/* <Form.Control
                            as="select"
                            name="exercise_name"
                            placeholder="Select Exercises"
                            parentid={index}
                            childid={indx}
                            value={item.exercise_id}
                            required
                            onChange={handleExerciseSelect_workout}
                          >
                            <option value="">Select Exercises</option>
                            {exerciseList.map((item, index) => (
                              <option
                                value={item.id}
                                selname={item.name}
                                key={index}
                              >
                                {item.name}
                              </option>
                            ))}
                          </Form.Control> */}
                          <Select
                            showSearch
                            name="exercise_name"
                            dataid={index}
                            className={
                              props.editData &&
                              props.editData.editAccess === false
                                ? "readOnly"
                                : "select_filter"
                            }
                            // defaultValue="Select Exercise"
                            value={
                              item.exercise_id != ""
                                ? item.exercise_id
                                : "Select Exercise"
                            }
                            optionFilterProp="children"
                            onChange={(e, option) => {
                              handleExerciseSelect_workout(
                                e,
                                index,
                                indx,
                                "exercise_name",
                                option.selname,
                                option.value
                              );
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {exerciseList.map((item, index) => (
                              <Select.Option
                                value={item.id}
                                selname={item.name}
                                key={index}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                          {/* <DropdownSingle
            options={exerciseList}
            value={exerciseWorkout}
            getSelections={getSelections}
            onChange={handleExerciseSelect_workout}
            name="select_exercise_workouts"
            placeholder="Select Exercise"

          /> */}
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="col-md">
                        <Form.Group
                          controlId={`workout-m-reps-${indx}-${index}-01`}
                        >
                          <Form.Control
                            type="number"
                            name="reps"
                            min="1"
                            placeholder="Reps"
                            parentid={index}
                            childid={indx}
                            required
                            disabled={
                              item.es || item.el || item.sec ? true : false
                            }
                            value={item.reps}
                            onChange={handleExerciseInput_workout}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required (any)
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="col-md">
                        <Form.Group
                          controlId={`workout-m-es-${indx}-${index}-01`}
                        >
                          <Form.Control
                            type="number"
                            name="es"
                            min="1"
                            placeholder="E/S"
                            parentid={index}
                            required
                            disabled={
                              item.sec || item.el || item.reps ? true : false
                            }
                            value={item.es}
                            childid={indx}
                            onChange={handleExerciseInput_workout}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required (any)
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="col-md">
                        <Form.Group
                          controlId={`workout-m-el-${indx}-${index}-01`}
                        >
                          <Form.Control
                            type="number"
                            name="el"
                            min="1"
                            placeholder="E/L"
                            parentid={index}
                            childid={indx}
                            value={item.el}
                            required
                            disabled={
                              item.es || item.sec || item.reps ? true : false
                            }
                            onChange={handleExerciseInput_workout}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required (any)
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="col-md">
                        <Form.Group
                          controlId={`workout-m-secs-${indx}-${index}-01`}
                        >
                          <Form.Control
                            type="number"
                            name="sec"
                            min="1"
                            placeholder="Secs"
                            parentid={index}
                            childid={indx}
                            value={item.sec}
                            required
                            disabled={
                              item.es || item.el || item.reps ? true : false
                            }
                            onChange={handleExerciseInput_workout}
                          />
                          <Form.Control.Feedback type="invalid">
                            Required (any)
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col className="col-md">
                        <Form.Group
                          controlId={`workout-m-break-${indx}-${index}-01`}
                        >
                          <Form.Control
                            type="number"
                            name="break_time"
                            min="1"
                            placeholder="Break Time"
                            parentid={index}
                            childid={indx}
                            value={item.break_time}
                            onChange={handleExerciseInput_workout}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="1" className="text-right">
                        {indx == 0 && arr.length == 1 && (
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            dataid={indx}
                            supdataid={index}
                            onClick={(e) =>
                              handleAddWorkoutInnerFields(e, indx)
                            }
                            className="font-20"
                          />
                        )}
                        {arr.length > 0 && arr.length - 1 != indx && (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            dataid={indx}
                            supdataid={index}
                            onClick={handleRemoveWorkoutInnerFields}
                            className="font-20"
                          />
                        )}
                        {arr.length - 1 == indx && indx != 0 && (
                          <>
                            <FontAwesomeIcon
                              icon={faPlusCircle}
                              dataid={indx}
                              supdataid={index}
                              onClick={(e) =>
                                handleAddWorkoutInnerFields(e, indx)
                              }
                              className="font-20 mr-2"
                            />
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              dataid={indx}
                              supdataid={index}
                              onClick={handleRemoveWorkoutInnerFields}
                              className="font-20"
                            />
                          </>
                        )}
                      </Col>
                    </Form.Row>
                  ))}

                <hr />
              </div>
            ))}
          </div>
          {/* end of workout */}

          <div className="mainCtrls d-flex mt-5">
            <div className="float-left">
              <Buttons
                variant="secondary"
                onClick={handleSubmitDraft}
                className="mr-3 btn-secondary"
                loading={btnLoading}
                disabled={btnLoading}
              >
                Save Draft
              </Buttons>
            </div>
            <div className="ml-auto pt-3">
              <Button
                variant="secondary"
                onClick={() => {
                  history.push({
                    pathname: "/manage-workout/",
                    appState: {
                      name: "quick",
                    },
                  });
                }}
                className="mr-3"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                name="submit"
                className="mr-3"
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <h5 className="mt-3">Loading... Please wait</h5>
      )}
      <div className="toastMessage">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={4000}
          autohide
        >
          <Toast.Body>
            {toastMessage}
            {". "}
          </Toast.Body>
        </Toast>
      </div>

      <ModalPop
        size="sm"
        // modalSize = "modal-90w"
        class={"modal-padding"}
        show={showPopup}
        onHide={handlePre}
        modalcontent={previewModalProgram()}
        modalhead="Preview Image"
      />
    </>
  );
}
